export default {
  debounce(callbackFn, delay = 1000) {
    let timeout

    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        callbackFn(args)
      }, delay);
    }
  }
}