<template>
  <modal-dialog ref="myModal" @submit.prevent="confirmRemoval">
    <template v-slot:modal-title>Remove From League?</template>
    <template v-slot:modal-contents>
      <p>
        Are you sure you want to remove
        <span class="bold">{{ userToRemove.name }}, </span
        ><a href="mailto:{{ userToRemove.email }}">{{ userToRemove.email }}</a> from the league, {{ leagueName }}?
      </p>
    </template>
    <template v-slot:modal-actions>
      <rebel-button button-type="submit" type="primary" color="danger" text="Remove From League"></rebel-button>
      <a @click.prevent="cancel">Cancel</a>
    </template>
  </modal-dialog>
</template>

<script>
import { recordLeagueUserRemove } from '@/services/GoogleTagManagerService.js'
import emitEvents from '@/utils/emitEvents'
import RebelButton from '@/components/RebelButton.vue'
import ModalDialog from '@/components/ModalDialog.vue'

export default {
  components: {
    RebelButton,
    ModalDialog
  },

  props: ['eventCategory', 'userToRemove', 'leagueName'],

  emits: [emitEvents.REMOVE_LEAGUE_MEMBER],

  methods: {
    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    confirmRemoval() {
      this.$emit(emitEvents.REMOVE_LEAGUE_MEMBER, this.userToRemove)
      recordLeagueUserRemove({ eventCategory: this.eventCategory || 'unknown' })
      this.closeModal()
    }
  }
}
</script>
