<template>
  <modal-dialog ref="myModal" @submit.prevent="updatePassword">
    <template v-slot:modal-title>Change Password</template>
    <template v-slot:modal-contents>
      <div class="form-input">
        <label for="old-password">Old Password</label>
        <input id="old-password" :type="showingPassword ? 'text' : 'password'"
          placeholder="Old Password" v-model="oldPassword" required />
        <i class="fa fa-eye-slash" v-show="!showingPassword" @click="showingPassword = true"></i>
        <i class="fa fa-eye" v-show="showingPassword" @click="showingPassword = false"></i>
      </div>

      <div class="form-input">
        <label for="new-password">New Password</label>
        <input id="new-password" :type="showingPassword ? 'text' : 'password'"
          placeholder="New Password" v-model="newPassword" :required="dataConstraints.user.password.required"
          :minlength="dataConstraints.user.password.minlength" :maxlength="dataConstraints.user.password.maxlength" />
        <i class="fa fa-eye-slash" v-show="!showingPassword" @click="showingPassword = true"></i>
        <i class="fa fa-eye" v-show="showingPassword" @click="showingPassword = false"></i>
      </div>

      <div class="form-input">
        <label for="confirm-new-password">Confirm New Password</label>
        <input id="confirm-new-password" :type="showingPassword ? 'text' : 'password'"
          placeholder="Confirm New Password" v-model="confirmNewPassword"
          :required="dataConstraints.user.password.required" :minlength="dataConstraints.user.password.minlength"
          :maxlength="dataConstraints.user.password.maxlength" />
        <i class="fa fa-eye-slash" v-show="!showingPassword" @click="showingPassword = true"></i>
        <i class="fa fa-eye" v-show="showingPassword" @click="showingPassword = false"></i>
      </div>
    </template>
    <template v-slot:modal-actions>
      <rebel-button button-type="submit" type="primary" color="default" :is-loading="isUpdating" text="Update"></rebel-button>

      <rebel-button type="borderless" color="default" text="Cancel" @click="cancel"></rebel-button>
    </template>
  </modal-dialog>
</template>

<script>
import dataConstraints from '@/utils/dataConstraints.js'
import authService from '@/services/AuthenticationService.js'

import ModalDialog from '@/components/ModalDialog.vue'
import RebelButton from '@/components/RebelButton.vue'

export default {
  setup() {
    return {
      dataConstraints
    }
  },

  components: {
    ModalDialog,
    RebelButton
  },

  data() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      isUpdating: false,
      showingPassword: false
    }
  },

  methods: {
    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    async updatePassword() {
      if (!this.oldPassword || !this.newPassword || !this.confirmNewPassword) {
        alert('All fields are required')
        return
      }

      if (this.newPassword !== this.confirmNewPassword) {
        alert('New passwords do not match')
        return
      }

      try {
        this.isUpdating = true
        // send request to server
        await authService.updatePassword(this.oldPassword, this.newPassword)

        // close if successful
        this.closeModal()
      } catch (error) {
        // show error message if not
        if (error.response.status === 401) {
          alert('Incorrect password')
        } else {
          alert('Something went wrong. Please try again.')
        }
      } finally {
        this.isUpdating = false
      }
    }
  }
}
</script>

