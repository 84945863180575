<template>
  <modal-dialog ref="myModal" @submit.prevent="confirm">
    <template v-slot:modal-title>Remove Competitor Group?</template>
    <template v-slot:modal-contents>
      <p>
        This will delete all competitors and matchups within the group <span class="bold">{{ groupName }}</span>.
      </p>
    </template>
    <template v-slot:modal-actions>
      <rebel-button button-type="submit" type="primary" color="danger" text="Remove Competitor Group" />
      <a @click.prevent="cancel">Cancel</a>
    </template>
  </modal-dialog>
</template>

<script>
import RebelButton from '@/components/RebelButton.vue'
import ModalDialog from '@/components/ModalDialog.vue'

import emitEvents from '@/utils/emitEvents'

export default {
  components: {
    RebelButton,
    ModalDialog
  },

  props: {
    groupIndex: {
      type: Number,
      required: true
    },

    groupName: {
      type: String,
      required: true
    }
  },

  emits: [emitEvents.REMOVE_COMPETITOR_GROUP],

  methods: {
    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    confirm() {
      this.$emit(emitEvents.REMOVE_COMPETITOR_GROUP, this.groupIndex)
      this.closeModal()
    }
  }
}
</script>
