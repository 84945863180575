<template>
  <div v-if="event">
    <admin-page-pick-em ref="adminPageComponent" v-if="eventTypes.isPickEm(event.typeId)" :event-data="event" />
    <admin-page-brackets ref="adminPageComponent" v-else :event-data="event" />
  </div>
</template>

<script>
import eventService from '@/services/EventService.js'
import eventTypes from '../utils/eventTypes.js'

import AdminPageBrackets from '../components/event-admin/brackets/AdminPageBrackets.vue'
import AdminPagePickEm from '../components/event-admin/pick-em/AdminPagePickEm.vue'

import { useEventAdminStore } from '@/stores/eventAdmin'

export default {
  setup() {
    return {
      eventAdminStore: useEventAdminStore(),
      eventTypes,
    }
  },

  components: {
    AdminPageBrackets,
    AdminPagePickEm
  },

  async created() {
    try {
      const response = await eventService.getById(this.eventId)
      this.event = { ...response.data }

      this.eventAdminStore.initialize(this.event)
    } catch {
      alert('Something went wrong fetching event data')
    }
  },

  computed: {
    eventId() {
      return this.$route.params.eventId
    }
  },

  data() {
    return {
      event: undefined
    }
  },

  async beforeRouteLeave() {
    if (this.$refs.adminPageComponent.beforeRouteLeaveImpl != null) {
      return await this.$refs.adminPageComponent.beforeRouteLeaveImpl()
    }
    
    return true
  },
}
</script>
