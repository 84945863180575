<template>
  <modal-dialog ref="myModal" @submit.prevent="confirm">
    <template v-slot:modal-title>Customize Design</template>
    <template v-slot:modal-contents>
      <div class="form-input">
        <label for="opacity">Opacity %</label>
        <div class="flex align-center gap-1">
          <input id="opacity" class=width-100 type="range" min="0" max="100" step="1" v-model.number="opacity" />
          <div id="opacity-value">
            {{ opacity }}
          </div>
        </div>
      </div>

      <div class="form-input">
        <label for="saturation">Saturation %</label>
        <div class="flex align-center gap-1">
          <input id="saturation" class=width-100 type="range" min="0" max="100" step="1" v-model.number="saturation" />
          <div id="saturation-value">
            {{ saturation }}
          </div>
        </div>
      </div>

      <div class="flex space-between align-center width-100">
        <div class="flex col">
          <label for="gaussian-blur">Gaussian Blur</label>
          <span># of pixels</span>
        </div>
        <input id="gaussian-blur" type="number" placeholder="0" min="0" step="1" v-model.number="gaussianBlur" />
      </div>

      <div class="flex space-between align-center width-100">
        <label for="blend-mode">Blend Mode Multiply</label>
        <toggle-button id="blend-mode" :value="blendMode" @toggled="blendMode = !blendMode"></toggle-button>
      </div>
    </template>
    <template v-slot:modal-actions>
      <rebel-button button-type="submit" type="primary" color="default" text="Save Changes"
        :is-loading="isLoading"></rebel-button>
      <a @click.prevent="cancel">Cancel</a>
    </template>
  </modal-dialog>
</template>

<script>
import emitEvents from '@/utils/emitEvents'
import eventService from '@/services/EventService'
import RebelButton from '@/components/RebelButton.vue'
import ModalDialog from '@/components/ModalDialog.vue'
import ToggleButton from '@/components/ToggleButton.vue'

export default {
  props: {
    eventId: {
      type: Number,
      required: true
    },
    currBlendMode: {
      type: Boolean,
      required: true
    },
    currGaussianBlur: {
      type: Number,
      required: true,
    },
    currOpacity: {
      type: Number,
      required: true,
    },
    currSaturation: {
      type: Number,
      required: true,
    }
  },

  components: {
    RebelButton,
    ModalDialog,
    ToggleButton
  },

  data() {
    return {
      isLoading: false,

      blendMode: this.currBlendMode,
      gaussianBlur: this.currGaussianBlur,
      opacity: this.currOpacity,
      saturation: this.currSaturation
    }
  },

  emits: [emitEvents.DESIGN_CUSTOMIZED],

  methods: {
    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    async confirm() {
      this.isLoading = true

      try {
        const dto = {
          eventId: this.eventId,
          blendMode: this.blendMode,
          gaussianBlur: this.gaussianBlur,
          opacity: this.opacity,
          saturation: this.saturation
        }

        await eventService.customizeDesign(this.eventId, dto)
        this.$emit(emitEvents.DESIGN_CUSTOMIZED, dto)

        this.closeModal()
      } catch {
        alert('Something went wrong')
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped>
#gaussian-blur {
  text-align: right;
  width: 5rem;
}

#opacity-value,
#saturation-value {
  padding-right: 2px;
  text-align: right;
  width: 30px;
}
</style>