<template>
  <modal-dialog ref="myModal" @submit.prevent="confirm">
    <template v-slot:modal-title>Customize Scoring</template>
    <template v-if="isLoading" v-slot:modal-contents>
      <div class="flex justify-center align-center">
        <svg class="loading-icon" style="height:100px" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"
          xml:space="preserve">
          <path fill="#1B2A39"
            d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
            <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50"
              to="360 50 50" repeatCount="indefinite" />
          </path>
        </svg>
      </div>
    </template>
    <template v-else v-slot:modal-contents>
      <h2 class="heading2 underline form-input">Picking Correct Winner</h2>
      <div class="form-input">
        <label for="matness-points">MATNESS Points</label>
        <input id="matness-points" type="number" v-model.lazy="scoring.points"
          :required="dataConstraints.scoring.matnessPoints.required" />
      </div>

      <template v-if="!disableAddRemove || scoring.victoryClassifications.length">
        <h2 class="heading2 underline form-input">Classification of Victory</h2>
        <div class="form-input">
          <label>Add Classification of Victory?</label>
          <div class="flex">
            <span class="body2">
              Users will be able to make additional picks for bonus points, and classification of victory will be
              displayed
              in results.
            </span>
            <toggle-button :value="scoring.addClassificationOfVictory"
              @toggled="addClassificationOfVictoryToggled"></toggle-button>
          </div>
        </div>
      </template>

      <template v-if="scoring.addClassificationOfVictory">
        <div class="flex gap-0-75 width-100" v-for="(vc, index) in scoring.victoryClassifications" :key="index">
          <div class="form-input grow" :class="{ 'classification-of-victory': isTeamEvent }">
            <!-- class here to cause width to adjust -->
            <label :for="`classification-of-victory${index}`">Classification of Victory</label>
            <input :id="`classification-of-victory${index}`" type="text" v-model.lazy="vc.outcomeName" maxlength="14"
              :required="dataConstraints.scoring.classificationOfVictory.name" />
          </div>

          <div class="form-input actual-points" v-if="isTeamEvent">
            <label :for="`actual-points${index}`">Actual Pts</label>
            <input :id="`actual-points${index}`" type="number" v-model.number="vc.teamPoints"
              :required="dataConstraints.scoring.classificationOfVictory.actualPoints" />
          </div>

          <div class="form-input matness-points">
            <label :for="`matness-points${index}`">MATNESS Pts</label>
            <input :id="`matness-points${index}`" type="number" v-model.number="vc.points"
              :required="dataConstraints.scoring.classificationOfVictory.matnessPoints" />
          </div>

          <div class="color-action flex col justify-center" v-if="!disableAddRemove">
            <span>&nbsp;</span>
            <svg class="pointer" @click="scoring.victoryClassifications.splice(index, 1)" width="20" height="20"
              viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.28033 5.21967C5.98744 4.92678 5.51256 4.92678 5.21967 5.21967C4.92678 5.51256 4.92678 5.98744 5.21967 6.28033L8.93934 10L5.21967 13.7197C4.92678 14.0126 4.92678 14.4874 5.21967 14.7803C5.51256 15.0732 5.98744 15.0732 6.28033 14.7803L10 11.0607L13.7197 14.7803C14.0126 15.0732 14.4874 15.0732 14.7803 14.7803C15.0732 14.4874 15.0732 14.0126 14.7803 13.7197L11.0607 10L14.7803 6.28033C15.0732 5.98744 15.0732 5.51256 14.7803 5.21967C14.4874 4.92678 14.0126 4.92678 13.7197 5.21967L10 8.93934L6.28033 5.21967Z"
                fill="#2860F0" />
            </svg>
          </div>
        </div>

        <div class="flex width-100" @click="addAnother" v-if="!disableAddRemove">
          <div class="flex pointer color-action">
            <plus-icon class="icon-20" />
            <h3 class="heading3 color-action">
              Add Another
            </h3>
          </div>
        </div>
      </template>
    </template>
    <template v-if="!isLoading" v-slot:modal-actions>
      <span v-if="errorMessages.length" class="color-danger">{{ errorMessages }}</span>
      <rebel-button button-type="submit" type="primary" color="default" text="Save" :is-loading="isSaving"></rebel-button>
      <a @click.prevent="cancel">Cancel</a>
    </template>
  </modal-dialog>
</template>

<script>
import eventService from '@/services/EventService.js'
import dataConstraints from '@/utils/dataConstraints'

import { PlusIcon } from '@heroicons/vue/20/solid'
import ModalDialog from '@/components/ModalDialog.vue'
import RebelButton from '@/components/RebelButton.vue'
import ToggleButton from '@/components/ToggleButton.vue'
import emitEvents from '@/utils/emitEvents'

export default {
  setup() {
    return {
      dataConstraints
    }
  },

  async created() {
    this.isLoading = true
    try {
      const customScoringData = (await eventService.getCustomScoringForEvent(this.eventId)).data

      this.scoring.points = customScoringData.points
      this.scoring.addClassificationOfVictory = customScoringData.addClassificationOfVictory
      this.scoring.victoryClassifications = customScoringData.matchOutcomeOptions
    } finally {
      this.isLoading = false
    }
  },

  components: {
    ModalDialog,
    PlusIcon,
    RebelButton,
    ToggleButton
  },

  props: ['eventId', 'isTeamEvent', 'disableAddRemove'],

  emits: [emitEvents.SCORING_CUSTOMIZED],

  data() {
    return {
      isLoading: false,
      isSaving: false,

      errorMessages: '',

      scoring: {}
    }
  },

  methods: {
    addAnother() {
      this.scoring.victoryClassifications.push({
        outcomeName: '',
        teamPoints: '',
        points: ''
      });
    },

    addClassificationOfVictoryToggled() {
      this.scoring.addClassificationOfVictory = !this.scoring.addClassificationOfVictory
    },

    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    async confirm() {
      if (!this.isValid()) return

      this.isSaving = true

      try {
        const dto = {
          eventId: this.eventId,
          points: this.scoring.points,
          addClassificationOfVictory: this.scoring.addClassificationOfVictory,
          matchOutcomeOptions: this.scoring.victoryClassifications.map((vc, vcIndex) => {
            return {
              eventId: this.eventId,
              id: vc.id,
              outcomeName: vc.outcomeName,
              points: vc.points,
              teamPoints: vc.teamPoints,
              sortOrder: vcIndex + 1
            }
          })
        }

        await eventService.customizeScoring(this.eventId, dto)
        this.$emit(emitEvents.SCORING_CUSTOMIZED, dto)

        this.closeModal()
      } catch {
        alert('Something went wrong')
      } finally {
        this.isSaving = false
      }
    },

    hasDuplicates(array) {
      return (new Set(array)).size !== array.length;
    },

    isValid() {
      this.errorMessages = ''

      if (this.scoring.addClassificationOfVictory) {
        if (this.scoring.victoryClassifications.length < 2) {
          this.errorMessages = 'Must have at least two classification of victory if toggled on.'
          return false
        }

        if (this.hasDuplicates(this.scoring.victoryClassifications.map(vc => vc.outcomeName.toLowerCase()))) {
          this.errorMessages = 'Each outcome name must be unique.'
          return false
        }
      }

      return true
    }
  }
}
</script>

<style scoped>
.heading4,
/* TODO: should these be global styles */
label {
  color: var(--color-gray4);
  font-size: .75rem;
}

.classification-of-victory {
  width: 141px;
}

.actual-points,
.matness-points {
  width: 70px;
}
</style>