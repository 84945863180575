<template>
  <div class="flex align-baseline gap-0-25">
    <span class="heading4 gray3">{{ value }}</span>
    <span class="caption gray2">{{ label }}</span>
  </div>
</template>

<script>
export default {

  props: {
    label: {
      type: String,
      required: true
    },

    value: {
      required: true
    }
  }

}
</script>
