<template>
<div class="flex row align-center gap-1">
  <div class="flex row align-center gap-0-25">
    <div class="box color1"></div>
    <span class="caption color-gray3">{{ label1 }}</span>
  </div>
  <div class="flex row align-center gap-0-25">
    <div class="box color2"></div>
    <span class="caption color-gray3">{{ label2 }}</span>
  </div>
</div>
</template>

<script>
export default {
  props: {
    label1: {
      type: String,
      required: true
    },
    label2: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.box {
  border-radius: 0.25rem;
  width: 1rem;
  height: 1rem;
}
.color1 {
  background-color: var(--color-action);
  
}
.color2 {
  background-color: var(--color-gray1);
}
</style>