import { defineStore } from 'pinia'


export const usePickEmStore = defineStore('pickem', {
  state: () => ({
    teamsBasedEvent: {
      isValid: false
    }
  }),

  getters: {

  },

  actions: {
    setIsValid(isValid) {
      this.teamsBasedEvent.isValid = isValid
    }
  },

  persist: {
    enabled: false,
  }
})
