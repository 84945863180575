import axios from 'axios'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPersist from 'pinia-plugin-persist'
import * as Sentry from "@sentry/vue";

import App from './App.vue'
import router from './router'

axios.defaults.baseURL = import.meta.env.VITE_API_URL

const app = createApp(App)

const pinia = createPinia()

if (import.meta.env.VITE_SENTRY == 'on') {
  Sentry.init({
    app: app,
    dsn: "https://25b898aad0f0c9d9bdc04bc2a1e99c15@o4506853839732736.ingest.sentry.io/4506853840977920",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

pinia.use(piniaPersist)

app.use(pinia)
app.use(router)

// automatic logout and redirect to login page when a 401 is received
import CommonRoutes from '@/services/CommonRoutes'
import { useAuthenticationStore } from '@/stores/authentication'
const authStore = useAuthenticationStore()

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response != null) {
    if (error.response.status === 404) {
      router.push({ name: 'not-found' })
    }

    if (error.response.status === 401
      && !error.request.responseURL.endsWith(CommonRoutes.CHANGE_PASSWORD)
      && !error.request.responseURL.endsWith(CommonRoutes.LOGIN)) {

      if (authStore.isLoggedIn) {
        authStore.logout()
        router.push({ name: 'login', query: { redirect: router.currentRoute.value.fullPath, 'session-expired': true } })
      } else {
        router.push({ name: 'login', query: { redirect: router.currentRoute.value.fullPath } })
      }

    }
  }
  return Promise.reject(error)
})

// import axiosRetry from 'axios-retry'
// axiosRetry(axios, { retries: 3 })


// modify Google Analytics
import { gtag } from '@/services/GoogleTagManagerService.js'

gtag('set', 'user_data', {
  "email": authStore?.loggedInUser?.user?.email || ''
});

app.mount('#app')
