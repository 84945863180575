<template>
  <modal-dialog ref="myModal" @submit.prevent="confirm">
    <template v-slot:modal-title>Copy Picks</template>
    <template v-slot:modal-contents>
      <p>
        Would you like to copy over all of your picks to/from another league?
      </p>

      <div class="layout-form-list">
        <div class="form-input">
          <label for="league-to-copy-from">League to Copy From</label>
          <select id="league-to-copy-from" v-model="copyPicksDetails.fromLeagueId" required>
            <option value="-1" disabled hidden>Select a league</option>
            <option v-for="league in leagues" :value="league.leagueId" :key="league.leagueId">{{ league.leagueName }}
            </option>
          </select>
        </div>

        <div class="form-input">
          <label for="league-to-copy-to">League to Copy To</label>
          <select id="league-to-copy-to" v-model="copyPicksDetails.toLeagueId" required>
            <option value="-1" disabled hidden>Select a league</option>
            <option v-for="league in leagues" :value="league.leagueId" :key="league.leagueId">{{ league.leagueName }}
            </option>
          </select>
        </div>

        <div class="flex gap-1">
          <div class="flex col"> <!-- first half -->
            <div class="flex align-center"
              v-for="(weightClass, i) in weightClasses.weightClasses.filter((w, j) => j < weightClasses.weightClasses.length / 2)"
              :key="i">
              <input :id="`wc-${weightClass}`" type="checkbox" :value="weightClass" v-model="copyPicksDetails.weightClassesToCopy">
              <label class="body2" :for="`wc-${weightClass}`">{{ weightClass }} {{ weightClasses.units }}</label>
            </div>
          </div>
          <div class="flex col"> <!-- second half -->
            <div class="flex align-center"
              v-for="(weightClass, i) in weightClasses.weightClasses.filter((w, j) => j >= weightClasses.weightClasses.length / 2)"
              :key="i">
              <input :id="`wc-${weightClass}`" type="checkbox" :value="weightClass" v-model="copyPicksDetails.weightClassesToCopy">
              <label class="body2" :for="`wc-${weightClass}`">{{ weightClass }} {{ weightClasses.units }}</label>
            </div>
          </div>
        </div>
      </div>

      <p>
        This will overwrite any picks that you have already made in your league, <span class="bold">{{ toLeagueName
        }}</span>.
      </p>

      <p>
        This is a one-time copy/paste action. Any future changes that you make to either bracket will not impact the other
        bracket.
      </p>
    </template>
    <template v-slot:modal-actions>
      <rebel-button button-type="submit" :is-loading="saveInProgress" type="primary" color="default"
        text="Copy Over My Picks"></rebel-button>
      <a @click.prevent="cancel">Cancel</a>

      <p class="color-danger" v-if="errorMessages.length">
        {{ errorMessages }}
      </p>
    </template>
  </modal-dialog>
</template>

<script>
import emitEvents from '../../utils/emitEvents'
import leagueService from '../../services/LeagueService'

import RebelButton from '@/components/RebelButton.vue'
import ModalDialog from '@/components/ModalDialog.vue'

export default {
  components: {
    RebelButton,
    ModalDialog
  },

  emits: [emitEvents.COPY_PICKS_COMPLETE],

  data() {
    return {
      errorMessages: '',

      saveInProgress: false,

      copyPicksDetails: {
        fromLeagueId: -1,
        toLeagueId: -1,

        weightClassesToCopy: [...this.weightClasses.weightClasses]
      },
    }
  },

  props: {
    leagues: {
      type: Array,
      required: true,
      default: () => []
    },

    weightClasses: {
      type: Object,
      required: true,
      defaultValue: {
        weightClasses: [],
        units: 'lbs'
      }
    }
  },

  computed: {
    copyAllWeightClasses() {
      return this.weightClasses.weightClasses.length === this.copyPicksDetails.weightClassesToCopy.length
    },

    toLeagueName() {
      if (!this.copyPicksDetails.toLeagueId || this.copyPicksDetails.toLeagueId === -1) return ''

      return this.leagues.find(l => l.leagueId == this.copyPicksDetails.toLeagueId).leagueName
    }
  },

  methods: {
    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    async confirm() {
      this.saveInProgress = true

      if (this.hasErrors()) {
        this.saveInProgress = false
        return
      }

      try {
        const dto = {
          ...this.copyPicksDetails,
          copyAllWeightClasses: this.copyAllWeightClasses
        }

        await leagueService.copyPicks(dto)
        this.$emit(emitEvents.COPY_PICKS_COMPLETE)
        this.$emit(emitEvents.CLOSE)
      } catch (e) {
        console.log(e)
        alert('Something went wrong')
      } finally {
        this.saveInProgress = false
      }
    },

    hasErrors() {
      this.errorMessages = ''

      if (this.copyPicksDetails.fromLeagueId == -1 || this.copyPicksDetails.toLeagueId == -1) {
        this.errorMessages = 'Both Leagues to Copy From and Leagues to Copy To are required to have selections.'
        return true
      }

      // only include this if there are weight classes
      if (this.weightClasses.length && this.copyPicksDetails.weightClassesToCopy.length === 0) {
        this.errorMessages = 'At least one weight class must be selected.'
        return true
      }

      if (this.copyPicksDetails.fromLeagueId === this.copyPicksDetails.toLeagueId) {
        this.errorMessages = 'Leagues to Copy From and Leagues to Copy To must be different leagues.'
        return true
      }

      return false
    }
  }
}
</script>

