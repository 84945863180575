<template>
  <div v-if="isLoaded" class="flex col gap-1-5">
    <header-action-bar title="Manage Event" :breakpointOuter="512">
      <template v-slot:linkBackTo>
        <link-back-to :router-link-to="{ name: 'events' }" page-name="Events" />
      </template>
      <template v-slot:gearFlyOutMenu>
        <fly-out-actions-menu>
          <template v-slot:icon>
            <cog-8-tooth-icon class="icon-24" />
          </template>
          <template v-slot:list-items>
            <li>
              <a @click.prevent="editEventDetails">Edit Event Details</a>
            </li>
            <li v-if="useEventLogos">
              <a @click.prevent="eventArtImageUploader.isShowing = true">{{ hasEventImage ? 'Edit' : 'Add' }} Event
                Image</a>
            </li>
            <li v-if="event.upcoming">
              <a data-testid="customize-weight-classes" @click.prevent="customizeWeightClasses">Customize Weight
                Classes</a>
            </li>
          </template>
        </fly-out-actions-menu>
      </template>
      <template v-if="!event.published" v-slot:primaryAction1>
        <rebel-button :disabled="!publishEventEnabled" :is-loading="eventAdminStore.isPublishing" color="danger"
          type="primary" text="Publish Brackets" @click="eventAdminStore.publishEvent(eventId)"></rebel-button>
      </template>
      <template v-else-if="event.published && event.participationEnded" v-slot:primaryAction1>
        <rebel-button :disabled="!publishResultsEnabled" :is-loading="eventAdminStore.isPublishing" color="danger"
          type="primary" text="Publish Results" @click="tryPublishResults"></rebel-button>
      </template>
      <template v-if="event.published" v-slot:secondaryAction1>
        <league-shortcut-button :eventData="eventData" />
      </template>
    </header-action-bar>

    <div class="event-card-container">
      <event-card-header :event="event" :darkBackground="false"></event-card-header>
    </div>

    <div>
      <label class="mb-0-25 block" for="weight-class-selector">
        Weight Class:
      </label>
      <div class="flex gap-0-5 align-start">
        <select class="toolbar" style="width: 120px" id="weight-class-selector" @change="selectedWeightClassChanged">
          <option v-for="weightClass in weightClasses" :key="weightClass" :value="weightClass">
            {{ weightClass }} {{ weightClassUnits }}
          </option>
        </select>

        <rebel-button v-if="!event.participationEnded" v-show="!selectedWeightClassHasCompetitors" color="default"
          type="primary" text="Add Competitors" @click="addCompetitors" />
        <rebel-button v-if="!event.participationEnded" v-show="selectedWeightClassHasCompetitors" color="default"
          type="primary" text="Edit Competitors" @click="editCompetitors" />
      </div>

      <p class="color-danger caption mt-0-25" v-if="weightClassesMissingCompetitors.length">
        These weight classes are missing competitors:
        <span class="color-danger caption semi-bold">{{ weightClassesMissingCompetitors.join(', ') }}</span>
      </p>
      <p class="color-danger caption mt-0-25" v-if="event.published && event.participationEnded && weightClassesMissingResults.length">
        These weight classes are missing results:
        <span class="color-danger caption semi-bold">{{ weightClassesMissingResults.join(', ') }}</span>
      </p>
    </div>

    <div ref="bracketPredictionElem" id="bracket-prediction" :class="{ border: isBracketScrollingHorizontally }">
      <div v-if="selectedBracket" class="bracket-outer" :class="{ border: !isBracketScrollingHorizontally }">
        <div class="rounds">
          <div v-for="(round, roundIndex) in selectedBracket.rounds" :key="round.round" class="round"
            :class="{ 'play-in': round.playIn }"
            :style="`--round: ${round.playIn ? 0 : Math.pow(2, round.round - 1)};`">
            <div class="round-name-edit flex col" v-if="round.editingRoundName">
              <input :id="`edit-round-${round.round}`" class="heading3 text-center" type="text" placeholder="Round Name"
                v-model="round.roundNameEditVal" @keyup.enter="saveCustomizeRoundName(round)">
              <div class="flex justify-center gap-0-5">
                <a class="action customize" @click.prevent="saveCustomizeRoundName(round)">Save</a>
                <a class="action customize" @click.prevent="round.editingRoundName = false;">Cancel</a>
              </div>
            </div>
            <h3 class="flex col" v-else>
              <template v-if="!round.editingRoundName">{{ round.roundName }}</template>
              <a class="action customize" @click.prevent="beginCustomizeRoundName(round)">Customize</a>
            </h3>

            <div class="round-wrapper"
              :style="`height: ${selectedBracket.rounds[this.indexOfRoundWithMostMatches].matchups.length * 80}px;`">
              <div class="games">
                <div class="game" :id="`game${matchup.matchupId}`"
                  :data-testid="`round${roundIndex + 1}-matchup${i + 1}`"
                  v-for="(matchup, i) in getMatchupsWithFiller(round, selectedBracket.rounds[roundIndex + 1])"
                  :key="matchup.matchupId" :class="{
                    hideFillerMatchup: matchup.isFillerForPlayInRound,
                    'first-round': round.playIn || round.round == 1,
                    'visibility-hidden': isInHiddenList(matchup.matchupId),
                  }">
                  <div class="player first" @click="playerClicked(matchup, matchup.participant1Id)">
                    <span class="grow">
                      <span v-if="matchup.participant1Ranking" class="caption gray2">{{
                        matchup.participant1Ranking
                      }}</span>
                      <span v-if="matchup.participant1Id != null" class="player-name body2"
                        :class="{ gray2: matchup.participant1Name.toUpperCase() == 'BYE' }">{{
                          abbreviateAsNeeded(round.round, matchup.participant1Name) }}</span>
                      <span v-else class="player-name body2 null-player-name"></span>
                    </span>
                    <span v-if="showVotes && !matchup.bye && matchup.participant1Id"
                      class="flex align-center gap-0-125 mr-0-25">
                      <span class="body2">{{ matchup.participantVotes[matchup.participant1Id] ?? 0 }}</span>
                      <span class="caption">{{ matchup.participantVotes[matchup.participant1Id] === 1 ? 'vote' :
                        'votes'
                        }}</span>
                    </span>
                    <span v-if="event.participationEnded && !matchup.bye">
                      <svg :data-testid="`round${roundIndex + 1}-matchup${i + 1}-p1selected`"
                        v-if="isWinner(matchup, matchup.participant1Id)" width="18" height="18" viewBox="0 0 20 18"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="9" cy="9" r="8.5" stroke="#2860F0" />
                        <circle cx="9" cy="9" r="5" fill="#2860F0" />
                      </svg>
                      <svg :data-testid="`round${roundIndex + 1}-matchup${i + 1}-p1unselected`" v-else width="18"
                        height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.5 9C17.5 13.6944 13.6944 17.5 9 17.5C4.30558 17.5 0.5 13.6944 0.5 9C0.5 4.30558 4.30558 0.5 9 0.5C13.6944 0.5 17.5 4.30558 17.5 9Z"
                          stroke="#3B5166" />
                      </svg>
                    </span>
                  </div>
                  <div class="inner-border"></div>
                  <div class="player last" @click="playerClicked(matchup, matchup.participant2Id)">
                    <span class="grow">
                      <span v-if="matchup.participant2Ranking" class="caption gray2">{{
                        matchup.participant2Ranking
                      }}</span>
                      <span v-if="matchup.participant2Id != null" class="player-name body2"
                        :class="{ gray2: matchup.participant2Name.toUpperCase() == 'BYE' }">{{
                          abbreviateAsNeeded(round.round, matchup.participant2Name) }}</span>
                      <span v-else class="player-name body2 null-player-name"></span>
                    </span>
                    <span v-if="showVotes && !matchup.bye && matchup.participant2Id"
                      class="flex align-center gap-0-125 mr-0-25">
                      <span class="body2">{{ matchup.participantVotes[matchup.participant2Id] ?? 0 }}</span>
                      <span class="caption">{{ matchup.participantVotes[matchup.participant2Id] === 1 ? 'vote' :
                        'votes'
                        }}</span>
                    </span>
                    <span v-if="event.participationEnded && !matchup.bye">
                      <svg :data-testid="`round${roundIndex + 1}-matchup${i + 1}-p2selected`"
                        v-if="isWinner(matchup, matchup.participant2Id)" width="18" height="18" viewBox="0 0 20 18"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="9" cy="9" r="8.5" stroke="#2860F0" />
                        <circle cx="9" cy="9" r="5" fill="#2860F0" />
                      </svg>
                      <svg :data-testid="`round${roundIndex + 1}-matchup${i + 1}-p2unselected`" v-else width="18"
                        height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.5 9C17.5 13.6944 13.6944 17.5 9 17.5C4.30558 17.5 0.5 13.6944 0.5 9C0.5 4.30558 4.30558 0.5 9 0.5C13.6944 0.5 17.5 4.30558 17.5 9Z"
                          stroke="#3B5166" />
                      </svg>
                    </span>
                  </div>
                  <div class="connector-last-round"
                    v-if="(roundIndex == selectedBracket.rounds?.length - 1 && round?.matchups?.length === 1 && selectedBracket?.rounds[roundIndex - 1]?.matchups?.length === 1)">
                    <div class="connector-after-last-round"></div>
                  </div>
                  <div class="connector connector-top"
                    v-else-if="(round.playIn || matchup.nextMatchupOrder == 1) && !(roundIndex + 1 == selectedBracket.rounds?.length - 1 && selectedBracket?.rounds[roundIndex + 1]?.matchups?.length === 1 && selectedBracket?.rounds[roundIndex]?.matchups?.length === 1)">
                  </div>
                  <div class="connector connector-bottom"
                    v-else-if="matchup.nextMatchupOrder == 2 && !(roundIndex + 1 == selectedBracket.rounds?.length - 1 && selectedBracket?.rounds[roundIndex + 1]?.matchups?.length === 1 && selectedBracket?.rounds[roundIndex]?.matchups?.length === 1)">
                  </div>
                  <div class="connector-before"
                    v-if="!(roundIndex == selectedBracket.rounds?.length - 1 && round?.matchups?.length === 1 && selectedBracket?.rounds[roundIndex - 1]?.matchups?.length === 1) && ((matchup.prevMatchup1Id != null && !isInHiddenList(matchup.prevMatchup1Id)) || (matchup.prevMatchup2Id != null && !isInHiddenList(matchup.prevMatchup2Id)))">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <add-competitors-modal v-if="addCompetitorsModal.isShowing" :event-id="eventId"
      :event-type-id="addCompetitorsModal.eventTypeId" :event-name="addCompetitorsModal.eventName"
      :event-start-date-time="addCompetitorsModal.eventStartDateTime" :weight-class="addCompetitorsModal.weightClass"
      @add-competitors="addCompetitorsCompleted" @close="addCompetitorsModal.isShowing = false" />
    <edit-competitors-modal v-if="editCompetitorsModal.isShowing" :bracket-id="selectedBracket?.bracketId"
      :play-in-competitors="selectedPlayInCompetitors" :round1Competitors="selectedRound1Competitors"
      :incumbent-competitor="selectedIncumbentCompetitor" :event-id="eventId"
      :event-type-id="editCompetitorsModal.eventTypeId" :event-is-published="event.published"
      :event-name="editCompetitorsModal.eventName" :event-start-date-time="editCompetitorsModal.eventStartDateTime"
      :weight-class="editCompetitorsModal.weightClass" @edit-competitors="editCompetitorsCompleted"
      @close="editCompetitorsModal.isShowing = false" />
    <edit-event-modal v-if="editEventDetailsModal.isShowing" :event-data="event" @delete-event="deleteEvent"
      @close="editEventDetailsModal.isShowing = false" />
    <delete-event-modal v-if="deleteEventModal.isShowing" :event-id="event.eventId" :event-name="event.name"
      @close="deleteEventModal.isShowing = false" />
    <save-changes-modal v-if="saveChangesModal.isShowing" :weight-class="saveChangesModal.weightClass"
      @save-changes="saveChanges" @discard-changes="discardChanges" @close="closeSaveChangesModal" />
    <customize-weight-classes-modal v-if="customizeWeightClassesModal.isShowing" :event-id="eventId"
      :weight-classes="event.weightClasses" @close="customizeWeightClassesModal.isShowing = false"
      @event-weight-classes-changed="eventWeightClassesChanged" />
    <event-art-image-uploader v-if="useEventLogos && eventArtImageUploader.isShowing" :event-id="eventId"
      :uploaderImageSrc="event.logoUrl" @close="eventArtImageUploader.isShowing = false"
      @image-uploader-deleted="event.logoUrl = ''" @image-uploader-saved="(imageSrc) => event.logoUrl = imageSrc" />
  </div>
</template>

<script>
import APP_SETTINGS from '@/appSettings.js'
import STRINGS from '@/utils/strings.json'
import eventService from '@/services/EventService.js'
import eventBracketService from '@/services/EventBracketService.js'

import { Cog8ToothIcon } from '@heroicons/vue/24/solid'
import AddCompetitorsModal from './AddCompetitorsModal.vue'
import CustomizeWeightClassesModal from './CustomizeWeightClassesModal.vue'
import DeleteEventModal from '../DeleteEventModal.vue'
import EditCompetitorsModal from './EditCompetitorsModal.vue'
import EditEventModal from '../EditEventModal.vue'
import EventCardHeader from '../../events/EventCardHeader.vue'
import EventArtImageUploader from '../EventArtImageUploader.vue'
import FlyOutActionsMenu from '../../FlyOutActionsMenu.vue'
import HeaderActionBar from '@/components/HeaderActionBar.vue'
import LeagueShortcutButton from '@/components/event-admin/LeagueShortcutButton.vue'
import LinkBackTo from '../../LinkBackTo.vue'
import RebelButton from '../../RebelButton.vue'
import SaveChangesModal from './SaveChangesModal.vue'

import defaults from '@/utils/defaults.js'
import DateUtils from '@/utils/dateUtils.js'
import eventTypes from '@/utils/eventTypes.js'

import { useEventAdminStore } from '@/stores/eventAdmin'

export default {
  props: {
    eventData: {
      type: Object,
      required: true
    }
  },

  setup() {
    return {
      eventAdminStore: useEventAdminStore(),
      eventTypes,
      STRINGS,
    }
  },

  async created() {
    try {
      this.selectedWeightClass = this.weightClasses[0]
      this.intendedWeightClass = this.weightClasses[0]
      await this.loadBrackets()
      this.setPublishedStateToCurrent()
    } catch {
      alert('Something went wrong fetching event data')
    } finally {
      this.isLoaded = true
      setTimeout(this.setIsBracketScrollingHorizontally, 1)
    }
  },

  components: {
    AddCompetitorsModal,
    Cog8ToothIcon,
    CustomizeWeightClassesModal,
    DeleteEventModal,
    EditCompetitorsModal,
    EditEventModal,
    EventCardHeader,
    EventArtImageUploader,
    FlyOutActionsMenu,
    HeaderActionBar,
    LeagueShortcutButton,
    LinkBackTo,
    RebelButton,
    SaveChangesModal,
  },

  computed: {
    hasEventImage() {
      return Boolean(this.event.logoUrl)
    },

    showVotes() {
      return !this.event.upcoming
    },

    eventId() {
      return this.event.eventId
    },

    eventSubText() {
      if (this.event.popularVoteTournament) {
        if (!this.event.published) return 'Voting begins when brackets are published'
        if (!this.event.participationEnded) return 'Voting ends ' + this.formattedStartDateTime
        return 'Voting ended ' + this.formattedStartDateTime
      }

      return this.formattedStartDateTime

    },

    formattedStartDateTime() {
      return DateUtils.formatDateTime(this.event.startDateTime, this.event.timeZoneName)
    },

    hiddenList() {
      if (this.selectedBracket == null) return []

      const allMatchups = this.selectedBracket?.rounds.flatMap(r => r.matchups)
      const allMatchupsMap = []
      allMatchups.forEach(matchup => {
        allMatchupsMap[matchup.matchupId] = matchup
      })

      return allMatchups
        .filter(m => m.participant1Name?.toUpperCase() == 'BYE' && m.participant2Name?.toUpperCase() == 'BYE')
        .concat(allMatchups.filter(m => (m.participant1Name?.toUpperCase() == 'BYE' || m.participant2Name?.toUpperCase() == 'BYE') && m.nextMatchupId != null && (allMatchupsMap[m.nextMatchupId]?.participant1Name?.toUpperCase() == m.participant1Name?.toUpperCase() || allMatchupsMap[m.nextMatchupId]?.participant2Name?.toUpperCase() == m.participant1Name?.toUpperCase()) && ((allMatchupsMap[m.nextMatchupId]?.participant1Name?.toUpperCase() == m.participant2Name?.toUpperCase() || allMatchupsMap[m.nextMatchupId]?.participant2Name?.toUpperCase() == m.participant2Name?.toUpperCase()))))
        .map(m => m.matchupId)
    },

    indexOfRoundWithMostMatches() {
      let index = -1
      let currentMax = 0

      this.selectedBracket?.rounds?.forEach((round, i) => {
        if (round.matchups.length > currentMax) {
          index = i
          currentMax = round.matchups.length
        }
      })

      return index
    },

    isOngoingOrPastEvent() {
      return this.isOngoingEvent || this.isPastEvent
    },

    isUnpublishedEvent() {
      return !this.event.published
    },

    isOngoingEvent() {
      return this.event.ongoing
    },

    isUpcomingEvent() {
      return this.event.upcoming
    },

    isPastEvent() {
      return this.event.past
    },

    projectedMatchupWinners() {
      if (this.selectedBracket == null) {
        return []
      }

      return this.selectedBracket.rounds
        .flatMap((round) => round.matchups)
        .map((matchup) => {
          return {
            matchupId: matchup.matchupId,
            winnerId: matchup.winner
          }
        })
    },

    publishEventEnabled() {
      return this.weightClassesMissingCompetitors.length === 0
    },

    publishResultsEnabled() {
      if (!this.event.published) return false
      if (!this.event.participationEnded) return false

      // check for difference between initial state of projected winners and current
      // if they are at all different, then a result must have changed.
      for (let i = 0; i < this.projectedMatchupWinners.length; i++) {
        if (this.initialProjectedMatchupWinners[i]?.winnerId !== this.projectedMatchupWinners[i].winnerId) {
          return true
        }
      }

      return false
    },

    selectedIncumbentCompetitor() {
      if (this.selectedBracket == null || this.selectedBracket.rounds.length == 0) return ''

      return this.selectedBracket.incumbentCompetitor
    },

    selectedPlayInCompetitors() {
      if (this.selectedBracket == null || this.selectedBracket.rounds.length == 0) return ''

      const hasPlayInRound = this.selectedBracket.rounds[0].matchups.length < this.selectedBracket.participantCount / 2
      if (!hasPlayInRound) return ''

      const playInRound = this.selectedBracket.rounds[0]
      const competitors = []
      playInRound.matchups.forEach((matchup) => {
        competitors.push(
          this.formatMatchupName(matchup.participant1Name, matchup.participant1Ranking),
          this.formatMatchupName(matchup.participant2Name, matchup.participant2Ranking)
        )
      })
      return competitors.join('\n')
    },
    selectedRound1Competitors() {
      if (this.selectedBracket == null || this.selectedBracket.rounds.length == 0) return ''

      const hasPlayInRound = this.selectedBracket.rounds[0].matchups.length < this.selectedBracket.participantCount / 2

      let firstRound = null
      if (!hasPlayInRound) {
        firstRound = this.selectedBracket.rounds[0]
      } else {
        firstRound = this.selectedBracket.rounds[1]
      }

      const competitors = []
      firstRound.matchups.forEach((matchup) => {
        competitors.push(
          this.formatMatchupName(matchup.participant1Name, matchup.participant1Ranking),
          this.formatMatchupName(matchup.participant2Name, matchup.participant2Ranking)
        )
      })
      return competitors.join('\n')
    },

    selectedBracketMatchups() {
      return this.selectedBracket.rounds.flatMap((round) => round.matchups)
    },

    selectedBracket() {
      if (this.selectedWeightClass === '-1' || this.event?.brackets == null || !this.event.brackets?.length) {
        return null
      }

      return this.event.brackets.find((bracket) => bracket.weightClass === this.selectedWeightClass)
    },

    selectedWeightClassHasCompetitors() {
      return this.weightClassesWithCompetitors.some((wc) => wc == this.selectedWeightClass)
    },

    weightClasses() {
      if (this.event?.weightClasses?.weightClasses == null || this.event?.weightClasses?.weightClasses.length === 0) {
        return defaults.WEIGHT_CLASSES
      }

      return this.event.weightClasses.weightClasses
    },

    weightClassUnits() {
      if (this.event?.weightClasses?.units == null) {
        return defaults.WEIGHT_CLASS_UNITS
      }

      return this.event.weightClasses.units
    },

    weightClassesWithCompetitors() {
      if (this.event?.brackets == null) return []

      return this.event.brackets.map((b) => b.weightClass)
    },

    weightClassesMissingCompetitors() {
      return this.weightClasses.filter((wc) => !this.weightClassesWithCompetitors.some((wc2) => wc == wc2))
    },

    weightClassesMissingResults() {
      // get all bracket data currently entered on the UI.
      // if any of them are missing winners, save that weight class to the list
      const initialListOfWeightClassesMissingResults = this.weightClasses.filter((wc) => {
        if (this.event.brackets == null || this.event?.brackets == null || this.event.brackets.length === 0) {
          return false
        }

        const bracketForWeightClass = this.event.brackets.find((b) => b.weightClass == wc)

        if (bracketForWeightClass == null) {
          return false
        }

        return bracketForWeightClass.rounds.flatMap((r) => r.matchups).some((m) => m.winner == null)
      })

      // determine if there are any modifications to the current weight class that include
      // winner results missing. if so, add the current weight class to the list if it's not already
      const selectedWeightClassResultsChanged = this.publishResultsEnabled

      if (
        !selectedWeightClassResultsChanged ||
        initialListOfWeightClassesMissingResults.some((wc) => wc == this.selectedWeightClass)
      ) {
        return initialListOfWeightClassesMissingResults
      }

      const areSelectedWeightClassPublishedResultsMissingWinners = this.selectedBracket.rounds
        .flatMap((r) => r.matchups)
        .some((m) => {
          const initialStateOfMatchup = this.initialProjectedMatchupWinners.find((ipmw) => ipmw.matchupId == m.matchupId)

          if (initialStateOfMatchup == null) return false

          return initialStateOfMatchup.winnerId == null
        })

      if (areSelectedWeightClassPublishedResultsMissingWinners) {
        initialListOfWeightClassesMissingResults.push(this.selectedWeightClass)
        initialListOfWeightClassesMissingResults.sort()
      }

      return initialListOfWeightClassesMissingResults
    }
  },

  data() {
    return {
      PLAY_IN_WINNER_PLACEHOLDER: '*',

      isBracketScrollingHorizontally: false,

      isLoaded: false,
      isPublishing: false,

      event: { ...this.eventData },

      eventArtImageUploader: {
        isShowing: false
      },

      addCompetitorsModal: {
        isShowing: false
      },

      deleteEventModal: {
        isShowing: false
      },

      editCompetitorsModal: {
        isShowing: false
      },

      editEventDetailsModal: {
        isShowing: false
      },

      saveChangesModal: {
        isShowing: false,
        weightClass: ''
      },

      customizeWeightClassesModal: {
        isShowing: false
      },

      intendedWeightClass: undefined,
      selectedWeightClass: undefined,

      initialProjectedMatchupWinners: [],

      useEventLogos: APP_SETTINGS.USE_EVENT_LOGOS
    }
  },

  methods: {
    isInHiddenList(matchupId) {
      return this.hiddenList.includes(matchupId)
    },

    beginCustomizeRoundName(round) {
      round.roundNameEditVal = round.roundName
      round.editingRoundName = true
      setTimeout(() => {
        document.getElementById(`edit-round-${round.round}`).focus()
      }, 1)
    },
    async saveCustomizeRoundName(round) {
      try {
        await eventBracketService.patchRoundName(round.bracketId, round.round, round.roundNameEditVal)
        round.roundName = round.roundNameEditVal
      } finally {
        round.editingRoundName = false
      }
    },

    customizeWeightClasses() {
      this.customizeWeightClassesModal.isShowing = true
    },

    eventWeightClassesChanged() {
      location.reload()
    },

    playerClicked(matchup, participantId) {
      if (!this.event.participationEnded || matchup.bye) return

      if (this.isWinner(matchup, participantId)) {
        this.removeWinner(matchup, participantId)
        return
      }

      this.advance(matchup, participantId)
    },


    abbreviateAsNeeded(roundNumber, name) {
      if (name == null) return ''

      if (roundNumber <= 1) return name

      name = name.trim()

      const rankRegex = /^\d+|^\(\s*\d+\s*\)/
      const schoolStarterRegex = /\(|\[/

      let rank = ''
      // let fName = ''
      // let lName = ''

      const rankingMatches = name.match(rankRegex)
      if (rankingMatches != null) {
        // ranking is present. extract ranking and revise name
        rank = rankingMatches[0] + ' '
        name = name.substring(rank.length).trim()
      }

      if (name.match(schoolStarterRegex) != null) {
        name = name.split(schoolStarterRegex)[0].trim().replace('\t', ' ')
      }

      return rank + name

      /*if (name.match(/\s+/) != null) {
        const nameParts = name.split(/\s+/)
        
        if (nameParts.length < 2) return rank + name

        fName = nameParts[0]
        lName = nameParts[nameParts.length-1]
      } else {
        return rank + name
      }

      return rank + fName + ' ' + lName*/
    },

    formatMatchupName(name, rank) {
      if (name == '') name = this.PLAY_IN_WINNER_PLACEHOLDER

      if (rank == null) return name

      return ('(' + rank + ') ' + name).trim()
    },

    setIsBracketScrollingHorizontally() {
      const bracketPredictionElement = this.$refs.bracketPredictionElem

      if (bracketPredictionElement != null) {
        this.isBracketScrollingHorizontally = bracketPredictionElement.scrollWidth > bracketPredictionElement.clientWidth
      } else {
        this.isBracketScrollingHorizontally = false
      }
    },

    getMatchupsWithFiller(round, nextRound) {
      if (!round.playIn || nextRound == null) return round.matchups

      // add filler matchup for the UI

      // find out which matchup is the next up and line up this matchup with it by adding filler
      // before it. then add filler after as needed

      const fakeMatchup = {
        participant1Id: null,
        participant1Name: '',
        participant1Ranking: '',
        participant2Id: null,
        participant2Name: '',
        participant2Ranking: '',
        winner: null,
        isFillerForPlayInRound: true
      }

      // go through each round 1 matchup. for each participant that does not have a prevMatchup associated
      // add a filler matchup in play in round
      const currRoundMatchupsWithFiller = []
      let playInMatchupCounter = 0
      let currPlayInMatchup = round.matchups[playInMatchupCounter]
      for (let i = 0; i < nextRound.matchups.length; i++) {
        const matchup = nextRound.matchups[i]
        if (matchup.prevMatchup1Id != null && matchup.prevMatchup2Id != null) {
          // add both
          currRoundMatchupsWithFiller.push(currPlayInMatchup)
          currPlayInMatchup = round.matchups[++playInMatchupCounter]
          currRoundMatchupsWithFiller.push(currPlayInMatchup)
          currPlayInMatchup = round.matchups[++playInMatchupCounter]
        } else if (matchup.prevMatchup1Id == null && matchup.prevMatchup2Id == null) {
          currRoundMatchupsWithFiller.push({ ...fakeMatchup })
        } else {
          // one matchup
          currRoundMatchupsWithFiller.push(currPlayInMatchup)
          currPlayInMatchup = round.matchups[++playInMatchupCounter]
        }
      }

      return currRoundMatchupsWithFiller
    },

    addCompetitors() {
      this.addCompetitorsModal.eventName = this.event.name
      this.addCompetitorsModal.eventTypeId = this.event.typeId
      this.addCompetitorsModal.eventStartDateTime = this.formattedStartDateTime
      this.addCompetitorsModal.weightClass = `${this.selectedWeightClass} ${this.weightClassUnits}`
      this.addCompetitorsModal.isShowing = true
    },

    async addCompetitorsCompleted() {
      await this.loadBrackets()
      setTimeout(this.setIsBracketScrollingHorizontally, 1)
      this.addCompetitorsModal.isShowing = false
    },

    deleteEvent() {
      this.deleteEventModal.isShowing = true
    },

    editCompetitors() {
      this.editCompetitorsModal.eventName = this.event.name
      this.editCompetitorsModal.eventTypeId = this.event.typeId
      this.editCompetitorsModal.eventStartDateTime = this.formattedStartDateTime
      this.editCompetitorsModal.weightClass = `${this.selectedWeightClass} ${this.weightClassUnits}`
      this.editCompetitorsModal.isShowing = true
    },

    async editCompetitorsCompleted() {
      await this.loadBrackets()
      setTimeout(this.setIsBracketScrollingHorizontally, 1)
      this.editCompetitorsModal.isShowing = false
    },

    editEventDetails() {
      this.editEventDetailsModal.isShowing = true
    },

    getUtcTime(event) {
      // TODO: duplicate method
      return Date.parse(`${event.startDate} ${event.startTime}+0`)
    },

    async loadBrackets() {
      const responseBrackets = await eventBracketService.getAll(this.eventId)
      this.event.brackets = responseBrackets.data
      setTimeout(this.updateMatchupLines, 1)
    },

    setPublishedStateToCurrent() {
      this.initialProjectedMatchupWinners = [...this.projectedMatchupWinners]
      setTimeout(this.updateMatchupLines, 1)
    },

    async tryPublishResults() {
      try {
        await this.publishResults()
      } catch (e) {
        alert('Something went wrong: ' + e)
      }
    },

    async publishResults() {
      this.isPublishing = true

      const dto = {
        bracketId: this.selectedBracket.bracketId,
        projectedMatchupWinners: this.projectedMatchupWinners
      }

      try {
        await eventService.publishResults(dto)

        const response = await eventService.getById(this.eventId)
        this.event = { ...response.data }
        await this.loadBrackets()

        this.setPublishedStateToCurrent()
      } finally {
        this.isPublishing = false
      }
    },

    async saveChanges() {
      this.saveChangesModal.isShowing = false

      try {
        await this.publishResults()
        this.setWeightClass(this.intendedWeightClass)
      } catch {
        document.getElementById('weight-class-selector').value = this.selectedWeightClass
        alert('Something went wrong')
      }
    },

    closeSaveChangesModal() {
      document.getElementById('weight-class-selector').value = this.selectedWeightClass
      this.saveChangesModal.isShowing = false
    },

    async discardChanges() {
      this.saveChangesModal.isShowing = false
      // TODO: reset this data locally instead
      await this.loadBrackets()

      this.setWeightClass(this.intendedWeightClass)
    },

    setWeightClass(weightClass) {
      this.selectedWeightClass = String(weightClass)
      this.intendedWeightClass = String(weightClass)

      this.setPublishedStateToCurrent()

      setTimeout(this.setIsBracketScrollingHorizontally, 1)
      setTimeout(this.updateMatchupLines, 1)
    },

    async selectedWeightClassChanged(e) {
      const updatedWeightClass = e.currentTarget.value

      // they have changed results without saving
      if (this.publishResultsEnabled) {
        this.intendedWeightClass = updatedWeightClass
        this.saveChangesModal.weightClass = `${this.selectedWeightClass} ${this.weightClassUnits}`
        this.saveChangesModal.isShowing = true
        return
      }

      this.setWeightClass(updatedWeightClass)
    },

    /* bracket methods */
    advance(matchup, matchupParticipantId) {
      if (matchupParticipantId == null || matchup == null || this.selectedBracket == null) {
        return
      }

      // if this participant is already assigned as the winner, do nothing
      if (this.isWinner(matchup, matchupParticipantId)) {
        return
      }

      // if doing it client side, remove all picks that would conflict with this pick
      const allMatchups = this.selectedBracket.rounds.flatMap((round) => round.matchups)
      let currMatchup = allMatchups.find((m) => m.matchupId === matchup.matchupId)
      let nextMatchup = allMatchups.find((m) => m.matchupId === matchup.nextMatchupId)

      // set next matchup's participant
      let isFinished = false
      currMatchup.winner = matchupParticipantId
      if (nextMatchup != null) {
        if (matchupParticipantId === currMatchup.participant1Id) {
          if (currMatchup.nextMatchupOrder === 1) {
            nextMatchup.participant1Id = currMatchup.participant1Id
            nextMatchup.participant1Name = currMatchup.participant1Name
            nextMatchup.participant1Ranking = currMatchup.participant1Ranking

            if (nextMatchup.winner != null && nextMatchup.winner !== nextMatchup.participant2Id) {
              nextMatchup.winner = null
            } else {
              isFinished = true
            }
          } else {
            nextMatchup.participant2Id = currMatchup.participant1Id
            nextMatchup.participant2Name = currMatchup.participant1Name
            nextMatchup.participant2Ranking = currMatchup.participant1Ranking

            if (nextMatchup.winner != null && nextMatchup.winner !== nextMatchup.participant1Id) {
              nextMatchup.winner = null
            } else {
              isFinished = true
            }
          }
        } else if (matchupParticipantId === currMatchup.participant2Id) {
          if (currMatchup.nextMatchupOrder === 1) {
            nextMatchup.participant1Id = currMatchup.participant2Id
            nextMatchup.participant1Name = currMatchup.participant2Name
            nextMatchup.participant1Ranking = currMatchup.participant2Ranking

            if (nextMatchup.winner != null && nextMatchup.winner !== nextMatchup.participant2Id) {
              nextMatchup.winner = null
            } else {
              isFinished = true
            }
          } else {
            nextMatchup.participant2Id = currMatchup.participant2Id
            nextMatchup.participant2Name = currMatchup.participant2Name
            nextMatchup.participant2Ranking = currMatchup.participant2Ranking

            if (nextMatchup.winner != null && nextMatchup.winner !== nextMatchup.participant1Id) {
              nextMatchup.winner = null
            } else {
              isFinished = true
            }
          }
        }
      }

      currMatchup = nextMatchup

      while (!isFinished && currMatchup != null) {
        const nextMatchup = allMatchups.find((m) => m.matchupId === currMatchup.nextMatchupId)
        if (nextMatchup != null) {
          if (currMatchup.nextMatchupOrder === 1) {
            nextMatchup.participant1Id = null
            nextMatchup.participant1Name = null
            nextMatchup.participant1Ranking = null

            if (nextMatchup.winner != null && nextMatchup.winner !== nextMatchup.participant2Id) {
              nextMatchup.winner = null
            } else {
              isFinished = true
            }
          } else if (currMatchup.nextMatchupOrder === 2) {
            nextMatchup.participant2Id = null
            nextMatchup.participant2Name = null
            nextMatchup.participant2Ranking = null

            if (nextMatchup.winner != null && nextMatchup.winner !== nextMatchup.participant1Id) {
              nextMatchup.winner = null
            } else {
              isFinished = true
            }
          }
        }
        currMatchup = nextMatchup
      }
    },

    removeWinner(matchup, matchupParticipantId) {
      if (matchupParticipantId == null || matchup == null || this.selectedBracket == null) {
        return
      }

      // if this participant is not assigned as the winner, do nothing
      if (!this.isWinner(matchup, matchupParticipantId)) {
        return
      }

      const allMatchups = this.selectedBracket.rounds.flatMap((round) => round.matchups)
      let currMatchup = allMatchups.find((m) => m.matchupId === matchup.matchupId)
      let nextMatchup = allMatchups.find((m) => m.matchupId === matchup.nextMatchupId)

      // set next matchup's participant
      let isFinished = false
      currMatchup.winner = null
      if (nextMatchup != null) {
        if (currMatchup.nextMatchupOrder === 1) {
          nextMatchup.participant1Id = null
          nextMatchup.participant1Name = ''
          nextMatchup.participant1Ranking = ''
        } else {
          nextMatchup.participant2Id = null
          nextMatchup.participant2Name = ''
          nextMatchup.participant2Ranking = ''
        }

        if (nextMatchup.winner != null && nextMatchup.winner === matchupParticipantId) {
          nextMatchup.winner = null
        } else {
          isFinished = true
        }
      }

      currMatchup = nextMatchup

      while (!isFinished && currMatchup != null) {
        const nextMatchup = allMatchups.find((m) => m.matchupId === currMatchup.nextMatchupId)
        if (nextMatchup != null) {
          if (currMatchup.nextMatchupOrder === 1) {
            nextMatchup.participant1Id = null
            nextMatchup.participant1Name = null
            nextMatchup.participant1Ranking = null
          } else if (currMatchup.nextMatchupOrder === 2) {
            nextMatchup.participant2Id = null
            nextMatchup.participant2Name = null
            nextMatchup.participant2Ranking = null
          }

          if (nextMatchup.winner != null && nextMatchup.winner === matchupParticipantId) {
            nextMatchup.winner = null
          } else {
            isFinished = true
          }
        }
        currMatchup = nextMatchup
      }
    },

    isWinner(matchup, participantId) {
      return matchup.winner != null && matchup.winner == participantId
    },

    updateMatchupLines() {
      if (this.selectedBracket == null) return
      const allMatchups = this.selectedBracket.rounds.flatMap((round) => round.matchups)
      const gameElements = document.querySelectorAll('.game')
      const idPrefix = 'game'

      for (const gameElement of gameElements) {
        const gameMatchupId = gameElement.id.substring(idPrefix.length)
        const gameMatchup = allMatchups.find(m => m.matchupId == gameMatchupId)

        if (gameMatchup && gameMatchup.nextMatchupId != null) {
          const nextGameElement = document.getElementById(`${idPrefix}${gameMatchup.nextMatchupId}`)
          const matchupYPos = gameElement.getBoundingClientRect().y
          const nextMatchupYPos = nextGameElement.getBoundingClientRect().y
          const deltaHeight = Math.abs(nextMatchupYPos - matchupYPos)
          const connectorElement = gameElement.querySelector('.connector')

          if (connectorElement == null) continue

          connectorElement.style.height = deltaHeight + 'px'

          if (nextMatchupYPos > matchupYPos) {
            connectorElement.classList.add('connector-top')
            connectorElement.classList.remove('connector-bottom')
          } else {
            connectorElement.classList.add('connector-bottom')
            connectorElement.classList.remove('connector-top')
          }
        }

      }
    }
  },

  mounted() {
    window.addEventListener('resize', this.setIsBracketScrollingHorizontally)
    window.addEventListener('resize', this.updateMatchupLines)
  },

  unmounted() {
    try {
      window.removeEventListener('resize', this.setIsBracketScrollingHorizontally)
      window.removeEventListener('resize', this.updateMatchupLines)
    } catch {
      /* empty */
    }
  }
}
</script>

<style scoped>
.event-card-container {
  padding: 0.5rem;
  background-color: var(--color-white);
}

.weight-class-selector-container {
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: var(--color-white);
}

/* BRACKET STYLING */
#bracket-prediction {
  --first-game-width: 310px;
  --game-width: 230px;
  --round-spacing: 12px;
  --player-height: 24px;
  --game-vertical-spacing: 32px;
  --border-width: 1px;
  overflow-x: auto;
}

.bracket-outer {
  width: fit-content;
}

#bracket-prediction.border {
  border: 1px solid var(--color-gray2);
}

.bracket-outer.border {
  border: 1px solid var(--color-gray2);
}

.rounds {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  background-color: var(--color-gray1);
}

.round h3,
.round-name-edit {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  text-align: center;
  border-bottom: 1px solid var(--color-gray2);
  background-color: var(--color-gray0-5);
}

.round-name-edit>input {
  padding: 0;
  line-height: 1.25rem;
}

.action.customize {
  font-size: 1rem;
}

.games {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-grow: 1;
}

.round-wrapper {
  display: flex;
  padding: calc(var(--round-spacing) / 2);
}

.round:first-of-type .round-wrapper {
  padding-left: var(--round-spacing);
}

.round:last-of-type .round-wrapper {
  padding-right: var(--round-spacing);
}

.game {
  border: 0.5px solid var(--color-gray1);
  position: relative;
  width: var(--game-width);
  box-shadow: 2px 4px 6px 0px #00000066;
  background-color: var(--color-white);
}

.player {
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--player-height);
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.player:hover {
  cursor: pointer;
}

.player-name {
  max-width: calc(var(--game-width) - 2.2rem);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 1rem;
}

.first-round .player-name {
  max-width: calc(var(--first-game-width) - 2.2rem);
}

.player .rank {
  text-align: center;
  min-width: 0.75rem;
}

.player>span {
  display: flex;
  align-items: baseline;
  gap: 0.125rem;
}

.connector {
  border: var(--border-width) solid var(--color-gray3);
  border-left: none;

  height: 60px;
  width: calc(var(--round-spacing) / 2);

  position: absolute;
  z-index: 1;
  left: calc(var(--game-width) + 2px);
}

.connector-top {
  border-bottom: none;
  top: 50%;
}

.connector-bottom {
  border-top: none;
  bottom: 50%;
}

.play-in .connector {
  border-top: 0;
}

.connector-before {
  position: absolute;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: var(--border-width) solid var(--color-gray3);
  width: calc(var(--round-spacing) / 2 - 1px);
  left: calc(var(--round-spacing) * -.5 + 1px);
  top: calc(50% - var(--border-width) / 2);
}

.connector-top>.connector-before {
  top: 100%;
}

.connector-bottom>.connector-before {
  top: 0;
}

.null-player-name {
  width: 95%;
  height: calc(var(--player-height) * 0.5);
  background: var(--color-gray1);
  flex-grow: 1;
  margin-right: 0.25rem;
  margin-left: 0.5rem;
}

.hideFillerMatchup {
  visibility: hidden;
}

.game.first-round {
  width: var(--first-game-width);
}

.first-round .connector {
  left: calc(var(--first-game-width) + 2px);
}

/* specific styles for matchups that only have one prev matchup */

.connector-last-round {
  width: 0;
  height: 0;
  border-left: none;
  position: absolute;
  top: 50%;
  z-index: 1;
}

.connector-after-last-round {
  position: absolute;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: var(--border-width) solid var(--color-gray3);
  width: calc(var(--round-spacing));
  left: calc(var(--round-spacing) * -1);
}

/* END OF BRACKET STYLING */

.visibility-hidden {
  visibility: hidden;
}</style>