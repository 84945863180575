<template>
  <div class="max-width-48">
    <span class="caption gray2-5">* </span>
    <span class="caption bold gray4 nowrap">🐷 PIG</span>
    <span class="caption gray2-5">
      represents the WINNER of the pigtail or previous round.
    </span>
    <span class="caption bold gray4 nowrap">🐷 PIG</span>
    <span class="caption gray2-5">
      can be advanced to score points in all rounds.
    </span>
  </div>
</template>
