<template>
  <div ref="bars" class="flex row align-center gap-0-5">
    <div class="value2-bar radius" :style="`width: ${value2Width}px;`">
      <div class="value1-bar radius" :style="`width: ${value1Width}px;`"></div>
      <div ref="insideText" v-if="labelSpaceAvailable >= labelSpaceRequired" class="heading4 value1-text">
        {{ this.valueFormatter(value1) }}
      </div>
    </div>
    <div ref="outsideText" v-if="labelSpaceAvailable < labelSpaceRequired" class="flex row gap-0-25">
      <div class="heading4 value1-text">{{ this.valueFormatter(value1) }}</div>
      <template v-if="showValue2">
        <span class="heading4 value2-text">/</span>
        <div class="heading4 value2-text">{{ this.valueFormatter(value2) }}</div>
      </template>
    </div>
    <div ref="outsideText" v-else-if="showValue2">
      <div class="heading4 value2-text">{{ this.valueFormatter(value2) }}</div>
    </div>
  </div>
</template>

<script>
const DEFAULT_CONTAINER_WIDTH = 100

export default {
  props: {
    containingElement: {
      type: HTMLElement,
      required: true
    },
    value1: {
      type: Number,
      required: true
    },
    value2: {
      type: Number,
      required: true
    },
    maxValue: {
      type: Number,
      required: true
    },
    showValue2: {
      type: Boolean,
      default: true
    },
    valueFormatter: {
      type: Function,
      default: (val) => val
    }
  },

  data() {
    return {
      containerWidth: DEFAULT_CONTAINER_WIDTH,
      INSIDE_TEXT_WIDTH_ESTIMATE: 30,
      OUTSIDE_TEXT_WIDTH_ESTIMATE: 30
    }
  },

  mounted() {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },

  computed: {
    value1Width() {
      if (this.value1 === 0) return 4

      return this.containerWidth * (this.value1 / this.maxValue)
    },
    value2Width() {
      if (this.value2 === 0) return 4
      if (!this.showValue2) return this.value1Width

      return this.containerWidth * (this.value2 / this.maxValue)
    },
    labelSpaceRequired() {
      return this.$refs.insideText?.getBoundingClientRect().width || this.INSIDE_TEXT_WIDTH_ESTIMATE
    },
    labelSpaceAvailable() {
      return this.value2Width - this.value1Width
    }
  },

  methods: {
    onResize() {
      if (this.$refs?.bars == null) {
        this.containerWidth = this.DEFAULT_CONTAINER_WIDTH
      }

      // const OUTSIDE_TEXT_WIDTH = this.$refs.outsideText?.getBoundingClientRect().width || this.OUTSIDE_TEXT_WIDTH_ESTIMATE
      const OUTSIDE_TEXT_WIDTH = this.OUTSIDE_TEXT_WIDTH_ESTIMATE
      this.containerWidth =
        this.containingElement.getBoundingClientRect().width - this.$refs.bars.getBoundingClientRect().x - OUTSIDE_TEXT_WIDTH
    }
  }
}
</script>

<style scoped>
.bars {
  color: var(--color-gray3);
  padding: 0;
  box-sizing: border-box;

  display: flex;
  flex-wrap: nowrap;

  align-items: center;
}
.value1-bar {
  background-color: var(--color-action);
  position: relative;
  height: 1.125rem;
}

.value2-bar {
  background-color: var(--color-gray1);
  height: 1.125rem;
  color: var(--color-action);
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

.value1-text {
  color: var(--color-action);
  padding-bottom: 1px;
}

.value2-text {
  color: var(--color-gray3);
  padding-bottom: 1px;
}

.radius {
  border-radius: 0.25rem;
}
</style>