<template>
  <div class="flex row-to-col-768 space-between gap-1 pb-1 align-start">
    <div class="flex col gap-0-25">
      <h1 class="black">{{ headerText }}</h1>
      <h3 v-if="subText != null" class="heading3 gray3">{{ subText }}</h3>
    </div>

    <div id="page-header-actions" class="flex gap-0-75 row-to-col-432">
      <slot name="header-actions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headerText: {
      type: String,
      required: true
    },
    subText: {
      type: String,
      default: null
    }
  }
}
</script>

<style>

@media screen and (max-width: 512px) {
  #page-header-actions {
    width: 100%;
  }

  #page-header-actions button {
    flex-grow: 1;
  }
}

</style>