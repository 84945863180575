<template>
  <div id="hero-container" class="hero layout-max-container">
    <div id="hero-overlay" class="hero layout-max-container">
      <div id="hero-text" class="layout-max-x layout-padding-x">
        <h1 class="marketing-h1 white mb-1">Join the MATNESS</h1>
        <p class="marketing-p1 white w-36">
          Wrestle MATNESS is a bracket competition experience created by and for wrestlers to share and spread the fun
        </p>
        <div>
          <rebel-button id="btn-join-now" type="primary-inverse" text="Join Now" @click="joinNow"></rebel-button>
        </div>
      </div>
    </div>
  </div>

  <div id="how-it-works">
    <div class="text-center">
      <img class="text-center logo-img" src="/Logo-No-Background.webp" alt="Wrestle MATNESS logo" />
    </div>

    <h2 class="marketing-h2 text-center">How it works</h2>

    <div class="marketing-section-container">
      <section class="marketing-section">
        <div class="num-container">
          <h3 class="num marketing-h3">1</h3>
        </div>
        <div>
          <h3 class="marketing-h3 gray4">Join Wrestle MATNESS</h3>
          <p class="marketing-p2">
            Wrestle MATNESS is free to play, and free to join! Sign up today and invite your friends. Wrestle MATNESS is a community of fans, athletes, coaches — anyone who is interested in the exciting world of wrestling.
          </p>
        </div>
      </section>
      <section class="marketing-section">
        <div class="num-container">
          <h3 class="num marketing-h3">2</h3>
        </div>
        <div class="num-container">
          <h3 class="marketing-h3 gray4">Enter a league</h3>
          <p class="marketing-p2">
            Start your own league and take the reigns as commissioner, or join an existing league. Play with your friends and rivals, or join fellow fans who you haven't yet met.
          </p>
        </div>
      </section>
      <section class="marketing-section">
        <div class="num-container">
          <h3 class="num marketing-h3">3</h3>
        </div>
        <div>
          <h3 class="marketing-h3 gray4">Pick your brackets</h3>
          <p class="marketing-p2">
            Keep your eye on the clock, and make sure all your picks are complete before wrestling starts! We offer all 10 championship weight brackets, so even if one bracket gets BUSTED, competitors have nine other brackets to make up ground.
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import RebelButton from '@/components/RebelButton.vue'

export default {
  components: {
    RebelButton
  },

  methods: {
    joinNow() {
      this.$router.push({ name: 'register' })
    }
  }
}
</script>

<style>
#hero-container {
  background: url('/hero-desktop.webp');
  background-size: 100vw;
  background-repeat: no-repeat;
  background-position: center center;
}

#hero-overlay {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.50) 44.38%, rgba(0, 0, 0, 0.2) 75.69%);
}

#hero-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  min-height: 28rem;
}

.hero {
  max-width: initial;
  padding: 0;
}

#btn-join-now {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}

#how-it-works {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-top: 3.5rem;
  padding-bottom: 4.5rem;
}

.logo-img {
  width: 14rem;
  height: auto;
}

.marketing-section-container {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  gap: 2.5rem;
}

.marketing-section {
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  align-content: flex-start;
  align-items: baseline;
  max-width: 32rem;
  gap: 1rem;
}

.marketing-section > div:last-child {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.num {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 0.25rem solid #6a89aa;
  color: #6a89aa;
  border-radius: 50%;

  line-height: 1.75rem;
  box-sizing: content-box;
}

.w-36{
  max-width: 36rem;
}

@media screen and (max-width: 1440px) {
  #hero-container {
    background-size: max(100vw, 90rem);
  }
}

@media screen and (max-width: 1280px) {
  #hero-container {
    background-size: max(100vw, 80rem);
  }
}

@media screen and (max-width: 1024px) {
  #hero-container {
    background-size: (110vw, 70rem);
  }

  #hero-text {
    min-height: 26rem;
  }

  #how-it-works {
    gap: 1.5rem;
  }

  .logo-img {
    width: 12rem;
    height: auto;
  }

  .marketing-section-container {
    gap: 2rem;
  }

  .marketing-section {
    gap: 0.75rem;
  }

  .num {
    width: 1.75rem;
    height: 1.75rem;
    border: 3px solid #6a89aa;
  }
}

@media screen and (max-width: 768px) {  
  #hero-container {
    background-size: (120vw, 58rem);
  }

  #hero-overlay {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.50) 44.38%, rgba(0, 0, 0, 0.30) 75.69%);
  }

  #how-it-works {
    gap: 1.25rem;
  }

  .marketing-section-container {
    flex-direction: column;
    align-items: center;
    max-width: initial;
    gap: 1.25rem;
  }

  .marketing-section {
    display: flex;
    align-content: flex-start;
    align-items: baseline;
    max-width: 24rem;
  }
}

@media screen and (max-width: 640px) {
  #hero-container {
    background-size: max(130vw, 52rem);
  }

  #hero-overlay {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.50) 44.38%, rgba(0, 0, 0, 0.40) 75.69%);
  }

  #hero-text {
    min-height: 24rem;
  }

  #btn-join-now {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 512px) {
  #hero-container {
    background-size: max(100vh, 52rem);
  }

  #how-it-works {
    gap: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 2rem;
  }

  .logo-img {
    width: 10rem;
    height: auto;
  }
}
</style>
