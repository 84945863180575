<template>
  <modal-dialog ref="myModal">
    <template v-if="bracketsArePublished" v-slot:modal-title>Leave League and Delete Picks?</template>
    <template v-else v-slot:modal-title>Leave League?</template>
    <template v-slot:modal-contents>
      <p v-if="bracketsArePublished">If you leave the league, all of your points will be lost, and your picks will be permanently deleted.</p>
      <p>
        Are you sure you want to permanently leave the league <span class="bold">{{ leagueName }}</span>?
      </p>
      <p>This cannot be undone.</p>
    </template>
    <template v-slot:modal-actions>
      <rebel-button v-if="bracketsArePublished" :is-loading="inProgress" type="primary" color="danger" text="Leave League and Delete Brackets"
        @click="confirm"></rebel-button>
      <rebel-button v-else :is-loading="inProgress" type="primary" color="danger" text="Leave League"
        @click="confirm"></rebel-button>
      <a @click.prevent="cancel">Cancel</a>
    </template>

  </modal-dialog>
</template>

<script>
import { recordLeagueUserLeave } from '@/services/GoogleTagManagerService.js'
import { useAuthenticationStore } from '@/stores/authentication'
import leagueService from '@/services/LeagueService.js'
import RebelButton from '@/components/RebelButton.vue'
import ModalDialog from '@/components/ModalDialog.vue'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore()
    }
  },

  components: {
    RebelButton,
    ModalDialog
  },

  data() {
    return {
      inProgress: false
    }
  },

  props: {
    bracketsArePublished: {
      type: Boolean,
      default: false
    },

    eventCategory: {
      required: false,
      default: 'unknown'
    },
    
    leagueId: {
      required: true
    },

    leagueName: {
      type: String,
      required: true
    }
  },

  methods: {
    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    async confirm() {
      this.inProgress = true
      try {
        await leagueService.removeMember(this.leagueId, this.authStore.loggedInUser.user.id)
        recordLeagueUserLeave({
          eventCategory: this.eventCategory
        })
        await this.$router.push({ name: 'events' })
      } catch {
        alert('Something went wrong.')
        this.inProgress = false
      }
    }
  }
}
</script>

