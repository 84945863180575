<template>
  <modal-dialog ref="myModal" @submit.prevent="confirm">
    <template v-slot:modal-title>Verify Your Email</template>
    <template v-slot:modal-contents>
      <p>We sent a verification email to <a href="mailto:{{ email }}">{{ email }}</a>.</p>
      <p>Click on the link in your email to verify your account.</p>  
    </template>
    <template v-slot:modal-actions>
      <rebel-button button-type="submit" type="primary" color="default" text="Go to Log In"></rebel-button>
    </template>
  </modal-dialog>
</template>

<script>
import RebelButton from '@/components/RebelButton.vue'
import ModalDialog from '@/components/ModalDialog.vue'

export default {
  components: {
    RebelButton,
    ModalDialog
  },

  props: {
    email: {
      type: String,
      default: 'you'
    }
  },

  methods: {
    closeModal() {
      this.$refs.myModal.close()

      if (this.$route.name !== 'login') {
        this.$router.push({ name: 'login' })
      }
    },

    confirm() {
      this.closeModal()
    }
  }
}
</script>
