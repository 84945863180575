<template>
  <section id="round-scoring-summary" class="flex col">

    <div class="flex col gap-0-75">
      <div class="flex row gap-1 align-baseline">
        <h3 class="grow">My Points</h3>
        <div class="caption gray3 text-center number-col">Won</div>
        <div class="caption gray3 text-center number-col">Lost</div>
        <div class="caption gray3 text-center number-col-last">Remaining</div>
      </div>
      <div class="flex col gap-0-5">
        <div class="flex row gap-1 align-baseline" v-for="(round, roundIndex) in selectedBracket.rounds"
          :key="roundIndex">
          <div class="flex row-to-col-512 align-baseline grow">
            <div class="body2 gray4">{{ round.roundName }}</div>
            <div class="caption gray2-5 grow">({{ round.pointsForCorrectProjection }} point{{
              round.pointsForCorrectProjection == 1 ? '' : 's' }} each)</div>
          </div>
          <div class="heading4 color-success text-center number-col">{{
            selectedBracket?.scoreByRound[round.round]?.pointsWon ?? 0 }}</div>
          <div class="heading4 color-danger text-center number-col">{{
            selectedBracket?.scoreByRound[round.round]?.pointsLost ?? 0 }}</div>
          <div class="heading4 gray2 number-col-last pl-0-75">{{
            selectedBracket?.scoreByRound[round.round]?.pointsRemaining }}</div>
        </div>
      </div>
      <div id="summary-separator"></div>
      <div class="flex row gap-1 align-baseline">
        <div class="heading4 grow">Total</div>
        <div class="heading4 color-success text-center number-col">{{ totalPointsWon }}</div>
        <div class="heading4 color-danger text-center number-col">{{ totalPointsLost }}</div>
        <div class="heading4 gray2 number-col-last pl-0-75">{{ totalPointsRemaining }}</div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    isPastEvent: {
      type: Boolean,
      required: true,
      default: false
    },

    selectedBracket: {
      type: Object,
      required: true,
      default: undefined
    }
  },

  computed: {
    totalPointsLost() {
      return this.selectedBracket.rounds.reduce((sum, curr) => {
        return sum + (this.selectedBracket?.scoreByRound[curr.round]?.pointsLost ?? 0)
      }, 0)
    },

    totalPointsRemaining() {
      return this.selectedBracket.rounds.reduce((sum, curr) => {
        return sum + (this.selectedBracket?.scoreByRound[curr.round]?.pointsRemaining ?? 0)
      }, 0)
    },

    totalPointsWon() {
      return this.selectedBracket.rounds.reduce((sum, curr) => {
        return sum + (this.selectedBracket?.scoreByRound[curr.round]?.pointsWon ?? 0)
      }, 0)
    }
  }
}
</script>

<style scoped>
#round-scoring-summary {
  background: var(--color-gray0-5);
  padding: 1.25rem 1rem;
  padding: 1.25rem 1rem;
  border-radius: 0.25rem;
  max-width: 30rem;
  gap: 1rem;
}

#summary-separator {
  border-top: 1px solid var(--color-gray2);
  width: 100%;
}

.number-col {
  width: 2rem;
}

.number-col-last {
  width: 4.25rem;
  box-sizing: border-box;
}

.row-to-col-512 {
  gap: 1rem;
}

@media screen and (max-width: 512px) {
  .row-to-col-512 {
    gap: 0rem;
  }
}</style>