<template>
  <modal-dialog ref="myModal" @submit.prevent="confirm">
    <template v-slot:modal-title>Assign New Commissioner?</template>
    <template v-slot:modal-contents>
      <p>
        Are you sure you want to assign
        <span class="bold">{{ user.name }}, </span><a href="mailto:{{ user.email }}">{{ user.email }}</a> to be
        the new league commissioner?
      </p>
      <p>There is only one commissioner per league, so this will revoke your commissioner status.</p>
    </template>
    <template v-slot:modal-actions>
      <rebel-button button-type="submit" type="primary" color="default" text="Assign New Commissioner"></rebel-button>
      <a @click.prevent="cancel">Cancel</a>
    </template>
  </modal-dialog>
</template>

<script>
import { recordLeagueAssignNewCommissioner } from '@/services/GoogleTagManagerService.js'
import emitEvents from '@/utils/emitEvents'
import RebelButton from '@/components/RebelButton.vue'
import ModalDialog from '@/components/ModalDialog.vue'

export default {
  components: {
    RebelButton,
    ModalDialog
  },

  props: ['user'],

  emits: [emitEvents.ASSIGN_LEAGUE_COMMISSIONER],

  methods: {
    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    confirm() {
      this.$emit(emitEvents.ASSIGN_LEAGUE_COMMISSIONER, this.user)
      recordLeagueAssignNewCommissioner()
      this.closeModal()
    }
  }
}
</script>
