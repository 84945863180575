<template>
  <h1 class="heading1">{{ event.name }}</h1>
  <div class="flex col justify-center align-center gap-0-25">
    <p class="heading3 gray3 mb-0-25 text-center">{{ subText }}</p>
    <label-admin v-if="showAdminData" data-testid="event-status-label" :event="event" />
  </div>
</template>

<script>
import LabelAdmin from '@/components/event-admin/LabelAdmin.vue'

export default {
  components: {
    LabelAdmin,
  },

  props: {
    event: {
      type: Object,
      required: true
    },

    subText: {
      type: String,
      required: false,
      default: ''
    },

    showAdminData: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.header-item {
  border-radius: 8px;
  overflow: hidden;
}

.light-background .header-item {
  background-color: white;
  box-shadow: 2px 4px 6px 0px #00000066;
}

.dark-background .header-item * {
  color: var(--color-white);
}
</style>