<template>
  <div v-if="isWithinMobileBreakpoint" class="flex align-stretch gap-0-75">
    <match-selector :is-editable="isEditable" :dark-background="darkBackground"
      :eligible-for-bonus="getUserPredictionState == 'correct'" :show-ways-to-win="showWaysToWin"
      :outcome-options="outcomeOptions" :result-option-id="matchup.winnerMatchOutcomeOptionId"
      :user-prediction-option-id="userMatchupProjection?.projectedMatchOutcomeOptionId"
      :weight-class="matchup.weightClass" :weight-class-units="weightClassUnits"
      @matchup-changed="(newResultOptionId) => resultOptionChanged(newResultOptionId)" />

    <match-participant-container :dark-background="darkBackground" :isMobileLayout="true">
      <template v-slot:content>
        <div class="flex col matchup-stacked grow" :class="`${getActualResultState} ${getIncorrectState} ${getLightDarkBackground}`">
          <match-participant ref="participant1" :key="lastUpdated" :actual-result-state="getActualResultState(1)"
            :dark-background="darkBackground" :has-selector="hasSelector(1)" :is-editable="isEditable"
            :isMobileLayout="true" :is-selected="participantIsSelected(1)" :who-is-editing="whoIsEditing"
            :participant-id="1" :participant-name="matchup.participant1Name"
            :participant-rank="matchup.participant1Rank" :user-prediction-state="getUserPredictionState"
            :user-score="getScoreForMatchup(1)" @matchup-changed="participantClicked(1)" />
          <match-participant ref="participant2" :key="lastUpdated" :actual-result-state="getActualResultState(2)"
            :dark-background="darkBackground" :has-selector="hasSelector(2)" :is-selected="participantIsSelected(2)"
            :who-is-editing="whoIsEditing" :participant-id="2" :is-editable="isEditable" :isMobileLayout="true"
            :participant-name="matchup.participant2Name" :participant-rank="matchup.participant2Rank"
            :user-prediction-state="getUserPredictionState" :user-score="getScoreForMatchup(2)"
            @matchup-changed="participantClicked(2)" />
        </div>
      </template>
    </match-participant-container>

  </div>
  <div v-else class="flex align-stretch gap-0-75">
    <match-participant ref="participant1" :key="lastUpdated" :actual-result-state="getActualResultState(1)"
      :dark-background="darkBackground" :has-selector="hasSelector(1)" :is-editable="isEditable"
      :is-selected="participantIsSelected(1)" :who-is-editing="whoIsEditing" :participant-id="1"
      :participant-name="matchup.participant1Name" :participant-rank="matchup.participant1Rank"
      :user-prediction-state="getUserPredictionState" :user-score="getScoreForMatchup(1)"
      @matchup-changed="participantClicked(1)" />

    <match-selector :is-editable="isEditable" :dark-background="darkBackground"
      :eligible-for-bonus="getUserPredictionState == 'correct'" :show-ways-to-win="showWaysToWin"
      :outcome-options="outcomeOptions" :result-option-id="matchup.winnerMatchOutcomeOptionId"
      :user-prediction-option-id="userMatchupProjection?.projectedMatchOutcomeOptionId"
      :weight-class="matchup.weightClass" :weight-class-units="weightClassUnits"
      @matchup-changed="(newResultOptionId) => resultOptionChanged(newResultOptionId)" />

    <match-participant ref="participant2" :key="lastUpdated" :actual-result-state="getActualResultState(2)"
      :dark-background="darkBackground" :has-selector="hasSelector(2)" :is-selected="participantIsSelected(2)"
      :who-is-editing="whoIsEditing" :participant-id="2" :is-editable="isEditable"
      :participant-name="matchup.participant2Name" :participant-rank="matchup.participant2Rank"
      :user-prediction-state="getUserPredictionState" :user-score="getScoreForMatchup(2)"
      @matchup-changed="participantClicked(2)" />
  </div>
</template>

<script>
import emitEvents from '@/utils/emitEvents.js';
import { validWhoIsEditing } from '@/utils/styles.js'
import MatchParticipant from '@/components/event-matches/MatchParticipant.vue';
import MatchParticipantContainer from '@/components/event-matches/MatchParticipantContainer.vue';
import MatchSelector from '@/components/event-matches/MatchSelector.vue';

export default {
  emits: [emitEvents.MATCHUP_CHANGED],

  components: {
    MatchParticipant,
    MatchParticipantContainer,
    MatchSelector
  },

  props: {
    darkBackground: {
      type: Boolean,
      required: false,
      default: false
    },

    isEditable: {
      type: Boolean,
      default: false,
    },

    matchup: {
      type: Object,
      required: true
    },

    outcomeOptions: {
      type: Array,
      required: false,
      defaultValue: () => []
    },

    showWaysToWin: {
      type: Boolean,
      default: false,
    },

    userMatchupProjection: {
      type: Object,
      required: false
    },

    weightClassUnits: {
      type: String,
      required: true
    },

    whoIsEditing: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return value == null || validWhoIsEditing.includes(value)
      }
    }
  },

  computed: {
    getIncorrectState() {
      return this.getUserPredictionState == 'incorrect' ? 'incorrect' : ''
    },
    
    getLightDarkBackground() {
      return this.darkBackground ? 'dark-background' : 'light-background'
    },

    getUserPredictionState() {
      if (this.whoIsEditing == null || this.whoIsEditing == 'admin' || this.whoIsEditing == 'admin-preview') {
        return null
      } else if (this.matchupHasWinner && this.userMatchupProjection?.projectedWinnerParticipantId == this.matchup.winnerParticipantId) {
        return 'correct'
      } else if (this.matchupHasWinner || (!this.isEditable && this.userMatchupProjection?.projectedWinnerParticipantId == null)) {
        return 'incorrect'
      } else {
        return 'user-result-tbd'
      }
    },

    isWithinMobileBreakpoint() {
      return this.windowWidth <= 512
    },

    matchupHasWinner() {
      return this.matchup.winnerParticipantId != null
    }
  },

  data() {
    return {
      lastUpdated: Date.now(),
      windowWidth: window.innerWidth
    }
  },

  methods: {
    getActualResultState(participantId) {
      if (!this.matchupHasWinner) return 'actual-result-tbd'
      if (this.matchup.winnerParticipantId == participantId) return 'winner'
      return 'loser'
    },

    getScoreForMatchup(participantId) {
      if (this.getUserPredictionState == 'correct' && this.matchup.winnerParticipantId == participantId) {
        return this.userMatchupProjection?.score
      }
    },

    hasSelector(participantId) {
      if (this.isEditable) {
        return true
      } else if (this.whoIsEditing == 'user') {
        return this.participantIsSelected(participantId)
      }
      return false
    },

    matchupChanged() {
      if (!this.isEditable) return

      if (this.whoIsEditing == 'user' || this.whoIsEditing == 'admin-preview') {

        const data = {
          matchupId: this.matchup.matchupId,
          projectedWinnerParticipantId: this.userMatchupProjection?.projectedWinnerParticipantId,
          projectedMatchOutcomeOptionId: this.userMatchupProjection?.projectedMatchOutcomeOptionId
        }

        this.$emit(emitEvents.MATCHUP_CHANGED, data)
      } else {
        const data = {
          matchupId: this.matchup.matchupId,
          winnerParticipantId: this.matchup.winnerParticipantId,
          winnerMatchOutcomeOptionId: this.matchup.winnerMatchOutcomeOptionId
        }

        this.$emit(emitEvents.MATCHUP_CHANGED, data)
      }

      this.lastUpdated = Date.now()
    },

    onResize() {
      this.windowWidth = window.innerWidth
    },

    resultOptionChanged(newResultOptionId) {
      if (!this.isEditable) return

      if (this.whoIsEditing == 'user' || this.whoIsEditing == 'admin-preview') {
        if (this.userMatchupProjection == null) return undefined

        this.userMatchupProjection.projectedMatchOutcomeOptionId = newResultOptionId
      } else {
        this.matchup.winnerMatchOutcomeOptionId = newResultOptionId
      }

      this.matchupChanged()
    },

    participantClicked(participantNumber) {
      if (!this.isEditable) return

      if (this.whoIsEditing == 'user' || this.whoIsEditing == 'admin-preview') {
        if (this.userMatchupProjection == null) return undefined

        const userClickedOnSelectedParticipant = this.userMatchupProjection.projectedWinnerParticipantId === participantNumber

        if (userClickedOnSelectedParticipant) {
          this.userMatchupProjection.projectedWinnerParticipantId = null
        } else {
          this.userMatchupProjection.projectedWinnerParticipantId = participantNumber
        }
      } else {
        const userClickedOnSelectedParticipant = this.matchup.winnerParticipantId === participantNumber

        if (userClickedOnSelectedParticipant) {
          this.matchup.winnerParticipantId = null
        } else {
          this.matchup.winnerParticipantId = participantNumber
        }
      }

      this.matchupChanged()

      if (participantNumber === 1) {
        this.$refs.participant2.unselect()
      } else {
        this.$refs.participant1.unselect()
      }
    },

    participantIsSelected(participantId) {
      if (this.whoIsEditing == 'user' || this.whoIsEditing == 'admin-preview') {
        return this.userMatchupProjection != null && this.userMatchupProjection.projectedWinnerParticipantId == participantId
      }

      return this.matchup.winnerParticipantId != null && this.matchup.winnerParticipantId == participantId
    },

    participantIsWinner(participantId) {
      return (this.matchupHasWinner && this.matchup.winnerParticipantId == participantId)
    },

    participantIsLoser(participantId) {
      return (this.matchupHasWinner && this.matchup.winnerParticipantId != participantId)
    }
  },

  mounted() {
    window.addEventListener('resize', this.onResize)
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style scoped>
.matchup-stacked.light-background {
  gap: 2px;
}

.matchup-stacked.incorrect {
  gap: 4px;
}
</style>