<template>
  <modal-dialog ref="myModal" @submit.prevent="submit">
    <template v-slot:modal-title>Customize Weight Classes</template>
    <template v-slot:modal-contents>
      <div class="layout-form-list">
        <div class="form-input">
          <label for="weights">Weight Classes</label>
          <textarea id="weights" type="text" :rows="weightClasses.weightClasses.length || 12"
            :placeholder="weightClassesPlaceholder" v-model="weightClassDetails.weights" required>
            </textarea>
        </div>

        <div class="form-input">
          <label for="units">Units</label>
          <select id="units" v-model="weightClassDetails.units" required>
            <option value="kg">kg</option>
            <option value="lbs" selected>lbs</option>
            <option value="">None</option>
          </select>
        </div>
      </div>
    </template>
    <template v-slot:modal-actions>
      <rebel-button button-type="submit" type="primary" color="default" text="Save" :is-loading="isSaving"
        :disabled="!dataIsDirty"></rebel-button>
      <a href="javascript:void(null)" @click.prevent="cancel">Cancel</a>
    </template>
  </modal-dialog>
</template>

<script>
import defaults from '@/utils/defaults'
import emitEvents from '@/utils/emitEvents'
import eventService from '@/services/EventService'
import ModalDialog from '@/components/ModalDialog.vue'
import RebelButton from '@/components/RebelButton.vue'

export default {
  created() {
    this.initialWeightClassDetails = { ...this.weightClassDetails }
  },

  components: {
    ModalDialog,
    RebelButton
  },

  emits: [emitEvents.EVENT_WEIGHT_CLASSES_CHANGED],

  props: {
    eventId: {
      type: [Number, String],
      required: true
    },

    weightClasses: {
      type: Object,
      default: () => {
        return {
          weightClasses: defaults.WEIGHT_CLASS,
          units: defaults.WEIGHT_CLASS_UNITS
        }
      }
    }
  },

  computed: {
    dataIsDirty() {
      const initialWeights = this.makeUniqueIntArray(this.initialWeightClassDetails.weights)
      const currWeights = this.makeUniqueIntArray(this.weightClassDetails.weights)

      return this.initialWeightClassDetails.units !== this.weightClassDetails.units ||
        initialWeights.length != currWeights.length ||
        initialWeights.some(wc => !currWeights.includes(wc))
    },

    weightClassesPlaceholder() {
      return defaults.WEIGHT_CLASSES.join('&#10;')
    }
  },

  data() {
    return {
      isSaving: false,

      initialWeightClassDetails: undefined,

      weightClassDetails: {
        weights: this.weightClasses.weightClasses.join('\n'),
        units: this.weightClasses.units
      },
    }
  },

  methods: {
    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    makeUniqueIntArray(data) {
      const notUniqueArr = data
        .split('\n')
        .map((line) => line.trim())
        .filter((line) => line.length > 0 && /^-?\d+$/.test(line))
        .map((line) => parseInt(line))

      return [...new Set(notUniqueArr)]
    },

    async submit() {
      this.isSaving = true
      try {
        const dto = {
          weightClasses: this.makeUniqueIntArray(this.weightClassDetails.weights),
          units: this.weightClassDetails.units
        }

        await eventService.updateWeightClasses(this.eventId, dto)

        this.$emit(emitEvents.EVENT_WEIGHT_CLASSES_CHANGED, dto)
      } finally {
        this.isSaving = false
      }
    }
  }
}
</script>