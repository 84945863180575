<template>
  <modal-dialog ref="myModal">

    <template v-slot:modal-title>Regenerate Invite Link?</template>

    <template v-slot:modal-contents>
      <p>This will invalidate any previous invite links to this league, and create a new link for you to share.</p>
    </template>


    <template v-slot:modal-actions>
      <rebel-button :is-loading="isRegeneratingLink" type="primary" color="danger" text="Regenerate Link"
        @click.prevent="regenerateLink"></rebel-button>
      <a @click.prevent="cancel">Cancel</a>
    </template>
  </modal-dialog>
</template>

<script>
import leagueService from '@/services/LeagueService'

import RebelButton from '@/components/RebelButton.vue'
import ModalDialog from '@/components/ModalDialog.vue'

export default {
  components: {
    RebelButton,
    ModalDialog
  },

  data() {
    return {
      isRegeneratingLink: false
    }
  },

  props: {
    leagueId: {
      required: true
    }
  },

  methods: {
    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    async regenerateLink() {
      this.isRegeneratingLink = true
      try {
        await leagueService.deleteInviteLink(this.leagueId)
        this.closeModal()
      } catch {
        alert('Something went wrong')
      } finally {
        this.isRegeneratingLink = false
      }
    }
  }
}
</script>
