<template>
  <select :id="id" ref="select" :required="required" :disabled="disabled" :value="selectedTimeZoneIndex" @change="emitChangeEvent">
    <option value="">-- Select time zone --</option>
    <option v-for="(zone, index) in sortedTimeZones" :key="index" :value="index">
      {{ zone.name }} (UTC-{{ getFormattedOffset(zone) }})
    </option>
  </select>
</template>

<script>
import emitEvents from '@/utils/emitEvents'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'

export default {

  computed: {
    sortedTimeZones() {
      return moment.tz.zonesForCountry('US', true).sort((a, b) => b.offset - a.offset)
    },

    selectedTimeZoneIndex() {
      if (this.value == null || this.value == "") {
        return ""
      }

      return this.sortedTimeZones.findIndex((tz) => tz.name === this.value)
    }
  },

  emits: [emitEvents.TIME_ZONE_UPDATED],

  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    id: {
      type: String,
      default: ''
    },

    required: {
      type: Boolean,
      default: false
    },

    timestampForOffset: {
      type: Number,
      required: false,
      default: () => new Date().getTime()
    },

    value: {
      default: ""
    }
  },

  methods: {
    emitChangeEvent(event) {
      if (event.target.value == "") {
        this.$emit(emitEvents.TIME_ZONE_UPDATED, { name: null } )
        return
      }

      this.$emit(emitEvents.TIME_ZONE_UPDATED, this.sortedTimeZones[event.target.value])
    },

    getFormattedOffset(zone) {
      const offset = moment.tz.zone(zone.name).utcOffset(this.timestampForOffset)

      if (offset === 0) {
        return '-00:00'
      }

      const hoursOffset = offset / 60 < 10 ? `0${offset / 60}` : `${offset / 60}`
      const minutesOffset = offset % 60 === 0 ? `${offset % 60}0` : `${offset % 60}`

      return `${hoursOffset}:${minutesOffset}`
    }
  }
}
</script>

