<template>
  <div ref="leaderboard" class="flex col gap-1">
    <div class="flex space-between gap-1 align-center">
      <h2>Leaderboard</h2>
      <rebel-button color="default" type="ghosted" text="My Results" @click="viewMyResults"></rebel-button>
    </div>

    <weight-class-selector v-if="isMounted" @selected-weight-class-change="weightClassChange"
      :containing-element="$refs.leaderboard" :weightClasses="weightClassDetails.weightClasses"
      :units="weightClassDetails.units"></weight-class-selector>

    <table
      style="width: calc(100vw - 4rem); max-width: min(1440px, 90rem); border-spacing: 0 6px; border-collapse: separate">
      <tr v-for="member in rankedLeagueMembers" :key="member.user.id">
        <td class="text-right" style="min-width: 1rem">
          <p class="heading2">{{ member.rank }}.</p>
        </td>
        <td style="padding: 0.5rem; white-space: nowrap">
          <div class="flex align-center">
            <avatar-component :src="member.user.imageSrc" :height="24" :width="24" />
            <p class="body2 ml-0-25">{{ member.user.displayName }}</p>
          </div>
        </td>
        <td style="width: 99%">
          <!-- take up all remaining space in the table -->
          <div>
            <two-value-bar v-if="isMounted" :value1="member.totalScore" :value2="member.possibleScore"
              :showValue2="isEventInProgress" :max-value="maxOfCurrentRankedMembers"
              :containing-element="$refs.leaderboard"></two-value-bar>
          </div>
        </td>
      </tr>
    </table>

    <leaderboard-data-key v-if="isEventInProgress" label1="Points won" label2="Points possible"></leaderboard-data-key>
  </div>
</template>

<script>
import defaults from '@/utils/defaults'
import eventTypes from '@/utils/eventTypes'
import AvatarComponent from '@/components/AvatarComponent.vue'
import LeaderboardDataKey from '@/components/leagues/LeaderboardDataKey.vue'
import RebelButton from '@/components/RebelButton.vue'
import TwoValueBar from '@/components/TwoValueBar.vue'
import WeightClassSelector from '@/components/WeightClassSelector.vue'

export default {
  components: {
    AvatarComponent,
    LeaderboardDataKey,
    RebelButton,
    TwoValueBar,
    WeightClassSelector
  },

  props: {
    eventTypeId: {
      type: Number,
      required: true
    },

    isEventInProgress: {
      type: Boolean,
      defaultValue: true
    },

    leagueId: {
      required: true
    },

    leagueMembers: {
      type: Array,
      default: () => []
    },

    weightClassDetails: {
      type: Object,
      default: () => {
        return {
          weightClasses: defaults.WEIGHT_CLASSES,
          units: defaults.WEIGHT_CLASS_UNITS
        }
      }
    }
  },

  data() {
    return {
      isMounted: false,
      selectedWeightClass: -1
    }
  },

  computed: {
    rankedLeagueMembers() {
      let leagueMembersCopy = [...this.leagueMembers]

      // add on total score, possible score so that it can be used within the template
      leagueMembersCopy.forEach((leagueMember) => {
        if (this.selectedWeightClass == -1) {
          leagueMember.totalScore = leagueMember.leagueScore
          leagueMember.possibleScore = leagueMember.pointsPossible
          return
        }

        const bracket = leagueMember.brackets.find((b) => b.weightClass === this.selectedWeightClass)
        leagueMember.totalScore = bracket?.totalScore || 0
        leagueMember.possibleScore = bracket?.possibleScore || 0
      })

      leagueMembersCopy = leagueMembersCopy.sort((lm1, lm2) => {
        // sort by score
        // if the same score, sort by possible points
        // if the same possible points, sort by displayName
        // if the same displayName, sort by userId

        if (lm1.totalScore === lm2.totalScore) {
          if (lm1.possibleScore === lm2.possibleScore) {
            if (lm1.user.displayName.toLowerCase() === lm2.user.displayName.toLowerCase()) {
              return lm1.user.id > lm2.user.id ? -1 : 1
            } else {
              return lm1.user.displayName.toLowerCase() > lm2.user.displayName.toLowerCase() ? 1 : -1
            }
          } else {
            return lm1.possibleScore > lm2.possibleScore ? -1 : 1
          }
        }

        return lm1.totalScore > lm2.totalScore ? -1 : 1
      })

      if (leagueMembersCopy.length === 0) return leagueMembersCopy

      // add on rank
      leagueMembersCopy[0].rank = 1

      for (let i = 1; i < leagueMembersCopy.length; i++) {
        if (leagueMembersCopy[i - 1].totalScore === leagueMembersCopy[i].totalScore) {
          leagueMembersCopy[i].rank = leagueMembersCopy[i - 1].rank
        } else {
          leagueMembersCopy[i].rank = i + 1
        }
      }

      return leagueMembersCopy
    },

    maxOfCurrentRankedMembers() {
      return this.rankedLeagueMembers.reduce((acc, curr) => (acc > curr.possibleScore ? acc : curr.possibleScore), 0)
    },

    overallMaxOfPossibles() {
      return this.leagueMembers.reduce((acc, curr) => (acc > curr.pointsPossible ? acc : curr.pointsPossible), 0)
    }
  },

  methods: {
    viewMyResults() {
      if (eventTypes.isPickEm(this.eventTypeId)) {
        this.$router.push({ name: 'league-pick-em-view', params: { leagueId: this.leagueId } })
        return
      }

      this.$router.push({ name: 'league-brackets-view', params: { leagueId: this.leagueId } })
    },

    weightClassChange(value) {
      this.selectedWeightClass = value
    }
  },

  mounted() {
    this.isMounted = true
  }
}
</script>

<style scoped>
td * {
  vertical-align: middle;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  vertical-align: middle;
}
</style>