<template>
  <modal-dialog ref="myModal" @submit.prevent="confirm">
    <template v-slot:modal-title>Success!</template>
    <template v-slot:modal-contents>
      <p>
        Your password has been reset successfully.
      </p>
    </template>
    <template v-slot:modal-actions>
      <rebel-button button-type="submit" type="primary" color="default" text="Go to Log In"></rebel-button>
    </template>
  </modal-dialog>
</template>

<script>
import RebelButton from '@/components/RebelButton.vue'
import ModalDialog from '@/components/ModalDialog.vue'

export default {
  components: {
    RebelButton,
    ModalDialog
  },

  methods: {
    closeModal() {
      this.$refs.myModal.close()
      this.$router.push({ name: 'login' })
    },

    confirm() {
      this.closeModal()
    }
  }
}
</script>
