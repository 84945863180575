<template>
  <modal-dialog ref="myModal" @submit.prevent="confirm">
    <template v-slot:modal-title>Bracket Re-Seeded</template>
    <template v-slot:modal-contents>
      <div>
        <span>The {{ weightClass }} {{ units }} weight class has been re-seeded. You will need to select new
          picks for this weight class.</span>
      </div>
    </template>
    <template v-slot:modal-actions>
      <rebel-button button-type="submit" :is-loading="isClosing" type="primary" color="default"
        text="Ok"></rebel-button>
    </template>
  </modal-dialog>
</template>


<script>
import RebelButton from '@/components/RebelButton.vue'
import ModalDialog from '@/components/ModalDialog.vue'

export default {
  components: {
    RebelButton,
    ModalDialog
  },

  data() {
    return {
      isClosing: false
    }
  },

  props: {
    weightClass: {
      type: String,
      required: true
    },

    units: {
      type: String,
      default: 'lbs'
    },
  },

  methods: {
    closeModal() {
      this.$refs.myModal.close()
    },

    confirm() {
      this.isClosing = true
      this.closeModal()
    }
  }
}
</script>
