<template>
  <div v-if="!isLoading" class="flex col gap-1-5">
    <div class="flex space-between align-center">
      <header-action-bar :breakpointOuter="512" :breakpointInner="432" :dark-background="darkBackground">
        <template v-slot:linkBackTo>
          <link-back-to :router-link-to="{ name: 'league-details', params: { leagueId: league.leagueId } }"
            :page-name="league.name" :dark-background="darkBackground" />
        </template>
        <template v-slot:primaryAction1>
          <select class="leagueUserSelect" :class="{ 'dark-background': darkBackground }"
            v-model="selectedLeagueUserViewing" @change="updateSelectedLeagueUserViewing">
            <option v-for="opt in resultSelectorOptions" :key="opt.value" :value="opt.value">
              {{ opt.text }}
            </option>
          </select>
        </template>
        <template v-if="league.event.infoUrl" v-slot:secondaryAction1>
          <rebel-button :dark-background="darkBackground" @click="goToEventInfo" type="ghosted" color="default"
            text="Event Info" class="nowrap">
            <template v-slot:icon-leading>
              <arrow-top-right-on-square-icon class="icon-20" />
            </template>
          </rebel-button>
        </template>
      </header-action-bar>
    </div>

    <pick-em-header :dark-background="darkBackground" :subText="eventSubText" :show-admin-data="false"
      :show-league-data="viewingActualResults === false" :event="league.event" :league-data="pickEmHeaderLeagueData"
      :leagues="allLeagueOptions" :league-id="league.leagueId" @league-changed="leagueChanged"
      :key="`header-${lastLoadedTime}`" />

    <pick-em-matches v-if="viewingActualResults" :dark-background="darkBackground" :event-data="league.event"
      :user-projections="viewingActualResults ? undefined : pickEmUserMatchups"
      :key="`matches1-${lastLoadedTime}`" />
    <pick-em-matches v-else :dark-background="darkBackground" :event-data="league.event" who-is-editing="user"
      :user-projections="pickEmUserMatchups" :key="`matches2-${lastLoadedTime}`" />
  </div>
</template>

<script>
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/20/solid'
import HeaderActionBar from '@/components/HeaderActionBar.vue'
import LinkBackTo from '@/components/LinkBackTo.vue'
import PickEmHeader from '@/components/feature/pick-em/header/PickEmHeader.vue'
import PickEmMatches from '@/components/feature/pick-em/PickEmMatches.vue'
import RebelButton from '@/components/RebelButton.vue'

import leagueService from '@/services/LeagueService'

import { useAuthenticationStore } from '@/stores/authentication'
import { useEventAdminStore } from '@/stores/eventAdmin'
import DateUtils from '@/utils/dateUtils'
import PubSub from 'pubsub-js'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore(),
      eventAdminStore: useEventAdminStore()
    }
  },

  components: {
    ArrowTopRightOnSquareIcon,
    LinkBackTo,
    HeaderActionBar,
    PickEmHeader,
    PickEmMatches,
    RebelButton,
  },

  computed: {
    eventSubText() {
      return this.formatDateTime(this.league.event.startDateTime)
    },

    leagueId() {
      return this.$route.params.leagueId
    },

    resultSelectorOptions() {
      const options = []
      options.push({ text: 'Actual Results', value: this.ACTUAL_RESULTS_VALUE })

      if (this.authStore.loggedInUser == null) {
        return options
      }

      const myMember = this.league.members.find((member) => member.user.id === this.authStore.loggedInUser.user.id)

      if (myMember.hasAtLeastOnePrediction) {
        options.push({ text: this.authStore.loggedInUser.user.displayName, value: this.authStore.loggedInUser.user.id })
      }

      const remainingLeagueMemberOptions = this.league.members
        .filter((m) => m.hasAtLeastOnePrediction)
        .flatMap((m) => m.user)
        .filter((u) => u.id != this.authStore.loggedInUser.user.id)
        .map((u) => {
          return { text: u.displayName, value: u.id }
        })

      options.push(...remainingLeagueMemberOptions)

      return options
    },

    pickEmHeaderLeagueData() {
      return {
        leagueOptions: this.allLeagueOptions,
        pointsWon: this.selectedLeagueMember?.leagueScore ?? 0,
        pointsPossible: this.selectedLeagueMember?.pointsPossible ?? 0,
        leagueRank: this.selectedLeagueMember?.leagueRankDisplay ?? '-',
        leagueMemberCount: this.league?.members?.length ?? 0,
        matnessRank: this.selectedLeagueMember?.overallRankDisplay ?? '-',
        matnessMemberCount: this.league?.event?.leagueMemberCount ?? 0,
        predictedTeam1Score: this.selectedLeagueMember?.predictedTeam1Score ?? 0,
        predictedTeam2Score: this.selectedLeagueMember?.predictedTeam2Score ?? 0,
      }
    },

    selectedLeagueMember() {
      return this.league?.members?.find(member => member.user.id === this.selectedLeagueUserViewing)
    },

    viewingActualResults() {
      return this.selectedLeagueUserViewing === this.ACTUAL_RESULTS_VALUE
    }
  },

  async created() {
    await this.loadData()

    const myMember = this.league.members.find((member) => member.user.id === this.authStore.loggedInUser.user.id)
    this.pickEmUserMatchups = myMember.pickEmUserMatchups

    // if I switched from another league, try to maintain viewing the same user
    const leagueUserViewing = this.resultSelectorOptions.find(rso => rso.value === this.$route.query.viewingUserId)
    if (leagueUserViewing != null) {
      this.pickEmUserMatchups = leagueUserViewing.pickEmUserMatchups
      this.selectedLeagueUserViewing = leagueUserViewing;
    }

    // if I don't have predictions, default to actual results
    if (!myMember.hasAtLeastOnePrediction) {
      this.selectedLeagueUserViewing = this.ACTUAL_RESULTS_VALUE
    }

    this.league.members.forEach((m) => (m.pickEmUserMatchupsLoaded = true)) // leagueService.getById is loading all matchups at the moment


    this.lastLoadedTime = new Date().getMilliseconds()
    this.isLoading = false
  },

  data() {
    return {
      ACTUAL_RESULTS_VALUE: -1,

      isLoading: true,

      allLeagueOptions: [],
      league: {},

      pickEmUserMatchups: undefined,

      selectedLeagueUserViewing: this.authStore.loggedInUser?.user?.id,

      lastLoadedTime: new Date().getMilliseconds(),

      darkBackground: this.$route.meta.darkBackground,
    }
  },

  methods: {
    changeLeagueName(topic, message) {
      const isMyLeague = message.leagueId != null && this.leagueId == message.leagueId

      if (isMyLeague && message.name != null) {
        this.league.name = message.name
      }
    },

    formatDateTime(date) {
      return DateUtils.formatDateTime(date, this.authStore?.loggedInUser?.user?.timeZoneSetting)
    },

    goToEventInfo() {
      window.open(this.league.event.infoUrl, '_blank')
    },

    goToPickEmEditPage() {
      this.$router.push({ name: 'league-pick-em-edit', params: { leagueId: this.leagueId } })
    },

    async leagueChanged(leagueIdToView) {
      this.$router.push({ name: 'league-pick-em-view', params: { leagueId: leagueIdToView }, query: { viewingUserId: this.selectedLeagueUserViewing } })
    },

    async loadData() {
      const response = await leagueService.getById(this.leagueId)

      this.league = response.data
      this.eventAdminStore.initialize(this.league.event)

      this.allLeagueOptions = (await leagueService.getAllOptions(this.league.event.eventId)).data

      if (this.league == null) {
        this.$router.push({ name: 'permission-denied' })
      }

      if (this.league.event.upcoming) {
        this.goToPickEmEditPage()
      }

      if (!this.league.event.participationEnded) {
        this.goToPickEmEditPage()
      }
    },

    async reloadPage(topic, message) {
      try {
        const isEventMessage = message.eventId != null
        const isMyEvent = this.league.event.eventId == message.eventId
        const isMyLeague = this.leagueId == message.leagueId
        const isALeagueMessage = message.leagueId != null

        if (isEventMessage && !isMyEvent) return
        if (isALeagueMessage && !isMyLeague) return

        const response = await leagueService.getById(this.leagueId)
        if (response?.data?.members != null) {
          const userLeftLeague = this.league.members.length < response.data.members

          this.league = response.data

          const selectedMember = this.league.members.find((member) => member.user.id === this.selectedLeagueUserViewing)
          const viewingBracketForUserThatLeftLeague = selectedMember == null

          if (this.viewingActualResults || (userLeftLeague && viewingBracketForUserThatLeftLeague)) {
            this.updateSelectedLeagueUserViewing({ currentTarget: { value: this.ACTUAL_RESULTS_VALUE } })
            // doesn't matter whose brackets we view when viewing actual results. just pick the first
            if (response.data.members.length) this.pickEmUserMatchups = response.data.members[0]?.pickEmUserMatchups
          } else {
            this.setPickEmUserMatchupsLoadedForMember(selectedMember, selectedMember.pickEmUserMatchups)
            this.pickEmUserMatchups = selectedMember.pickEmUserMatchups
          }
          this.lastLoadedTime = new Date().getMilliseconds()
        }
      } catch {
        // 
      }
    },

    setPickEmUserMatchupsLoadedForMember(member, pickEmUserMatchups) {
      member.pickEmUserMatchups = pickEmUserMatchups
      member.pickEmUserMatchupsLoaded = true
    },

    updateSelectedLeagueUserViewing(e) {
      const val = Number(e.currentTarget.value)

      if (val === this.ACTUAL_RESULTS_VALUE) {
        return
      }

      const selectedMember = this.league.members.find((member) => member.user.id === val)

      if (!selectedMember.pickEmUserMatchupsLoaded) {
        // const response = await pickEmService.getPickEmUserMatchups(this.leagueId, selectedMember.user.id)
        // this.setPickEmUserMatchupsLoadedForMember(selectedMember, response.data)
      }

      this.pickEmUserMatchups = selectedMember.pickEmUserMatchups

    }
  },

  mounted() {
    PubSub.subscribe('league-members-changed', this.reloadPage)
    PubSub.subscribe('event-results-published', this.reloadPage)
    PubSub.subscribe('league-name-changed', this.changeLeagueName)
  },


  unmounted() {
    try {
      PubSub.subscribe('league-members-changed', this.reloadPage)
      PubSub.subscribe('event-results-published', this.reloadPage)
      PubSub.unsubscribe('league-name-changed', this.changeLeagueName)
    } catch {
      /* empty */
    }
  },

}
</script>

<style scoped>
.leagueUserSelect {
  background-color: var(--color-action);
  background-image: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" class="size-5"><path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" /></svg>');
  background-position-x: calc(100% - .5rem);
  background-position-y: 50%;
  background-size: 1.25rem;
  color: var(--color-white);
  font-size: 1rem;
  font-weight: 600;
  padding-top: .625rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  padding-right: 1.75rem;
  text-align: center;
  width: fit-content;
}

.leagueUserSelect>option {
  background-color: var(--color-white);
  color: var(--color-gray4);
}

.leagueUserSelect.dark-background {
  background-color: var(--color-dark-orange);
  border: 2px solid var(--color-white);
}
</style>