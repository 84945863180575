import { defineStore } from 'pinia'
import eventService from '../services/EventService'

export const useEventAdminStore = defineStore('eventAdmin', {
  state: () => ({
    backgroundImage: '',
    backgroundImageLoaded: false,
    blendMode: null,
    gaussianBlur: null,
    opacity: null,
    saturation: null,
    isPublishing: false,
    teamsChanged: false
  }),

  getters: {

  },

  actions: {
    initialize(event) {
      this.setBackgroundImage(event.backgroundImage)
      this.setDesign({ blendMode: event.blendMode, gaussianBlur: event.gaussianBlur, opacity: event.opacity, saturation: event.saturation })

      var img = new Image();
      img.onload = () => {
        this.backgroundImageLoaded = true
      }
      img.src = this.backgroundImage;
      if (img.complete) img.onload();
    },

    async publishEvent(eventId) {
      this.isPublishing = true
      try {
        await eventService.publishEvent(eventId)
        location.reload()
      } catch {
        alert('Something went wrong')
      } finally {
        this.isPublishing = false
      }
    },

    setBackgroundImage(backgroundImage) {
      this.backgroundImage = backgroundImage
    },

    setDesign(designDto) {
      this.blendMode = designDto.blendMode
      this.gaussianBlur = designDto.gaussianBlur
      this.opacity = designDto.opacity
      this.saturation = designDto.saturation
    }
  },

  persist: {
    enabled: false,
  }
})
