<template>
  <modal-dialog ref="myModal">
    <template v-slot:modal-title>{{ hasImage ? 'Edit' : 'Add' }} {{ headerLabel }} Image?</template>
    <template v-slot:modal-contents>
      <div class="flex col gap-1">
        <p v-if="descriptionText" class="body2">
          {{ descriptionText }}
        </p>

        <div v-show="editingCroppie" class="flex gap-0-5 justify-center align-center">
          <img id="img-preview" src="" alt=""  />
        </div>
        <div v-show="!editingCroppie">
          <div v-if="hasImage" class="flex justify-center align-center">
            <img ref="imgResult" id="img-result" :src="img.src" :alt="`${headerLabel} Image`" :width="Math.min(300, imageWidth)">
          </div>
          <div v-else>
            <div v-if="placeholderImageName.toLowerCase() === 'landscape'">
              <svg :width="300" :height="300" viewBox="0 0 300 300" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2702_222871)">
                  <rect width="300" height="300" fill="#DFE6ED" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M29.3999 86.35C29.3999 69.6986 42.8985 56.2 59.5499 56.2H240.45C257.101 56.2 270.6 69.6986 270.6 86.35V213.65C270.6 230.301 257.101 243.8 240.45 243.8H59.5499C42.8985 243.8 29.3999 230.301 29.3999 213.65V86.35ZM49.4999 164.213V213.65C49.4999 219.2 53.9994 223.7 59.5499 223.7H240.45C246 223.7 250.5 219.2 250.5 213.65V177.613L220.756 147.869C216.832 143.944 210.468 143.944 206.543 147.869L180.963 173.45L187.256 179.744C191.181 183.668 191.181 190.032 187.256 193.956C183.332 197.881 176.968 197.881 173.043 193.956L103.506 124.419C99.5816 120.494 93.2183 120.494 89.2935 124.419L49.4999 164.213ZM176.8 109.8C176.8 117.201 170.801 123.2 163.4 123.2C155.999 123.2 150 117.201 150 109.8C150 102.399 155.999 96.4 163.4 96.4C170.801 96.4 176.8 102.399 176.8 109.8Z"
                    fill="#B0BFCF" />
                </g>
                <defs>
                  <clipPath id="clip0_2702_222871">
                    <rect width="300" height="300" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div v-else>
              <svg :width="300" :height="300" viewBox="0 0 300 300" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2702_222939)">
                  <rect width="300" height="300" rx="150" fill="#DFE6ED" />
                  <g clip-path="url(#clip1_2702_222939)">
                    <circle cx="150" cy="106.25" r="62.5" fill="#B0BFCF" />
                    <circle cx="150" cy="306.25" r="125" fill="#B0BFCF" />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_2702_222939">
                    <rect width="300" height="300" rx="150" fill="white" />
                  </clipPath>
                  <clipPath id="clip1_2702_222939">
                    <rect width="300" height="300" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>

        <div v-if="errorMessages" class="flex col gap-0-125">
          <span class="color-danger body2" v-for="(msg, msgIndex) in errorMessages" :key="msgIndex">{{ msg }}</span>
        </div>
      </div>
    </template>
    <template v-slot:modal-actions>
      <template v-if="editingCroppie">
        <rebel-button button-type="button" @click="saveCroppieResult" type="primary" color="default" text="Save"
          :is-loading="savingCroppie" :disabled="errorMessages.length > 0" />
        <a @click.prevent="cancelCroppieResult">Cancel</a>
      </template>
      <template v-else>
        <rebel-button button-type="button" @click="uploadImage" type="primary" color="default" text="Upload Image"
          class="nowrap">
          <template v-slot:icon-leading>
            <camera-icon class="icon-20"/>
          </template>
        </rebel-button>

        <rebel-button button-type="button" v-if="hasImage" @click="deleteImage" type="ghosted" color="danger"
          text="Delete Image" class="nowrap">
          <template v-slot:icon-leading>
            <trash-icon class="icon-20"/>
          </template>
        </rebel-button>

        <a @click.prevent="cancel">Cancel</a>
      </template>

      <input ref="imgUploader" id="img-uploader" class="btn-file" type="file" :accept="acceptedFileTypesString"
        @change="previewFile" v-show="false" />
    </template>
  </modal-dialog>
</template>

<script>
import { fileTypeFromStream } from 'file-type';
import { CameraIcon, TrashIcon } from '@heroicons/vue/20/solid';

import Croppie from 'croppie'
import 'croppie/croppie.css'
import { v4 as uuid } from 'uuid'
import fileService from '@/services/FileService.js'

import emitEvents from '../utils/emitEvents'
import matnessImageTypes from '../utils/matnessImageTypes'
import RebelButton from './RebelButton.vue'
import ModalDialog from './ModalDialog.vue'

export default {
  components: {
    CameraIcon,
    ModalDialog,
    RebelButton,
    TrashIcon
  },

  props: {
    matnessImageType: {
      type: String,
      required: true,
      validator(value) {
        return matnessImageTypes.includes(value)
      }
    },

    headerLabel: {
      type: String,
      required: false
    },

    descriptionText: {
      type: String,
      required: false
    },

    imageHeight: {
      type: Number,
      required: true,
    },

    imageSrc: {
      type: String,
      required: false,
    },

    imageWidth: {
      type: Number,
      required: true,
    },

    boundaryHeight: {
      type: Number,
      required: true
    },

    boundaryWidth: {
      type: Number,
      required: true
    },

    maskType: {
      type: String,
      required: false,
      default: 'square',
      validator(value) {
        return ['circle', 'square'].includes(value)
      }
    },

    maxFileSizeBytes: {
      type: Number,
      required: false,
      default: 10000000
    },

    placeholderImageName: {
      type: String,
      required: false,
      default: 'landscape'
    },

    showZoomer: {
      type: Boolean,
      required: false,
      default: true
    },

    supportedFileTypes: {
      type: String,
      required: false
    }
  },

  computed: {
    acceptedFileTypesString() {
      if (!this.supportedFileTypes) return undefined

      return this.supportedFileTypes.split('|').join(', ')
    },

    hasImage() {
      return !!this.img.src
    },

    invalidDimensionsErrorMessage() {
      return `Minimum image size ${this.imageWidth}x${this.imageHeight} pixels.`
    },

    invalidFileTypeErrorMessage() {
      if (this.supportedFileTypes.split('|').length === 1) {
        return `File type must be ${this.acceptedFileTypesString}`
      }

      return `File type must be one of the following: ${this.acceptedFileTypesString}`
    },

    invalidFileSizeErrorMessage() {
      return `Maximum file size ${this.maxFileSizeBytes / 1000}KB`
    },

    widthToResizeTo() {
      return 3 * this.imageWidth
    },

    heightToResizeTo() {
      return 3 * this.imageHeight
    }
  },

  data() {
    return {
      editingCroppie: false,
      savingCroppie: false,

      errorMessages: [],

      img: {
        src: this.imageSrc
      },
    }
  },


  methods: {
    cancelCroppieResult() {
      this.errorMessages = []
      this.editingCroppie = false
      if (this.croppieInstance != null) {
        this.croppieInstance.destroy()
        this.croppieInstance = null
      }
      const fileElement = document.getElementById('img-uploader')
      if (fileElement) {
        fileElement.value = ''
      }
      const previewElement = document.getElementById('img-preview')
      if (previewElement) {
        previewElement.src = ''
      }
    },

    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    deleteImage() {
      // delete from AWS bucket already happening when overwriting image src for particular
      // matness image type
      // fileService.delete(this.matnessImageType, this.img.src)
      this.img.src = ''
      this.$emit(emitEvents.IMAGE_UPLOADER_DELETED)
    },

    uploadImage() {
      this.cancelCroppieResult()

      document.getElementById('img-uploader').click()
    },

    previewFile() {
      const croppieOptions = {
        enableExif: true,
        viewport: {
          width: Math.min(300, this.boundaryWidth, this.imageWidth),
          height: (this.imageHeight / this.imageWidth) * Math.min(300, this.boundaryWidth, this.imageWidth), // preserve aspect ratio, without making it bigger than 300 px
          type: this.maskType
        },
        boundary: {
          width: this.boundaryWidth,
          height: this.boundaryHeight
        },
        showZoomer: this.showZoomer,
      }
      const previewElement = document.getElementById('img-preview')
      const file = document.getElementById('img-uploader').files[0]
      const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          // convert image file to base64 string
          previewElement.src = reader.result

          previewElement.onload = () => {
            this.validate(file).then((isValid) => {
              if (isValid) {

                if (this.croppieInstance != null) {
                  this.croppieInstance.destroy()
                  this.croppieInstance = null
                }

                this.croppieInstance = new Croppie(previewElement, croppieOptions)

                this.editingCroppie = true
              }
            })
          };
        },
        false
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },

    async saveCroppieResult() {
      if (!this.croppieInstance) return

      try {
        this.savingCroppie = true

        const fileElement = document.getElementById('img-uploader')
        const fileToUpload = fileElement.files && fileElement.files.length ? fileElement.files[0] : null

        const isValid = await this.validate(fileToUpload)
        if (!isValid) {
          this.savingCroppie = false
          return
        }

        const blob = await this.croppieInstance.result({ type: 'blob', circle: this.maskType === 'circle', size: { width: this.widthToResizeTo, height: this.heightToResizeTo }, quality: 1 })

        let fileExtension = ''

        if (fileToUpload) {
          const lastDotLocation = fileToUpload.name.lastIndexOf('.')
          if (lastDotLocation >= 0) {
            fileExtension = '.' + fileToUpload.name.substring(lastDotLocation + 1)
          }
        }

        if (blob) {
          const formData = new FormData()
          formData.append('file', blob, uuid() + fileExtension)

          const res = await fileService.upload(formData, this.matnessImageType)

          const savedImageUrl = res.data
          this.$emit(emitEvents.IMAGE_UPLOADER_SAVED, savedImageUrl)
        }

      } catch {
        alert('Image upload failed')
      }
      finally {
        this.savingCroppie = false
        this.cancelCroppieResult()
      }
    },

    async validate(file) {
      this.errorMessages = []

      if (!file) return true

      let result = true

      const dimensions = this.getDimensions()
      if (dimensions.height < this.imageHeight || dimensions.width < this.imageWidth) {
        this.errorMessages.push(this.invalidDimensionsErrorMessage)
        result = false
      }

      if (this.supportedFileTypes != null) {
        const fileType = await this.getFileType(file)
        if (!this.supportedFileTypes.includes(fileType.mime)) {
          this.errorMessages.push(this.invalidFileTypeErrorMessage)
          result = false
        }
      }

      if (this.maxFileSizeBytes && file.size > this.maxFileSizeBytes) {
        this.errorMessages.push(this.invalidFileSizeErrorMessage)
        result = false
      }

      return result
    },

    getDimensions() {
      let img = document.getElementById('img-preview')
      if (!img) img = document.getElementById('img-result')

      if (!img || img.height === 0) return {
        height: this.imageHeight,
        width: this.imageWidth,
      }

      return {
        height: img.height,
        width: img.width,
      }
    },

    async getFileType(file) {
      return await fileTypeFromStream(file.stream())
    },

  },

  mounted() {
    // TODO
    // if (this.$refs.imgResult) {
    //   this.$refs.imgResult.addEventListener('error', this.avatarFailedToLoad)
    // }
  },

  unmounted() {
    // if (this.$refs.imgResult) {
    //   this.$refs.imgResult.removeEventListener('error', this.avatarFailedToLoad)
    // }
  }
}
</script>
