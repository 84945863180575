<template>
  <modal-dialog ref="myModal" @submit.prevent="createBracket">
    <template v-slot:modal-title>Add Competitors</template>
    <template v-slot:modal-contents>
      <div class="form-labeled-value">
        <h4>Event</h4>
        <p data-testid="event-name">{{ eventName }}</p>
      </div>

      <div class="form-labeled-value">
        <h4>Event Type</h4>
        <p data-testid="event-type">{{ eventTypeLabel }}</p>
      </div>

      <div class="form-labeled-value">
        <h4>{{ eventDateTimeLabel }}</h4>
        <p data-testid="start-date-time">{{ eventStartDateTime }}</p>
      </div>

      <div class="form-labeled-value">
        <h4>Weight Class</h4>
        <p data-testid="weight-class">{{ weightClass }}</p>
      </div>

      <div class="form-input">
        <label for="round-1-competitors">List of Round 1 Competitors</label>
        <textarea id="round-1-competitors" rows="12"
          placeholder="(1) First Last Name (SCHOOL) #-#&#10;(33) First Last Name (SCHOOL) #-#&#10;(17) First Last Name (SCHOOL) #-#&#10;(16) First Last Name (SCHOOL) #-#&#10;(9) First Last Name (SCHOOL) #-#&#10;(24) First Last Name (SCHOOL) #-#&#10;etc."
          v-model="round1CompetitorsString"></textarea>
        <p class="caption gray2">
          Copy and paste the list of Round 1 names in the order that they appear in the NCAA bracket. Each wrestler should
          be
          listed on a separate line.
        </p>
        <p class="caption gray2">
          {{ playInAndByeHelperMessage }}
        </p>
      </div>
      <div class="form-input">
        <p class="heading4" data-testid="round1-competitor-count"># Round 1 Competitors: {{ round1CompetitorsCount +
          totalPlayInWinnersCount }}</p>
        <p class="caption color-danger" v-show="showPowerOf2ErrorMessage" data-testid="power-of-2-error">
          {{ powerOf2ErrorMessage }}
        </p>
        <p class="caption color-danger" v-show="showOneAsteriskPerPlayInMatch"
          data-testid="one-asterisk-per-play-in-match-error">
          There must be one asterisk for each play-in match winner.
        </p>
        <p class="caption color-danger" v-show="showPlayInCompetitorsCannotMatchUpError"
          data-testid="play-in-competitors-cannot-match-up-error">
          Play-in winners ({{ PLAY_IN_WINNER_PLACEHOLDER }}) cannot play against each other in Round 1. The app does not
          yet support this.
        </p>
      </div>

      <div class="form-input" v-if="usePigTail && showPlayInSection">
        <label for="play-in-competitors">List of Play-In Competitors</label>
        <textarea id="play-in-competitors" rows="2"
          placeholder="(33) First Last Name (SCHOOL) #-#&#10;(32) First Last Name (SCHOOL) #-#"
          v-model="playInCompetitorsString"></textarea>
        <p class="caption gray2">
          Copy and paste the list of Play-In names in the order that they appear in the NCAA bracket. Each wrestler should
          be
          listed on a separate line.
        </p>
      </div>
      <div class="form-input" v-if="usePigTail && showPlayInSection">
        <p class="heading4"># Play-In Competitors: {{ playInCompetitorsCount }}</p>
        <p class="caption color-danger" v-show="showPlayInEvenCompetitorCountError">
          Play-in round must have an even number of competitors.
        </p>
      </div>

      <div class="form-input" v-if="showIncumbentSection">
        <label for="incumbent-competitor">Incumbent Competitor</label>
        <input id="incumbent-competitor" type="text" placeholder="(1) Jordan Burroughs"
          v-model="incumbentCompetitorString">
      </div>

      <div class="form-input" v-if="usePigTail && !showPlayInSection">
        <a class="action" @click.prevent="showPlayInSection = true">+ Add Play-In Competitors</a>
      </div>

      <div class="form-input" v-if="!showIncumbentSection">
        <a class="action" @click.prevent="showIncumbentSection = true">+ Add Incumbent Competitor</a>
      </div>
    </template>
    <template v-slot:modal-actions>
      <rebel-button button-type="submit" type="primary" color="default" text="Create Bracket"
        :is-loading="isSavingCompetitors" :disabled="!isValidBracket"></rebel-button>
      <a href="javascript:void(null)" @click.prevent="cancel">Cancel</a>
    </template>
  </modal-dialog>
</template>

<script>
import APP_SETTINGS from '@/appSettings.js'
import emitEvents from '@/utils/emitEvents'
import eventBracketService from '@/services/EventBracketService.js'

import ModalDialog from '@/components/ModalDialog.vue'
import RebelButton from '@/components/RebelButton.vue'

import eventTypes from '@/utils/eventTypes'

export default {
  setup() {
    return {
      eventTypes,
    }
  },
  
  components: {
    ModalDialog,
    RebelButton
  },

  computed: {
    eventTypeLabel() {
      return eventTypes.getEventTypeLabel(this.eventTypeId)
    },

    eventDateTimeLabel() {
      if (this.isPopularVoteTournament) return 'Voting End Date and Time'

      return 'Start Date and Time'
    },

    isPopularVoteTournament() {
      return eventTypes.isPopularVoteTournament(this.eventTypeId)
    },

    playInAndByeHelperMessage() {
      if (this.usePigTail) {
        return 'Use an asterisk (' + this.PLAY_IN_WINNER_PLACEHOLDER + ') to indicate the position of each play-in match winner. Use BYE in place of an opponent\'s name when a competitor has a bye.'
      }

      return 'Use BYE in place of an opponent\'s name when a competitor has a bye.'
    },

    showPlayInEvenCompetitorCountError() {
      return this.playInCompetitorsCount % 2 !== 0
    },

    showPowerOf2ErrorMessage() {
      const totalRound1Competitors = this.round1CompetitorsCount + this.totalPlayInWinnersCount
      if (totalRound1Competitors === 1) return true

      return !this.isPowerOfTwo(totalRound1Competitors)
    },

    powerOf2ErrorMessage() {
      if (this.usePigTail) {
        return 'The total number of Round 1 competitors (plus BYEs and Play-in winners) must be an exact power of 2.'
      }

      return 'The total number of Round 1 competitors (plus BYEs) must be an exact power of 2.'
    },

    showOneAsteriskPerPlayInMatch() {
      if (!this.usePigTail) return false

      return this.totalPlayInWinnersCount !== this.playInCompetitorsCount / 2
    },

    showPlayInCompetitorsCannotMatchUpError() {
      if (!this.usePigTail) return false

      const arr = this.makeCompetitorArray(this.round1CompetitorsString)

      for (let i = 1; i < arr.length; i += 2) {
        if (arr[i - 1] === this.PLAY_IN_WINNER_PLACEHOLDER && arr[i] === this.PLAY_IN_WINNER_PLACEHOLDER) {
          return true
        }
      }

      return false
    },

    playInCompetitorsCount() {
      return this.makeCompetitorArray(this.playInCompetitorsString).length
    },

    round1CompetitorsCount() {
      return this.makeCompetitorArray(this.round1CompetitorsString).filter(
        (competitor) => !this.usePigTail || competitor !== this.PLAY_IN_WINNER_PLACEHOLDER
      ).length
    },

    totalCompetitorsCount() {
      return this.playInCompetitorsCount + this.round1CompetitorsCount
    },

    totalPlayInWinnersCount() {
      return this.makeCompetitorArray(this.round1CompetitorsString).filter(
        (competitor) => this.usePigTail && competitor === this.PLAY_IN_WINNER_PLACEHOLDER
      ).length
    },

    isValidBracket() {
      const errorsArray = [
        this.showPlayInEvenCompetitorCountError,
        this.showPowerOf2ErrorMessage,
        this.showOneAsteriskPerPlayInMatch,
        this.showPlayInCompetitorsCannotMatchUpError
      ]

      return errorsArray.every(isErrorShowing => isErrorShowing === false)
    }
  },

  props: {
    eventId: {
      required: true
    },

    eventTypeId: {
      required: true
    }, 

    eventName: {
      type: String,
      required: true
    },

    eventStartDateTime: {
      type: String,
      required: true
    },

    weightClass: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      PLAY_IN_WINNER_PLACEHOLDER: '*',

      isSavingCompetitors: false,
      incumbentCompetitorString: '',
      playInCompetitorsString: '',
      round1CompetitorsString: '',

      usePigTail: APP_SETTINGS.USE_PIG_TAIL,

      showPlayInSection: false,
      showIncumbentSection: false,
    }
  },

  emits: [emitEvents.ADD_COMPETITORS],

  methods: {
    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    async createBracket() {
      if (!this.isValidBracket) return

      const playInCompetitors = this.makeCompetitorArray(this.playInCompetitorsString)
      const round1Competitors = this.makeCompetitorArray(this.round1CompetitorsString)
      const participantCount = this.totalCompetitorsCount

      this.isSavingCompetitors = true
      try {
        const dto = {
          eventId: this.eventId,
          weightClass: String(this.weightClass.split(' ')[0]),
          participantCount: participantCount,
          playInCompetitors,
          round1Competitors: this.replaceWithPigEmoji(round1Competitors),
          incumbentCompetitor: this.incumbentCompetitorString ? this.incumbentCompetitorString : null
        }

        await eventBracketService.create(this.eventId, dto)
        this.$emit(emitEvents.ADD_COMPETITORS, dto.weightClass)
      } catch {
        alert('Something went wrong')
      } finally {
        this.isSavingCompetitors = false
      }
    },

    isPowerOfTwo(x) {
      return Math.log2(x) % 1 === 0
    },

    makeCompetitorArray(competitors) {
      return competitors
        .split('\n')
        .map((line) => line.trim())
        .filter((line) => line.length > 0)
    },

    replaceWithPigEmoji(arr) {
      return arr.map((elem) => {
        if (typeof elem === 'string' && elem.toLowerCase() === 'pig') {
          return '🐷 PIG'
        }
        return elem
      })
    }
  }
}
</script>

<style scoped>
textarea,
input[type="text"] {
  font-size: .83rem;
}</style>
