<template>
  <div v-if="!isLoading" class="flex col gap-1">
    <p v-if="successfullyUnsubscribed">
      You have been successfully unsubscribed from {{ notificationName }}.
    </p>
    <p v-else>Something went wrong unsubscribing you from this type of notification.</p>

    <p>To manage your notification preferences visit
      your <router-link :to="{ name: 'user-profile-settings' }">user settings page.</router-link>
    </p>
  </div>
</template>

<script>
import { useAuthenticationStore } from '@/stores/authentication'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore()
    }
  },

  async created() {
    if (!this.isValidNotificationType) {
      this.isLoading = false
      return
    }

    await this.unsubscribe()
  },

  computed: {
    notificationType() {
      return this.$route.params?.notificationType?.toLowerCase()
    },

    notificationName() {
      switch (this.notificationType) {
        case 'event-starting-soon':
          return 'notifications for when it\'s 24 hours before the prediction / voting period ends'
        case 'bracket-reseeding':
          return 'notifications for when event matchups are re-seeded'
        case 'event-bracket-publish':
          return 'notifications for when matchups become available'
      }

      return ''
    },

    isValidNotificationType() {
      switch (this.notificationType) {
        case 'event-starting-soon':
        case 'bracket-reseeding':
        case 'event-bracket-publish':
          return true
      }

      return false
    }
  },

  data() {
    return {
      successfullyUnsubscribed: false,
      isLoading: true
    }
  },

  methods: {
    async unsubscribe() {
      try {

        let result = { success: false }

        switch (this.notificationType) {
          case 'event-starting-soon':
            result = await this.authStore.updateEventStartingSoonNotificationPreference(false)
            break;
          case 'bracket-reseeding':
            result = await this.authStore.updateBracketReseedNotificationPreference(false)
            break;
          case 'event-bracket-publish':
            result = await this.authStore.updateEventBracketPublishNotificationPreference(false)
            break;
        }

        if (result.success) {
          this.successfullyUnsubscribed = true
        }
      } catch {
        //
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>