import axios from 'axios'

export default {
  getById(eventId) {
    return axios.get(`/events/${eventId}`)

  },

  publishEvent(eventId) {
    return axios.patch(`/events/${eventId}/published`)
  },

  createV2(event) {
    return axios.post(`/events/v2`, event)
  },

  update(event) {
    return axios.put(`/events/${event.eventId}`, event)
  },

  updateEventImage(eventId, imageSrc) {
    return axios.put(`/events/${eventId}/logo-url`, { logoUrl: imageSrc })
  },

  updateEventBackgroundImage(eventId, imageSrc) {
    return axios.put(`/events/${eventId}/background-image`, { backgroundImage: imageSrc })
  },

  delete(eventId) {
    return axios.delete(`/events/${eventId}`)
  },

  publishResults(dto) {
    return axios.put(`/matchups`, dto)
  },

  updateWeightClasses(eventId, dto) {
    return axios.put(`/events/${eventId}/weight-classes`, dto)
  },

  updateWeightClassUnits(eventId, dto) {
    return axios.put(`/events/${eventId}/weight-classes/units`, dto)
  },

  getCustomScoringForEvent(eventId) {
    return axios.get(`/events/${eventId}/scoring`)
  },

  customizeScoring(eventId, dto) {
    return axios.put(`/events/${eventId}/scoring`, dto)
  },

  customizeDesign(eventId, dto) {
    return axios.put(`/events/${eventId}/design`, dto)
  }
}
