<template>
  <div id="login-component" class="layout-form-page">
    <p v-if="invalidCredentials" class="color-danger">The username or password you entered is incorrect</p>
    <p v-else-if="sessionExpired" class="color-danger">Your session has expired. Please log in.</p>
    <p v-else-if="invalidResetPasswordToken" class="color-danger">Reset password link was invalid or expired</p>
    <p v-else-if="invalidVerifyEmailToken" class="color-danger">Email verification link was invalid or expired</p>

    <h1>Log In</h1>

    <form class="layout-form-list" @submit.prevent="submitLoginForm">
      <div class="form-input">
        <label for="email">Email</label>
        <input autofocus autocomplete="email" id="email" type="email" placeholder="Email" v-model="user.email" required />
      </div>
      <div class="form-input">
        <label for="password">Password</label>
        <input
          id="password"
          :type="showingPassword ? 'text' : 'password'"
          placeholder="Password"
          v-model="user.password"
          required
        />
        <i class="fa fa-eye-slash" v-show="!showingPassword" @click="showingPassword = true"></i>
        <i class="fa fa-eye" v-show="showingPassword" @click="showingPassword = false"></i>
      </div>
      <div class="actions-list">
        <rebel-button
          button-type="submit"
          type="primary"
          color="default"
          :is-loading="submitInProgress"
          text="Log In"
        ></rebel-button>
        <a href="javascript:void(null)" @click.prevent="forgotPassword">Forgot Password?</a>
      </div>
    </form>

    <div class="separator"></div>

    <div class="actions-list">
      <p>Don't have an account?</p>
      <router-link :to="{ name: 'register' }">Sign Up</router-link>
    </div>

    <forgot-password
      v-if="forgotPasswordModalVisible"
      @close="forgotPasswordModalClosed"
      @reset-password-sent="resetPasswordSent"
    ></forgot-password>
    <forgot-password-confirmation
      :email="forgotPasswordEmail"
      v-if="forgotPasswordConfirmationModalVisible"
      @close="forgotPasswordConfirmationModalClosed"
    ></forgot-password-confirmation>
    <verify-email-modal v-if="showingVerifyEmailModal" :email="user.email" @close="verifyEmailModalClosed" />
  </div>
</template>

<script>
import ForgotPassword from '@/components/ForgotPasswordModal.vue'
import ForgotPasswordConfirmation from '@/components/ForgotPasswordConfirmationModal.vue'
import VerifyEmailModal from '@/components/VerifyEmailModal.vue'
import RebelButton from '@/components/RebelButton.vue'
import { recordLogin } from '@/services/GoogleTagManagerService.js'
import { useAuthenticationStore } from '@/stores/authentication'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore()
    }
  },

  components: {
    ForgotPassword,
    ForgotPasswordConfirmation,
    RebelButton,
    VerifyEmailModal
  },

  computed: {
    invalidResetPasswordToken() {
      return this.$route.query && this.$route.query['invalid-reset-password-token'] && !this.hasSubmittedOnce
    },
    invalidVerifyEmailToken() {
      return this.$route.query && this.$route.query['invalid-verify-email-token'] && !this.hasSubmittedOnce
    },
    sessionExpired() {
      return this.$route.query && this.$route.query['session-expired'] && !this.hasSubmittedOnce
    }
  },

  data() {
    return {
      forgotPasswordEmail: '',
      forgotPasswordModalVisible: false,
      forgotPasswordConfirmationModalVisible: false,
      invalidCredentials: false,
      showingPassword: false,
      showingVerifyEmailModal: false,
      submitInProgress: false,
      hasSubmittedOnce: false,
      user: {
        email: '',
        password: '',
        inviteToken: null
      }
    }
  },

  methods: {
    forgotPassword() {
      this.forgotPasswordModalVisible = true
    },

    forgotPasswordModalClosed() {
      this.forgotPasswordModalVisible = false
    },

    forgotPasswordConfirmationModalClosed() {
      this.forgotPasswordConfirmationModalVisible = false
      this.forgotPasswordEmail = ''
    },

    resetPasswordSent(emailSentTo) {
      this.forgotPasswordEmail = emailSentTo
      this.forgotPasswordConfirmationModalVisible = true
    },

    async submitLoginForm() {
      this.submitInProgress = true
      this.hasSubmittedOnce = true
      this.invalidCredentials = false

      if (this.$route.query.inviteToken) {
        this.user.inviteToken = this.$route.query.inviteToken
      }

      const loginResponse = await this.authStore.login(this.user)

      this.submitInProgress = false

      if (!loginResponse.success) {
        if (loginResponse.error?.response?.status == 401) {
          this.invalidCredentials = true
          return
        }
        alert('An error occurred')
        console.log(loginResponse.error)
        return
      }

      recordLogin({
        method: 'form'
      })

      if (loginResponse.user && loginResponse.user.emailVerified === false) {
        this.showingVerifyEmailModal = true
        return
      }

      if (this.$route.query.redirect) {
        this.$router.push(this.$route.query.redirect)
      } else {
        this.$router.push({ name: 'events' })
      }
    },

    verifyEmailModalClosed() {
      this.showingVerifyEmailModal = false
    }
  }
}
</script>

