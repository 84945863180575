<template>
  <div v-if="!isLoading" id="bracket-prediction-outer">
    <link-back-to class="mb-0-5" :router-link-to="{ name: 'events' }" page-name="Events" />

    <page-header :headerText="event.name" :subText="eventSubText">
      <template v-slot:header-actions>
        <rebel-button :is-loading="saveBracketLoading" :disabled="!hasUnsavedChanges" @click="saveBracket"
          type="primary" color="default" text="Save Changes" class="nowrap">
          <template v-if="hasUnsavedChanges" v-slot:icon-trailing>
            <save-changes-bubble :value="countOfUnsavedChanges" />
          </template>
        </rebel-button>

        <rebel-button v-if="event.infoUrl" @click="goToEventInfo" type="ghosted" color="default" text="Event Info"
          class="nowrap">
          <template v-slot:icon-leading>
            <arrow-top-right-on-square-icon class="icon-20" />
          </template>
        </rebel-button>
      </template>
    </page-header>

    <div class="mb-2">
      <countdown-timer :startDateTime="countdownTimerDate" :darkBackground="false" @time-expires="countdownTimeExpires"
        @time-running-out="countdownTimerRunningOut" />
    </div>

    <div class="flex col gap-1-5">
      <weight-class-selector v-if="predictionsRemainingByWeightClass?.size > 0" :show-overall="false"
        :selected-value="selectedWeightClass" @selected-weight-class-change="weightClassChange"
        :show-predictions-remaining="true" :predictions-remaining-by-weight-class="predictionsRemainingByWeightClass"
        :weightClasses="event?.weightClasses.weightClasses" :units="event?.weightClasses.units"></weight-class-selector>

      <div v-if="!bracketsLoading" ref="bracketPredictionElem" id="bracket-prediction"
        :class="{ border: isBracketScrollingHorizontally }">
        <div v-if="selectedBracket" class="bracket-outer" :class="{ border: !isBracketScrollingHorizontally }">
          <div class="rounds">
            <div v-for="(round, roundIndex) in selectedBracket.rounds" :key="round.round" class="round"
              :class="{ 'play-in': round.playIn }"
              :style="`--round: ${round.playIn ? 0 : Math.pow(2, round.round - 1)};`">
              <h3>
                {{ round.roundName }}
              </h3>

              <div class="round-wrapper"
                :style="`height: ${selectedBracket.rounds[this.indexOfRoundWithMostMatches].matchups.length * 80}px;`">
                <div class="games">
                  <div class="game" :id="`game${matchup.matchupId}`"
                    v-for="(matchup) in getMatchupsWithFiller(round, selectedBracket.rounds[roundIndex + 1])"
                    :key="matchup.matchupId" :class="{
                      hideFillerMatchup: matchup.isFillerForPlayInRound,
                      'first-round': round.playIn || round.round == 1,
                      'visibility-hidden': isInHiddenList(matchup.matchupId),
                    }">
                    <div class="player first" @click="playerClicked(matchup, matchup.participant1Id)">
                      <span class="grow">
                        <span v-if="matchup.participant1Ranking" class="caption gray2 rank">{{
                          matchup.participant1Ranking
                        }}</span>
                        <span v-if="matchup.participant1Id != null" class="player-name body2"
                          :class="{ gray2: matchup.participant1Name.toUpperCase() == 'BYE' }">
                          <span v-if="isPigParticipant(matchup.participant1Name)" class="player-name"
                            @click.stop="pigModal.isShowing = true">
                            {{ abbreviateAsNeeded(round.round, matchup.participant1Name) }}
                          </span>
                          <template v-else>
                            {{ abbreviateAsNeeded(round.round, matchup.participant1Name) }}
                          </template>
                        </span>
                        <span v-else class="player-name body2 null-player-name"></span>
                      </span>
                      <span v-if="!matchup.bye">
                        <svg v-if="isWinner(matchup, matchup.participant1Id)" width="18" height="18" viewBox="0 0 20 18"
                          fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="9" cy="9" r="8.5" stroke="#2860F0" />
                          <circle cx="9" cy="9" r="5" fill="#2860F0" />
                        </svg>
                        <svg v-else width="18" height="18" viewBox="0 0 20 18" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.5 9C17.5 13.6944 13.6944 17.5 9 17.5C4.30558 17.5 0.5 13.6944 0.5 9C0.5 4.30558 4.30558 0.5 9 0.5C13.6944 0.5 17.5 4.30558 17.5 9Z"
                            stroke="#3B5166" />
                        </svg>
                      </span>
                    </div>
                    <div class="inner-border"></div>
                    <div class="player last" @click="playerClicked(matchup, matchup.participant2Id)">
                      <span class="grow">
                        <span v-if="matchup.participant2Ranking" class="caption gray2 rank">{{
                          matchup.participant2Ranking
                        }}</span>
                        <span v-if="matchup.participant2Id != null" class="player-name body2"
                          :class="{ gray2: matchup.participant2Name.toUpperCase() == 'BYE' }"><span
                            v-if="isPigParticipant(matchup.participant2Name)" class="player-name"
                            @click.stop="pigModal.isShowing = true">
                            {{ abbreviateAsNeeded(round.round, matchup.participant2Name) }}
                          </span>
                          <template v-else>
                            {{ abbreviateAsNeeded(round.round, matchup.participant2Name) }}
                          </template>
                        </span>
                        <span v-else class="player-name body2 null-player-name"></span>
                      </span>
                      <span v-if="!matchup.bye">
                        <svg v-if="isWinner(matchup, matchup.participant2Id)" width="18" height="18" viewBox="0 0 20 18"
                          fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="9" cy="9" r="8.5" stroke="#2860F0" />
                          <circle cx="9" cy="9" r="5" fill="#2860F0" />
                        </svg>
                        <svg v-else width="18" height="18" viewBox="0 0 20 18" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.5 9C17.5 13.6944 13.6944 17.5 9 17.5C4.30558 17.5 0.5 13.6944 0.5 9C0.5 4.30558 4.30558 0.5 9 0.5C13.6944 0.5 17.5 4.30558 17.5 9Z"
                            stroke="#3B5166" />
                        </svg>
                      </span>
                    </div>
                    <div class="connector-last-round"
                      v-if="(roundIndex == selectedBracket.rounds?.length - 1 && round?.matchups?.length === 1 && selectedBracket?.rounds[roundIndex - 1]?.matchups?.length === 1)">
                      <div class="connector-after-last-round"></div>
                    </div>
                    <div class="connector connector-top"
                      v-else-if="(round.playIn || matchup.nextMatchupOrder == 1) && !(roundIndex + 1 == selectedBracket.rounds?.length - 1 && selectedBracket?.rounds[roundIndex + 1]?.matchups?.length === 1 && selectedBracket?.rounds[roundIndex]?.matchups?.length === 1)">
                    </div>
                    <div class="connector connector-bottom"
                      v-else-if="matchup.nextMatchupOrder == 2 && !(roundIndex + 1 == selectedBracket.rounds?.length - 1 && selectedBracket?.rounds[roundIndex + 1]?.matchups?.length === 1 && selectedBracket?.rounds[roundIndex]?.matchups?.length === 1)">
                    </div>
                    <div class="connector-before"
                      v-if="!(roundIndex == selectedBracket.rounds?.length - 1 && round?.matchups?.length === 1 && selectedBracket?.rounds[roundIndex - 1]?.matchups?.length === 1) && ((matchup.prevMatchup1Id != null && !isInHiddenList(matchup.prevMatchup1Id)) || (matchup.prevMatchup2Id != null && !isInHiddenList(matchup.prevMatchup2Id)))">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <pig-explanation v-if="bracketHasPig"></pig-explanation>
    </div>

    <save-changes-modal v-if="saveChangesModal.isShowing" @save-changes="saveChanges" @discard-changes="discardChanges"
      @close="closeSaveChangesModal" />

    <event-starting-soon-modal v-if="eventStartingSoonModal.isShowing" :has-unsaved-changes="hasUnsavedChanges"
      @save-changes="saveChanges" @discard-changes="discardChanges" @close="eventStartingSoonModal.isShowing = false" />

    <pig-modal v-if="pigModal.isShowing" @close="pigModal.isShowing = false"></pig-modal>

    <bracket-reseeded-modal v-if="bracketReseededModal.isShowing" :weight-class="bracketReseededModal.weightClass"
      @close="bracketReseededModalClosed" />
  </div>
</template>

<script>
import { useAuthenticationStore } from '@/stores/authentication'
import { useEventAdminStore } from '@/stores/eventAdmin'

import DateUtils from '@/utils/dateUtils.js'
import eventService from '@/services/EventService.js'
import userBracketService from '@/services/UserBracketService.js'
import PubSub from 'pubsub-js'

import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/20/solid'
import BracketReseededModal from '@/components/leagues/BracketReseededModal.vue'
import CountdownTimer from '@/components/leagues/CountdownTimer.vue'
import EventStartingSoonModal from '@/components/leagues/EventStartingSoonModal.vue'
import LinkBackTo from '@/components/LinkBackTo.vue'
import PageHeader from '@/components/PageHeader.vue'
import PigExplanation from '@/components/PigExplanation.vue'
import PigModal from '@/components/leagues/PigModal.vue'
import RebelButton from '@/components/RebelButton.vue'
import SaveChangesBubble from '@/components/SaveChangesBubble.vue'
import SaveChangesModal from '@/components/leagues/SaveChangesModal.vue'
import WeightClassSelector from '@/components/WeightClassSelector.vue'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore(),
      eventAdminStore: useEventAdminStore()
    }
  },

  components: {
    ArrowTopRightOnSquareIcon,
    BracketReseededModal,
    CountdownTimer,
    EventStartingSoonModal,
    LinkBackTo,
    PageHeader,
    PigExplanation,
    PigModal,
    RebelButton,
    SaveChangesBubble,
    SaveChangesModal,
    WeightClassSelector
  },

  async created() {
    const response = await eventService.getById(this.eventId)

    this.event = response.data
    this.eventAdminStore.initialize(this.event)

    // if the event is not there
    if (this.event == null) {
      this.$router.push({ name: 'permission-denied' })
    }

    if (!this.event.published) {
      alert('The brackets for this event are not yet available.')
      this.goToEventsPage()
    }

    if (this.event.participationEnded) {
      this.goToBracketViewPage()
    }

    this.selectedWeightClass = this.event?.weightClasses?.weightClasses[0]
    this.isLoading = false

    try {
      await this.loadData()
      this.bracketsLoading = false

      setTimeout(this.setIsBracketScrollingHorizontally, 1)
      setTimeout(this.updateMatchupLines, 1)
    } catch {
      alert('Something went wrong')
    } finally {
      this.isLoading = false
    }
  },

  data() {
    return {
      bracketReseededModal: {
        isShowing: false,
        weightClass: ''
      },

      initialProjectedMatchupWinners: [],

      isBracketScrollingHorizontally: false,

      isLoading: true,
      bracketsLoading: true,
      event: null,
      saveBracketLoading: false,

      selectedWeightClass: undefined,
      brackets: [],

      pigModal: {
        isShowing: false
      },

      saveChangesModal: {
        isShowing: false
      },

      eventStartingSoonModal: {
        isShowing: false
      },

      timeHasExpired: false
    }
  },

  computed: {
    eventSubText() {
      if (this.event.bracketTournament) return this.formatDateTime(this.event.startDateTime)

      if (this.event.popularVoteTournament) {
        if (!this.event.published) return 'Voting begins when brackets are published'
        if (!this.event.participationEnded) return 'Voting ends ' + this.formatDateTime(this.event.startDateTime)
        return 'Voting ended ' + this.formatDateTime(this.event.startDateTime)
      }

      return ''
    },

    bracketHasPig() {
      if (this.selectedBracket == null) return false

      return this.selectedBracket.rounds
        .flatMap((r) => r.matchups)
        .some((m) => {
          return this.isPigParticipant(m.participant1Name) || this.isPigParticipant(m.participant2Name)
        })
    },

    countdownTimerDate() {
      return DateUtils.dateInUsersTimezone(this.event.startDateTime, this.authStore?.loggedInUser?.user?.timeZoneSetting)
    },

    hiddenList() {
      if (this.selectedBracket == null) return []

      const allMatchups = this.selectedBracket?.rounds.flatMap(r => r.matchups)
      const allMatchupsMap = []
      allMatchups.forEach(matchup => {
        allMatchupsMap[matchup.matchupId] = matchup
      })

      return allMatchups
        .filter(m => m.participant1Name?.toUpperCase() == 'BYE' && m.participant2Name?.toUpperCase() == 'BYE')
        .concat(allMatchups.filter(m => (m.participant1Name?.toUpperCase() == 'BYE' || m.participant2Name?.toUpperCase() == 'BYE') && m.nextMatchupId != null && (allMatchupsMap[m.nextMatchupId]?.participant1Name?.toUpperCase() == m.participant1Name?.toUpperCase() || allMatchupsMap[m.nextMatchupId]?.participant2Name?.toUpperCase() == m.participant1Name?.toUpperCase()) && ((allMatchupsMap[m.nextMatchupId]?.participant1Name?.toUpperCase() == m.participant2Name?.toUpperCase() || allMatchupsMap[m.nextMatchupId]?.participant2Name?.toUpperCase() == m.participant2Name?.toUpperCase()))))
        .map(m => m.matchupId)
    },

    indexOfRoundWithMostMatches() {
      let index = -1
      let currentMax = 0

      this.selectedBracket?.rounds?.forEach((round, i) => {
        if (round.matchups.length > currentMax) {
          index = i
          currentMax = round.matchups.length
        }
      })

      return index
    },

    eventId() {
      return this.$route.params.eventId
    },

    predictionsRemainingByWeightClass() {
      // Produce an array that is compatible with the Map constructor
      // [ [ weightClass1: predictionsRemaining1 ], [ weightClass2: predictionsRemaining2 ], ... [ weightClassn: predictionsRemainingn ] ]
      const mappedData = this.brackets.map((b) => {
        const predictionsRemaining = b.totalMatches - b.matchesWithAPrediction
        return [b.weightClass, predictionsRemaining]
      })

      const map = new Map(mappedData)

      return map
    },

    selectedBracket() {
      if (this.selectedWeightClass === -1 || !this.brackets.length) {
        return null
      }

      return this.brackets.find((bracket) => bracket.weightClass === this.selectedWeightClass)
    },

    userBracketId() {
      if (this.selectedBracket == null) {
        return null
      }

      return this.selectedBracket.userBracketId
    },

    hasUnsavedChanges() {
      for (let i = 0; i < this.projectedMatchupWinners.length; i++) {
        const currentUserBracketId = this.projectedMatchupWinners[i].userBracketId
        if (this.hasUnsavedChangesForUserBracket(currentUserBracketId)) {
          return true
        }
      }

      return false
    },

    countOfUnsavedChanges() {
      let count = 0

      for (let i = 0; i < this.projectedMatchupWinners.length; i++) {
        const currentUserBracketId = this.projectedMatchupWinners[i].userBracketId
        count += this.countOfUnsavedChangesForUserBracket(currentUserBracketId)
      }

      return count
    },

    projectedMatchupWinners() {
      if (this.brackets == null) {
        return []
      }

      return this.brackets.map((b) => {
        const projectedMatchupWinners = b.rounds
          .flatMap((round) => round.matchups)
          .map((matchup) => {
            return {
              matchupId: matchup.matchupId,
              winnerId: matchup.projectedWinner
            }
          })

        return {
          userBracketId: b.userBracketId,
          weightClass: b.weightClass,
          projectedMatchupWinners
        }
      })
    }
  },

  methods: {
    goToEventInfo() {
      window.open(this.event.infoUrl, '_blank')
    },

    isInHiddenList(matchupId) {
      return this.hiddenList.includes(matchupId)
    },

    bracketReseeded(topicName, message) {
      const isMyEvent = message.eventId != null && message.eventId == this.event.eventId
      if (!isMyEvent) return

      this.bracketReseededModal.weightClass = String(message.weightClass)
      this.bracketReseededModal.isShowing = true
    },

    async bracketReseededModalClosed() {
      try {
        const weightClassToReload = this.bracketReseededModal.weightClass

        const bracketsResponse = await this.getMyBrackets()

        const bracketData = bracketsResponse.data

        const bracketToReload = bracketData.find(b => b.weightClass == weightClassToReload)
        const bracketToReplace = this.brackets.findIndex(b => b.weightClass == weightClassToReload)
        this.brackets.splice(bracketToReplace, 1, bracketToReload)
        this.setPublishedStateToCurrentForBracketReseed(bracketToReload)
      } catch {
        alert('Something went wrong')
      } finally {
        this.bracketReseededModal.isShowing = false
      }
    },

    isPigParticipant(name) {
      if (name == null) return false
      return name.includes('🐷 PIG')
    },

    countdownTimerRunningOut() {
      this.eventStartingSoonModal.isShowing = true
    },

    countdownTimeExpires() {
      this.timeHasExpired = true

      setTimeout(() => {
        if (this.event.popularVoteTournament) {
          this.goToEventsPage()
        } else {
          this.goToBracketViewPage()
        }
      }, 3000)
    },

    goToEventsPage() {
      this.$router.push({ name: 'events' })
    },

    goToBracketViewPage() {
      this.$router.push({ name: 'user-brackets-view', params: { eventId: this.eventId } })
    },

    playerClicked(matchup, participantId) {
      if (matchup.bye) return

      if (this.isWinner(matchup, participantId)) {
        this.removeWinner(matchup, participantId, true)
        return
      }

      this.advance(matchup, participantId, this.selectedBracket, true)
    },

    abbreviateAsNeeded(roundNumber, name) {
      if (name == null) return ''

      if (roundNumber <= 1) return name

      name = name.trim()

      const rankRegex = /^\d+|^\(\s*\d+\s*\)/
      const schoolStarterRegex = /\(|\[/

      let rank = ''
      // let fName = ''
      // let lName = ''

      const rankingMatches = name.match(rankRegex)
      if (rankingMatches != null) {
        // ranking is present. extract ranking and revise name
        rank = rankingMatches[0] + ' '
        name = name.substring(rank.length).trim()
      }

      if (name.match(schoolStarterRegex) != null) {
        name = name.split(schoolStarterRegex)[0].trim().replace('\t', ' ')
      }

      return rank + name
    },

    hasUnsavedChangesForUserBracket(userBracketId) {
      return this.countOfUnsavedChangesForUserBracket(userBracketId) > 0
    },

    countOfUnsavedChangesForUserBracket(userBracketId) {
      const projectedMatchupWinners = this.projectedMatchupWinners.find(
        (pmw) => pmw.userBracketId == userBracketId
      )?.projectedMatchupWinners
      const initialProjectedMatchupWinners = this.initialProjectedMatchupWinners.find(
        (pmw) => pmw.userBracketId == userBracketId
      )?.projectedMatchupWinners

      if (projectedMatchupWinners == null || initialProjectedMatchupWinners == null) {
        return 0
      }

      let count = 0
      // check for difference between initial state of projected winners and current
      // if they are at all different, then a result must have changed.
      for (let i = 0; i < projectedMatchupWinners.length; i++) {
        if (initialProjectedMatchupWinners[i]?.winnerId != projectedMatchupWinners[i].winnerId) {
          count++
        }
      }

      return count
    },

    resetBrackets() {
      this.brackets.forEach((b) => {
        b.rounds.forEach((r) => {
          r.matchups.forEach((m) => {
            const initialPrediction = this.initialProjectedMatchupWinners.find((initial) => initial.matchupId == m.matchupId)
            if (m.projectedWinner == null && initialPrediction.winnerId != null) {
              b.matchesWithAPrediction++
            } else if (m.projectedWinner != null && initialPrediction.winnerId == null) {
              b.matchesWithAPrediction--
            }

            m.projectedWinner = initialPrediction.winnerId
          })
        })
      })
    },

    async showSaveChangesModal() {
      return new Promise((resolve, reject) => {
        this.saveChangesModal.resolveFn = resolve
        this.saveChangesModal.rejectFn = reject
        this.saveChangesModal.isShowing = true
      })
    },
    async saveChanges() {
      this.saveChangesModal.isShowing = false
      await this.saveBracket()
      if (this.saveChangesModal.resolveFn != null) {
        await this.saveChangesModal.resolveFn()
      }
    },
    async discardChanges() {
      // this.resetBrackets()
      this.saveChangesModal.isShowing = false
      if (this.saveChangesModal.resolveFn != null) {
        await this.saveChangesModal.resolveFn()
      }
    },
    async closeSaveChangesModal() {
      this.saveChangesModal.isShowing = false
      if (this.saveChangesModal.rejectFn != null) {
        await this.saveChangesModal.rejectFn()
      }
    },

    setPublishedStateToCurrent() {
      this.initialProjectedMatchupWinners = this.projectedMatchupWinners
    },
    setPublishedStateToCurrentForBracketReseed(reseededBracket) {
      const projectedMatchupWinners = reseededBracket.rounds
        .flatMap((round) => round.matchups)
        .map((matchup) => {
          return {
            matchupId: matchup.matchupId,
            winnerId: null // clear out all predictions for this bracket
          }
        })

      const currentStateForBracket = {
        userBracketId: reseededBracket.userBracketId,
        weightClass: reseededBracket.weightClass,
        projectedMatchupWinners
      }

      const indexOfItemToReplace = this.initialProjectedMatchupWinners.findIndex(ipmw => ipmw.weightClass == reseededBracket.weightClass)

      this.initialProjectedMatchupWinners.splice(indexOfItemToReplace, 1, currentStateForBracket)
    },

    getMatchupsWithFiller(round, nextRound) {
      if (!round.playIn || nextRound == null) return round.matchups

      // add filler matchup for the UI

      // find out which matchup is the next up and line up this matchup with it by adding filler
      // before it. then add filler after as needed

      const fakeMatchup = {
        participant1Id: null,
        participant1Name: '',
        participant1Ranking: '',
        participant2Id: null,
        participant2Name: '',
        participant2Ranking: '',
        winner: null,
        isFillerForPlayInRound: true
      }

      // go through each round 1 matchup. for each participant that does not have a prevMatchup associated
      // add a filler matchup in play in round
      const currRoundMatchupsWithFiller = []
      let playInMatchupCounter = 0
      let currPlayInMatchup = round.matchups[playInMatchupCounter]
      for (let i = 0; i < nextRound.matchups.length; i++) {
        const matchup = nextRound.matchups[i]
        if (matchup.prevMatchup1Id != null && matchup.prevMatchup2Id != null) {
          // add both
          currRoundMatchupsWithFiller.push(currPlayInMatchup)
          currPlayInMatchup = round.matchups[++playInMatchupCounter]
          currRoundMatchupsWithFiller.push(currPlayInMatchup)
          currPlayInMatchup = round.matchups[++playInMatchupCounter]
        } else if (matchup.prevMatchup1Id == null && matchup.prevMatchup2Id == null) {
          currRoundMatchupsWithFiller.push({ ...fakeMatchup })
        } else {
          // one matchup
          currRoundMatchupsWithFiller.push(currPlayInMatchup)
          currPlayInMatchup = round.matchups[++playInMatchupCounter]
        }
      }

      return currRoundMatchupsWithFiller
    },

    weightClassChange(weightClass) {
      this.selectedWeightClass = weightClass
      setTimeout(this.setIsBracketScrollingHorizontally, 1)
      setTimeout(this.updateMatchupLines, 1)
    },

    advance(matchup, matchupParticipantId, forBracket, wasAdvancedInUI) {
      if (forBracket == null) {
        forBracket = this.selectedBracket
      }

      if (matchupParticipantId == null || matchup == null || this.selectedBracket == null) {
        return
      }

      // if this participant is already assigned as the winner, do nothing
      if (this.isWinner(matchup, matchupParticipantId)) {
        return
      }

      // if doing it client side, remove all picks that would conflict with this pick
      const allMatchups = forBracket.rounds.flatMap((round) => round.matchups)
      let currMatchup = allMatchups.find((m) => m.matchupId === matchup.matchupId)
      let nextMatchup = allMatchups.find((m) => m.matchupId === matchup.nextMatchupId)

      if (!currMatchup) return

      // set next matchup's participant
      let isFinished = false
      if (wasAdvancedInUI && currMatchup.projectedWinner == null) forBracket.matchesWithAPrediction++
      currMatchup.projectedWinner = matchupParticipantId

      if (nextMatchup != null) {
        if (matchupParticipantId === currMatchup.participant1Id) {
          if (currMatchup.nextMatchupOrder === 1) {
            nextMatchup.participant1Id = currMatchup.participant1Id
            nextMatchup.participant1Name = currMatchup.participant1Name
            nextMatchup.participant1Ranking = currMatchup.participant1Ranking

            if (nextMatchup.projectedWinner != null && nextMatchup.projectedWinner !== nextMatchup.participant2Id) {
              nextMatchup.projectedWinner = null
              if (wasAdvancedInUI) forBracket.matchesWithAPrediction--
            } else {
              isFinished = true
            }
          } else {
            nextMatchup.participant2Id = currMatchup.participant1Id
            nextMatchup.participant2Name = currMatchup.participant1Name
            nextMatchup.participant2Ranking = currMatchup.participant1Ranking

            if (nextMatchup.projectedWinner != null && nextMatchup.projectedWinner !== nextMatchup.participant1Id) {
              nextMatchup.projectedWinner = null
              if (wasAdvancedInUI) forBracket.matchesWithAPrediction--
            } else {
              isFinished = true
            }
          }
        } else if (matchupParticipantId === currMatchup.participant2Id) {
          if (currMatchup.nextMatchupOrder === 1) {
            nextMatchup.participant1Id = currMatchup.participant2Id
            nextMatchup.participant1Name = currMatchup.participant2Name
            nextMatchup.participant1Ranking = currMatchup.participant2Ranking

            if (nextMatchup.projectedWinner != null && nextMatchup.projectedWinner !== nextMatchup.participant2Id) {
              nextMatchup.projectedWinner = null
              if (wasAdvancedInUI) forBracket.matchesWithAPrediction--
            } else {
              isFinished = true
            }
          } else {
            nextMatchup.participant2Id = currMatchup.participant2Id
            nextMatchup.participant2Name = currMatchup.participant2Name
            nextMatchup.participant2Ranking = currMatchup.participant2Ranking

            if (nextMatchup.projectedWinner != null && nextMatchup.projectedWinner !== nextMatchup.participant1Id) {
              nextMatchup.projectedWinner = null
              if (wasAdvancedInUI) forBracket.matchesWithAPrediction--
            } else {
              isFinished = true
            }
          }
        }
      }

      currMatchup = nextMatchup

      while (!isFinished && currMatchup != null) {
        const nextMatchup = allMatchups.find((m) => m.matchupId === currMatchup.nextMatchupId)
        if (nextMatchup != null) {
          if (currMatchup.nextMatchupOrder === 1) {
            nextMatchup.participant1Id = null
            nextMatchup.participant1Name = null
            nextMatchup.participant1Ranking = null

            if (nextMatchup.projectedWinner != null && nextMatchup.projectedWinner !== nextMatchup.participant2Id) {
              nextMatchup.projectedWinner = null
              if (wasAdvancedInUI) forBracket.matchesWithAPrediction--
            } else {
              isFinished = true
            }
          } else if (currMatchup.nextMatchupOrder === 2) {
            nextMatchup.participant2Id = null
            nextMatchup.participant2Name = null
            nextMatchup.participant2Ranking = null

            if (nextMatchup.projectedWinner != null && nextMatchup.projectedWinner !== nextMatchup.participant2Id) {
              nextMatchup.projectedWinner = null
              if (wasAdvancedInUI) forBracket.matchesWithAPrediction--
            } else {
              isFinished = true
            }
          }
        }
        currMatchup = nextMatchup
      }
    },

    removeWinner(matchup, matchupParticipantId, wasAdvancedInUI) {
      if (matchupParticipantId == null || matchup == null || this.selectedBracket == null) {
        return
      }

      // if this participant is not assigned as the winner, do nothing
      if (!this.isWinner(matchup, matchupParticipantId)) {
        return
      }

      const allMatchups = this.selectedBracket.rounds.flatMap((round) => round.matchups)
      let currMatchup = allMatchups.find((m) => m.matchupId === matchup.matchupId)
      let nextMatchup = allMatchups.find((m) => m.matchupId === matchup.nextMatchupId)

      // set next matchup's participant
      let isFinished = false
      currMatchup.projectedWinner = null
      if (wasAdvancedInUI) this.selectedBracket.matchesWithAPrediction--
      if (nextMatchup != null) {
        if (currMatchup.nextMatchupOrder === 1) {
          nextMatchup.participant1Id = null
          nextMatchup.participant1Name = ''
          nextMatchup.participant1Ranking = ''
        } else {
          nextMatchup.participant2Id = null
          nextMatchup.participant2Name = ''
          nextMatchup.participant2Ranking = ''
        }

        if (nextMatchup.projectedWinner != null && nextMatchup.projectedWinner === matchupParticipantId) {
          nextMatchup.projectedWinner = null
          if (wasAdvancedInUI) this.selectedBracket.matchesWithAPrediction--
        } else {
          isFinished = true
        }
      }

      currMatchup = nextMatchup

      while (!isFinished && currMatchup != null) {
        const nextMatchup = allMatchups.find((m) => m.matchupId === currMatchup.nextMatchupId)
        if (nextMatchup != null) {
          if (currMatchup.nextMatchupOrder === 1) {
            nextMatchup.participant1Id = null
            nextMatchup.participant1Name = null
            nextMatchup.participant1Ranking = null
          } else if (currMatchup.nextMatchupOrder === 2) {
            nextMatchup.participant2Id = null
            nextMatchup.participant2Name = null
            nextMatchup.participant2Ranking = null
          }

          if (nextMatchup.projectedWinner != null && nextMatchup.projectedWinner === matchupParticipantId) {
            nextMatchup.projectedWinner = null
            if (wasAdvancedInUI) this.selectedBracket.matchesWithAPrediction--
          } else {
            isFinished = true
          }
        }
        currMatchup = nextMatchup
      }
    },

    isWinner(matchup, participantId) {
      return matchup.projectedWinner != null && matchup.projectedWinner == participantId
    },

    formatDateTime(date) {
      return DateUtils.formatDateTime(date, this.authStore?.loggedInUser?.user?.timeZoneSetting)
    },

    async getMyBrackets() {
      return await userBracketService.getMyBracketsForEvent(this.event.eventId)
    },

    async loadData() {
      const bracketsResponse = await this.getMyBrackets()

      this.brackets = bracketsResponse.data

      this.brackets.forEach((bracket) => {
        bracket.rounds.forEach((round) => {
          round.matchups.forEach((matchup) => {
            if (bracket.userMatchupMap[matchup.matchupId] != null) {
              const projectedWinnerParticipantId = bracket.userMatchupMap[matchup.matchupId].projectedWinnerParticipantId
              this.advance(matchup, projectedWinnerParticipantId, bracket)
            }
          })
        })
      })

      this.setPublishedStateToCurrent()
    },

    async saveBracket() {
      this.saveBracketLoading = true

      try {
        for (let i = 0; i < this.projectedMatchupWinners.length; i++) {
          const dto = {
            userBracketId: this.projectedMatchupWinners[i].userBracketId,
            projectedMatchupWinners: this.projectedMatchupWinners[i].projectedMatchupWinners
          }

          if (this.hasUnsavedChangesForUserBracket(dto.userBracketId)) {
            await userBracketService.updateUserBracket(dto.userBracketId, dto)
          }
        }

        await this.loadData()
      } catch {
        alert('Something went wrong')
      } finally {
        setTimeout(this.setIsBracketScrollingHorizontally, 1)
        setTimeout(this.updateMatchupLines, 1)
        this.saveBracketLoading = false
      }
    },

    setIsBracketScrollingHorizontally() {
      const bracketPredictionElement = this.$refs.bracketPredictionElem

      if (bracketPredictionElement != null) {
        this.isBracketScrollingHorizontally = bracketPredictionElement.scrollWidth > bracketPredictionElement.clientWidth
      } else {
        this.isBracketScrollingHorizontally = false
      }
    },

    checkForUnsavedChanges() {
      return true
    },

    preventDefaultBehavior(e) {
      e.preventDefault()
    },

    updateMatchupLines() {
      if (this.selectedBracket == null) return
      const allMatchups = this.selectedBracket.rounds.flatMap((round) => round.matchups)
      const gameElements = document.querySelectorAll('.game')
      const idPrefix = 'game'

      for (const gameElement of gameElements) {
        const gameMatchupId = gameElement.id.substring(idPrefix.length)
        const gameMatchup = allMatchups.find(m => m.matchupId == gameMatchupId)

        if (gameMatchup && gameMatchup.nextMatchupId != null) {
          const nextGameElement = document.getElementById(`${idPrefix}${gameMatchup.nextMatchupId}`)
          const matchupYPos = gameElement.getBoundingClientRect().y
          const nextMatchupYPos = nextGameElement.getBoundingClientRect().y
          const deltaHeight = Math.abs(nextMatchupYPos - matchupYPos)
          const connectorElement = gameElement.querySelector('.connector')

          if (connectorElement == null) continue

          connectorElement.style.height = deltaHeight + 'px'

          if (nextMatchupYPos > matchupYPos) {
            connectorElement.classList.add('connector-top')
            connectorElement.classList.remove('connector-bottom')
          } else {
            connectorElement.classList.add('connector-bottom')
            connectorElement.classList.remove('connector-top')
          }
        }

      }
    }
  },

  mounted() {
    PubSub.subscribe('bracket-reseeded', this.bracketReseeded)
    window.addEventListener('resize', this.setIsBracketScrollingHorizontally)
    window.addEventListener('resize', this.updateMatchupLines)
  },

  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventDefaultBehavior)
    window.removeEventListener('resize', this.updateMatchupLines)
  },

  unmounted() {
    try {
      PubSub.unsubscribe('bracket-reseeded', this.bracketReseeded)
      window.removeEventListener('resize', this.setIsBracketScrollingHorizontally)
    } catch {
      /* empty */
    }
  },

  // TODO: add something similar for event details page
  async beforeRouteLeave() {
    if (!this.hasUnsavedChanges || this.timeHasExpired) {
      return true
    }

    try {
      await this.showSaveChangesModal()
      // Resolved
      return true
    } catch (err) {
      // Rejected
    }

    return false
  },

  watch: {
    hasUnsavedChanges(curr) {
      if (curr) {
        window.addEventListener('beforeunload', this.preventDefaultBehavior)
      } else {
        window.removeEventListener('beforeunload', this.preventDefaultBehavior)
      }
    }
  }
}
</script>


<style scoped>
/* BRACKET STYLING */
#bracket-prediction {
  --first-game-width: 260px;
  --game-width: 180px;
  --round-spacing: 12px;
  --player-height: 24px;
  --game-vertical-spacing: 32px;
  --border-width: 1px;

  overflow-x: auto;
}

.bracket-outer {
  width: fit-content;
}

#bracket-prediction.border {
  border: 1px solid var(--color-gray2);
}

.bracket-outer.border {
  border: 1px solid var(--color-gray2);
}

.rounds {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  background-color: var(--color-gray1);
}

.round h3 {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
  border-bottom: 1px solid var(--color-gray2);
  background-color: var(--color-gray0-5);
}

.games {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-grow: 1;
}

.round-wrapper {
  display: flex;
  padding: calc(var(--round-spacing) / 2);
}

.round:first-of-type .round-wrapper {
  padding-left: var(--round-spacing);
}

.round:last-of-type .round-wrapper {
  padding-right: var(--round-spacing);
}

.game {
  border: 0.5px solid var(--color-gray1);
  position: relative;
  width: var(--game-width);
  box-shadow: 2px 4px 6px 0px #00000066;
  background-color: var(--color-white);
}

.player {
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--player-height);
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.player:hover {
  cursor: pointer;
}

.player-name {
  max-width: calc(var(--game-width) - 2.2rem);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 1rem;
}

.player .rank {
  text-align: center;
  min-width: 0.75rem;
}

.player>span {
  display: flex;
  align-items: baseline;
  gap: 0.125rem;
}

.grow {
  flex-grow: 1;
}

.connector {
  border: var(--border-width) solid var(--color-gray3);
  border-left: none;

  height: 60px;
  width: calc(var(--round-spacing) / 2);

  position: absolute;
  z-index: 1;
  left: calc(var(--game-width) + 2px);
}

.connector-top {
  border-bottom: none;
  top: 50%;
}

.connector-bottom {
  border-top: none;
  bottom: 50%;
}

.play-in .connector {
  border-top: 0;
}

.connector-before {
  position: absolute;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: var(--border-width) solid var(--color-gray3);
  width: calc(var(--round-spacing) / 2 - 1px);
  left: calc(var(--round-spacing) * -.5 + 1px);
  top: calc(50% - var(--border-width) / 2);
}

.connector-top>.connector-before {
  top: 100%;
}

.connector-bottom>.connector-before {
  top: 0;
}

.null-player-name {
  width: 95%;
  height: calc(var(--player-height) * 0.5);
  background: var(--color-gray1);
  flex-grow: 1;
  margin-right: 0.25rem;
  margin-left: 0.5rem;
}

.hideFillerMatchup {
  visibility: hidden;
}

.game.first-round {
  width: var(--first-game-width);
}

.first-round .player-name {
  max-width: calc(var(--first-game-width) - 2.2rem);
}

.first-round .connector {
  left: calc(var(--first-game-width) + 2px);
}

.connector-last-round {
  width: 0;
  height: 0;
  border-left: none;
  position: absolute;
  top: 50%;
  z-index: 1;
}

.connector-after-last-round {
  position: absolute;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: var(--border-width) solid var(--color-gray3);
  width: calc(var(--round-spacing));
  left: calc(var(--round-spacing) * -1);
}

.visibility-hidden {
  visibility: hidden;
}

/* END OF BRACKET STYLING */
</style>