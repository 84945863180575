<template>
  <div v-if="isMobile" class="flex col gap-0-5" :class="getLightDarkBackground">

    <router-link v-if="isAdmin" :to="{ name: 'event-details', params: { eventId: event.eventId } }" class="event-text">
      <div class="header-item event-summary flex col justify-center align-center gap-0-5 grow"
        :class="getBackgroundBlurClass">
        <event-summary :event="event" :showAdminData="showAdminData" :subText="subText" />
        <countdown-timer v-if="event.upcoming" :startDateTime="countdownTimerDate" @time-expires="countdownTimeExpires"
          @time-running-out="countdownTimerRunningOut" />
      </div>
    </router-link>

    <div v-else class="header-item event-summary flex col justify-center align-center gap-0-5 grow" :class="getBackgroundBlurClass">
      <event-summary :event="event" :showAdminData="showAdminData" :subText="subText" />
      <countdown-timer v-if="event.upcoming" :startDateTime="countdownTimerDate" @time-expires="countdownTimeExpires"
        @time-running-out="countdownTimerRunningOut" />
    </div>

    <div class="flex gap-0-5 grow">
      <team-score class="grow" v-if="isTeamEvent" :eventIsUpcoming="event.upcoming" :team-name="event.teams.team1Name" :dark-background="darkBackground"
        :actual-points="event.upcoming ? 0 : event.teams.team1Score" :event-has-team-points="eventHasTeamPoints"
        :predicted-points="showLeagueData ? leagueData.predictedTeam1Score : undefined"
        :team-image-url="event.teams.team1ImageUrl" />

      <team-score class="grow" v-if="isTeamEvent" :eventIsUpcoming="event.upcoming" :team-name="event.teams.team2Name" :dark-background="darkBackground"
        :actual-points="event.upcoming ? 0 : event.teams.team2Score" :event-has-team-points="eventHasTeamPoints"
        :predicted-points="showLeagueData ? leagueData.predictedTeam2Score : undefined"
        :team-image-url="event.teams.team2ImageUrl" />
    </div>

    <div v-if="showLeagueData" id="league-data" class="header-item flex col justify-center align-center" :class="getBackgroundBlurClass">
      <div class="league-indicator flex justify-center align-center heading4">
        League:&nbsp;&nbsp;
        <select @change="leagueChanged" class="bg-white caption p-0-25" v-model="leagueIdToView">
          <option v-for="league in leagues" :value="league.leagueId" :key="league.leagueId">{{ league.leagueName }}
          </option>
        </select>
      </div>
      <div id="league-data-group" v-if="!event.upcoming" :class="{ 'wrap': event.resultsPending }">
        <div class="league-data-item">
          <h4 class="heading4 nowrap">Points Won</h4>
          <div class="flex align-baseline gap-0-125">
            <h3 class="heading3">{{ numberWithCommas(leagueData.pointsWon) }}</h3>
            <span class="caption">points</span>
          </div>
        </div>
        <div class="league-data-item" v-if="event.resultsPending">
          <h4 class="heading4 nowrap">Points Possible</h4>
          <div class="flex align-baseline gap-0-125">
            <h3 class="heading3">{{ numberWithCommas(leagueData.pointsPossible) }}</h3>
            <span class="caption">points</span>
          </div>
        </div>
        <div class="league-data-item">
          <h4 class="heading4 nowrap">League Rank</h4>
          <div class="flex align-baseline gap-0-125">
            <h3 class="heading3">{{ numberWithCommas(leagueData.leagueRank) }}</h3>
            <span class="caption nowrap">out of {{ numberWithCommas(leagueData.leagueMemberCount) }}</span>
          </div>
        </div>
        <div class="league-data-item">
          <h4 class="heading4 nowrap">MATNESS Rank</h4>
          <div class="flex align-baseline gap-0-125">
            <h3 class="heading3">{{ numberWithCommas(leagueData.matnessRank) }}</h3>
            <span class="caption nowrap">out of {{ numberWithCommas(leagueData.matnessMemberCount) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="flex gap-1" :class="`${getTeamEventClasses} ${getLightDarkBackground}`">
    <team-score v-if="isTeamEvent" :eventIsUpcoming="event.upcoming" :team-name="event.teams.team1Name"
      :dark-background="darkBackground" :actual-points="event.upcoming ? 0 : event.teams.team1Score"
      :event-has-team-points="eventHasTeamPoints"
      :predicted-points="showLeagueData ? leagueData.predictedTeam1Score : undefined"
      :team-image-url="event.teams.team1ImageUrl" />

    <div class="flex col gap-0-5 grow">
      <router-link v-if="isAdmin" :to="{ name: 'event-details', params: { eventId: event.eventId } }"
        class="event-text">
        <div class="header-item event-summary flex col justify-center align-center gap-0-75 grow" :class="getBackgroundBlurClass">
          <event-summary :event="event" :showAdminData="showAdminData" :subText="subText" />
          <countdown-timer v-if="event.upcoming" :startDateTime="countdownTimerDate"
            @time-expires="countdownTimeExpires" @time-running-out="countdownTimerRunningOut" />
        </div>
      </router-link>

      <div v-else class="header-item event-summary flex col justify-center align-center gap-0-75 grow" :class="getBackgroundBlurClass">
        <event-summary :event="event" :showAdminData="showAdminData" :subText="subText" />
        <countdown-timer v-if="event.upcoming" :startDateTime="countdownTimerDate" @time-expires="countdownTimeExpires"
          @time-running-out="countdownTimerRunningOut" />
      </div>

      <div v-if="showLeagueData" id="league-data" class="header-item flex col justify-center align-center" :class="getBackgroundBlurClass">
        <div class="league-indicator flex justify-center align-center heading4">
          League:&nbsp;&nbsp;
          <select @change="leagueChanged" class="bg-white caption p-0-25" v-model="leagueIdToView">
            <option v-for="league in leagues" :value="league.leagueId" :key="league.leagueId">{{ league.leagueName }}
            </option>
          </select>
        </div>
        <div id="league-data-group" v-if="!event.upcoming" :class="{ 'wrap': event.resultsPending }">
          <div class="league-data-item">
            <h4 class="heading4 nowrap">Points Won</h4>
            <div class="flex align-baseline gap-0-125">
              <h3 class="heading3">{{ numberWithCommas(leagueData.pointsWon) }}</h3>
              <span class="caption">points</span>
            </div>
          </div>
          <div class="league-data-item" v-if="event.resultsPending">
            <h4 class="heading4 nowrap">Points Possible</h4>
            <div class="flex align-baseline gap-0-125">
              <h3 class="heading3">{{ numberWithCommas(leagueData.pointsPossible) }}</h3>
              <span class="caption">points</span>
            </div>
          </div>
          <div class="league-data-item">
            <h4 class="heading4 nowrap">League Rank</h4>
            <div class="flex align-baseline gap-0-125">
              <h3 class="heading3">{{ numberWithCommas(leagueData.leagueRank) }}</h3>
              <span class="caption nowrap">out of {{ numberWithCommas(leagueData.leagueMemberCount) }}</span>
            </div>
          </div>
          <div class="league-data-item">
            <h4 class="heading4 nowrap">MATNESS Rank</h4>
            <div class="flex align-baseline gap-0-125">
              <h3 class="heading3">{{ numberWithCommas(leagueData.matnessRank) }}</h3>
              <span class="caption nowrap">out of {{ numberWithCommas(leagueData.matnessMemberCount) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <team-score v-if="isTeamEvent" :eventIsUpcoming="event.upcoming" :team-name="event.teams.team2Name" :dark-background="darkBackground"
      :actual-points="event.upcoming ? 0 : event.teams.team2Score" :event-has-team-points="eventHasTeamPoints"
      :predicted-points="showLeagueData ? leagueData.predictedTeam2Score : undefined"
      :team-image-url="event.teams.team2ImageUrl" />
  </div>
</template>

<script>
import CountdownTimer from '@/components/leagues/CountdownTimer.vue'
import EventSummary from './EventSummary.vue';
import TeamScore from './TeamScore.vue';

import { useAuthenticationStore } from '@/stores/authentication'
import DateUtils from '@/utils/dateUtils.js'
import emitEvents from '@/utils/emitEvents';

export default {
  setup() {
    return {
      authStore: useAuthenticationStore()
    }
  },
  
  emits: [emitEvents.LEAGUE_CHANGED, emitEvents.TIME_RUNNING_OUT, emitEvents.TIME_EXPIRES],

  components: {
    CountdownTimer,
    EventSummary,
    TeamScore
  },

  props: {
    darkBackground: {
      type: Boolean,
      default: false
    },

    event: {
      type: Object,
      required: true
    },

    subText: {
      type: String,
      required: false,
      default: ''
    },

    showAdminData: {
      type: Boolean,
      default: false
    },

    showLeagueData: {
      type: Boolean,
      default: false
    },

    leagueData: {
      type: Object,
      required: false,
      default: () => {
        return {
          leagueOptions: [],
          pointsWon: 0,
          pointsPossible: 0,
          leagueRank: '-',
          leagueMemberCount: 0,
          matnessRank: '-',
          matnessMemberCount: 0
        }
      }
    },

    leagueId: {
      required: false
    },

    leagues: {
      type: Array,
      required: false
    },
  },

  computed: {
    countdownTimerDate() {
      return DateUtils.dateInUsersTimezone(this.event.startDateTime, this.event.timeZoneName)
    },
    eventHasTeamPoints() {
      const isClassificationsOfVictoryTurnedOn = this.event.customizeScoringDto?.addClassificationOfVictory
      const hasAtLeastOneClassificationOfVictoryWithTeamPoints = this.event.customizeScoringDto?.matchOutcomeOptions.some(moo => moo.teamPoints > 0)

      return isClassificationsOfVictoryTurnedOn && hasAtLeastOneClassificationOfVictoryWithTeamPoints
    },

    getBackgroundBlurClass() {
      return this.darkBackground ? 'dark-opacity-blur' : ''
    },

    getLightDarkBackground() {
      return this.darkBackground ? 'dark-background' : 'light-background'
    },

    getTeamEventClasses() {
      return this.isTeamEvent ? '' : 'justify-center'
    },

    isAdmin() {
      return this.authStore.isAdmin
    },

    isMobile() {
      return this.windowWidth <= 768
    },
    isTeamEvent() {
      return this.event.teams?.teamEvent
    }
  },

  data() {
    return {
      leagueIdToView: this.leagueId,
      windowWidth: window.innerWidth
    }
  },

  methods: {
    countdownTimeExpires() {
      this.$emit(emitEvents.TIME_EXPIRES)
    },
    countdownTimerRunningOut() {
      this.$emit(emitEvents.TIME_RUNNING_OUT)
    },
    leagueChanged() {
      this.$emit(emitEvents.LEAGUE_CHANGED, this.leagueIdToView)
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
  },

  mounted() {
    window.addEventListener('resize', this.onResize)
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },
}
</script>

<style scoped>
.header-item {
  border-radius: 8px;
  overflow: hidden;
}

.light-background .header-item {
  background-color: white;
  box-shadow: 2px 4px 6px 0px #00000066;
}

.dark-background .header-item * {
  color: var(--color-white);
}

.event-summary {
  padding: .5rem;
}

.dark-background #league-data {
  --league-data-border-color: var(--color-gray2);
}

.light-background #league-data {
  --league-data-border-color: var(--color-gray3);
}

.league-indicator {
  box-sizing: border-box;
  padding: .25rem .5rem;
  width: 100%;
  border-bottom: 2px solid var(--league-data-border-color);
}

.dark-background .league-indicator {
  background-color: var(--color-gray4-opacity50);
  border-bottom: none;
}

.league-indicator select {
  background-size: 1.25rem;
  background-position-x: calc(100% - .25rem);
  padding-right: 2rem;
  width: fit-content;
}

.dark-background .league-indicator select {
  background-image: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" class="size-5"><path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" /></svg>');
  background-size: 1.25rem;
  color: var(--color-white);
  background-color: #1B2A3900;
}

.dark-background .league-indicator select>option {
  color: var(--color-gray4);
}

#league-data-group {
  display: flex;
  width: 100%;
  justify-content: center;
}

#league-data-group.wrap {
  flex-wrap: wrap;
}

.league-data-item {
  width: 110px;
  flex-grow: 1;

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: .5rem;
}

.wrap>.league-data-item {
  flex-basis: calc(50% - .5rem);
  /* take up half of the width of the container subtract out padding) */
}

.league-data-item:nth-child(1) {
  border-right: 2px solid var(--league-data-border-color);
}

.league-data-item:nth-child(3) {
  border-left: 2px solid var(--league-data-border-color);
}

.wrap>.league-data-item:nth-child(1),
.wrap>.league-data-item:nth-child(3) {
  border-left: none;
  border-right: 2px solid var(--league-data-border-color);
}

.wrap>.league-data-item:nth-child(1),
.wrap>.league-data-item:nth-child(2) {
  border-bottom: 2px solid var(--league-data-border-color);
}

.dark-background .league-data-item .heading3 {
  color: var(--color-dark-orange);
}

@media screen and (max-width: 512px) {}
</style>