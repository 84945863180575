<template>
  <modal-dialog ref="myModal">

    <template v-slot:modal-title>Invite League Members</template>

    <template v-slot:modal-contents>
      <p>Share this invite link with your friends to allow them to join your league!</p>
    </template>

    <template v-if="copied" v-slot:modal-actions>
      <rebel-button v-if="isSharingAvailable" type="primary" text="Share Invite" color="default" @click.prevent="share">
        <template v-slot:icon-leading>
          <arrow-up-tray-icon class="icon-20" />
        </template>
      </rebel-button>
      <rebel-button type="ghosted" color="default" text="Link Copied!" @click.prevent="confirm">
        <template v-slot:icon-leading>
          <check-icon class="icon-20" />
        </template>
      </rebel-button>
      <a @click.prevent="cancel">Close</a>
    </template>

    <template v-else v-slot:modal-actions>
      <rebel-button v-if="isSharingAvailable" type="primary" text="Share Invite" color="default" @click.prevent="share">
        <template v-slot:icon-leading>
          <arrow-up-tray-icon class="icon-20" />
        </template>
      </rebel-button>
      <rebel-button type="ghosted" color="default" text="Copy Invite Link" @click.prevent="confirm">
        <template v-slot:icon-leading>
          <clipboard-document-icon class="icon-20" />
        </template>
      </rebel-button>
      <a @click.prevent="cancel">Cancel</a>
    </template>
  </modal-dialog>
</template>

<script>
import { ArrowUpTrayIcon, CheckIcon, ClipboardDocumentIcon } from '@heroicons/vue/20/solid';
import ModalDialog from '@/components/ModalDialog.vue'
import RebelButton from '@/components/RebelButton.vue'

export default {
  components: {
    ArrowUpTrayIcon,
    CheckIcon,
    ClipboardDocumentIcon,
    ModalDialog,
    RebelButton
  },

  computed: {
    isSharingAvailable() {
      if (navigator == null || navigator.canShare == null) return false

      return navigator?.canShare(this.shareData)
    }
  },

  data() {
    return {
      copied: false,

      shareData: {
        title: `Open this link to join my Wrestle MATNESS league`,
        text: `Open this link to join my Wrestle MATNESS league for the ${this.eventName}!`, // text message text
        url: this.inviteLink
        // TODO: customize meta tag og.title to be the league name
      }
    }
  },

  props: {
    eventName: {
      type: String,
      required: true,
      default: 'upcoming event'
    },

    inviteLink: {
      type: String,
      required: true
    }
  },

  methods: {
    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    confirm() {
      navigator.clipboard.writeText(this.inviteLink)
      this.copied = true
    },

    async share() {
      try {
        await navigator.share(this.shareData)
      } catch { 
        // throws exception when share is cancelled. ignoring this.
      }
    }
  }
}
</script>
