<template>
  <modal-dialog ref="myModal">
    <template v-slot:modal-title>Create New Event</template>
    <template v-slot:modal-contents>
      <form id="create-event-form" @submit.prevent="createEvent">
        <div class="layout-form-list">
          <div class="form-input">
            <label for="event-name">Event Name</label>
            <input id="event-name" type="text" placeholder="Event Name" v-model="event.name"
              :required="dataConstraints.event.name.required" :minlength="dataConstraints.event.name.minlength"
              :maxlength="dataConstraints.event.name.maxlength" />
          </div>

          <div class="form-input">
            <label for="event-type">Event Type</label>
            <select id="event-type" v-model.number="event.typeId" required>
              <option v-for="(option, index) in eventTypes.getEventTypeOptions()" :key="index" :value="option.value">{{ option.text }}
              </option>
            </select>
          </div>

          <div class="form-input">
            <label for="event-start-date">{{ eventDateLabel }}</label>
            <input id="event-start-date" type="date" v-model.lazy="event.startDate"
              :required="dataConstraints.event.startDate.required" :minlength="dataConstraints.event.startDate.minlength"
              :maxlength="dataConstraints.event.startDate.maxlength" />
          </div>

          <div class="form-input">
            <label for="event-start-time">{{ eventTimeLabel }}</label>
            <input id="event-start-time" type="time" v-model.lazy="event.startTime"
              :required="dataConstraints.event.startTime.required" :minlength="dataConstraints.event.startTime.minlength"
              :maxlength="dataConstraints.event.startTime.maxlength" />
          </div>

          <div class="form-input">
            <label for="event-time-zone">Time Zone</label>
            <select-time-zone id="event-time-zone" :required="true" :timestampForOffset="getTimestamp"
              :value="event.timeZoneName" @time-zone-updated="updateTimeZone"></select-time-zone>
          </div>

          <div class="form-input">
            <label for="event-name">Link to Event Info [Blog]</label>
            <input id="event-name" type="text" :placeholder="urlUtils.SAMPLE_BLOG_URL" v-model="event.infoUrl"
              @blur="urlUtils.setValidityForHtmlInputElementWithUrl($event.currentTarget)" />
          </div>
        </div>

        <div class="layout-form-sections mt-2">
          <div class="actions-list">
            <rebel-button button-type="submit" type="primary" color="default" text="Create Event"
              :is-loading="isSavingEvent"></rebel-button>
            <a href="javascript:void(null)" @click.prevent="cancel">Cancel</a>
          </div>
        </div>
      </form>
    </template>
  </modal-dialog>
</template>

<script>
import dataConstraints from '@/utils/dataConstraints.js'
import eventService from '@/services/EventService.js'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'

import ModalDialog from '@/components/ModalDialog.vue'
import RebelButton from '@/components/RebelButton.vue'
import SelectTimeZone from '@/components/SelectTimeZone.vue'

import eventTypes from '../../utils/eventTypes.js'
import urlUtils from '../../utils/urlUtils.js'

export default {
  setup() {
    return {
      eventTypes,
      dataConstraints,
      urlUtils
    }
  },

  components: {
    ModalDialog,
    RebelButton,
    SelectTimeZone
  },

  computed: {
    eventDateLabel() {
      if (this.isPopularVoteTournament) return 'Voting End Date'

      return 'Start Date'
    },

    eventTimeLabel() {
      if (this.isPopularVoteTournament) return 'Voting End Time'

      return 'Start Time'
    },

    isPopularVoteTournament() {
      return eventTypes.isPopularVoteTournament(this.event.typeId)
    },

    getTimestamp() {
      if (this.event.startDate == null || this.event.startTime == null) {
        return new Date().getTime()
      }

      return moment(`${this.event.startDate} ${this.event.startTime}`).toDate().getTime()
    }
  },

  props: {
    eventData: {
      type: Object,
      default: () => {
        return {
          name: '',
          startDate: null,
          startTime: null,
          logoUrl: '',
          timeZoneName: null
        }
      }
    }
  },

  data() {
    return {
      event: {
        eventId: this.eventData.eventId,
        name: this.eventData.name,
        startDate: this.eventData.startDateTime == null ? null : moment.tz(this.eventData.startDateTime, this.eventData.timeZoneName).format('yyyy-MM-DD'),
        startTime: this.eventData.startDateTime == null ? null : moment.tz(this.eventData.startDateTime, this.eventData.timeZoneName).format('HH:mm'),
        logoUrl: this.eventData.logoUrl ?? '',
        timeZoneName: this.eventData.timeZoneName
      },
      isSavingEvent: false,
    }
  },

  methods: {
    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    async createEvent() {
      this.isSavingEvent = true

      try {
        const localDateTime = moment.tz(`${this.event.startDate} ${this.event.startTime}`, this.event.timeZoneName)
        const utcDateTime = localDateTime.utc()

        const eventDto = { ...this.event }

        eventDto.startDateTime = utcDateTime
        delete eventDto.startDate
        delete eventDto.startTime

        const response = await eventService.createV2(eventDto)

        if (response.data?.eventId == null) {
          alert('Something went wrong')
          return
        }

        this.$router.push({ name: 'event-details', params: { eventId: response.data.eventId } })
      } catch {
        alert('Something went wrong')
      } finally {
        this.isSavingEvent = false
      }
    },

    updateTimeZone(newTimeZone) {
      this.event.timeZoneName = newTimeZone.name
    }
  }
}
</script>


