<template>
  <div class="flex align-center gap-0-25 ">
    <div class="avatar-grouping flex align-center" :style="{ height: radius, width: widthForGrouping }">
      <template v-for="n in Math.min(memberCount, MAX_AVATARS_DISPLAYED)" v-bind:key="n">
        <avatar-component :style="getStyleObjectForAvatarIndex(n - 1)" :src="avatarSources[n - 1]?.imageSrc" :width="radius"
          :height="radius" :title="avatarSources[n - 1]?.displayName" />
      </template>
    </div>
    <div v-if="memberCount > MAX_AVATARS_DISPLAYED">
      <span class="heading3 color-action">+{{ memberCount - MAX_AVATARS_DISPLAYED }}</span>
    </div>
  </div>
</template>

<script>
import AvatarComponent from '@/components/AvatarComponent.vue'

export default {
  components: {
    AvatarComponent
  },

  props: {
    maxAvatarsDisplayed: {
      type: Number,
      default: 4
    },

    memberCount: {
      type: Number,
      default: 0
    },

    overlapAmount: {
      type: Number,
      default: 4
    },

    radius: {
      type: Number,
      default: 24
    },

    stroke: {
      type: Number,
      default: 2
    },

    avatarSources: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    widthForGrouping() {
      if (this.memberCount === 0) return 0

      if (this.memberCount <= this.MAX_AVATARS_DISPLAYED) {
        return `${((this.radius + (this.stroke * 2)) * this.memberCount) - (this.overlapAmount * (this.memberCount - 1))}px`
      }

      return `${((this.radius + (this.stroke * 2)) * this.MAX_AVATARS_DISPLAYED) - (this.overlapAmount * (this.MAX_AVATARS_DISPLAYED - 1))}px`
    }
  },

  data() {
    return {
      MAX_AVATARS_DISPLAYED: this.maxAvatarsDisplayed,
      COUNT_TEXT_WIDTH: this.$refs.plusCountText?.getBoundingClientRect().width
    }
  },

  methods: {
    getStyleObjectForAvatarIndex(avatarIndex) {
      return {
        position: 'relative',
        left: `${-this.overlapAmount * avatarIndex}px`,
        'z-index': avatarIndex
      }
    }
  }
}
</script>