<template>
  <modal-dialog ref="myModal" @submit.prevent="joinOpenTournament">
    <template v-slot:modal-title>Join Open Tournament?</template>
    <template v-slot:modal-contents>
      <p>
        The <span class="bold">Open Tournament</span> is available for all Wrestle MATNESS fans to join! At the start of
        the event, you will be randomly assigned to an <span class="bold">Open Tournament League</span> to compete against
        other fans.
      </p>
      <p>
        Would you like to join the <span class="bold">Open Tournament</span>, for the
        <span class="bold">{{ event?.name || 'event' }}</span
        >?
      </p>

      <error-summary class="text-center" ref="errorSummary" />
    </template>

    <template v-slot:modal-actions>
      <rebel-button
        button-type="submit"
        type="primary"
        color="default"
        text="Join Open Tournament"
        :is-loading="isLoading"
      ></rebel-button>
      <a href="javascript:void(null)" @click.prevent="cancel">Cancel</a>
    </template>
  </modal-dialog>
</template>

<script>
import { useAuthenticationStore } from '@/stores/authentication'

import leagueService from '@/services/LeagueService.js'

import ErrorSummary from '@/components/ErrorSummary.vue'
import RebelButton from '@/components/RebelButton.vue'
import ModalDialog from '@/components/ModalDialog.vue'

export default {
  beforeCreate() {
    if (!this.authStore.isLoggedIn) {
      const loginRoute = {
        name: 'login',
        // save the location we were at to come back later
        query: { redirect: this.$route.fullPath }
      }

      this.$router.push(loginRoute)
    }
  },
  
  setup() {
    return {
      authStore: useAuthenticationStore()
    }
  },

  components: {
    ErrorSummary,
    RebelButton,
    ModalDialog
  },

  props: {
    event: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isLoading: false
    }
  },

  methods: {
    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    async joinOpenTournament() {
      this.resetErrors()

      this.isLoading = true

      try {
        // send request to server
        const response = await leagueService.joinOpenTournamentForEvent(this.event.eventId)

        // TODO
        // recordLeagueCreate({
        //   event_name: this.event.name
        // })

        if (response?.data == null) {
          this.$router.push({ name: 'events' });
        }

        // navigate to open tournament if successful
        this.$router.push({ name: 'league-details', params: { leagueId: response.data } })
      } catch (error) {
        if (error?.response?.status == 400 && error.response?.data?.message) {
          this.$refs.errorSummary.setErrors(error.response.data.message)
          return
        }

        this.$refs.errorSummary.setErrors('Something went wrong')
        // show error message if not
        alert(error)
      } finally {
        this.isLoading = false
      }
    },

    resetErrors() {
      this.$refs.errorSummary.clearErrors()
    }
  }
}
</script>

