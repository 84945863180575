<template>
  <div class="pill" :class="classes">
    {{ text }}
  </div>
</template>

<script>
export default {
  props: ['text', 'color', 'darkBackground'],

  computed: {
    classes() {
      if (this.darkBackground) {
        return `dark-background small-caps-1 ${this.color}`
      }

      return `light-background small-caps-1 ${this.color}`
    }
  }
}
</script>

<style scoped>
.light-background.success {
  --color-actual: var(--color-success);
  --background-color-actual: var(--color-success-opacity10);
}

.light-background.danger {
  --color-actual: var(--color-danger);
  --background-color-actual: var(--color-danger-opacity10);
}

.light-background.warning {
  --color-actual: var(--color-warning);
  --background-color-actual: var(--color-warning-opacity10);
}

.dark-background.success {
  --color-actual: var(--color-white);
  --background-color-actual: var(--color-success);
}

.dark-background.danger {
  --color-actual: var(--color-white);
  --background-color-actual: var(--color-danger);
}

.dark-background.warning {
  --color-actual: var(--color-warnwhiteing);
  --background-color-actual: var(--color-warning);
}

.pill {
  background-color: var(--background-color-actual);
  border-radius: 2px;
  color: var(--color-actual);
  white-space: nowrap;
  width: fit-content;
  box-sizing: border-box
}

.light-background.pill {
  padding: 0.25rem 0.5rem;
}

.dark-background.pill {
  padding: 0.2rem 0.4rem;
}


</style>