<template>
  <modal-dialog ref="myModal" @submit.prevent="updateLeagueName">
    <template v-slot:modal-title>Edit League Name</template>
    <template v-slot:modal-contents>
      <div class="form-labeled-value">
        <h4>Event</h4>
        <p class="black">{{ event.name }}</p>
      </div>

      <div class="form-labeled-value">
        <h4>Start Date</h4>
        <p class="black">{{ formatStartDate(event.startDateTime) }}</p>
      </div>

      <div class="form-labeled-value">
        <h4>Start Time</h4>
        <p class="black">{{ formatStartTime(event.startDateTime) }}</p>
      </div>

      <div class="form-input">
        <label for="league-name">League Name</label>
        <input
          id="league-name"
          type="text"
          placeholder="League Name"
          v-model="leagueNameData"
          :required="dataConstraints.league.name.required"
          :minlength="dataConstraints.league.name.minlength"
          :maxlength="dataConstraints.league.name.maxlength"
        />
        <div class="caption gray2">Each league name for this event must be unique.</div>
      </div>

      <error-summary class="text-center" ref="errorSummary" />
    </template>
    <template v-slot:modal-actions>
      <rebel-button
        button-type="submit"
        :disabled="!wasLeagueNameEdited"
        :is-loading="isUpdating"
        type="primary"
        color="default"
        text="Update League Name"
      ></rebel-button>
      <a href="javascript:void(null)" @click.prevent="cancel">Cancel</a>
    </template>
  </modal-dialog>
</template>

<script>
import dataConstraints from '@/utils/dataConstraints.js'
import { useAuthenticationStore } from '@/stores/authentication'

import DateUtils from '@/utils/dateUtils.js'

import emitEvents from '@/utils/emitEvents'
import leagueService from '@/services/LeagueService.js'

import ErrorSummary from '@/components/ErrorSummary.vue'
import RebelButton from '@/components/RebelButton.vue'
import ModalDialog from '@/components/ModalDialog.vue'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore(),
      dataConstraints
    }
  },

  components: {
    ErrorSummary,
    RebelButton,
    ModalDialog
  },

  emits: [emitEvents.LEAGUE_UPDATED],

  props: {
    event: {
      type: Object,
      required: true
    },

    leagueId: {
      required: true
    },

    leagueName: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      leagueNameData: this.leagueName,
      savedLeagueName: '',
      isUpdating: false
    }
  },

  created() {
    this.savedLeagueName = this.leagueName
  },

  computed: {
    wasLeagueNameEdited() {
      return this.leagueNameData !== this.savedLeagueName
    }
  },

  methods: {
    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    formatStartDate(startDateTime) {
      return DateUtils.formatDateFromDateTime(startDateTime, this.authStore.loggedInUser.user.timeZoneSetting)
    },

    formatStartTime(startTime) {
      return DateUtils.formatTimeFromDateTime(startTime, this.authStore.loggedInUser.user.timeZoneSetting)
    },

    isValidForm() {
      if (this.leagueNameData == null || this.leagueNameData === '') {
        this.$refs.errorSummary.setErrors('League Name is required')
        return false
      }

      return true
    },

    resetErrors() {
      this.$refs.errorSummary.clearErrors()
    },

    async updateLeagueName() {
      this.resetErrors()

      if (!this.isValidForm()) {
        return
      }

      this.isUpdating = true

      try {
        if (this.wasLeagueNameEdited) {
          const dto = {
            leagueId: this.leagueId,
            name: this.leagueNameData
          }
          const response = await leagueService.update(dto)
          this.$emit(emitEvents.LEAGUE_UPDATED, response.data)
        }

        this.savedLeagueName = this.leagueName
        this.closeModal()
      } catch (error) {
        if (error?.response?.status == 400 && error.response?.data?.message) {
          this.$refs.errorSummary.setErrors(error.response.data.message)
          return
        }

        this.$refs.errorSummary.setErrors('Something went wrong')
      } finally {
        this.isUpdating = false
      }
    }
  }
}
</script>

