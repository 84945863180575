<template>
  <div class="flex align-baseline gap-0-25">
    <div class="flex align-baseline gap-0-25">
      <span class="heading4 color-action">{{ value1 }}</span>
      <span class="caption color-action">{{ label1 }}</span>
    </div>
    <span class="caption gray2">/</span>
    <div class="flex align-baseline gap-0-25">
      <span class="heading4 gray3">{{ value2 }}</span>
      <span class="caption gray2">{{ label2 }}</span>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    label1: {
      type: String,
      required: true
    },

    label2: {
      type: String,
      required: true
    },
    
    value1: {
      type: Number,
      required: true
    },

    value2: {
      type: Number,
      required: true
    }
  }

}
</script>
