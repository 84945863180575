<template>
  <section class="timer" :class="getLightDarkBackground">
    <div class="timer-part">
      <div class="timer-flipper-container">
        <div class="flipper-image">
          <timer-flipper />
        </div>
        <div class=timer-num>{{ timeRemaining.days }}</div>
      </div>
      <div class="timer-label small-caps-1">days</div>
    </div>

    <div class="timer-colon">:</div>

    <div class="timer-part">
      <div class="timer-flipper-container">
        <div class="flipper-image">
          <timer-flipper />
        </div>
        <div class=timer-num>{{ timeRemaining.hours }}</div>
      </div>
      <div class="timer-label small-caps-1">hours</div>
    </div>

    <div class="timer-colon">:</div>

    <div class="timer-part">
      <div class="timer-flipper-container">
        <div class="flipper-image">
          <timer-flipper />
        </div>
        <div class=timer-num>{{ timeRemaining.minutes }}</div>
      </div>
      <div class="timer-label small-caps-1">minutes</div>
    </div>

    <div class="timer-colon">:</div>

    <div class="timer-part">
      <div class="timer-flipper-container">
        <div class="flipper-image">
          <timer-flipper />
        </div>
        <div class=timer-num>{{ timeRemaining.seconds }}</div>
      </div>
      <div class="timer-label small-caps-1">seconds</div>
    </div>


  </section>
</template>

<script>
import emitEvents from '@/utils/emitEvents.js'

import TimerFlipper from '../icons/TimerFlipper.vue';

export default {
  props: ['startDateTime', 'darkBackground'],

  emits: [emitEvents.TIME_RUNNING_OUT, emitEvents.TIME_EXPIRES],

  computed: {
    getLightDarkBackground() {
      return this.darkBackground ? 'dark-background' : 'light-background'
    }
  },

  data() {
    return {
      timer: null,

      timeRemaining: {
        seconds: 0,
        minutes: 0,
        hours: 0,
        days: 0
      },

      hasEmittedTimeRunningOut: false
    }
  },

  methods: {
    getRemainingTime() {
      const currentTime = new Date().getTime()

      if (currentTime > this.startDateTime) return 0

      return this.startDateTime - currentTime
    },

    pad(value) {
      return ('0' + Math.floor(value)).slice(-2)
    },

    updateTimeRemaining() {
      cancelAnimationFrame(this.timer)

      const remainingTime = this.getRemainingTime(this.startDateTime)

      this.timeRemaining.seconds = this.pad((remainingTime / 1000) % 60)
      this.timeRemaining.minutes = this.pad((remainingTime / (60 * 1000)) % 60)
      this.timeRemaining.hours = this.pad((remainingTime / (60 * 60 * 1000)) % 24)
      this.timeRemaining.days = this.pad(remainingTime / (24 * 60 * 60 * 1000))

      // ensure clock only updates if a second or more is remaining
      if (remainingTime >= 1000) {
        this.timer = requestAnimationFrame(this.updateTimeRemaining)
      } else {
        this.$emit(emitEvents.TIME_EXPIRES)
      }

      const EMIT_WHEN_BELOW_THIS_MANY_MINUTES = 3
      if (remainingTime <= (EMIT_WHEN_BELOW_THIS_MANY_MINUTES * 60 * 1000) && !this.hasEmittedTimeRunningOut) {
        this.hasEmittedTimeRunningOut = true
        this.$emit(emitEvents.TIME_RUNNING_OUT)
      }
    }
  },

  created() {
    this.timer = requestAnimationFrame(this.updateTimeRemaining)
  },

  components: {
    TimerFlipper
  }
}
</script>

<style scoped>

.timer {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
}

.timer-part {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.075rem;
}

.timer-flipper-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.75rem;
  height: 3.5rem;
  position: relative;
}

timer-flipper {
  z-index: 0;
}

.dark-background .flipper-image {
  opacity: 0.5;
}

.timer-num{
  z-index: 1;
  position: absolute;
  top: 0;
  text-align: center;
  color: var(--color-white);
  line-height: 3.5rem;
}

.timer-num,
.timer-colon {
  font-size: 2.25rem;
  font-weight: 300;
}

.timer-colon {
  padding-top: 0.375rem;
}

.light-background .timer-label,
.light-background .timer-colon {
  color: var(--color-dark-blue);
}

.dark-background .timer-label,
.dark-background .timer-colon {
  color: var(--color-white);
}
</style>