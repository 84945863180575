<template>
  <modal-dialog ref="myModal" @submit.prevent="saveChanges">
    <template v-slot:modal-title>Save Changes?</template>
    <template v-slot:modal-contents>
      <p>You have unsaved changes. Would you like to save before leaving this page?</p>
    </template>
    <template v-slot:modal-actions>
      <rebel-button button-type="submit" type="primary" color="default" text="Save Changes"></rebel-button>
      <a href="javascript:void(null)" @click.prevent="cancel" class="color-danger">Discard Changes</a>
    </template>
  </modal-dialog>
</template>

<script>
import emitEvents from '@/utils/emitEvents.js'
import RebelButton from '@/components/RebelButton.vue'
import ModalDialog from '@/components/ModalDialog.vue'

export default {
  components: {
    RebelButton,
    ModalDialog
  },

  emits: [emitEvents.DISCARD_CHANGES, emitEvents.SAVE_CHANGES],

  methods: {
    cancel() {
      this.$emit(emitEvents.DISCARD_CHANGES)
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    saveChanges() {
      this.$emit(emitEvents.SAVE_CHANGES)
    }
  }
}
</script>
