<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" :class="CLOSE_ME_CLASS">
      <div class="modal" role="dialog" aria-labelledby="modalTitle">
        <div class="icon-container flex justify-end">
          <x-mark-icon data-testid="close-btn" class="icon icon-24" :class="CLOSE_ME_CLASS" @click="close" />
        </div>
        <form class="modal-body layout-form-page">
          <h1 id="modalTitle">
            <slot name="modal-title"></slot>
          </h1>
          <div id="modal-contents" class="layout-form-list">
            <slot name="modal-contents"></slot>
          </div>
          <div class="actions-list" v-if="$slots['modal-actions']">
            <slot name="modal-actions"></slot>
          </div>
        </form>
      </div>
    </div>
  </transition>
</template>

<script>
import { XMarkIcon } from '@heroicons/vue/24/solid';
import emitEvents from '../utils/emitEvents.js'

export default {
  components: {
    XMarkIcon
  },
  created() {
    window.addEventListener('click', this.tryClose)
    window.addEventListener('touchstart', this.tryClose)
  },

  unmounted() {
    window.removeEventListener('click', this.tryClose)
    window.removeEventListener('touchstart', this.tryClose)
  },

  data() {
    return {
      CLOSE_ME_CLASS: 'close-me-when-clicked'
    }
  },

  emits: [emitEvents.CLOSE, emitEvents.SUBMIT_MODAL],

  methods: {
    close() {
      window.removeEventListener('click', this.tryClose)
      this.$emit(emitEvents.CLOSE)
    },

    tryClose(event) {
      if (event.target.classList.contains(this.CLOSE_ME_CLASS)) {
        this.close()
      }
    }
  }
}
</script>

<style scoped>
.icon {
  cursor: pointer;
}

.icon-container {
  padding-right: 0.5rem;
  padding-top: 0.5rem;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal {
  background: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.5rem;
  width: 512px;
  max-height: 100%;
  overflow-y: auto;
  z-index: 100;
  margin: 0.5rem;
}

@media screen and (max-width: 768px) {
  .modal {
    width: 448px;
  }
}

@media screen and (max-width: 512px) {
  .modal {
    width: 100%;
  }
}

.modal>* {
  width: 100%;
}

.text-right {
  text-align: right;
}

.modal-body {
  position: relative;
  padding: 3rem 4rem 4rem 4rem;
  box-sizing: border-box;
}

#modalTitle {
  text-align: center;
}

#modal-contents>* {
  align-self: stretch;
  text-align: center;
}

@media screen and (max-width: 448px) {
  .modal-body {
    padding: 1rem 2rem 2rem 2rem;
  }
}
</style>