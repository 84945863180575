<template>
  <div v-if="!isLoading" class="flex col gap-1-5">
    <div class="flex space-between align-center">


      <header-action-bar :dark-background="darkBackground">
        <template v-slot:linkBackTo>
          <link-back-to :router-link-to="{ name: 'events' }" page-name="Events"  :dark-background="darkBackground" />
        </template>
        <template v-slot:secondaryAction1>
          <rebel-button v-if="event.infoUrl" :dark-background="darkBackground" @click="goToEventInfo" type="ghosted"
            color="default" text="Event Info" class="nowrap">
            <template v-slot:icon-leading>
              <arrow-top-right-on-square-icon class="icon-20" />
            </template>
          </rebel-button>
        </template>
      </header-action-bar>

    </div>

    <pick-em-header :dark-background="darkBackground" :subText="eventSubText" :show-admin-data="false"
      :show-league-data="false" :event="event" :key="`header-${lastLoadedTime}`" />

    <pick-em-matches :dark-background="darkBackground"
      :event-data="event" :key="`matches-${lastLoadedTime}`" />
  </div>
</template>

<script>
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/20/solid'
import HeaderActionBar from '@/components/HeaderActionBar.vue'
import LinkBackTo from '@/components/LinkBackTo.vue'
import PickEmHeader from '@/components/feature/pick-em/header/PickEmHeader.vue'
import PickEmMatches from '@/components/feature/pick-em/PickEmMatches.vue'
import RebelButton from '@/components/RebelButton.vue'

import eventService from '@/services/EventService'

import { useAuthenticationStore } from '@/stores/authentication'
import { useEventAdminStore } from '@/stores/eventAdmin'
import DateUtils from '@/utils/dateUtils'
import PubSub from 'pubsub-js'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore(),
      eventAdminStore: useEventAdminStore()
    }
  },

  components: {
    ArrowTopRightOnSquareIcon,
    HeaderActionBar,
    LinkBackTo,
    PickEmHeader,
    PickEmMatches,
    RebelButton,
  },

  computed: {
    eventId() {
      return this.$route.params.eventId
    },

    eventSubText() {
      return this.formatDateTime(this.event.startDateTime)
    },
  },

  async created() {
    await this.loadData()

    this.isLoading = false
  },

  data() {
    return {
      isLoading: true,

      event: {},

      darkBackground: this.$route.meta.darkBackground,

      lastLoadedTime: new Date().getMilliseconds(),
    }
  },

  methods: {
    formatDateTime(date) {
      return DateUtils.formatDateTime(date, this.authStore?.loggedInUser?.user?.timeZoneSetting)
    },

    goToEventInfo() {
      window.open(this.event.infoUrl, '_blank')
    },

    async loadData() {
      const response = await eventService.getById(this.eventId)

      this.event = response.data
      this.lastLoadedTime = new Date().getMilliseconds()
      this.eventAdminStore.initialize(this.event)

      // if the event is upcoming, redirect to not found
      if (this.event == null || this.event.upcoming) {
        this.$router.push({ name: 'not-found' })
      }
    },
  },

  mounted() {
    PubSub.subscribe('event-results-published', this.loadData)
  },


  unmounted() {
    try {
      PubSub.unsubscribe('event-results-published', this.loadData)
    } catch {
      /* empty */
    }
  },

}
</script>