const MATNESS_HOSTNAME = import.meta.env.VITE_MATNESS_HOSTNAME

export default {
  SAMPLE_BLOG_URL: `https://${MATNESS_HOSTNAME}/blog/1`,

  setValidityForHtmlInputElementWithUrl(el) {
    if (el.value == null || el.value === '') {
      el.setCustomValidity('')
      return
    }

    if (!this.isValidHttpUrl(el.value)) {
      el.setCustomValidity('Invalid Url. Expecting something like ' + this.SAMPLE_BLOG_URL)
    } else {
      el.setCustomValidity('')
    }
  },

  isValidHttpUrl(str) {
    try {
      const newUrl = new URL(str);
      return newUrl.protocol === 'http:' || newUrl.protocol === 'https:'
    } catch (err) {
      return false
    }
  },

}