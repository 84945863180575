<template>
  <modal-dialog ref="myModal" @submit.prevent="submit">
    <template v-if="hasUnsavedChanges" v-slot:modal-title>You have unsaved changes!</template>
    <template v-else v-slot:modal-title>Event begins soon!</template>
    <template v-slot:modal-contents>
      <p>
        There are less than {{ timeRemainingVerbiage }} remaining until the start of the event.
      </p>

      <p v-if="hasUnsavedChanges">
        Once the event begins you will no longer be able to edit or save your changes.
      </p>
      <p v-else>Once the event begins you will no longer be able to edit your picks.</p>

      <p v-if="hasUnsavedChanges">
        Would you like to save them now?
      </p>
    </template>
    <template v-slot:modal-actions>
      <rebel-button button-type="submit" type="primary" color="default" :text="submitButtonText"></rebel-button>
      <a v-if="hasUnsavedChanges" href="javascript:void(null)" @click.prevent="cancel" class="color-action">Keep Editing</a>
    </template>
  </modal-dialog>
</template>

<script>
import emitEvents from '@/utils/emitEvents.js'
import RebelButton from '@/components/RebelButton.vue'
import ModalDialog from '@/components/ModalDialog.vue'

export default {
  components: {
    RebelButton,
    ModalDialog
  },

  props: {
    hasUnsavedChanges: {
      type: Boolean,
      default: false
    },

    timeRemainingVerbiage: {
      type: String,
      default: 'three minutes'
    }
  },

  emits: [emitEvents.SAVE_CHANGES],

  computed: {
    submitButtonText() {
      if (this.hasUnsavedChanges) {
        return 'Save Changes'
      }

      return 'Ok'
    }
  },

  methods: {
    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    submit() {
      if (this.hasUnsavedChanges) {
        this.saveChanges()
      } 

      this.closeModal()
    },

    saveChanges() {
      this.$emit(emitEvents.SAVE_CHANGES)
    }
  }
}
</script>
