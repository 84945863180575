<template>
  <div data-custom-class="body">
    <div>
      <strong
        ><span style="font-size: 26px"
          ><span data-custom-class="title"
            ><span class="block-component"></span><span class="question">PRIVACY POLICY</span
            ><span class="statement-end-if-in-editor"></span></span></span
      ></strong>
    </div>
    <div><br /></div>
    <div>
      <span style="color: rgb(127, 127, 127)"
        ><strong
          ><span style="font-size: 15px"
            ><span data-custom-class="subtitle">Last updated <span class="question">January 30, 2024</span></span></span
          ></strong
        ></span
      >
    </div>
    <div><br /></div>
    <div><br /></div>
    <div><br /></div>
    <div style="line-height: 1.5">
      <span style="color: rgb(127, 127, 127)"
        ><span style="color: rgb(89, 89, 89); font-size: 15px"
          ><span data-custom-class="body_text"
            >This privacy notice for <span class="question">MATNESS, LLC<span class="block-component"></span></span> (<span
              class="block-component"
            ></span
            >"<strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>"<span
              class="statement-end-if-in-editor"
            ></span></span
          ><span data-custom-class="body_text"
            >), describes how and why we might collect, store, use, and/or share (<span class="block-component"></span
            >"<strong>process</strong>"<span class="statement-end-if-in-editor"></span>) your information when you use our
            services (<span class="block-component"></span>"<strong>Services</strong>"<span
              class="statement-end-if-in-editor"
            ></span
            >), such as when you:</span
          ></span
        ></span
      ><span style="font-size: 15px"
        ><span style="color: rgb(127, 127, 127)"
          ><span data-custom-class="body_text"
            ><span style="color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"><span class="block-component"></span></span></span></span></span
      ></span>
    </div>
    <ul>
      <li style="line-height: 1.5">
        <span style="font-size: 15px; color: rgb(89, 89, 89)"
          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span data-custom-class="body_text"
              >Visit our website<span class="block-component"></span> at
              <span style="color: rgb(0, 58, 250)"
                ><span class="question"
                  ><a href="https://wrestlematness.com" target="_blank" data-custom-class="link"
                    >https://wrestlematness.com</a
                  ></span
                ></span
              ><span style="font-size: 15px"
                ><span style="color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px"
                      ><span style="color: rgb(89, 89, 89)"
                        ><span class="statement-end-if-in-editor"
                          >, or any website of ours that links to this privacy notice</span
                        ></span
                      ></span
                    ></span
                  ></span
                ></span
              ></span
            ></span
          ></span
        >
      </li>
    </ul>
    <div>
      <span class="block-component"
        ><span style="font-size: 15px"
          ><span style="font-size: 15px"
            ><span style="color: rgb(127, 127, 127)"
              ><span data-custom-class="body_text"
                ><span style="color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><span class="block-component"></span></span></span></span></span></span></span
      ></span>
      <div style="line-height: 1.5">
        <span style="font-size: 15px"
          ><span style="color: rgb(127, 127, 127)"
            ><span data-custom-class="body_text"
              ><span style="color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"><span class="block-component"></span></span></span></span></span
        ></span>
      </div>
      <ul>
        <li style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                >Engage with us in other related ways, including any sales, marketing, or events<span style="font-size: 15px"
                  ><span style="color: rgb(89, 89, 89)"
                    ><span data-custom-class="body_text"
                      ><span style="font-size: 15px"
                        ><span style="color: rgb(89, 89, 89)"
                          ><span class="statement-end-if-in-editor"></span></span></span></span></span></span></span></span
          ></span>
        </li>
      </ul>
      <div style="line-height: 1.5">
        <span style="font-size: 15px"
          ><span style="color: rgb(127, 127, 127)"
            ><span data-custom-class="body_text"
              ><strong>Questions or concerns?&nbsp;</strong>Reading this privacy notice will help you understand your privacy
              rights and choices. If you do not agree with our policies and practices, please do not use our Services.<span
                class="block-component"
              ></span>
              If you still have any questions or concerns, please contact us at
              <span class="question">info@wrestlematness.com</span>.</span
            ></span
          ></span
        >
      </div>
      <div style="line-height: 1.5"><br /></div>
      <div style="line-height: 1.5"><br /></div>
      <div style="line-height: 1.5">
        <strong
          ><span style="font-size: 15px"><span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span></span></strong
        >
      </div>
      <div style="line-height: 1.5"><br /></div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px"
          ><span data-custom-class="body_text"
            ><strong
              ><em
                >This summary provides key points from our privacy notice, but you can find out more details about any of
                these topics by clicking the link following each key point or by using our&nbsp;</em
              ></strong
            ></span
          ></span
        ><a data-custom-class="link" href="#toc"
          ><span style="color: rgb(0, 58, 250); font-size: 15px"
            ><span data-custom-class="body_text"
              ><strong><em>table of contents</em></strong></span
            ></span
          ></a
        ><span style="font-size: 15px"
          ><span data-custom-class="body_text"
            ><strong><em>&nbsp;below to find the section you are looking for.</em></strong></span
          ></span
        >
      </div>
      <div style="line-height: 1.5"><br /></div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px"
          ><span data-custom-class="body_text"
            ><strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may
            process personal information depending on how you interact with us and the Services, the choices you make, and
            the products and features you use. Learn more about&nbsp;</span
          ></span
        ><a data-custom-class="link" href="#personalinfo"
          ><span style="color: rgb(0, 58, 250); font-size: 15px"
            ><span data-custom-class="body_text">personal information you disclose to us</span></span
          ></a
        ><span data-custom-class="body_text">.</span>
      </div>
      <div style="line-height: 1.5"><br /></div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px"
          ><span data-custom-class="body_text"
            ><strong>Do we process any sensitive personal information?</strong> <span class="block-component"></span>We do not
            process sensitive personal information.<span class="else-block"></span></span
        ></span>
      </div>
      <div style="line-height: 1.5"><br /></div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px"
          ><span data-custom-class="body_text"
            ><strong>Do we receive any information from third parties?</strong> <span class="block-component"></span>We do not
            receive any information from third parties.<span class="else-block"></span></span
        ></span>
      </div>
      <div style="line-height: 1.5"><br /></div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px"
          ><span data-custom-class="body_text"
            ><strong>How do we process your information?</strong> We process your information to provide, improve, and
            administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may
            also process your information for other purposes with your consent. We process your information only when we have
            a valid legal reason to do so. Learn more about&nbsp;</span
          ></span
        ><a data-custom-class="link" href="#infouse"
          ><span style="color: rgb(0, 58, 250); font-size: 15px"
            ><span data-custom-class="body_text">how we process your information</span></span
          ></a
        ><span data-custom-class="body_text">.</span>
      </div>
      <div style="line-height: 1.5"><br /></div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px"
          ><span data-custom-class="body_text"
            ><strong
              >In what situations and with which <span class="block-component"></span>parties do we share personal
              information?</strong
            >
            We may share information in specific situations and with specific <span class="block-component"></span>third
            parties. Learn more about&nbsp;</span
          ></span
        ><a data-custom-class="link" href="#whoshare"
          ><span style="color: rgb(0, 58, 250); font-size: 15px"
            ><span data-custom-class="body_text">when and with whom we share your personal information</span></span
          ></a
        ><span style="font-size: 15px"
          ><span data-custom-class="body_text">.<span class="block-component"></span></span
        ></span>
      </div>
      <div style="line-height: 1.5"><br /></div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px"
          ><span data-custom-class="body_text"
            ><strong>How do we keep your information safe?</strong> We have
            <span class="block-component"></span>organizational<span class="statement-end-if-in-editor"></span> and technical
            processes and procedures in place to protect your personal information. However, no electronic transmission over
            the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or
            guarantee that hackers, cybercriminals, or other <span class="block-component"></span>unauthorized<span
              class="statement-end-if-in-editor"
            ></span>
            third parties will not be able to defeat our security and improperly collect, access, steal, or modify your
            information. Learn more about&nbsp;</span
          ></span
        ><a data-custom-class="link" href="#infosafe"
          ><span style="color: rgb(0, 58, 250); font-size: 15px"
            ><span data-custom-class="body_text">how we keep your information safe</span></span
          ></a
        ><span data-custom-class="body_text">.</span
        ><span style="font-size: 15px"
          ><span data-custom-class="body_text"><span class="statement-end-if-in-editor"></span></span
        ></span>
      </div>
      <div style="line-height: 1.5"><br /></div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px"
          ><span data-custom-class="body_text"
            ><strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy
            law may mean you have certain rights regarding your personal information. Learn more about&nbsp;</span
          ></span
        ><a data-custom-class="link" href="#privacyrights"
          ><span style="color: rgb(0, 58, 250); font-size: 15px"
            ><span data-custom-class="body_text">your privacy rights</span></span
          ></a
        ><span data-custom-class="body_text">.</span>
      </div>
      <div style="line-height: 1.5"><br /></div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px"
          ><span data-custom-class="body_text"
            ><strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by
            <span class="block-component"></span>visiting
            <span style="color: rgb(0, 58, 250)"><span class="question">info@wrestlematness.com</span></span
            ><span class="else-block"></span>, or by contacting us. We will consider and act upon any request in accordance
            with applicable data protection laws.</span
          ></span
        >
      </div>
      <div style="line-height: 1.5"><br /></div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px"
          ><span data-custom-class="body_text"
            >Want to learn more about what we do with any information we collect?&nbsp;</span
          ></span
        ><a data-custom-class="link" href="#toc"
          ><span style="color: rgb(0, 58, 250); font-size: 15px"
            ><span data-custom-class="body_text">Review the privacy notice in full</span></span
          ></a
        ><span style="font-size: 15px"><span data-custom-class="body_text">.</span></span>
      </div>
      <div style="line-height: 1.5"><br /></div>
      <div style="line-height: 1.5"><br /></div>
      <div id="toc" style="line-height: 1.5">
        <span style="font-size: 15px"
          ><span style="color: rgb(127, 127, 127)"
            ><span style="color: rgb(0, 0, 0)"
              ><strong><span data-custom-class="heading_1">TABLE OF CONTENTS</span></strong></span
            ></span
          ></span
        >
      </div>
      <div style="line-height: 1.5"><br /></div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px"
          ><a data-custom-class="link" href="#infocollect"
            ><span style="color: rgb(0, 58, 250)">1. WHAT INFORMATION DO WE COLLECT?</span></a
          ></span
        >
      </div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px"
          ><a data-custom-class="link" href="#infouse"
            ><span style="color: rgb(0, 58, 250)"
              >2. HOW DO WE PROCESS YOUR INFORMATION?<span class="block-component"></span></span></a
        ></span>
      </div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px"
          ><a data-custom-class="link" href="#legalbases"
            ><span style="color: rgb(0, 58, 250)"
              >3.
              <span style="font-size: 15px"
                ><span style="color: rgb(0, 58, 250)"
                  >WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</span
                ></span
              ><span class="statement-end-if-in-editor"></span></span></a
        ></span>
      </div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px"
          ><span style="color: rgb(0, 58, 250)"
            ><a data-custom-class="link" href="#whoshare"
              >4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a
            ></span
          ><span data-custom-class="body_text"
            ><span class="block-component"></span
            ><span style="color: rgb(127, 127, 127)"
              ><span style="color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><span style="color: rgb(89, 89, 89)"><span class="block-component"></span></span></span></span></span></span
        ></span>
      </div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px"
          ><a data-custom-class="link" href="#cookies"
            ><span style="color: rgb(0, 58, 250)">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span></a
          ><span style="color: rgb(127, 127, 127)"
            ><span style="color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                ><span style="color: rgb(89, 89, 89)"><span class="statement-end-if-in-editor"></span></span></span
              ><span data-custom-class="body_text"
                ><span style="color: rgb(89, 89, 89)"
                  ><span style="color: rgb(89, 89, 89)"
                    ><span style="color: rgb(89, 89, 89)"><span class="block-component"></span></span></span
                  ><span class="block-component"></span></span></span></span></span
        ></span>
      </div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px"
          ><a data-custom-class="link" href="#inforetain"
            ><span style="color: rgb(0, 58, 250)">6. HOW LONG DO WE KEEP YOUR INFORMATION?</span></a
          ><span style="color: rgb(127, 127, 127)"
            ><span style="color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                ><span style="color: rgb(89, 89, 89)"
                  ><span style="color: rgb(89, 89, 89)"><span class="block-component"></span></span></span></span></span></span
        ></span>
      </div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px"
          ><a data-custom-class="link" href="#infosafe"
            ><span style="color: rgb(0, 58, 250)">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></a
          ><span style="color: rgb(127, 127, 127)"
            ><span style="color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                ><span style="color: rgb(89, 89, 89)"
                  ><span class="statement-end-if-in-editor"></span
                  ><span class="block-component"></span></span></span></span></span
        ></span>
      </div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px"
          ><a data-custom-class="link" href="#infominors"
            ><span style="color: rgb(0, 58, 250)">8. DO WE COLLECT INFORMATION FROM MINORS?</span></a
          ><span style="color: rgb(127, 127, 127)"
            ><span style="color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                ><span style="color: rgb(89, 89, 89)"
                  ><span class="statement-end-if-in-editor"></span></span></span></span></span
        ></span>
      </div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px"
          ><span style="color: rgb(0, 58, 250)"
            ><a data-custom-class="link" href="#privacyrights">9. WHAT ARE YOUR PRIVACY RIGHTS?</a></span
          ></span
        >
      </div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px"
          ><a data-custom-class="link" href="#DNT"
            ><span style="color: rgb(0, 58, 250)"
              >10. CONTROLS FOR DO-NOT-TRACK FEATURES<span class="block-component"></span></span></a
        ></span>
      </div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px"
          ><a data-custom-class="link" href="#uslaws"
            ><span style="color: rgb(0, 58, 250)">11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></a
          ></span
        ><span class="block-component"
          ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
        ></span>
      </div>
      <div style="line-height: 1.5">
        <span class="block-component"><span style="font-size: 15px"></span></span><span class="block-component"></span
        ><span class="block-component"></span><span class="block-component"></span><span class="block-component"></span
        ><span class="block-component"></span><span class="block-component"></span><span class="block-component"></span
        ><span class="block-component"></span><span class="block-component"></span>
      </div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px"
          ><a data-custom-class="link" href="#policyupdates"
            ><span style="color: rgb(0, 58, 250)">12. DO WE MAKE UPDATES TO THIS NOTICE?</span></a
          ></span
        >
      </div>
      <div style="line-height: 1.5">
        <a data-custom-class="link" href="#contact"
          ><span style="color: rgb(0, 58, 250); font-size: 15px">13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></a
        >
      </div>
      <div style="line-height: 1.5">
        <a data-custom-class="link" href="#request"
          ><span style="color: rgb(0, 58, 250)"
            >14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span
          ></a
        >
      </div>
      <div style="line-height: 1.5"><br /></div>
      <div style="line-height: 1.5"><br /></div>
      <div id="infocollect" style="line-height: 1.5">
        <span style="color: rgb(0, 0, 0)"
          ><span style="color: rgb(0, 0, 0); font-size: 15px"
            ><span style="font-size: 15px; color: rgb(0, 0, 0)"
              ><span style="font-size: 15px; color: rgb(0, 0, 0)"
                ><span id="control" style="color: rgb(0, 0, 0)"
                  ><strong><span data-custom-class="heading_1">1. WHAT INFORMATION DO WE COLLECT?</span></strong></span
                ></span
              ></span
            ></span
          ></span
        >
      </div>
      <div style="line-height: 1.5"><br /></div>
      <div id="personalinfo" style="line-height: 1.5">
        <span data-custom-class="heading_2" style="color: rgb(0, 0, 0)"
          ><span style="font-size: 15px"><strong>Personal information you disclose to us</strong></span></span
        >
      </div>
      <div>
        <div><br /></div>
        <div style="line-height: 1.5">
          <span style="color: rgb(127, 127, 127)"
            ><span style="color: rgb(89, 89, 89); font-size: 15px"
              ><span data-custom-class="body_text"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span data-custom-class="body_text"
                      ><strong><em>In Short:</em></strong></span
                    ></span
                  ></span
                ></span
              ><span data-custom-class="body_text"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span data-custom-class="body_text"
                      ><strong><em>&nbsp;</em></strong
                      ><em>We collect personal information that you provide to us.</em></span
                    ></span
                  ></span
                ></span
              ></span
            ></span
          >
        </div>
      </div>
      <div style="line-height: 1.5"><br /></div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px; color: rgb(89, 89, 89)"
          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span data-custom-class="body_text"
              >We collect personal information that you voluntarily provide to us when you
              <span style="font-size: 15px"><span class="block-component"></span></span>register on the Services,&nbsp;</span
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><span style="font-size: 15px"><span class="statement-end-if-in-editor"></span></span></span
              ><span data-custom-class="body_text"
                >express an interest in obtaining information about us or our products and Services, when you participate in
                activities on the Services, or otherwise when you contact us.</span
              ></span
            ></span
          ></span
        >
      </div>
      <div style="line-height: 1.5"><br /></div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px; color: rgb(89, 89, 89)"
          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span data-custom-class="body_text"
              ><span style="font-size: 15px"><span class="block-component"></span></span></span></span
        ></span>
      </div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px; color: rgb(89, 89, 89)"
          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span data-custom-class="body_text"
              ><strong>Personal Information Provided by You.</strong> The personal information that we collect depends on the
              context of your interactions with us and the Services, the choices you make, and the products and features you
              use. The personal information we collect may include the following:<span style="font-size: 15px"
                ><span data-custom-class="body_text"><span class="forloop-component"></span></span></span></span></span
        ></span>
      </div>
      <ul>
        <li style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                ><span style="font-size: 15px"
                  ><span data-custom-class="body_text"><span class="question">names</span></span></span
                ></span
              ></span
            ></span
          >
        </li>
      </ul>
      <div style="line-height: 1.5">
        <span style="font-size: 15px; color: rgb(89, 89, 89)"
          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span data-custom-class="body_text"
              ><span style="font-size: 15px"
                ><span data-custom-class="body_text"><span class="forloop-component"></span></span></span></span></span
        ></span>
      </div>
      <ul>
        <li style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                ><span style="font-size: 15px"
                  ><span data-custom-class="body_text"><span class="question">email addresses</span></span></span
                ></span
              ></span
            ></span
          >
        </li>
      </ul>
      <div style="line-height: 1.5">
        <span style="font-size: 15px; color: rgb(89, 89, 89)"
          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span data-custom-class="body_text"
              ><span style="font-size: 15px"
                ><span data-custom-class="body_text"><span class="forloop-component"></span></span></span></span></span
        ></span>
      </div>
      <ul>
        <li style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                ><span style="font-size: 15px"
                  ><span data-custom-class="body_text"><span class="question">usernames</span></span></span
                ></span
              ></span
            ></span
          >
        </li>
      </ul>
      <div style="line-height: 1.5">
        <span style="font-size: 15px; color: rgb(89, 89, 89)"
          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span data-custom-class="body_text"
              ><span style="font-size: 15px"
                ><span data-custom-class="body_text"><span class="forloop-component"></span></span></span></span></span
        ></span>
      </div>
      <ul>
        <li style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                ><span style="font-size: 15px"
                  ><span data-custom-class="body_text"><span class="question">contact preferences</span></span></span
                ></span
              ></span
            ></span
          >
        </li>
      </ul>
      <div style="line-height: 1.5">
        <span style="font-size: 15px; color: rgb(89, 89, 89)"
          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span data-custom-class="body_text"
              ><span style="font-size: 15px"
                ><span data-custom-class="body_text"><span class="forloop-component"></span></span></span></span></span
        ></span>
      </div>
      <ul>
        <li style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                ><span style="font-size: 15px"
                  ><span data-custom-class="body_text"><span class="question">passwords</span></span></span
                ></span
              ></span
            ></span
          >
        </li>
      </ul>
      <div style="line-height: 1.5">
        <span style="font-size: 15px; color: rgb(89, 89, 89)"
          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span data-custom-class="body_text"
              ><span style="font-size: 15px"
                ><span data-custom-class="body_text"><span class="forloop-component"></span></span></span></span></span
        ></span>
      </div>
      <ul>
        <li style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                ><span style="font-size: 15px"
                  ><span data-custom-class="body_text"><span class="question">photos</span></span></span
                ></span
              ></span
            ></span
          >
        </li>
      </ul>
      <div style="line-height: 1.5">
        <span style="font-size: 15px; color: rgb(89, 89, 89)"
          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span data-custom-class="body_text"
              ><span style="font-size: 15px"
                ><span data-custom-class="body_text"><span class="forloop-component"></span></span></span></span></span
        ></span>
      </div>
      <ul>
        <li style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                ><span style="font-size: 15px"
                  ><span data-custom-class="body_text"><span class="question">ip address</span></span></span
                ></span
              ></span
            ></span
          >
        </li>
      </ul>
      <div style="line-height: 1.5">
        <span style="font-size: 15px; color: rgb(89, 89, 89)"
          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span data-custom-class="body_text"
              ><span style="font-size: 15px"
                ><span data-custom-class="body_text"><span class="forloop-component"></span></span></span></span></span
        ></span>
      </div>
      <ul>
        <li style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                ><span style="font-size: 15px"
                  ><span data-custom-class="body_text"><span class="question">geolocation</span></span></span
                ></span
              ></span
            ></span
          >
        </li>
      </ul>
      <div style="line-height: 1.5">
        <span style="font-size: 15px; color: rgb(89, 89, 89)"
          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span data-custom-class="body_text"
              ><span style="font-size: 15px"
                ><span data-custom-class="body_text"><span class="forloop-component"></span></span
                ><span data-custom-class="body_text"
                  ><span class="statement-end-if-in-editor"></span></span></span></span></span
        ></span>
      </div>
      <div id="sensitiveinfo" style="line-height: 1.5">
        <span style="font-size: 15px"
          ><span data-custom-class="body_text"
            ><strong>Sensitive Information.</strong> <span class="block-component"></span>We do not process sensitive
            information.</span
          ></span
        >
      </div>
      <div style="line-height: 1.5"><br /></div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px"
          ><span data-custom-class="body_text"><span class="else-block"></span></span></span
        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span data-custom-class="body_text"
              ><span style="font-size: 15px"
                ><span data-custom-class="body_text"
                  ><span class="block-component"><span class="block-component"></span></span></span></span></span></span
          ><span class="block-component"></span
        ></span>
      </div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px; color: rgb(89, 89, 89)"
          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span data-custom-class="body_text"
              >All personal information that you provide to us must be true, complete, and accurate, and you must notify us
              of any changes to such personal information.</span
            ></span
          ></span
        >
      </div>
      <div style="line-height: 1.5"><br /></div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px; color: rgb(89, 89, 89)"
          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span data-custom-class="body_text"><span class="block-component"></span></span></span
        ></span>
      </div>
      <div style="line-height: 1.5">
        <span data-custom-class="heading_2" style="color: rgb(0, 0, 0)"
          ><span style="font-size: 15px"><strong>Information automatically collected</strong></span></span
        >
      </div>
      <div>
        <div><br /></div>
        <div style="line-height: 1.5">
          <span style="color: rgb(127, 127, 127)"
            ><span style="color: rgb(89, 89, 89); font-size: 15px"
              ><span data-custom-class="body_text"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span data-custom-class="body_text"
                      ><strong><em>In Short:</em></strong></span
                    ></span
                  ></span
                ></span
              ><span data-custom-class="body_text"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span data-custom-class="body_text"
                      ><strong><em>&nbsp;</em></strong
                      ><em
                        >Some information — such as your Internet Protocol (IP) address and/or browser and device
                        characteristics — is collected automatically when you visit our Services.</em
                      ></span
                    ></span
                  ></span
                ></span
              ></span
            ></span
          >
        </div>
      </div>
      <div style="line-height: 1.5"><br /></div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px; color: rgb(89, 89, 89)"
          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span data-custom-class="body_text"
              >We automatically collect certain information when you visit, use, or navigate the Services. This information
              does not reveal your specific identity (like your name or contact information) but may include device and usage
              information, such as your IP address, browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location, information about how and when you use our
              Services, and other technical information. This information is primarily needed to maintain the security and
              operation of our Services, and for our internal analytics and reporting purposes.</span
            ></span
          ></span
        >
      </div>
      <div style="line-height: 1.5"><br /></div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px; color: rgb(89, 89, 89)"
          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span data-custom-class="body_text"><span class="block-component"></span></span></span
        ></span>
      </div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px; color: rgb(89, 89, 89)"
          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span data-custom-class="body_text"
              >Like many businesses, we also collect information through cookies and similar technologies.
              <span class="block-component"></span>You can find out more about this in our Cookie Notice:
              <span class="statement-end-if-in-editor"></span><span class="block-component"></span
              ><span style="color: rgb(0, 58, 250)"><span class="question">wrestlematness.com/cookie-policy</span></span
              >.<span class="block-component"></span></span></span
        ></span>
      </div>
      <div style="line-height: 1.5"><br /></div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px; color: rgb(89, 89, 89)"
          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span data-custom-class="body_text"
              ><span class="statement-end-if-in-editor"><span data-custom-class="body_text"></span></span></span
            ><span data-custom-class="body_text"><span class="block-component"></span></span></span
        ></span>
      </div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px; color: rgb(89, 89, 89)"
          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span data-custom-class="body_text"
              >The information we collect includes:<span class="block-component"></span></span></span
        ></span>
      </div>
      <ul>
        <li style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                ><em>Log and Usage Data.</em> Log and usage data is service-related, diagnostic, usage, and performance
                information our servers automatically collect when you access or use our Services and which we record in log
                files. Depending on how you interact with us, this log data may include your IP address, device information,
                browser type, and settings and information about your activity in the Services<span style="font-size: 15px"
                  >&nbsp;</span
                >(such as the date/time stamps associated with your usage, pages and files viewed, searches, and other
                actions you take such as which features you use), device event information (such as system activity, error
                reports (sometimes called <span class="block-component"></span>"crash dumps"<span
                  class="statement-end-if-in-editor"
                ></span
                >), and hardware settings).<span style="font-size: 15px"
                  ><span style="color: rgb(89, 89, 89)"
                    ><span data-custom-class="body_text"
                      ><span style="font-size: 15px"
                        ><span style="color: rgb(89, 89, 89)"
                          ><span class="statement-end-if-in-editor"></span></span></span></span></span></span></span></span
          ></span>
        </li>
      </ul>
      <div style="line-height: 1.5">
        <span class="block-component"
          ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
        ></span>
      </div>
      <ul>
        <li style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                ><em>Device Data.</em> We collect device data such as information about your computer, phone, tablet, or
                other device you use to access the Services. Depending on the device used, this device data may include
                information such as your IP address (or proxy server), device and application identification numbers,
                location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system,
                and system configuration information.<span style="font-size: 15px"
                  ><span style="color: rgb(89, 89, 89)"
                    ><span data-custom-class="body_text"
                      ><span style="font-size: 15px"
                        ><span style="color: rgb(89, 89, 89)"
                          ><span class="statement-end-if-in-editor"></span></span></span></span></span></span></span></span
          ></span>
        </li>
      </ul>
      <div style="line-height: 1.5">
        <span class="block-component"
          ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
        ></span>
      </div>
      <ul>
        <li style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                ><em>Location Data.</em> We collect location data such as information about your device's location, which can
                be either precise or imprecise. How much information we collect depends on the type and settings of the
                device you use to access the Services. For example, we may use GPS and other technologies to collect
                geolocation data that tells us your current location (based on your IP address). You can opt out of allowing
                us to collect this information either by refusing access to the information or by disabling your Location
                setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the
                Services.<span style="font-size: 15px"
                  ><span style="color: rgb(89, 89, 89)"
                    ><span data-custom-class="body_text"
                      ><span style="font-size: 15px"
                        ><span style="color: rgb(89, 89, 89)"
                          ><span class="statement-end-if-in-editor"></span></span></span></span></span></span></span></span
          ></span>
        </li>
      </ul>
      <div>
        <span class="block-component"><span style="font-size: 15px"></span></span><span class="statement-end-if-in-editor"></span
        ><span data-custom-class="body_text"
          ><span style="color: rgb(89, 89, 89); font-size: 15px"
            ><span data-custom-class="body_text"
              ><span style="color: rgb(89, 89, 89); font-size: 15px"
                ><span data-custom-class="body_text"
                  ><span class="statement-end-if-in-editor"
                    ><span class="block-component"></span></span></span></span></span></span></span
        ><span style="font-size: 15px"
          ><span data-custom-class="body_text"><span class="block-component"></span></span
        ></span>
      </div>
      <br>
      <div id="infouse" style="line-height: 1.5">
        <span style="color: rgb(127, 127, 127)"
          ><span style="color: rgb(89, 89, 89); font-size: 15px"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span id="control" style="color: rgb(0, 0, 0)"
                  ><strong><span data-custom-class="heading_1">2. HOW DO WE PROCESS YOUR INFORMATION?</span></strong></span
                ></span
              ></span
            ></span
          ></span
        >
      </div>
      <div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5">
          <span style="color: rgb(127, 127, 127)"
            ><span style="color: rgb(89, 89, 89); font-size: 15px"
              ><span data-custom-class="body_text"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span data-custom-class="body_text"
                      ><strong><em>In Short:&nbsp;</em></strong
                      ><em
                        >We process your information to provide, improve, and administer our Services, communicate with you,
                        for security and fraud prevention, and to comply with law. We may also process your information for
                        other purposes with your consent.</em
                      ></span
                    ></span
                  ></span
                ></span
              ></span
            ></span
          >
        </div>
      </div>
      <div style="line-height: 1.5"><br /></div>
      <div style="line-height: 1.5">
        <span style="font-size: 15px; color: rgb(89, 89, 89)"
          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span data-custom-class="body_text"
              ><strong
                >We process your personal information for a variety of reasons, depending on how you interact with our
                Services, including:</strong
              ><span class="block-component"></span></span></span
        ></span>
      </div>
      <ul>
        <li style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                ><strong>To facilitate account creation and authentication and otherwise manage user accounts.&nbsp;</strong
                >We may process your information so you can create and log in to your account, as well as keep your account
                in working order.<span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span data-custom-class="body_text"
                      ><span style="font-size: 15px"
                        ><span style="color: rgb(89, 89, 89)"
                          ><span data-custom-class="body_text"
                            ><span style="font-size: 15px"
                              ><span style="color: rgb(89, 89, 89)"
                                ><span data-custom-class="body_text"
                                  ><span
                                    class="statement-end-if-in-editor"
                                  ></span></span></span></span></span></span></span></span></span></span></span></span
          ></span>
        </li>
      </ul>
      <div style="line-height: 1.5">
        <span style="font-size: 15px; color: rgb(89, 89, 89)"
          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span data-custom-class="body_text"><span class="block-component"></span></span></span
        ></span>
      </div>
      <ul>
        <li style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                ><strong>To deliver and facilitate delivery of services to the user.&nbsp;</strong>We may process your
                information to provide you with the requested service.<span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span data-custom-class="body_text"
                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                          ><span data-custom-class="body_text"
                            ><span style="font-size: 15px"
                              ><span style="color: rgb(89, 89, 89)"
                                ><span data-custom-class="body_text"
                                  ><span style="font-size: 15px"
                                    ><span style="color: rgb(89, 89, 89)"
                                      ><span data-custom-class="body_text"
                                        ><span
                                          class="statement-end-if-in-editor"
                                        ></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span
          ></span>
        </li>
      </ul>
      <div style="line-height: 1.5">
        <span style="font-size: 15px; color: rgb(89, 89, 89)"
          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span data-custom-class="body_text"><span class="block-component"></span></span></span
        ></span>
        <div style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"><span class="block-component"></span></span></span
          ></span>
        </div>
        <ul>
          <li style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><strong>To respond to user inquiries/offer support to users.&nbsp;</strong>We may process your information
                  to respond to your inquiries and solve any potential issues you might have with the requested service.<span
                    style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span data-custom-class="body_text"
                        ><span style="font-size: 15px"
                          ><span style="color: rgb(89, 89, 89)"
                            ><span data-custom-class="body_text"
                              ><span style="font-size: 15px"
                                ><span style="color: rgb(89, 89, 89)"
                                  ><span data-custom-class="body_text"
                                    ><span
                                      class="statement-end-if-in-editor"
                                    ></span></span></span></span></span></span></span></span></span></span></span></span
            ></span>
          </li>
        </ul>
        <div style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"><span class="block-component"></span></span></span
          ></span>
          <div style="line-height: 1.5">
            <span class="block-component"><span style="font-size: 15px"></span></span>
          </div>
          <ul>
            <li style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><strong>To send administrative information to you.&nbsp;</strong>We may process your information to send
                    you details about our products and services, changes to our terms and policies, and other similar
                    information.<span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                        ><span data-custom-class="body_text"
                          ><span class="statement-end-if-in-editor"></span></span></span></span></span></span
              ></span>
            </li>
          </ul>
          <div style="line-height: 1.5">
            <span class="block-component"><span style="font-size: 15px"></span></span>
            <div style="line-height: 1.5">
              <span class="block-component"><span style="font-size: 15px"></span></span>
              <div style="line-height: 1.5">
                <span class="block-component"
                  ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
                ></span>
                <p style="font-size: 15px; line-height: 1.5">
                  <span class="block-component"><span style="font-size: 15px"></span></span>
                </p>
                <p style="font-size: 15px; line-height: 1.5">
                  <span class="block-component"><span style="font-size: 15px"></span></span>
                </p>
                <p style="font-size: 15px; line-height: 1.5"><span class="block-component"></span></p>
                <p style="font-size: 15px; line-height: 1.5"><span class="block-component"></span></p>
                <div style="line-height: 1.5">
                  <span class="block-component"
                    ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
                  ></span>
                </div>
                <ul>
                  <li style="line-height: 1.5">
                    <span style="font-size: 15px"
                      ><span data-custom-class="body_text"
                        ><strong>To send you marketing and promotional communications.&nbsp;</strong>We may process the
                        personal information you send to us for our marketing purposes, if this is in accordance with your
                        marketing preferences. You can opt out of our marketing emails at any time. For more information, see
                        <span class="block-component"></span>"<span class="statement-end-if-in-editor"></span></span></span
                    ><a href="#privacyrights"
                      ><span data-custom-class="link"
                        ><span style="color: rgb(0, 58, 250); font-size: 15px"
                          ><span data-custom-class="link">WHAT ARE YOUR PRIVACY RIGHTS?</span></span
                        ></span
                      ></a
                    ><span style="font-size: 15px"
                      ><span data-custom-class="body_text"
                        ><span class="block-component"></span>"<span class="statement-end-if-in-editor"></span> below.</span
                      ><span class="statement-end-if-in-editor"><span data-custom-class="body_text"></span></span
                    ></span>
                  </li>
                </ul>
                <div style="line-height: 1.5">
                  <span class="block-component"><span style="font-size: 15px"></span></span>
                  <div style="line-height: 1.5">
                    <span class="block-component"><span style="font-size: 15px"></span></span>
                  </div>
                  <ul>
                    <li style="line-height: 1.5">
                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                          ><span data-custom-class="body_text"
                            ><strong>To deliver targeted advertising to you.&nbsp;</strong>We may process your information to
                            develop and display <span class="block-component"></span>personalized<span
                              class="statement-end-if-in-editor"
                            ></span>
                            content and advertising tailored to your interests, location, and more.<span
                              class="block-component"
                            ></span>
                            For more information see our Cookie Notice: <span class="block-component"></span
                            ><span style="color: rgb(0, 58, 250)"
                              ><span class="question">wrestlematness.com/cookie-policy</span></span
                            ><span class="block-component"></span>.<span class="statement-end-if-in-editor"></span></span></span
                      ></span>
                    </li>
                  </ul>
                  <div style="line-height: 1.5">
                    <span style="font-size: 15px"
                      ><span class="block-component"><span data-custom-class="body_text"></span></span
                    ></span>
                    <div style="line-height: 1.5">
                      <span class="block-component"
                        ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
                      ></span>
                      <div style="line-height: 1.5">
                        <span class="block-component"
                          ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
                        ></span>
                        <div style="line-height: 1.5">
                          <span class="block-component"
                            ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
                          ></span>
                          <div style="line-height: 1.5">
                            <span class="block-component"
                              ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
                            ></span>
                            <div style="line-height: 1.5">
                              <span class="block-component"
                                ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
                              ></span>
                            </div>
                            <ul>
                              <li style="line-height: 1.5">
                                <span data-custom-class="body_text"
                                  ><span style="font-size: 15px"
                                    ><strong
                                      >To evaluate and improve our Services, products, marketing, and your
                                      experience.</strong
                                    >
                                    We may process your information when we believe it is necessary to identify usage trends,
                                    determine the effectiveness of our promotional campaigns, and to evaluate and improve our
                                    Services, products, marketing, and your experience.</span
                                  ></span
                                ><span class="statement-end-if-in-editor"
                                  ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
                                ></span>
                              </li>
                            </ul>
                            <div style="line-height: 1.5">
                              <span class="block-component"
                                ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
                              ></span>
                            </div>
                            <ul>
                              <li style="line-height: 1.5">
                                <span data-custom-class="body_text"
                                  ><span style="font-size: 15px"
                                    ><strong>To identify usage trends.</strong> We may process information about how you use
                                    our Services to better understand how they are being used so we can improve them.</span
                                  ></span
                                ><span class="statement-end-if-in-editor"
                                  ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
                                ></span>
                              </li>
                            </ul>
                            <div style="line-height: 1.5">
                              <span class="block-component"
                                ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
                              ></span>
                              <div style="line-height: 1.5">
                                <span class="block-component"
                                  ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
                                ></span>
                                <div style="line-height: 1.5">
                                  <span class="block-component"
                                    ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
                                  ></span>
                                  <div style="line-height: 1.5">
                                    <span class="block-component"
                                      ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
                                    ></span>
                                    <div style="line-height: 1.5">
                                      <span class="block-component"
                                        ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
                                      ></span>
                                      <div style="line-height: 1.5">
                                        <span class="block-component"
                                          ><span style="font-size: 15px"
                                            ><span data-custom-class="body_text"></span></span></span
                                        ><span class="block-component"
                                          ><span style="font-size: 15px"
                                            ><span data-custom-class="body_text"></span></span></span
                                        ><span class="block-component"
                                          ><span style="font-size: 15px"
                                            ><span data-custom-class="body_text"></span></span></span
                                        ><span class="block-component"
                                          ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
                                        ></span>
                                      </div>
                                      <div style="line-height: 1.5"><br /></div>
                                      <div id="legalbases" style="line-height: 1.5">
                                        <strong
                                          ><span style="font-size: 15px"
                                            ><span data-custom-class="heading_1"
                                              >3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</span
                                            ></span
                                          ></strong
                                        >
                                      </div>
                                      <div style="line-height: 1.5"><br /></div>
                                      <div style="line-height: 1.5">
                                        <em
                                          ><span style="font-size: 15px"
                                            ><span data-custom-class="body_text"
                                              ><strong>In Short:&nbsp;</strong>We only process your personal information when
                                              we believe it is necessary and we have a valid legal reason (i.e.<span
                                                class="block-component"
                                              ></span
                                              >,<span class="statement-end-if-in-editor"></span> legal basis) to do so under
                                              applicable law, like with your consent, to comply with laws, to provide you
                                              with services to enter into or <span class="block-component"></span>fulfill<span
                                                class="statement-end-if-in-editor"
                                              ></span>
                                              our contractual obligations, to protect your rights, or to
                                              <span class="block-component"></span>fulfill<span
                                                class="statement-end-if-in-editor"
                                              ></span>
                                              our legitimate business interests.</span
                                            ></span
                                          ></em
                                        ><span style="font-size: 15px"
                                          ><span data-custom-class="body_text"
                                            ><span class="block-component"></span></span></span
                                        ><span data-custom-class="body_text"
                                          ><span style="font-size: 15px"
                                            ><span class="block-component"><span class="block-component"></span></span></span
                                        ></span>
                                      </div>
                                      <div style="line-height: 1.5"><br /></div>
                                      <div style="line-height: 1.5">
                                        <span data-custom-class="body_text"
                                          ><span style="font-size: 15px"
                                            ><strong
                                              ><u
                                                ><em>If you are located in Canada, this section applies to you.</em></u
                                              ></strong
                                            ><span class="statement-end-if-in-editor"></span></span
                                        ></span>
                                      </div>
                                      <div style="line-height: 1.5"><br /></div>
                                      <div style="line-height: 1.5">
                                        <span data-custom-class="body_text"
                                          ><span style="font-size: 15px"
                                            >We may process your information if you have given us specific permission
                                            (i.e.<span class="block-component"></span>,<span
                                              class="statement-end-if-in-editor"
                                            ></span>
                                            express consent) to use your personal information for a specific purpose, or in
                                            situations where your permission can be inferred (i.e.<span
                                              class="block-component"
                                            ></span
                                            >,<span class="statement-end-if-in-editor"></span> implied consent). You
                                            can&nbsp;</span
                                          ></span
                                        ><a data-custom-class="link" href="#withdrawconsent"
                                          ><span data-custom-class="body_text"
                                            ><span style="color: rgb(0, 58, 250); font-size: 15px"
                                              >withdraw your consent</span
                                            ></span
                                          ></a
                                        ><span data-custom-class="body_text"
                                          ><span style="font-size: 15px">&nbsp;at any time.</span></span
                                        >
                                      </div>
                                      <div style="line-height: 1.5"><br /></div>
                                      <div style="line-height: 1.5">
                                        <span data-custom-class="body_text"
                                          ><span style="font-size: 15px"
                                            >In some exceptional cases, we may be legally permitted under applicable law to
                                            process your information without your consent, including, for example:</span
                                          ></span
                                        >
                                      </div>
                                      <ul>
                                        <li style="line-height: 1.5">
                                          <span data-custom-class="body_text"
                                            ><span style="font-size: 15px"
                                              >If collection is clearly in the interests of an individual and consent cannot
                                              be obtained in a timely way</span
                                            ></span
                                          >
                                        </li>
                                      </ul>
                                      <div style="line-height: 1.5">
                                        <span data-custom-class="body_text"
                                          ><span style="font-size: 15px"><span class="block-component"></span></span
                                        ></span>
                                      </div>
                                      <ul>
                                        <li style="line-height: 1.5">
                                          <span data-custom-class="body_text"
                                            ><span style="font-size: 15px"
                                              >For investigations and fraud detection and prevention<span
                                                class="statement-end-if-in-editor"
                                              ></span></span
                                          ></span>
                                        </li>
                                      </ul>
                                      <div style="line-height: 1.5">
                                        <span class="block-component"
                                          ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
                                        ></span>
                                      </div>
                                      <ul>
                                        <li style="line-height: 1.5">
                                          <span data-custom-class="body_text"
                                            ><span style="font-size: 15px"
                                              >For business transactions provided certain conditions are met</span
                                            ></span
                                          ><span class="statement-end-if-in-editor"
                                            ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
                                          ></span>
                                        </li>
                                      </ul>
                                      <div style="line-height: 1.5">
                                        <span class="block-component"
                                          ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
                                        ></span>
                                      </div>
                                      <ul>
                                        <li style="line-height: 1.5">
                                          <span data-custom-class="body_text"
                                            ><span style="font-size: 15px"
                                              >If it is contained in a witness statement and the collection is necessary to
                                              assess, process, or settle an insurance claim</span
                                            ></span
                                          ><span class="statement-end-if-in-editor"
                                            ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
                                          ></span>
                                        </li>
                                      </ul>
                                      <div style="line-height: 1.5">
                                        <span class="block-component"
                                          ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
                                        ></span>
                                      </div>
                                      <ul>
                                        <li style="line-height: 1.5">
                                          <span data-custom-class="body_text"
                                            ><span style="font-size: 15px"
                                              >For identifying injured, ill, or deceased persons and communicating with next
                                              of kin</span
                                            ></span
                                          ><span class="statement-end-if-in-editor"
                                            ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
                                          ></span>
                                        </li>
                                      </ul>
                                      <div style="line-height: 1.5">
                                        <span data-custom-class="body_text"
                                          ><span style="font-size: 15px"><span class="block-component"></span></span
                                        ></span>
                                      </div>
                                      <ul>
                                        <li style="line-height: 1.5">
                                          <span data-custom-class="body_text"
                                            ><span style="font-size: 15px"
                                              >If we have reasonable grounds to believe an individual has been, is, or may be
                                              victim of financial abuse<span class="statement-end-if-in-editor"></span></span
                                          ></span>
                                        </li>
                                      </ul>
                                      <div style="line-height: 1.5">
                                        <span data-custom-class="body_text"
                                          ><span style="font-size: 15px"><span class="block-component"></span></span
                                        ></span>
                                      </div>
                                      <ul>
                                        <li style="line-height: 1.5">
                                          <span data-custom-class="body_text"
                                            ><span style="font-size: 15px"
                                              >If it is reasonable to expect collection and use with consent would compromise
                                              the availability or the accuracy of the information and the collection is
                                              reasonable for purposes related to investigating a breach of an agreement or a
                                              contravention of the laws of Canada or a province<span
                                                class="statement-end-if-in-editor"
                                              ></span></span
                                          ></span>
                                        </li>
                                      </ul>
                                      <div style="line-height: 1.5">
                                        <span data-custom-class="body_text"
                                          ><span style="font-size: 15px"><span class="block-component"></span></span
                                        ></span>
                                      </div>
                                      <ul>
                                        <li style="line-height: 1.5">
                                          <span data-custom-class="body_text"
                                            ><span style="font-size: 15px"
                                              >If disclosure is required to comply with a subpoena, warrant, court order, or
                                              rules of the court relating to the production of records<span
                                                class="statement-end-if-in-editor"
                                              ></span></span
                                          ></span>
                                        </li>
                                      </ul>
                                      <div style="line-height: 1.5">
                                        <span class="block-component"
                                          ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
                                        ></span>
                                      </div>
                                      <ul>
                                        <li style="line-height: 1.5">
                                          <span style="font-size: 15px"
                                            ><span data-custom-class="body_text"
                                              >If it was produced by an individual in the course of their employment,
                                              business, or profession and the collection is consistent with the purposes for
                                              which the information was produced<span
                                                class="statement-end-if-in-editor"
                                              ></span></span
                                          ></span>
                                        </li>
                                      </ul>
                                      <div style="line-height: 1.5">
                                        <span style="font-size: 15px"
                                          ><span data-custom-class="body_text"><span class="block-component"></span></span
                                        ></span>
                                      </div>
                                      <ul>
                                        <li style="line-height: 1.5">
                                          <span style="font-size: 15px"
                                            ><span data-custom-class="body_text"
                                              >If the collection is solely for journalistic, artistic, or literary
                                              purposes<span class="statement-end-if-in-editor"></span></span
                                          ></span>
                                        </li>
                                      </ul>
                                      <div style="line-height: 1.5">
                                        <span style="font-size: 15px"
                                          ><span data-custom-class="body_text"><span class="block-component"></span></span
                                        ></span>
                                      </div>
                                      <ul>
                                        <li style="line-height: 1.5">
                                          <span style="font-size: 15px"
                                            ><span data-custom-class="body_text"
                                              >If the information is publicly available and is specified by the
                                              regulations</span
                                            ><span class="statement-end-if-in-editor"
                                              ><span data-custom-class="body_text"></span></span
                                          ></span>
                                        </li>
                                      </ul>
                                      <div style="line-height: 1.5">
                                        <span class="statement-end-if-in-editor"
                                          ><span style="font-size: 15px"
                                            ><span data-custom-class="body_text"></span></span></span
                                        ><span class="statement-end-if-in-editor"
                                          ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
                                        ></span>
                                      </div>
                                      <div style="line-height: 1.5"><br /></div>
                                      <div id="whoshare" style="line-height: 1.5">
                                        <span style="color: rgb(127, 127, 127)"
                                          ><span style="color: rgb(89, 89, 89); font-size: 15px"
                                            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                ><span id="control" style="color: rgb(0, 0, 0)"
                                                  ><strong
                                                    ><span data-custom-class="heading_1"
                                                      >4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span
                                                    ></strong
                                                  ></span
                                                ></span
                                              ></span
                                            ></span
                                          ></span
                                        >
                                      </div>
                                      <div style="line-height: 1.5"><br /></div>
                                      <div style="line-height: 1.5">
                                        <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                            ><span data-custom-class="body_text"
                                              ><strong><em>In Short:</em></strong
                                              ><em
                                                >&nbsp;We may share information in specific situations described in this
                                                section and/or with the following <span class="block-component"></span>third
                                                parties.</em
                                              ></span
                                            ></span
                                          ></span
                                        >
                                      </div>
                                      <div style="line-height: 1.5">
                                        <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                            ><span data-custom-class="body_text"
                                              ><span class="block-component"></span></span></span
                                        ></span>
                                      </div>
                                      <div style="line-height: 1.5"><br /></div>
                                      <div style="line-height: 1.5">
                                        <span style="font-size: 15px"
                                          ><span data-custom-class="body_text"
                                            >We <span class="block-component"></span>may need to share your personal
                                            information in the following situations:</span
                                          ></span
                                        >
                                      </div>
                                      <ul>
                                        <li style="line-height: 1.5">
                                          <span style="font-size: 15px"
                                            ><span data-custom-class="body_text"
                                              ><strong>Business Transfers.</strong> We may share or transfer your information
                                              in connection with, or during negotiations of, any merger, sale of company
                                              assets, financing, or acquisition of all or a portion of our business to
                                              another company.</span
                                            ></span
                                          >
                                        </li>
                                      </ul>
                                      <div style="line-height: 1.5">
                                        <span class="block-component"><span style="font-size: 15px"></span></span>
                                      </div>
                                      <ul>
                                        <li style="line-height: 1.5">
                                          <span style="font-size: 15px"
                                            ><strong
                                              ><span data-custom-class="body_text"
                                                >When we use Google Analytics.</span
                                              ></strong
                                            ><span data-custom-class="body_text"
                                              >&nbsp;We may share your information with Google Analytics to track and analyze
                                              the use of the Services.<span class="block-component"></span> To opt out of being
                                              tracked by Google Analytics across the Services, visit
                                              <span style="color: rgb(0, 58, 250)"
                                                ><a
                                                  data-custom-class="link"
                                                  href="https://tools.google.com/dlpage/gaoptout"
                                                  rel="noopener noreferrer"
                                                  target="_blank"
                                                  >https://tools.google.com/dlpage/gaoptout</a
                                                ></span
                                              >.<span class="block-component"></span> For more information on the privacy
                                              practices of Google, please visit the
                                              <span style="color: rgb(0, 58, 250)"
                                                ><a
                                                  data-custom-class="link"
                                                  href="https://policies.google.com/privacy"
                                                  rel="noopener noreferrer"
                                                  target="_blank"
                                                  >Google Privacy &amp; Terms page</a
                                                ></span
                                              >.</span
                                            ></span
                                          ><span class="statement-end-if-in-editor"
                                            ><span style="font-size: 15px"></span
                                          ></span>
                                        </li>
                                      </ul>
                                      <div style="line-height: 1.5">
                                        <span style="font-size: 15px"
                                          ><span data-custom-class="body_text"><span class="block-component"></span></span
                                        ></span>
                                        <div style="line-height: 1.5">
                                          <span style="font-size: 15px"
                                            ><span class="block-component"><span data-custom-class="body_text"></span></span
                                          ></span>
                                        </div>
                                        <ul>
                                          <li style="line-height: 1.5">
                                            <span style="font-size: 15px"
                                              ><span data-custom-class="body_text"
                                                ><strong>Affiliates.&nbsp;</strong>We may share your information with our
                                                affiliates, in which case we will require those affiliates to
                                                <span class="block-component"></span>honor<span
                                                  class="statement-end-if-in-editor"
                                                ></span>
                                                this privacy notice. Affiliates include our parent company and any
                                                subsidiaries, joint venture partners, or other companies that we control or
                                                that are under common control with us.</span
                                              ><span class="statement-end-if-in-editor"
                                                ><span data-custom-class="body_text"></span></span
                                            ></span>
                                          </li>
                                        </ul>
                                        <div style="line-height: 1.5">
                                          <span class="block-component"
                                            ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
                                          ></span>
                                        </div>
                                        <ul>
                                          <li style="line-height: 1.5">
                                            <span data-custom-class="body_text"
                                              ><span style="font-size: 15px"
                                                ><strong>Business Partners.</strong> We may share your information with our
                                                business partners to offer you certain products, services, or
                                                promotions.</span
                                              ></span
                                            ><span class="statement-end-if-in-editor"
                                              ><span style="font-size: 15px"
                                                ><span data-custom-class="body_text"></span></span
                                            ></span>
                                          </li>
                                        </ul>
                                        <div style="line-height: 1.5">
                                          <span class="block-component"
                                            ><span style="font-size: 15px"><span data-custom-class="body_text"></span></span
                                          ></span>
                                          <div style="line-height: 1.5">
                                            <span class="block-component"
                                              ><span style="font-size: 15px"
                                                ><span data-custom-class="body_text"></span></span
                                            ></span>
                                            <div style="line-height: 1.5">
                                              <span class="block-component"
                                                ><span style="font-size: 15px"
                                                  ><span data-custom-class="body_text"></span></span></span
                                              ><span style="color: rgb(89, 89, 89); font-size: 15px"
                                                ><span style="font-size: 15px"
                                                  ><span style="color: rgb(89, 89, 89)"
                                                    ><span style="font-size: 15px"
                                                      ><span style="color: rgb(89, 89, 89)"
                                                        ><span class="block-component"
                                                          ><span
                                                            data-custom-class="heading_1"
                                                          ></span></span></span></span></span></span
                                              ></span>
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div id="cookies" style="line-height: 1.5">
                                              <span style="color: rgb(127, 127, 127)"
                                                ><span style="color: rgb(89, 89, 89); font-size: 15px"
                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                      ><span id="control" style="color: rgb(0, 0, 0)"
                                                        ><strong
                                                          ><span data-custom-class="heading_1"
                                                            >5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span
                                                          ></strong
                                                        ></span
                                                      ></span
                                                    ></span
                                                  ></span
                                                ></span
                                              >
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div style="line-height: 1.5">
                                              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                  ><span data-custom-class="body_text"
                                                    ><strong><em>In Short:</em></strong
                                                    ><em
                                                      >&nbsp;We may use cookies and other tracking technologies to collect
                                                      and store your information.</em
                                                    ></span
                                                  ></span
                                                ></span
                                              >
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div style="line-height: 1.5">
                                              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                  ><span data-custom-class="body_text"
                                                    >We may use cookies and similar tracking technologies (like web beacons
                                                    and pixels) to access or store information. Specific information about
                                                    how we use such technologies and how you can refuse certain cookies is
                                                    set out in our Cookie Notice<span
                                                      style="color: rgb(89, 89, 89); font-size: 15px"
                                                      ><span data-custom-class="body_text"
                                                        ><span class="block-component"></span></span></span
                                                    >:
                                                    <span style="color: rgb(0, 58, 250); font-size: 15px"
                                                      ><span data-custom-class="body_text"
                                                        ><span class="question">wrestlematness.com/cookie-policy</span></span
                                                      ></span
                                                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                      ><span class="block-component"></span>.</span
                                                    ><span style="color: rgb(89, 89, 89); font-size: 15px"
                                                      ><span style="font-size: 15px"
                                                        ><span style="color: rgb(89, 89, 89)"
                                                          ><span style="font-size: 15px"
                                                            ><span style="color: rgb(89, 89, 89)"
                                                              ><span data-custom-class="body_text"
                                                                ><span
                                                                  class="statement-end-if-in-editor"
                                                                ></span></span></span></span></span></span></span></span></span
                                                ><span data-custom-class="body_text"
                                                  ><span style="color: rgb(89, 89, 89); font-size: 15px"
                                                    ><span style="color: rgb(89, 89, 89); font-size: 15px"
                                                      ><span style="color: rgb(89, 89, 89); font-size: 15px"
                                                        ><span style="font-size: 15px"
                                                          ><span style="color: rgb(89, 89, 89)"
                                                            ><span style="font-size: 15px"
                                                              ><span style="color: rgb(89, 89, 89)"
                                                                ><span data-custom-class="body_text"
                                                                  ><span class="block-component"></span></span
                                                                ><span class="block-component"
                                                                  ><span data-custom-class="body_text"
                                                                    ><span
                                                                      class="block-component"
                                                                    ></span></span></span></span></span></span></span></span></span></span></span
                                              ></span>
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div id="inforetain" style="line-height: 1.5">
                                              <span style="color: rgb(127, 127, 127)"
                                                ><span style="color: rgb(89, 89, 89); font-size: 15px"
                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                      ><span id="control" style="color: rgb(0, 0, 0)"
                                                        ><strong
                                                          ><span data-custom-class="heading_1"
                                                            >6. HOW LONG DO WE KEEP YOUR INFORMATION?</span
                                                          ></strong
                                                        ></span
                                                      ></span
                                                    ></span
                                                  ></span
                                                ></span
                                              >
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div style="line-height: 1.5">
                                              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                  ><span data-custom-class="body_text"
                                                    ><strong><em>In Short:&nbsp;</em></strong
                                                    ><em
                                                      >We keep your information for as long as necessary to
                                                      <span class="block-component"></span>fulfill<span
                                                        class="statement-end-if-in-editor"
                                                      ></span>
                                                      the purposes outlined in this privacy notice unless otherwise required
                                                      by law.</em
                                                    ></span
                                                  ></span
                                                ></span
                                              >
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div style="line-height: 1.5">
                                              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                  ><span data-custom-class="body_text"
                                                    >We will only keep your personal information for as long as it is
                                                    necessary for the purposes set out in this privacy notice, unless a
                                                    longer retention period is required or permitted by law (such as tax,
                                                    accounting, or other legal requirements).<span
                                                      class="block-component"
                                                    ></span>
                                                    No purpose in this notice will require us keeping your personal
                                                    information for longer than
                                                    <span style="font-size: 15px"
                                                      ><span style="color: rgb(89, 89, 89)"
                                                        ><span data-custom-class="body_text"
                                                          ><span class="block-component"></span></span></span></span
                                                    ><span class="block-component"></span>the period of time in which users
                                                    have an account with us<span class="block-component"></span
                                                    ><span style="font-size: 15px"
                                                      ><span style="color: rgb(89, 89, 89)"
                                                        ><span data-custom-class="body_text"
                                                          ><span class="else-block"></span></span></span></span
                                                    >.</span
                                                  ></span
                                                ></span
                                              >
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div style="line-height: 1.5">
                                              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                  ><span data-custom-class="body_text"
                                                    >When we have no ongoing legitimate business need to process your
                                                    personal information, we will either delete or
                                                    <span class="block-component"></span>anonymize<span
                                                      class="statement-end-if-in-editor"
                                                    ></span>
                                                    such information, or, if this is not possible (for example, because your
                                                    personal information has been stored in backup archives), then we will
                                                    securely store your personal information and isolate it from any further
                                                    processing until deletion is possible.<span
                                                      style="color: rgb(89, 89, 89)"
                                                      ><span class="block-component"></span></span></span></span
                                              ></span>
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div id="infosafe" style="line-height: 1.5">
                                              <span style="color: rgb(127, 127, 127)"
                                                ><span style="color: rgb(89, 89, 89); font-size: 15px"
                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                      ><span id="control" style="color: rgb(0, 0, 0)"
                                                        ><strong
                                                          ><span data-custom-class="heading_1"
                                                            >7. HOW DO WE KEEP YOUR INFORMATION SAFE?</span
                                                          ></strong
                                                        ></span
                                                      ></span
                                                    ></span
                                                  ></span
                                                ></span
                                              >
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div style="line-height: 1.5">
                                              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                  ><span data-custom-class="body_text"
                                                    ><strong><em>In Short:&nbsp;</em></strong
                                                    ><em
                                                      >We aim to protect your personal information through a system of
                                                      <span class="block-component"></span>organizational<span
                                                        class="statement-end-if-in-editor"
                                                      ></span>
                                                      and technical security measures.</em
                                                    ></span
                                                  ></span
                                                ></span
                                              >
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div style="line-height: 1.5">
                                              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                  ><span data-custom-class="body_text"
                                                    >We have implemented appropriate and reasonable technical and
                                                    <span class="block-component"></span>organizational<span
                                                      class="statement-end-if-in-editor"
                                                    ></span>
                                                    security measures designed to protect the security of any personal
                                                    information we process. However, despite our safeguards and efforts to
                                                    secure your information, no electronic transmission over the Internet or
                                                    information storage technology can be guaranteed to be 100% secure, so we
                                                    cannot promise or guarantee that hackers, cybercriminals, or other
                                                    <span class="block-component"></span>unauthorized<span
                                                      class="statement-end-if-in-editor"
                                                    ></span>
                                                    third parties will not be able to defeat our security and improperly
                                                    collect, access, steal, or modify your information. Although we will do
                                                    our best to protect your personal information, transmission of personal
                                                    information to and from our Services is at your own risk. You should only
                                                    access the Services within a secure environment.<span
                                                      style="color: rgb(89, 89, 89)"
                                                      ><span class="statement-end-if-in-editor"></span></span
                                                    ><span style="color: rgb(89, 89, 89); font-size: 15px"
                                                      ><span data-custom-class="body_text"
                                                        ><span class="block-component"></span></span></span></span></span
                                              ></span>
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div id="infominors" style="line-height: 1.5">
                                              <span style="color: rgb(127, 127, 127)"
                                                ><span style="color: rgb(89, 89, 89); font-size: 15px"
                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                      ><span id="control" style="color: rgb(0, 0, 0)"
                                                        ><strong
                                                          ><span data-custom-class="heading_1"
                                                            >8. DO WE COLLECT INFORMATION FROM MINORS?</span
                                                          ></strong
                                                        ></span
                                                      ></span
                                                    ></span
                                                  ></span
                                                ></span
                                              >
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div style="line-height: 1.5">
                                              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                  ><span data-custom-class="body_text"
                                                    ><strong><em>In Short:</em></strong
                                                    ><em
                                                      >&nbsp;We do not knowingly collect data from or market to
                                                      <span class="block-component"></span>children under 18 years of age<span
                                                        class="else-block"
                                                      ></span
                                                      >.</em
                                                    ><span class="block-component"></span></span></span
                                              ></span>
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div style="line-height: 1.5">
                                              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                  ><span data-custom-class="body_text"
                                                    >We do not knowingly solicit data from or market to children under 18
                                                    years of age. By using the Services, you represent that you are at least
                                                    18 or that you are the parent or guardian of such a minor and consent to
                                                    such minor dependent’s use of the Services. If we learn that personal
                                                    information from users less than 18 years of age has been collected, we
                                                    will deactivate the account and take reasonable measures to promptly
                                                    delete such data from our records. If you become aware of any data we may
                                                    have collected from children under age 18, please contact us at
                                                    <span style="color: rgb(89, 89, 89); font-size: 15px"
                                                      ><span data-custom-class="body_text"
                                                        ><span class="block-component"></span
                                                        ><span class="question">bob@bob.com</span
                                                        ><span class="statement-end-if-in-editor"></span></span></span
                                                    >.</span
                                                  ><span data-custom-class="body_text"
                                                    ><span class="else-block"
                                                      ><span class="block-component"></span></span></span></span
                                              ></span>
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div id="privacyrights" style="line-height: 1.5">
                                              <span style="color: rgb(127, 127, 127)"
                                                ><span style="color: rgb(89, 89, 89); font-size: 15px"
                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                      ><span id="control" style="color: rgb(0, 0, 0)"
                                                        ><strong
                                                          ><span data-custom-class="heading_1"
                                                            >9. WHAT ARE YOUR PRIVACY RIGHTS?</span
                                                          ></strong
                                                        ></span
                                                      ></span
                                                    ></span
                                                  ></span
                                                ></span
                                              >
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div style="line-height: 1.5">
                                              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                  ><span data-custom-class="body_text"
                                                    ><strong><em>In Short:</em></strong
                                                    ><em
                                                      >&nbsp;<span style="color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px"
                                                          ><span data-custom-class="body_text"
                                                            ><em><span class="block-component"></span></em></span></span></span
                                                      >In some regions, such as <span class="block-component"></span>Canada
                                                      <span class="statement-end-if-in-editor"></span>, you have rights that
                                                      allow you greater access to and control over your personal
                                                      information.<span style="color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px"
                                                          ><span data-custom-class="body_text"
                                                            ><em
                                                              ><span
                                                                class="statement-end-if-in-editor"
                                                              ></span></em></span></span
                                                        >&nbsp;</span
                                                      >You may review, change, or terminate your account at any time.</em
                                                    ><span style="color: rgb(89, 89, 89)"
                                                      ><span style="font-size: 15px"
                                                        ><span class="block-component"></span></span></span></span></span
                                              ></span>
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div style="line-height: 1.5">
                                              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                  ><span data-custom-class="body_text"
                                                    >In some regions (like <span class="block-component"></span>Canada<span
                                                      class="statement-end-if-in-editor"
                                                    ></span
                                                    >), you have certain rights under applicable data protection laws. These
                                                    may include the right (i) to request access and obtain a copy of your
                                                    personal information, (ii) to request rectification or erasure; (iii) to
                                                    restrict the processing of your personal information; (iv) if applicable,
                                                    to data portability; and (v) not to be subject to automated
                                                    decision-making. In certain circumstances, you may also have the right to
                                                    object to the processing of your personal information. You can make such
                                                    a request by contacting us by using the contact details provided in the
                                                    section <span class="block-component"></span>"<span
                                                      class="statement-end-if-in-editor"
                                                    ></span></span></span></span
                                              ><a data-custom-class="link" href="#contact"
                                                ><span style="font-size: 15px; color: rgb(0, 58, 250)"
                                                  ><span style="font-size: 15px; color: rgb(0, 58, 250)"
                                                    ><span data-custom-class="body_text"
                                                      >HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span
                                                    ></span
                                                  ></span
                                                ></a
                                              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                  ><span data-custom-class="body_text"
                                                    ><span class="block-component"></span>"<span
                                                      class="statement-end-if-in-editor"
                                                    ></span>
                                                    below.</span
                                                  ></span
                                                ></span
                                              >
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div style="line-height: 1.5">
                                              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                  ><span data-custom-class="body_text"
                                                    >We will consider and act upon any request in accordance with applicable
                                                    data protection laws.<span class="block-component"></span></span></span
                                              ></span>
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div id="withdrawconsent" style="line-height: 1.5">
                                              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                  ><span data-custom-class="body_text"
                                                    ><strong><u>Withdrawing your consent:</u></strong> If we are relying on
                                                    your consent to process your personal information,<span
                                                      class="block-component"
                                                    ></span>
                                                    which may be express and/or implied consent depending on the applicable
                                                    law,<span class="statement-end-if-in-editor"></span> you have the right to
                                                    withdraw your consent at any time. You can withdraw your consent at any
                                                    time by contacting us by using the contact details provided in the
                                                    section <span class="block-component"></span>"<span
                                                      class="statement-end-if-in-editor"
                                                    ></span></span></span></span
                                              ><a data-custom-class="link" href="#contact"
                                                ><span style="font-size: 15px; color: rgb(0, 58, 250)"
                                                  ><span style="font-size: 15px; color: rgb(0, 58, 250)"
                                                    ><span data-custom-class="body_text"
                                                      >HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span
                                                    ></span
                                                  ></span
                                                ></a
                                              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                  ><span data-custom-class="body_text"
                                                    ><span class="block-component"></span>"<span
                                                      class="statement-end-if-in-editor"
                                                    ></span>
                                                    below<span class="block-component"></span>.</span
                                                  ></span
                                                ></span
                                              >
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div style="line-height: 1.5">
                                              <span style="font-size: 15px"
                                                ><span data-custom-class="body_text"
                                                  >However, please note that this will not affect the lawfulness of the
                                                  processing before its withdrawal nor,<span class="block-component"></span>
                                                  when applicable law allows,<span class="statement-end-if-in-editor"></span>
                                                  will it affect the processing of your personal information conducted in
                                                  reliance on lawful processing grounds other than consent.<span
                                                    class="block-component"
                                                  ></span></span
                                              ></span>
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div style="line-height: 1.5">
                                              <span style="font-size: 15px"
                                                ><span data-custom-class="body_text"
                                                  ><strong
                                                    ><u>Opting out of marketing and promotional communications:</u></strong
                                                  ><strong><u>&nbsp;</u></strong
                                                  >You can unsubscribe from our marketing and promotional communications at
                                                  any time by<span class="block-component"></span> clicking on the unsubscribe
                                                  link in the emails that we send,<span
                                                    class="statement-end-if-in-editor"
                                                  ></span
                                                  ><span class="block-component"></span><span class="block-component"></span> or
                                                  by contacting us using the details provided in the section
                                                  <span class="block-component"></span>"<span
                                                    class="statement-end-if-in-editor"
                                                  ></span></span></span
                                              ><a data-custom-class="link" href="#contact"
                                                ><span style="color: rgb(0, 58, 250); font-size: 15px"
                                                  ><span data-custom-class="body_text"
                                                    >HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span
                                                  ></span
                                                ></a
                                              ><span style="font-size: 15px"
                                                ><span data-custom-class="body_text"
                                                  ><span class="block-component"></span>"<span
                                                    class="statement-end-if-in-editor"
                                                  ></span>
                                                  below. You will then be removed from the marketing lists. However, we may
                                                  still communicate with you — for example, to send you service-related
                                                  messages that are necessary for the administration and use of your account,
                                                  to respond to service requests, or for other non-marketing purposes.</span
                                                ><span data-custom-class="body_text"
                                                  ><span class="statement-end-if-in-editor"></span></span></span
                                              ><span class="block-component"
                                                ><span style="font-size: 15px"
                                                  ><span data-custom-class="body_text"></span></span
                                              ></span>
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div style="line-height: 1.5">
                                              <span style="font-size: 15px"
                                                ><span data-custom-class="heading_2"
                                                  ><strong>Account Information</strong></span
                                                ></span
                                              >
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div style="line-height: 1.5">
                                              <span data-custom-class="body_text"
                                                ><span style="font-size: 15px"
                                                  >If you would at any time like to review or change the information in your
                                                  account or terminate your account, you can:<span
                                                    class="forloop-component"
                                                  ></span></span
                                              ></span>
                                            </div>
                                            <ul>
                                              <li style="line-height: 1.5">
                                                <span data-custom-class="body_text"
                                                  ><span style="font-size: 15px"
                                                    ><span class="question"
                                                      >Log in to your account settings and update your user account.</span
                                                    ></span
                                                  ></span
                                                >
                                              </li>
                                            </ul>
                                            <div style="line-height: 1.5">
                                              <span data-custom-class="body_text"
                                                ><span style="font-size: 15px"><span class="forloop-component"></span></span
                                              ></span>
                                            </div>
                                            <ul>
                                              <li style="line-height: 1.5">
                                                <span data-custom-class="body_text"
                                                  ><span style="font-size: 15px"
                                                    ><span class="question"
                                                      >Contact us using the contact information provided.</span
                                                    ></span
                                                  ></span
                                                >
                                              </li>
                                            </ul>
                                            <div style="line-height: 1.5">
                                              <span data-custom-class="body_text"
                                                ><span style="font-size: 15px"><span class="forloop-component"></span></span
                                              ></span>
                                            </div>
                                            <div style="line-height: 1.5">
                                              <span style="font-size: 15px"
                                                ><span data-custom-class="body_text"
                                                  >Upon your request to terminate your account, we will deactivate or delete
                                                  your account and information from our active databases. However, we may
                                                  retain some information in our files to prevent fraud, troubleshoot
                                                  problems, assist with any investigations, enforce our legal terms and/or
                                                  comply with applicable legal requirements.</span
                                                ></span
                                              ><span class="statement-end-if-in-editor"
                                                ><span style="font-size: 15px"
                                                  ><span data-custom-class="body_text"></span></span></span
                                              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                  ><span data-custom-class="body_text"
                                                    ><span style="font-size: 15px"
                                                      ><span style="color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px"
                                                          ><span style="color: rgb(89, 89, 89)"
                                                            ><span data-custom-class="body_text"
                                                              ><span style="font-size: 15px"
                                                                ><span style="color: rgb(89, 89, 89)"
                                                                  ><span
                                                                    class="block-component"
                                                                  ></span></span></span></span></span></span></span></span></span></span
                                              ></span>
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div style="line-height: 1.5">
                                              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                  ><span data-custom-class="body_text"
                                                    ><strong><u>Cookies and similar technologies:</u></strong> Most Web
                                                    browsers are set to accept cookies by default. If you prefer, you can
                                                    usually choose to set your browser to remove cookies and to reject
                                                    cookies. If you choose to remove cookies or reject cookies, this could
                                                    affect certain features or services of our Services.
                                                    <span class="block-component"
                                                      ><span style="font-size: 15px"
                                                        ><span style="color: rgb(89, 89, 89)"
                                                          ><span data-custom-class="body_text"
                                                            ><span class="block-component"></span></span></span></span
                                                      >For further information, please see our Cookie Notice:
                                                      <span style="font-size: 15px"
                                                        ><span style="color: rgb(89, 89, 89)"
                                                          ><span data-custom-class="body_text"
                                                            ><span style="color: rgb(0, 58, 250)"
                                                              ><span class="question"
                                                                >wrestlematness.com/cookie-policy</span
                                                              ></span
                                                            >.<span class="block-component"></span
                                                            ><span style="font-size: 15px"
                                                              ><span style="color: rgb(89, 89, 89)"
                                                                ><span style="font-size: 15px"
                                                                  ><span style="color: rgb(89, 89, 89)"
                                                                    ><span style="font-size: 15px"
                                                                      ><span style="color: rgb(89, 89, 89)"
                                                                        ><span
                                                                          class="statement-end-if-in-editor"
                                                                        ></span></span></span></span></span></span></span></span></span></span></span></span></span></span
                                              ><span class="block-component"
                                                ><span style="font-size: 15px"
                                                  ><span data-custom-class="body_text"></span></span
                                              ></span>
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div style="line-height: 1.5">
                                              <span data-custom-class="body_text"
                                                ><span style="font-size: 15px"
                                                  >If you have questions or comments about your privacy rights, you may email
                                                  us at <span class="question">info@wrestlematness.com</span>.</span
                                                ></span
                                              ><span class="statement-end-if-in-editor"
                                                ><span style="font-size: 15px"
                                                  ><span data-custom-class="body_text"></span></span
                                              ></span>
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div id="DNT" style="line-height: 1.5">
                                              <span style="color: rgb(127, 127, 127)"
                                                ><span style="color: rgb(89, 89, 89); font-size: 15px"
                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                      ><span id="control" style="color: rgb(0, 0, 0)"
                                                        ><strong
                                                          ><span data-custom-class="heading_1"
                                                            >10. CONTROLS FOR DO-NOT-TRACK FEATURES</span
                                                          ></strong
                                                        ></span
                                                      ></span
                                                    ></span
                                                  ></span
                                                ></span
                                              >
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div style="line-height: 1.5">
                                              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                  ><span data-custom-class="body_text"
                                                    >Most web browsers and some mobile operating systems and mobile
                                                    applications include a Do-Not-Track (<span class="block-component"></span
                                                    >"DNT"<span class="statement-end-if-in-editor"></span>) feature or setting
                                                    you can activate to signal your privacy preference not to have data about
                                                    your online browsing activities monitored and collected. At this stage no
                                                    uniform technology standard for
                                                    <span class="block-component"></span>recognizing<span
                                                      class="statement-end-if-in-editor"
                                                    ></span>
                                                    and implementing DNT signals has been
                                                    <span class="block-component"></span>finalized<span
                                                      class="statement-end-if-in-editor"
                                                    ></span
                                                    >. As such, we do not currently respond to DNT browser signals or any
                                                    other mechanism that automatically communicates your choice not to be
                                                    tracked online. If a standard for online tracking is adopted that we must
                                                    follow in the future, we will inform you about that practice in a revised
                                                    version of this privacy notice.<span
                                                      class="block-component"
                                                    ></span></span></span
                                              ></span>
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div id="uslaws" style="line-height: 1.5">
                                              <span style="color: rgb(127, 127, 127)"
                                                ><span style="color: rgb(89, 89, 89); font-size: 15px"
                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                      ><span id="control" style="color: rgb(0, 0, 0)"
                                                        ><strong
                                                          ><span data-custom-class="heading_1"
                                                            >11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY
                                                            RIGHTS?</span
                                                          ></strong
                                                        ></span
                                                      ></span
                                                    ></span
                                                  ></span
                                                ></span
                                              >
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div style="line-height: 1.5">
                                              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                  ><span data-custom-class="body_text"
                                                    ><strong><em>In Short:&nbsp;</em></strong
                                                    ><em
                                                      >If you are a resident of <span class="forloop-component"></span
                                                      ><span class="block-component"></span
                                                      ><span class="forloop-component"></span> California<span
                                                        class="forloop-component"
                                                      ></span
                                                      ><span class="forloop-component"></span
                                                      ><span class="forloop-component"></span
                                                      ><span class="forloop-component"></span
                                                      ><span class="block-component"></span
                                                      ><span class="forloop-component"></span
                                                      ><span class="block-component"></span
                                                      ><span class="forloop-component"></span
                                                      ><span class="forloop-component"></span>, Colorado<span
                                                        class="forloop-component"
                                                      ></span
                                                      ><span class="forloop-component"></span
                                                      ><span class="statement-end-if-in-editor"></span
                                                      ><span class="forloop-component"></span
                                                      ><span class="block-component"></span
                                                      ><span class="forloop-component"></span
                                                      ><span class="forloop-component">, Connecticut</span
                                                      ><span class="forloop-component"></span
                                                      ><span class="statement-end-if-in-editor"></span
                                                      ><span class="forloop-component"></span
                                                      ><span class="block-component"></span
                                                      ><span class="forloop-component"></span
                                                      ><span class="forloop-component">, Utah</span
                                                      ><span class="forloop-component"></span
                                                      ><span class="statement-end-if-in-editor"></span
                                                      ><span class="forloop-component"></span
                                                      ><span class="block-component"></span
                                                      ><span class="forloop-component"></span
                                                      ><span class="forloop-component"></span
                                                      ><span class="forloop-component"></span
                                                      ><span class="forloop-component"></span> or Virginia<span
                                                        class="forloop-component"
                                                      ></span
                                                      ><span class="else-block"></span><span class="forloop-component"></span>,
                                                      you are granted specific rights regarding access to your personal
                                                      information.</em
                                                    ></span
                                                  ></span
                                                ></span
                                              >
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div style="line-height: 1.5">
                                              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                  ><span data-custom-class="body_text"
                                                    ><strong
                                                      >What categories of personal information do we collect?</strong
                                                    ></span
                                                  ></span
                                                ></span
                                              >
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div style="line-height: 1.5">
                                              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                  ><span data-custom-class="body_text"
                                                    >We have collected the following categories of personal information in
                                                    the past twelve (12) months:</span
                                                  ></span
                                                ></span
                                              >
                                            </div>
                                            <div style="line-height: 1.5"><br /></div>
                                            <table style="width: 100%">
                                              <tbody>
                                                <tr>
                                                  <td
                                                    style="
                                                      width: 33.8274%;
                                                      border-left: 1px solid black;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span data-custom-class="body_text"
                                                          ><strong>Category</strong></span
                                                        ></span
                                                      ></span
                                                    >
                                                  </td>
                                                  <td
                                                    style="
                                                      width: 51.4385%;
                                                      border-top: 1px solid black;
                                                      border-right: 1px solid black;
                                                    "
                                                  >
                                                    <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span data-custom-class="body_text"
                                                          ><strong>Examples</strong></span
                                                        ></span
                                                      ></span
                                                    >
                                                  </td>
                                                  <td
                                                    style="
                                                      width: 14.9084%;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                      text-align: center;
                                                    "
                                                  >
                                                    <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span data-custom-class="body_text"
                                                          ><strong>Collected</strong></span
                                                        ></span
                                                      ></span
                                                    >
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style="
                                                      width: 33.8274%;
                                                      border-left: 1px solid black;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5">
                                                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                          ><span data-custom-class="body_text">A. Identifiers</span></span
                                                        ></span
                                                      >
                                                    </div>
                                                  </td>
                                                  <td
                                                    style="
                                                      width: 51.4385%;
                                                      border-top: 1px solid black;
                                                      border-right: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5">
                                                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                          ><span data-custom-class="body_text"
                                                            >Contact details, such as real name, alias, postal address,
                                                            telephone or mobile contact number, unique personal identifier,
                                                            online identifier, Internet Protocol address, email address, and
                                                            account name</span
                                                          ></span
                                                        ></span
                                                      >
                                                    </div>
                                                  </td>
                                                  <td
                                                    style="
                                                      width: 14.9084%;
                                                      text-align: center;
                                                      vertical-align: middle;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5"><br /></div>
                                                    <div style="line-height: 1.5">
                                                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                          ><span data-custom-class="body_text"
                                                            ><span class="block-component"
                                                              ><span class="block-component"></span></span
                                                            >YES<span class="else-block"
                                                              ><span class="block-component"></span></span></span></span
                                                      ></span>
                                                    </div>
                                                    <div style="line-height: 1.5"><br /></div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <div style="line-height: 1.5"><span class="block-component"></span></div>
                                            <table style="width: 100%">
                                              <tbody>
                                                <tr>
                                                  <td
                                                    style="
                                                      width: 33.8274%;
                                                      border-left: 1px solid black;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5">
                                                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                          ><span data-custom-class="body_text"
                                                            >B. Personal information as defined in the California Customer
                                                            Records statute</span
                                                          ></span
                                                        ></span
                                                      >
                                                    </div>
                                                  </td>
                                                  <td
                                                    style="
                                                      width: 51.4385%;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5">
                                                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                          ><span data-custom-class="body_text"
                                                            >Name, contact information, education, employment, employment
                                                            history, and financial information</span
                                                          ></span
                                                        ></span
                                                      >
                                                    </div>
                                                  </td>
                                                  <td
                                                    style="
                                                      width: 14.9084%;
                                                      text-align: center;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5"><br /></div>
                                                    <div style="line-height: 1.5">
                                                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                          ><span data-custom-class="body_text"
                                                            ><span class="forloop-component"
                                                              ><span class="block-component"
                                                                ><span class="block-component"></span>YES<span
                                                                  class="block-component"
                                                                ></span></span></span></span></span
                                                      ></span>
                                                    </div>
                                                    <div style="line-height: 1.5"><br /></div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <div style="line-height: 1.5"><span class="block-component"></span></div>
                                            <table style="width: 100%">
                                              <tbody>
                                                <tr>
                                                  <td
                                                    style="
                                                      width: 33.8274%;
                                                      border-left: 1px solid black;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5">
                                                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                          ><span data-custom-class="body_text"
                                                            ><span class="block-component"></span>C<span
                                                              class="else-block"
                                                            ></span
                                                            >. Protected classification characteristics under state or
                                                            federal law</span
                                                          ></span
                                                        ></span
                                                      >
                                                    </div>
                                                  </td>
                                                  <td
                                                    style="
                                                      width: 51.4385%;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5">
                                                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                          ><span data-custom-class="body_text"
                                                            >Gender and date of birth</span
                                                          ></span
                                                        ></span
                                                      >
                                                    </div>
                                                  </td>
                                                  <td
                                                    style="
                                                      width: 14.9084%;
                                                      text-align: center;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5"><br /></div>
                                                    <div data-custom-class="body_text" style="line-height: 1.5">
                                                      <span class="forloop-component"
                                                        ><span data-custom-class="body_text"
                                                          ><span class="block-component"></span
                                                          ><span class="block-component"></span>NO<span
                                                            class="statement-end-if-in-editor"
                                                          ></span
                                                          ><span class="block-component"></span></span
                                                      ></span>
                                                    </div>
                                                    <div style="line-height: 1.5"><br /></div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style="
                                                      width: 33.8274%;
                                                      border-left: 1px solid black;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5">
                                                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                          ><span data-custom-class="body_text"
                                                            ><span class="block-component"></span>D<span
                                                              class="else-block"
                                                            ></span
                                                            >. Commercial information</span
                                                          ></span
                                                        ></span
                                                      >
                                                    </div>
                                                  </td>
                                                  <td
                                                    style="
                                                      width: 51.4385%;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5">
                                                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                          ><span data-custom-class="body_text"
                                                            >Transaction information, purchase history, financial details,
                                                            and payment information</span
                                                          ></span
                                                        ></span
                                                      >
                                                    </div>
                                                  </td>
                                                  <td
                                                    style="
                                                      width: 14.9084%;
                                                      text-align: center;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5"><br /></div>
                                                    <div data-custom-class="body_text" style="line-height: 1.5">
                                                      <span class="forloop-component"
                                                        ><span data-custom-class="body_text"
                                                          ><span class="block-component"></span
                                                          ><span class="block-component"></span>NO<span
                                                            class="statement-end-if-in-editor"
                                                            ><span class="block-component"></span></span></span
                                                      ></span>
                                                    </div>
                                                    <div style="line-height: 1.5"><br /></div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style="
                                                      width: 33.8274%;
                                                      border-left: 1px solid black;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5">
                                                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                          ><span data-custom-class="body_text"
                                                            ><span class="block-component"></span>E<span
                                                              class="else-block"
                                                            ></span
                                                            >. Biometric information</span
                                                          ></span
                                                        ></span
                                                      >
                                                    </div>
                                                  </td>
                                                  <td
                                                    style="
                                                      width: 51.4385%;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5">
                                                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                          ><span data-custom-class="body_text"
                                                            >Fingerprints and voiceprints</span
                                                          ></span
                                                        ></span
                                                      >
                                                    </div>
                                                  </td>
                                                  <td
                                                    style="
                                                      width: 14.9084%;
                                                      text-align: center;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5"><br /></div>
                                                    <div data-custom-class="body_text" style="line-height: 1.5">
                                                      <span class="forloop-component"
                                                        ><span data-custom-class="body_text"
                                                          ><span class="block-component"
                                                            ><span class="block-component">NO</span
                                                            ><span class="statement-end-if-in-editor"></span
                                                            ><span class="block-component"></span></span></span
                                                      ></span>
                                                    </div>
                                                    <div style="line-height: 1.5"><br /></div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style="
                                                      width: 33.8274%;
                                                      border-left: 1px solid black;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5">
                                                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                          ><span data-custom-class="body_text"
                                                            ><span class="block-component"></span>F<span
                                                              class="else-block"
                                                            ></span
                                                            >. Internet or other similar network activity</span
                                                          ></span
                                                        ></span
                                                      >
                                                    </div>
                                                  </td>
                                                  <td
                                                    style="
                                                      width: 51.4385%;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5">
                                                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                          ><span data-custom-class="body_text"
                                                            >Browsing history, search history, online
                                                            <span class="block-component"></span>behavior<span
                                                              class="statement-end-if-in-editor"
                                                            ></span
                                                            >, interest data, and interactions with our and other websites,
                                                            applications, systems, and advertisements</span
                                                          ></span
                                                        ></span
                                                      >
                                                    </div>
                                                  </td>
                                                  <td
                                                    style="
                                                      width: 14.9084%;
                                                      text-align: center;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5"><br /></div>
                                                    <div data-custom-class="body_text" style="line-height: 1.5">
                                                      <span class="forloop-component"
                                                        ><span data-custom-class="body_text"
                                                          ><span class="block-component"></span
                                                          ><span class="block-component"></span>NO<span
                                                            class="statement-end-if-in-editor"
                                                          ></span
                                                          ><span class="block-component"></span></span
                                                      ></span>
                                                    </div>
                                                    <div style="line-height: 1.5"><br /></div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style="
                                                      width: 33.8274%;
                                                      border-left: 1px solid black;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5">
                                                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                          ><span data-custom-class="body_text"
                                                            ><span class="block-component"></span>G<span
                                                              class="else-block"
                                                            ></span
                                                            >. Geolocation data</span
                                                          ></span
                                                        ></span
                                                      >
                                                    </div>
                                                  </td>
                                                  <td
                                                    style="
                                                      width: 51.4385%;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5">
                                                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                          ><span data-custom-class="body_text">Device location</span></span
                                                        ></span
                                                      >
                                                    </div>
                                                  </td>
                                                  <td
                                                    style="
                                                      width: 14.9084%;
                                                      text-align: center;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5"><br /></div>
                                                    <div data-custom-class="body_text" style="line-height: 1.5">
                                                      <span class="forloop-component"
                                                        ><span data-custom-class="body_text"
                                                          ><span class="block-component"></span
                                                          ><span class="block-component"></span>YES<span
                                                            class="else-block"
                                                          ></span
                                                          ><span class="block-component"></span></span
                                                      ></span>
                                                    </div>
                                                    <div style="line-height: 1.5"><br /></div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style="
                                                      width: 33.8274%;
                                                      border-left: 1px solid black;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5">
                                                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                          ><span data-custom-class="body_text"
                                                            ><span class="block-component"></span>H<span
                                                              class="else-block"
                                                            ></span
                                                            >. Audio, electronic, visual, thermal, olfactory, or similar
                                                            information</span
                                                          ></span
                                                        ></span
                                                      >
                                                    </div>
                                                  </td>
                                                  <td
                                                    style="
                                                      width: 51.4385%;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5">
                                                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                          ><span data-custom-class="body_text"
                                                            >Images and audio, video or call recordings created in connection
                                                            with our business activities</span
                                                          ></span
                                                        ></span
                                                      >
                                                    </div>
                                                  </td>
                                                  <td
                                                    style="
                                                      width: 14.9084%;
                                                      text-align: center;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5"><br /></div>
                                                    <div data-custom-class="body_text" style="line-height: 1.5">
                                                      <span class="forloop-component"
                                                        ><span data-custom-class="body_text"
                                                          ><span class="block-component"></span
                                                          ><span class="block-component"></span>NO<span
                                                            class="statement-end-if-in-editor"
                                                          ></span
                                                          ><span class="block-component"></span></span
                                                      ></span>
                                                    </div>
                                                    <div style="line-height: 1.5"><br /></div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style="
                                                      width: 33.8274%;
                                                      border-left: 1px solid black;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5">
                                                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                          ><span data-custom-class="body_text"
                                                            ><span class="block-component"></span>I<span
                                                              class="else-block"
                                                            ></span
                                                            >. Professional or employment-related information</span
                                                          ></span
                                                        ></span
                                                      >
                                                    </div>
                                                  </td>
                                                  <td
                                                    style="
                                                      width: 51.4385%;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5">
                                                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                          ><span data-custom-class="body_text"
                                                            >Business contact details in order to provide you our Services at
                                                            a business level or job title, work history, and professional
                                                            qualifications if you apply for a job with us</span
                                                          ></span
                                                        ></span
                                                      >
                                                    </div>
                                                  </td>
                                                  <td
                                                    style="
                                                      width: 14.9084%;
                                                      text-align: center;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5"><br /></div>
                                                    <div data-custom-class="body_text" style="line-height: 1.5">
                                                      <span class="forloop-component"
                                                        ><span data-custom-class="body_text"
                                                          ><span class="block-component"></span
                                                          ><span class="block-component"></span>NO<span
                                                            class="statement-end-if-in-editor"
                                                          ></span
                                                          ><span class="block-component"></span></span
                                                      ></span>
                                                    </div>
                                                    <div style="line-height: 1.5"><br /></div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style="
                                                      border-left: 1px solid black;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                      width: 33.8274%;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5">
                                                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                          ><span data-custom-class="body_text"
                                                            ><span class="block-component"></span>J<span
                                                              class="else-block"
                                                            ></span
                                                            >. Education Information</span
                                                          ></span
                                                        ></span
                                                      >
                                                    </div>
                                                  </td>
                                                  <td
                                                    style="
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                      width: 51.4385%;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5">
                                                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                          ><span data-custom-class="body_text"
                                                            >Student records and directory information</span
                                                          ></span
                                                        ></span
                                                      >
                                                    </div>
                                                  </td>
                                                  <td
                                                    style="
                                                      text-align: center;
                                                      border-right: 1px solid black;
                                                      border-top: 1px solid black;
                                                      width: 14.9084%;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5"><br /></div>
                                                    <div data-custom-class="body_text" style="line-height: 1.5">
                                                      <span class="forloop-component"
                                                        ><span data-custom-class="body_text"
                                                          ><span class="block-component"></span
                                                          ><span class="block-component"></span>NO<span
                                                            class="statement-end-if-in-editor"
                                                          ></span
                                                          ><span class="block-component"></span></span
                                                      ></span>
                                                    </div>
                                                    <div style="line-height: 1.5"><br /></div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style="
                                                      border-width: 1px;
                                                      border-color: black;
                                                      border-style: solid;
                                                      width: 33.8274%;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5">
                                                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                          ><span data-custom-class="body_text"
                                                            ><span class="block-component"></span>K<span
                                                              class="else-block"
                                                            ></span
                                                            >. Inferences drawn from collected personal information</span
                                                          ></span
                                                        ></span
                                                      >
                                                    </div>
                                                  </td>
                                                  <td
                                                    style="
                                                      border-bottom: 1px solid black;
                                                      border-top: 1px solid black;
                                                      border-right: 1px solid black;
                                                      width: 51.4385%;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5">
                                                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                          ><span data-custom-class="body_text"
                                                            >Inferences drawn from any of the collected personal information
                                                            listed above to create a profile or summary about, for example,
                                                            an individual’s preferences and characteristics</span
                                                          ></span
                                                        ></span
                                                      >
                                                    </div>
                                                  </td>
                                                  <td
                                                    style="
                                                      text-align: center;
                                                      border-right: 1px solid black;
                                                      border-bottom: 1px solid black;
                                                      border-top: 1px solid black;
                                                      width: 14.9084%;
                                                    "
                                                  >
                                                    <div style="line-height: 1.5"><br /></div>
                                                    <div data-custom-class="body_text" style="line-height: 1.5">
                                                      <span data-custom-class="body_text"
                                                        ><span class="block-component"></span>NO<span
                                                          style="font-size: 15px; color: rgb(89, 89, 89)"
                                                          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                            ><span data-custom-class="body_text"
                                                              ><span style="color: rgb(89, 89, 89)"
                                                                ><span data-custom-class="body_text"
                                                                  ><span
                                                                    class="statement-end-if-in-editor"
                                                                  ></span></span></span></span></span></span
                                                      ></span>
                                                    </div>
                                                    <div style="line-height: 1.5"><br /></div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style="
                                                      border-left: 1px solid black;
                                                      border-right: 1px solid black;
                                                      border-bottom: 1px solid black;
                                                      line-height: 1.5;
                                                    "
                                                  >
                                                    <span data-custom-class="body_text"
                                                      ><span class="block-component"></span>L<span class="else-block"></span>.
                                                      Sensitive personal Information</span
                                                    >
                                                  </td>
                                                  <td
                                                    style="
                                                      border-right: 1px solid black;
                                                      border-bottom: 1px solid black;
                                                      line-height: 1.5;
                                                    "
                                                  >
                                                    <span class="block-component"
                                                      ><span data-custom-class="body_text"></span
                                                    ></span>
                                                  </td>
                                                  <td style="border-right: 1px solid black; border-bottom: 1px solid black">
                                                    <div data-empty="true" style="text-align: center"><br /></div>
                                                    <div
                                                      data-custom-class="body_text"
                                                      data-empty="true"
                                                      style="text-align: center; line-height: 1.5"
                                                    >
                                                      <span class="block-component"
                                                        ><span data-custom-class="body_text"></span></span
                                                      >NO<span class="statement-end-if-in-editor"
                                                        ><span data-custom-class="body_text"></span
                                                      ></span>
                                                    </div>
                                                    <div data-empty="true" style="text-align: center"><br /></div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <div style="line-height: 1.5"><br /></div>
                                            <div style="line-height: 1.5">
                                              <span class="block-component"><span data-custom-class="body_text"></span></span
                                              ><span data-custom-class="body_text"
                                                >We will use and retain the collected personal information as needed to
                                                provide the Services or for:<span class="block-component"></span
                                              ></span>
                                            </div>
                                            <ul>
                                              <li style="line-height: 1.5">
                                                <span data-custom-class="body_text"
                                                  >Category A -
                                                  <span class="question">As long as the user has an account with us</span
                                                  ><span class="statement-end-if-in-editor"></span
                                                ></span>
                                              </li>
                                            </ul>
                                            <div style="line-height: 1.5">
                                              <span data-custom-class="body_text"><span class="block-component"></span></span>
                                            </div>
                                            <ul>
                                              <li style="line-height: 1.5">
                                                <span data-custom-class="body_text"
                                                  >Category B -
                                                  <span class="question">As long as the user has an account with us</span
                                                  ><span class="statement-end-if-in-editor"></span
                                                ></span>
                                              </li>
                                            </ul>
                                            <div style="line-height: 1.5">
                                              <span data-custom-class="body_text"><span class="block-component"></span></span>
                                              <div style="line-height: 1.5">
                                                <span data-custom-class="body_text"
                                                  ><span class="block-component"></span
                                                ></span>
                                                <div style="line-height: 1.5">
                                                  <span data-custom-class="body_text"
                                                    ><span class="block-component"></span
                                                  ></span>
                                                  <div style="line-height: 1.5">
                                                    <span data-custom-class="body_text"
                                                      ><span class="block-component"></span
                                                    ></span>
                                                    <div style="line-height: 1.5">
                                                      <span data-custom-class="body_text"
                                                        ><span class="block-component"></span
                                                      ></span>
                                                    </div>
                                                    <ul>
                                                      <li style="line-height: 1.5">
                                                        <span data-custom-class="body_text"
                                                          >Category <span class="block-component"></span>G<span
                                                            class="else-block"
                                                          ></span>
                                                          -
                                                          <span class="question"
                                                            >As long as the user has an account with us</span
                                                          ><span class="statement-end-if-in-editor"></span
                                                        ></span>
                                                      </li>
                                                    </ul>
                                                    <div style="line-height: 1.5">
                                                      <span data-custom-class="body_text"
                                                        ><span class="block-component"></span
                                                      ></span>
                                                      <div style="line-height: 1.5">
                                                        <span data-custom-class="body_text"
                                                          ><span class="block-component"></span
                                                        ></span>
                                                        <div style="line-height: 1.5">
                                                          <span data-custom-class="body_text"
                                                            ><span class="block-component"></span
                                                          ></span>
                                                          <div style="line-height: 1.5">
                                                            <span data-custom-class="body_text"
                                                              ><span class="block-component"></span
                                                            ></span>
                                                            <div style="line-height: 1.5">
                                                              <span data-custom-class="body_text"
                                                                ><span class="block-component"></span></span
                                                              ><span class="statement-end-if-in-editor"
                                                                ><span data-custom-class="body_text"></span
                                                              ></span>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      ><span class="block-component"></span></span></span
                                                                ></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      >We may also collect other personal information outside
                                                                      of these categories through instances where you
                                                                      interact with us in person, online, or by phone or mail
                                                                      in the context of:<span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                        ><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                          ><span data-custom-class="body_text"
                                                                            ><span
                                                                              style="color: rgb(89, 89, 89); font-size: 15px"
                                                                              ><span data-custom-class="body_text"
                                                                                ><span
                                                                                  class="block-component"
                                                                                ></span></span></span></span></span></span></span></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                      ><span data-custom-class="body_text"
                                                                        >Receiving help through our customer support
                                                                        channels;</span
                                                                      ><span data-custom-class="body_text"
                                                                        ><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                          ><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                            ><span data-custom-class="body_text"
                                                                              ><span
                                                                                style="
                                                                                  color: rgb(89, 89, 89);
                                                                                  font-size: 15px;
                                                                                "
                                                                                ><span data-custom-class="body_text"
                                                                                  ><span
                                                                                    style="
                                                                                      color: rgb(89, 89, 89);
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><span data-custom-class="body_text"
                                                                                      ><span style="font-size: 15px"
                                                                                        ><span style="color: rgb(89, 89, 89)"
                                                                                          ><span
                                                                                            data-custom-class="body_text"
                                                                                            ><span
                                                                                              class="
                                                                                                statement-end-if-in-editor
                                                                                              "
                                                                                            ></span></span></span></span></span></span></span></span></span></span></span></span></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div>
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                        ><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                          ><span data-custom-class="body_text"
                                                                            ><span
                                                                              style="color: rgb(89, 89, 89); font-size: 15px"
                                                                              ><span data-custom-class="body_text"
                                                                                ><span
                                                                                  class="block-component"
                                                                                ></span></span></span></span></span></span></span></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                      ><span data-custom-class="body_text"
                                                                        >Participation in customer surveys or contests;
                                                                        and</span
                                                                      ><span data-custom-class="body_text"
                                                                        ><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                          ><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                            ><span data-custom-class="body_text"
                                                                              ><span
                                                                                style="
                                                                                  color: rgb(89, 89, 89);
                                                                                  font-size: 15px;
                                                                                "
                                                                                ><span data-custom-class="body_text"
                                                                                  ><span
                                                                                    style="
                                                                                      color: rgb(89, 89, 89);
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><span data-custom-class="body_text"
                                                                                      ><span style="font-size: 15px"
                                                                                        ><span style="color: rgb(89, 89, 89)"
                                                                                          ><span
                                                                                            data-custom-class="body_text"
                                                                                            ><span
                                                                                              class="
                                                                                                statement-end-if-in-editor
                                                                                              "
                                                                                            ></span></span></span></span></span></span></span></span></span></span></span></span></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div>
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                        ><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                          ><span data-custom-class="body_text"
                                                                            ><span
                                                                              style="color: rgb(89, 89, 89); font-size: 15px"
                                                                              ><span data-custom-class="body_text"
                                                                                ><span
                                                                                  class="block-component"
                                                                                ></span></span></span></span></span></span></span></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                      ><span data-custom-class="body_text"
                                                                        >Facilitation in the delivery of our Services and to
                                                                        respond to your inquiries.</span
                                                                      ><span data-custom-class="body_text"
                                                                        ><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                          ><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                            ><span data-custom-class="body_text"
                                                                              ><span
                                                                                style="
                                                                                  color: rgb(89, 89, 89);
                                                                                  font-size: 15px;
                                                                                "
                                                                                ><span data-custom-class="body_text"
                                                                                  ><span
                                                                                    style="
                                                                                      color: rgb(89, 89, 89);
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><span data-custom-class="body_text"
                                                                                      ><span style="font-size: 15px"
                                                                                        ><span style="color: rgb(89, 89, 89)"
                                                                                          ><span
                                                                                            data-custom-class="body_text"
                                                                                            ><span
                                                                                              class="
                                                                                                statement-end-if-in-editor
                                                                                              "
                                                                                            ></span></span></span></span></span></span></span></span></span></span></span></span></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      ><strong
                                                                        >How do we use and share your personal
                                                                        information?</strong
                                                                      ></span
                                                                    ></span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <span data-custom-class="body_text" style="font-size: 15px"
                                                                  >Learn about how we use your personal information in the
                                                                  section, <span class="block-component"></span>"<span
                                                                    class="statement-end-if-in-editor"
                                                                  ></span></span
                                                                ><a data-custom-class="link" href="#infouse"
                                                                  ><span style="color: rgb(0, 58, 250); font-size: 15px"
                                                                    >HOW DO WE PROCESS YOUR INFORMATION?</span
                                                                  ></a
                                                                ><span data-custom-class="body_text" style="font-size: 15px"
                                                                  ><span class="block-component"></span>"</span
                                                                ><span class="statement-end-if-in-editor"
                                                                  ><span
                                                                    data-custom-class="body_text"
                                                                    style="font-size: 15px"
                                                                  ></span
                                                                ></span>
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      ><span style="color: rgb(89, 89, 89); font-size: 15px"
                                                                        ><span data-custom-class="body_text"
                                                                          ><span
                                                                            class="block-component"
                                                                          ></span></span></span></span></span
                                                                ></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      ><strong
                                                                        >Will your information be shared with anyone
                                                                        else?</strong
                                                                      ></span
                                                                    ></span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      >We may disclose your personal information with our
                                                                      service providers pursuant to a written contract
                                                                      between us and each service provider. Learn more about
                                                                      how we disclose personal information to in the section,
                                                                      <span class="block-component"></span>"<span
                                                                        class="statement-end-if-in-editor"
                                                                      ></span></span></span></span
                                                                ><a data-custom-class="link" href="#whoshare"
                                                                  ><span style="font-size: 15px; color: rgb(0, 58, 250)"
                                                                    ><span style="font-size: 15px; color: rgb(0, 58, 250)"
                                                                      >WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                                                                      INFORMATION?</span
                                                                    ></span
                                                                  ></a
                                                                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      ><span class="block-component"></span>"<span
                                                                        class="statement-end-if-in-editor"
                                                                      ></span></span></span
                                                                ></span>
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      >We may use your personal information for our own
                                                                      business purposes, such as for undertaking internal
                                                                      research for technological development and
                                                                      demonstration. This is not considered to be
                                                                      <span class="block-component"></span>"selling"<span
                                                                        class="statement-end-if-in-editor"
                                                                      ></span>
                                                                      of your personal information.<span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px"
                                                                        ><span data-custom-class="body_text"
                                                                          ><span
                                                                            class="block-component"
                                                                          ></span></span></span></span></span
                                                                ></span>
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      >We have not disclosed, sold, or shared any personal
                                                                      information to third parties for a business or
                                                                      commercial purpose in the preceding twelve (12) months.
                                                                      We<span style="color: rgb(89, 89, 89)">&nbsp;</span
                                                                      >will not sell or share personal information in the
                                                                      future belonging to website visitors, users, and other
                                                                      consumers.<span style="color: rgb(89, 89, 89)"
                                                                        ><span data-custom-class="body_text"
                                                                          ><span style="color: rgb(89, 89, 89)"
                                                                            ><span data-custom-class="body_text"
                                                                              ><span style="color: rgb(89, 89, 89)"
                                                                                ><span data-custom-class="body_text"
                                                                                  ><span
                                                                                    class="statement-end-if-in-editor"
                                                                                  ></span></span></span></span></span
                                                                          ><span
                                                                            class="block-component"
                                                                          ></span></span></span></span></span></span
                                                                ><span data-custom-class="body_text"
                                                                  ><span style="color: rgb(0, 0, 0)"
                                                                    ><span data-custom-class="body_text"
                                                                      ><span
                                                                        class="block-component"
                                                                      ></span></span></span></span
                                                                ><span class="block-component"></span>
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <strong
                                                                  ><span
                                                                    data-custom-class="heading_2"
                                                                    style="font-size: 15px"
                                                                    >California Residents</span
                                                                  ></strong
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <span data-custom-class="body_text" style="font-size: 15px"
                                                                  >California Civil Code Section 1798.83, also known as the
                                                                  <span class="block-component"></span>"Shine The Light"<span
                                                                    class="statement-end-if-in-editor"
                                                                  ></span>
                                                                  law permits our users who are California residents to
                                                                  request and obtain from us, once a year and free of charge,
                                                                  information about categories of personal information (if
                                                                  any) we disclosed to third parties for direct marketing
                                                                  purposes and the names and addresses of all third parties
                                                                  with which we shared personal information in the
                                                                  immediately preceding calendar year. If you are a
                                                                  California resident and would like to make such a request,
                                                                  please submit your request in writing to us using the
                                                                  contact information provided below.</span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text"><br /></span
                                                                ></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span data-custom-class="body_text" style="font-size: 15px"
                                                                  >If you are under 18 years of age, reside in California,
                                                                  and have a registered account with the Services, you have
                                                                  the right to request removal of unwanted data that you
                                                                  publicly post on the Services. To request removal of such
                                                                  data, please contact us using the contact information
                                                                  provided below and include the email address associated
                                                                  with your account and a statement that you reside in
                                                                  California. We will make sure the data is not publicly
                                                                  displayed on the Services, but please be aware that the
                                                                  data may not be completely or comprehensively removed from
                                                                  all our systems (e.g.<span class="block-component"></span
                                                                  >,<span class="statement-end-if-in-editor"></span> backups,
                                                                  etc.).</span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"><br /></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text"
                                                                    ><strong><u>CCPA Privacy Notice</u></strong></span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text"><br /></span
                                                                ></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text"
                                                                    >This section applies only to California residents. Under
                                                                    the California Consumer Privacy Act (CCPA), you have the
                                                                    rights listed below.</span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text"><br /></span
                                                                ></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text"
                                                                    >The California Code of Regulations defines a
                                                                    <span class="block-component"></span>"residents"<span
                                                                      class="statement-end-if-in-editor"
                                                                    ></span>
                                                                    as:</span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text"><br /></span
                                                                ></span>
                                                              </div>
                                                              <div style="margin-left: 20px; line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text"
                                                                    >(1) every individual who is in the State of California
                                                                    for other than a temporary or transitory purpose
                                                                    and</span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="margin-left: 20px; line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text"
                                                                    >(2) every individual who is domiciled in the State of
                                                                    California who is outside the State of California for a
                                                                    temporary or transitory purpose</span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text"><br /></span
                                                                ></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text"
                                                                    >All other individuals are defined as
                                                                    <span class="block-component"></span>"non-residents."<span
                                                                      class="statement-end-if-in-editor"
                                                                    ></span></span
                                                                ></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text"><br /></span
                                                                ></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text"
                                                                    >If this definition of
                                                                    <span class="block-component"></span>"resident"<span
                                                                      class="statement-end-if-in-editor"
                                                                    ></span>
                                                                    applies to you, we must adhere to certain rights and
                                                                    obligations regarding your personal information.</span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      ><strong
                                                                        >Your rights with respect to your personal
                                                                        data</strong
                                                                      ></span
                                                                    ></span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      ><u
                                                                        >Right to request deletion of the data — Request to
                                                                        delete</u
                                                                      ></span
                                                                    ></span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      >You can ask for the deletion of your personal
                                                                      information. If you ask us to delete your personal
                                                                      information, we will respect your request and delete
                                                                      your personal information, subject to certain
                                                                      exceptions provided by law, such as (but not limited
                                                                      to) the exercise by another consumer of his or her
                                                                      right to free speech, our compliance requirements
                                                                      resulting from a legal obligation, or any processing
                                                                      that may be required to protect against illegal
                                                                      activities.</span
                                                                    ></span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      ><u>Right to be informed — Request to know</u></span
                                                                    ></span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      >Depending on the circumstances, you have a right to
                                                                      know:<span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                        ><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                          ><span data-custom-class="body_text"
                                                                            ><span
                                                                              style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                              ><span
                                                                                style="
                                                                                  font-size: 15px;
                                                                                  color: rgb(89, 89, 89);
                                                                                "
                                                                                ><span data-custom-class="body_text"
                                                                                  ><span
                                                                                    style="
                                                                                      color: rgb(89, 89, 89);
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><span data-custom-class="body_text"
                                                                                      ><span
                                                                                        class="block-component"
                                                                                      ></span></span></span></span></span></span></span></span></span></span></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                      ><span data-custom-class="body_text"
                                                                        >whether we collect and use your personal
                                                                        information;<span style="color: rgb(0, 0, 0)"
                                                                          ><span style="font-size: 15px"
                                                                            ><span data-custom-class="body_text"
                                                                              ><span style="color: rgb(0, 0, 0)"
                                                                                ><span style="font-size: 15px"
                                                                                  ><span data-custom-class="body_text"
                                                                                    ><span
                                                                                      class="statement-end-if-in-editor"
                                                                                    ></span></span></span></span></span></span></span></span></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div>
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                        ><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                          ><span data-custom-class="body_text"
                                                                            ><span
                                                                              style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                              ><span
                                                                                style="
                                                                                  font-size: 15px;
                                                                                  color: rgb(89, 89, 89);
                                                                                "
                                                                                ><span data-custom-class="body_text"
                                                                                  ><span
                                                                                    style="
                                                                                      color: rgb(89, 89, 89);
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><span data-custom-class="body_text"
                                                                                      ><span
                                                                                        class="block-component"
                                                                                      ></span></span></span></span></span></span></span></span></span></span></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                      ><span data-custom-class="body_text"
                                                                        >the categories of personal information that we
                                                                        collect;<span style="color: rgb(0, 0, 0)"
                                                                          ><span style="font-size: 15px"
                                                                            ><span data-custom-class="body_text"
                                                                              ><span style="color: rgb(0, 0, 0)"
                                                                                ><span style="font-size: 15px"
                                                                                  ><span data-custom-class="body_text"
                                                                                    ><span
                                                                                      class="statement-end-if-in-editor"
                                                                                    ></span></span></span></span></span></span></span></span></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div>
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                        ><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                          ><span data-custom-class="body_text"
                                                                            ><span
                                                                              style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                              ><span
                                                                                style="
                                                                                  font-size: 15px;
                                                                                  color: rgb(89, 89, 89);
                                                                                "
                                                                                ><span data-custom-class="body_text"
                                                                                  ><span
                                                                                    style="
                                                                                      color: rgb(89, 89, 89);
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><span data-custom-class="body_text"
                                                                                      ><span
                                                                                        class="block-component"
                                                                                      ></span></span></span></span></span></span></span></span></span></span></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                      ><span data-custom-class="body_text"
                                                                        >the purposes for which the collected personal
                                                                        information is used;<span style="color: rgb(0, 0, 0)"
                                                                          ><span style="font-size: 15px"
                                                                            ><span data-custom-class="body_text"
                                                                              ><span style="color: rgb(0, 0, 0)"
                                                                                ><span style="font-size: 15px"
                                                                                  ><span data-custom-class="body_text"
                                                                                    ><span
                                                                                      class="statement-end-if-in-editor"
                                                                                    ></span></span></span></span></span></span></span></span></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div>
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                        ><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                          ><span data-custom-class="body_text"
                                                                            ><span
                                                                              style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                              ><span
                                                                                style="
                                                                                  font-size: 15px;
                                                                                  color: rgb(89, 89, 89);
                                                                                "
                                                                                ><span data-custom-class="body_text"
                                                                                  ><span
                                                                                    style="
                                                                                      color: rgb(89, 89, 89);
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><span data-custom-class="body_text"
                                                                                      ><span
                                                                                        class="block-component"
                                                                                      ></span></span></span></span></span></span></span></span></span></span></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                      ><span data-custom-class="body_text"
                                                                        >whether we sell or share personal information to
                                                                        third parties;<span style="color: rgb(0, 0, 0)"
                                                                          ><span style="font-size: 15px"
                                                                            ><span data-custom-class="body_text"
                                                                              ><span style="color: rgb(0, 0, 0)"
                                                                                ><span style="font-size: 15px"
                                                                                  ><span data-custom-class="body_text"
                                                                                    ><span
                                                                                      class="statement-end-if-in-editor"
                                                                                    ></span></span></span></span></span></span></span></span></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div>
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                        ><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                          ><span data-custom-class="body_text"
                                                                            ><span
                                                                              style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                              ><span
                                                                                style="
                                                                                  font-size: 15px;
                                                                                  color: rgb(89, 89, 89);
                                                                                "
                                                                                ><span data-custom-class="body_text"
                                                                                  ><span
                                                                                    style="
                                                                                      color: rgb(89, 89, 89);
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><span data-custom-class="body_text"
                                                                                      ><span
                                                                                        class="block-component"
                                                                                      ></span></span></span></span></span></span></span></span></span></span></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                      ><span data-custom-class="body_text"
                                                                        >the categories of personal information that we sold,
                                                                        shared, or disclosed for a business purpose;<span
                                                                          style="color: rgb(0, 0, 0)"
                                                                          ><span style="font-size: 15px"
                                                                            ><span data-custom-class="body_text"
                                                                              ><span style="color: rgb(0, 0, 0)"
                                                                                ><span style="font-size: 15px"
                                                                                  ><span data-custom-class="body_text"
                                                                                    ><span
                                                                                      class="statement-end-if-in-editor"
                                                                                    ></span></span></span></span></span></span></span></span></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div>
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                        ><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                          ><span data-custom-class="body_text"
                                                                            ><span
                                                                              style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                              ><span
                                                                                style="
                                                                                  font-size: 15px;
                                                                                  color: rgb(89, 89, 89);
                                                                                "
                                                                                ><span data-custom-class="body_text"
                                                                                  ><span
                                                                                    style="
                                                                                      color: rgb(89, 89, 89);
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><span data-custom-class="body_text"
                                                                                      ><span
                                                                                        class="block-component"
                                                                                      ></span></span></span></span></span></span></span></span></span></span></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                      ><span data-custom-class="body_text"
                                                                        >the categories of third parties to whom the personal
                                                                        information was sold, shared, or disclosed for a
                                                                        business purpose;<span style="color: rgb(0, 0, 0)"
                                                                          ><span style="font-size: 15px"
                                                                            ><span data-custom-class="body_text"
                                                                              ><span style="color: rgb(0, 0, 0)"
                                                                                ><span style="font-size: 15px"
                                                                                  ><span data-custom-class="body_text"
                                                                                    ><span
                                                                                      class="statement-end-if-in-editor"
                                                                                    ></span></span></span></span></span></span></span></span></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div>
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                        ><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                          ><span data-custom-class="body_text"
                                                                            ><span
                                                                              style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                              ><span
                                                                                style="
                                                                                  font-size: 15px;
                                                                                  color: rgb(89, 89, 89);
                                                                                "
                                                                                ><span data-custom-class="body_text"
                                                                                  ><span
                                                                                    style="
                                                                                      color: rgb(89, 89, 89);
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><span data-custom-class="body_text"
                                                                                      ><span
                                                                                        class="block-component"
                                                                                      ></span></span></span></span></span></span></span></span></span></span></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                      ><span data-custom-class="body_text"
                                                                        >the business or commercial purpose for collecting,
                                                                        selling, or sharing personal information; and<span
                                                                          style="color: rgb(0, 0, 0)"
                                                                          ><span style="font-size: 15px"
                                                                            ><span data-custom-class="body_text"
                                                                              ><span style="color: rgb(0, 0, 0)"
                                                                                ><span style="font-size: 15px"
                                                                                  ><span data-custom-class="body_text"
                                                                                    ><span
                                                                                      class="statement-end-if-in-editor"
                                                                                    ></span></span></span></span></span></span></span></span></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div style="line-height: 1.5">
                                                                <span class="block-component"
                                                                  ><span data-custom-class="body_text"></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span data-custom-class="body_text"
                                                                    >the specific pieces of personal information we collected
                                                                    about you.<span class="statement-end-if-in-editor"></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      >In accordance with applicable law, we are not
                                                                      obligated to provide or delete consumer information
                                                                      that is de-identified in response to a consumer request
                                                                      or to re-identify individual data to verify a consumer
                                                                      request.</span
                                                                    ></span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      ><u
                                                                        >Right to Non-Discrimination for the Exercise of a
                                                                        Consumer’s Privacy Rights</u
                                                                      ></span
                                                                    ></span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      >We will not discriminate against you if you exercise
                                                                      your privacy rights.</span
                                                                    ></span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><u
                                                                    ><span data-custom-class="body_text"
                                                                      >Right to Limit Use and Disclosure of Sensitive
                                                                      Personal Information</span
                                                                    ></u
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span class="block-component"
                                                                  ><span data-custom-class="body_text"></span
                                                                ></span>
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <span data-custom-class="body_text"
                                                                  >We do not process consumer's sensitive personal
                                                                  information.</span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <span class="statement-end-if-in-editor"
                                                                  ><span data-custom-class="body_text"></span
                                                                ></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      ><u>Verification process</u></span
                                                                    ></span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      >Upon receiving your request, we will need to verify
                                                                      your identity to determine you are the same person
                                                                      about whom we have the information in our system. These
                                                                      verification efforts require us to ask you to provide
                                                                      information so that we can match it with information
                                                                      you have previously provided us. For instance,
                                                                      depending on the type of request you submit, we may ask
                                                                      you to provide certain information so that we can match
                                                                      the information you provide with the information we
                                                                      already have on file, or we may contact you through a
                                                                      communication method (e.g.<span
                                                                        class="block-component"
                                                                      ></span
                                                                      >,<span class="statement-end-if-in-editor"></span> phone
                                                                      or email) that you have previously provided to us. We
                                                                      may also use other verification methods as the
                                                                      circumstances dictate.</span
                                                                    ></span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      >We will only use personal information provided in your
                                                                      request to verify your identity or authority to make
                                                                      the request. To the extent possible, we will avoid
                                                                      requesting additional information from you for the
                                                                      purposes of verification. However, if we cannot verify
                                                                      your identity from the information already maintained
                                                                      by us, we may request that you provide additional
                                                                      information for the purposes of verifying your identity
                                                                      and for security or fraud-prevention purposes. We will
                                                                      delete such additionally provided information as soon
                                                                      as we finish verifying you.</span
                                                                    ></span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      ><u>Other privacy rights</u></span
                                                                    ></span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div>
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                        ><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                          ><span data-custom-class="body_text"
                                                                            ><span
                                                                              style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                              ><span
                                                                                style="
                                                                                  font-size: 15px;
                                                                                  color: rgb(89, 89, 89);
                                                                                "
                                                                                ><span data-custom-class="body_text"
                                                                                  ><span
                                                                                    style="
                                                                                      color: rgb(89, 89, 89);
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><span data-custom-class="body_text"
                                                                                      ><span
                                                                                        class="block-component"
                                                                                      ></span></span></span></span></span></span></span></span></span></span></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                      ><span data-custom-class="body_text"
                                                                        >You may object to the processing of your personal
                                                                        information.<span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                          ><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                            ><span data-custom-class="body_text"
                                                                              ><span style="color: rgb(0, 0, 0)"
                                                                                ><span style="font-size: 15px"
                                                                                  ><span data-custom-class="body_text"
                                                                                    ><span style="color: rgb(0, 0, 0)"
                                                                                      ><span style="font-size: 15px"
                                                                                        ><span data-custom-class="body_text"
                                                                                          ><span
                                                                                            class="
                                                                                              statement-end-if-in-editor
                                                                                            "
                                                                                          ></span></span></span></span></span></span></span></span></span></span></span></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div>
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                        ><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                          ><span data-custom-class="body_text"
                                                                            ><span
                                                                              style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                              ><span
                                                                                style="
                                                                                  font-size: 15px;
                                                                                  color: rgb(89, 89, 89);
                                                                                "
                                                                                ><span data-custom-class="body_text"
                                                                                  ><span
                                                                                    style="
                                                                                      color: rgb(89, 89, 89);
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><span data-custom-class="body_text"
                                                                                      ><span
                                                                                        class="block-component"
                                                                                      ></span></span></span></span></span></span></span></span></span></span></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                      ><span data-custom-class="body_text"
                                                                        >You may request correction of your personal data if
                                                                        it is incorrect or no longer relevant, or ask to
                                                                        restrict the processing of the information.<span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                          ><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                            ><span data-custom-class="body_text"
                                                                              ><span style="color: rgb(0, 0, 0)"
                                                                                ><span style="font-size: 15px"
                                                                                  ><span data-custom-class="body_text"
                                                                                    ><span style="color: rgb(0, 0, 0)"
                                                                                      ><span style="font-size: 15px"
                                                                                        ><span data-custom-class="body_text"
                                                                                          ><span
                                                                                            class="
                                                                                              statement-end-if-in-editor
                                                                                            "
                                                                                          ></span></span></span></span></span></span></span></span></span></span></span></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div>
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                        ><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                          ><span data-custom-class="body_text"
                                                                            ><span
                                                                              style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                              ><span
                                                                                style="
                                                                                  font-size: 15px;
                                                                                  color: rgb(89, 89, 89);
                                                                                "
                                                                                ><span data-custom-class="body_text"
                                                                                  ><span
                                                                                    style="
                                                                                      color: rgb(89, 89, 89);
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><span data-custom-class="body_text"
                                                                                      ><span
                                                                                        class="block-component"
                                                                                      ></span></span></span></span></span></span></span></span></span></span></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                      ><span data-custom-class="body_text"
                                                                        >You can designate an
                                                                        <span class="block-component"></span>authorized<span
                                                                          class="statement-end-if-in-editor"
                                                                        ></span>
                                                                        agent to make a request under the CCPA on your
                                                                        behalf. We may deny a request from an
                                                                        <span class="block-component"></span>authorized<span
                                                                          class="statement-end-if-in-editor"
                                                                        ></span>
                                                                        agent that does not submit proof that they have been
                                                                        validly
                                                                        <span class="block-component"></span>authorized<span
                                                                          class="statement-end-if-in-editor"
                                                                        ></span>
                                                                        to act on your behalf in accordance with the
                                                                        CCPA.<span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                          ><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                            ><span data-custom-class="body_text"
                                                                              ><span style="color: rgb(0, 0, 0)"
                                                                                ><span style="font-size: 15px"
                                                                                  ><span data-custom-class="body_text"
                                                                                    ><span style="color: rgb(0, 0, 0)"
                                                                                      ><span style="font-size: 15px"
                                                                                        ><span data-custom-class="body_text"
                                                                                          ><span
                                                                                            class="
                                                                                              statement-end-if-in-editor
                                                                                            "
                                                                                          ></span></span></span></span></span></span></span></span></span></span></span></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div>
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                        ><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                          ><span data-custom-class="body_text"
                                                                            ><span
                                                                              style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                              ><span
                                                                                style="
                                                                                  font-size: 15px;
                                                                                  color: rgb(89, 89, 89);
                                                                                "
                                                                                ><span data-custom-class="body_text"
                                                                                  ><span
                                                                                    style="
                                                                                      color: rgb(89, 89, 89);
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><span data-custom-class="body_text"
                                                                                      ><span
                                                                                        class="block-component"
                                                                                      ></span></span></span></span></span></span></span></span></span></span></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                      ><span data-custom-class="body_text"
                                                                        >You may request to opt out from future selling or
                                                                        sharing of your personal information to third
                                                                        parties. Upon receiving an opt-out request, we will
                                                                        act upon the request as soon as feasibly possible,
                                                                        but no later than fifteen (15) days from the date of
                                                                        the request submission.<span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                          ><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                            ><span data-custom-class="body_text"
                                                                              ><span style="color: rgb(0, 0, 0)"
                                                                                ><span style="font-size: 15px"
                                                                                  ><span data-custom-class="body_text"
                                                                                    ><span style="color: rgb(0, 0, 0)"
                                                                                      ><span style="font-size: 15px"
                                                                                        ><span data-custom-class="body_text"
                                                                                          ><span
                                                                                            class="
                                                                                              statement-end-if-in-editor
                                                                                            "
                                                                                          ></span></span></span></span></span></span></span></span></span></span></span></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div style="line-height: 1.5">
                                                                <span class="block-component"
                                                                  ><span style="font-size: 15px"></span
                                                                ></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      >To exercise these rights, you can contact us
                                                                      <span class="block-component"></span>by visiting
                                                                      <span style="color: rgb(0, 58, 250)"
                                                                        ><span class="question"
                                                                          >info@wrestlematness.com</span
                                                                        ></span
                                                                      >, <span class="else-block"></span></span
                                                                    ><span style="color: rgb(89, 89, 89); font-size: 15px"
                                                                      ><span data-custom-class="body_text"
                                                                        ><span
                                                                          style="color: rgb(89, 89, 89); font-size: 15px"
                                                                          ><span data-custom-class="body_text"
                                                                            ><span class="block-component"></span>by email at
                                                                            <span class="question"
                                                                              >info@wrestlematness.com</span
                                                                            >, <span class="statement-end-if-in-editor"></span
                                                                            ><span class="block-component"></span></span
                                                                          ><span data-custom-class="body_text"
                                                                            ><span class="block-component"></span
                                                                            ><span class="block-component"
                                                                              ><span
                                                                                data-custom-class="body_text"
                                                                              ></span></span></span></span></span></span></span></span
                                                                ><span data-custom-class="body_text"
                                                                  >or by referring to the contact details at the bottom of
                                                                  this document. If you have a complaint about how we handle
                                                                  your data, we would like to hear from you.</span
                                                                ><span style="color: rgb(89, 89, 89); font-size: 15px"
                                                                  ><span data-custom-class="body_text"
                                                                    ><span class="block-component"
                                                                      ><span class="block-component"></span></span></span
                                                                ></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span class="block-component"
                                                                  ><span style="font-size: 15px"></span
                                                                ></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"><br /></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><strong
                                                                    ><span data-custom-class="heading_2"
                                                                      >Colorado Residents</span
                                                                    ></strong
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"><br /></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text"
                                                                    >This section applies only to Colorado residents. Under
                                                                    the Colorado Privacy Act (CPA), you have the rights
                                                                    listed below. However, these rights are not absolute, and
                                                                    in certain cases, we may decline your request as
                                                                    permitted by law.</span
                                                                  ><span class="block-component"></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px"
                                                                    ><span data-custom-class="body_text"
                                                                      >Right to be informed whether or not we are processing
                                                                      your personal data</span
                                                                    ><span class="statement-end-if-in-editor"></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span class="block-component"></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px"
                                                                    ><span data-custom-class="body_text"
                                                                      >Right to access your personal data</span
                                                                    ><span class="statement-end-if-in-editor"></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div style="line-height: 1.5">
                                                                <span class="block-component"></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px"
                                                                    ><span data-custom-class="body_text"
                                                                      >Right to correct inaccuracies in your personal
                                                                      data</span
                                                                    ></span
                                                                  ><span class="statement-end-if-in-editor"></span>
                                                                </li>
                                                              </ul>
                                                              <div style="line-height: 1.5">
                                                                <span class="block-component"></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px"
                                                                    ><span data-custom-class="body_text"
                                                                      >Right to request deletion of your personal data</span
                                                                    ></span
                                                                  ><span class="statement-end-if-in-editor"></span>
                                                                </li>
                                                              </ul>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span class="block-component"></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px"
                                                                    ><span data-custom-class="body_text"
                                                                      >Right to obtain a copy of the personal data you
                                                                      previously shared with us</span
                                                                    ><span class="statement-end-if-in-editor"></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div style="line-height: 1.5">
                                                                <span class="block-component"></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px"
                                                                    ><span data-custom-class="body_text"
                                                                      >Right to opt out of the processing of your personal
                                                                      data if it is used for targeted advertising, the sale
                                                                      of personal data, or profiling in furtherance of
                                                                      decisions that produce legal or similarly significant
                                                                      effects (<span class="block-component"></span
                                                                      >"profiling"<span
                                                                        class="statement-end-if-in-editor"
                                                                      ></span
                                                                      >)</span
                                                                    ></span
                                                                  ><span class="statement-end-if-in-editor"></span>
                                                                </li>
                                                              </ul>
                                                              <div data-custom-class="body_text" style="line-height: 1.5">
                                                                <span class="block-component"
                                                                  ><span data-custom-class="body_text"></span></span
                                                                ><span style="font-size: 15px"
                                                                  >To submit a request to exercise</span
                                                                >
                                                                <span class="block-component" style="font-size: 15px"></span
                                                                ><span style="font-size: 15px">these</span>
                                                                <span
                                                                  class="statement-end-if-in-editor"
                                                                  style="font-size: 15px"
                                                                ></span
                                                                ><span style="font-size: 15px"
                                                                  >rights described above, please
                                                                  <span class="block-component"></span>email</span
                                                                >
                                                                <span class="question" style="font-size: 15px"
                                                                  >info@wrestlematness.com</span
                                                                ><span style="font-size: 15px">&nbsp;or&nbsp;</span
                                                                ><span class="block-component" style="font-size: 15px"></span
                                                                ><span style="font-size: 15px">visit</span>
                                                                <span style="color: rgb(0, 58, 250)"
                                                                  ><span class="question" style="font-size: 15px"
                                                                    >info@wrestlematness.com</span
                                                                  ></span
                                                                ><span class="else-block" style="font-size: 15px"></span
                                                                ><span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text">.</span></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text"
                                                                    >If we decline to take action regarding your request and
                                                                    you wish to appeal our decision, please email us at
                                                                    <span class="block-component"></span
                                                                    ><span class="question">info@wrestlematness.com</span
                                                                    ><span class="else-block"></span>. Within forty-five (45)
                                                                    days of receipt of an appeal, we will inform you in
                                                                    writing of any action taken or not taken in response to
                                                                    the appeal, including a written explanation of the
                                                                    reasons for the decisions.</span
                                                                  ><span class="statement-end-if-in-editor"></span
                                                                ></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span class="block-component"></span>
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <strong
                                                                  ><span style="font-size: 15px"
                                                                    ><span data-custom-class="heading_2"
                                                                      >Connecticut Residents</span
                                                                    ></span
                                                                  ></strong
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"><br /></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text"
                                                                    >This section applies only to Connecticut residents.
                                                                    Under the Connecticut Data Privacy Act (CTDPA), you have
                                                                    the rights listed below. However, these rights are not
                                                                    absolute, and in certain cases, we may decline your
                                                                    request as permitted by law.</span
                                                                  ><span class="block-component"></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px"
                                                                    ><span data-custom-class="body_text"
                                                                      >Right to be informed whether or not we are processing
                                                                      your personal data</span
                                                                    ><span class="statement-end-if-in-editor"></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div style="line-height: 1.5">
                                                                <span class="block-component"></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span data-custom-class="body_text"
                                                                    >Right to access your personal data</span
                                                                  ><span class="statement-end-if-in-editor"></span>
                                                                </li>
                                                              </ul>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span class="block-component"></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px"
                                                                    ><span data-custom-class="body_text"
                                                                      >Right to correct inaccuracies in your personal
                                                                      data</span
                                                                    ><span class="statement-end-if-in-editor"></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div style="line-height: 1.5">
                                                                <span class="block-component"></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px"
                                                                    ><span data-custom-class="body_text"
                                                                      >Right to request deletion of your personal data</span
                                                                    ></span
                                                                  ><span class="statement-end-if-in-editor"></span>
                                                                </li>
                                                              </ul>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span class="block-component"></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px"
                                                                    ><span data-custom-class="body_text"
                                                                      >Right to obtain a copy of the personal data you
                                                                      previously shared with us</span
                                                                    ><span class="statement-end-if-in-editor"></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div style="line-height: 1.5">
                                                                <span class="block-component"></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px"
                                                                    ><span data-custom-class="body_text"
                                                                      >Right to opt out of the processing of your personal
                                                                      data if it is used for targeted advertising, the sale
                                                                      of personal data, or profiling in furtherance of
                                                                      decisions that produce legal or similarly significant
                                                                      effects (<span class="block-component"></span
                                                                      >"profiling"<span
                                                                        class="statement-end-if-in-editor"
                                                                      ></span
                                                                      >)</span
                                                                    ></span
                                                                  ><span class="statement-end-if-in-editor"></span>
                                                                </li>
                                                              </ul>
                                                              <div data-custom-class="body_text" style="line-height: 1.5">
                                                                <span class="block-component"
                                                                  ><span style="font-size: 15px"
                                                                    ><span data-custom-class="body_text"></span></span></span
                                                                >To submit a request to exercise
                                                                <span class="block-component"></span>these<span
                                                                  class="statement-end-if-in-editor"
                                                                ></span>
                                                                rights described above, please
                                                                <span class="block-component"></span>email
                                                                <span class="question">info@wrestlematness.com</span> or
                                                                <span class="block-component"></span>visit
                                                                <span style="color: rgb(0, 58, 250)"
                                                                  ><span class="question"
                                                                    >info@wrestlematness.com</span
                                                                  ></span
                                                                ><span class="else-block"></span>.
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.4">
                                                                <span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text"
                                                                    >If we decline to take action regarding your request and
                                                                    you wish to appeal our decision, please email us at
                                                                    <span class="block-component"></span
                                                                    ><span class="question">info@wrestlematness.com</span
                                                                    ><span class="else-block"></span>. Within sixty (60) days
                                                                    of receipt of an appeal, we will inform you in writing of
                                                                    any action taken or not taken in response to the appeal,
                                                                    including a written explanation of the reasons for the
                                                                    decisions.</span
                                                                  ></span
                                                                ><span class="statement-end-if-in-editor"></span
                                                                ><span class="block-component"
                                                                  ><span style="font-size: 15px"></span
                                                                ></span>
                                                              </div>
                                                              <div style="line-height: 1.4">
                                                                <span style="font-size: 15px"><br /></span>
                                                              </div>
                                                              <div style="line-height: 1.4">
                                                                <span style="font-size: 15px"
                                                                  ><strong
                                                                    ><span data-custom-class="heading_2"
                                                                      >Utah Residents</span
                                                                    ></strong
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.4">
                                                                <span style="font-size: 15px"><br /></span>
                                                              </div>
                                                              <div style="line-height: 1.4">
                                                                <span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text"
                                                                    >This section applies only to Utah residents. Under the
                                                                    Utah Consumer Privacy Act (UCPA), you have the rights
                                                                    listed below. However, these rights are not absolute, and
                                                                    in certain cases, we may decline your request as
                                                                    permitted by law.</span
                                                                  ><span class="block-component"></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.4">
                                                                  <span style="font-size: 15px"
                                                                    ><span data-custom-class="body_text"
                                                                      >Right to be informed whether or not we are processing
                                                                      your personal data</span
                                                                    ><span class="statement-end-if-in-editor"></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div style="line-height: 1.4">
                                                                <span style="font-size: 15px"
                                                                  ><span class="block-component"></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.4">
                                                                  <span style="font-size: 15px"
                                                                    ><span data-custom-class="body_text"
                                                                      >Right to access your personal data</span
                                                                    ><span class="statement-end-if-in-editor"></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div style="line-height: 1.4">
                                                                <span style="font-size: 15px"
                                                                  ><span class="block-component"></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.4">
                                                                  <span style="font-size: 15px"
                                                                    ><span data-custom-class="body_text"
                                                                      >Right to request deletion of your personal data</span
                                                                    ><span class="statement-end-if-in-editor"></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div style="line-height: 1.4">
                                                                <span style="font-size: 15px"
                                                                  ><span class="block-component"></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.4">
                                                                  <span style="font-size: 15px"
                                                                    ><span data-custom-class="body_text"
                                                                      >Right to obtain a copy of the personal data you
                                                                      previously shared with us</span
                                                                    ><span class="statement-end-if-in-editor"></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div style="line-height: 1.4">
                                                                <span style="font-size: 15px"
                                                                  ><span class="block-component"></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.4">
                                                                  <span style="font-size: 15px"
                                                                    ><span data-custom-class="body_text"
                                                                      >Right to opt out of the processing of your personal
                                                                      data if it is used for targeted advertising or the sale
                                                                      of personal data</span
                                                                    ><span class="statement-end-if-in-editor"></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div data-custom-class="body_text" style="line-height: 1.4">
                                                                <span style="font-size: 15px"
                                                                  ><span class="block-component"
                                                                    ><span data-custom-class="body_text"></span></span
                                                                  >To submit a request to exercise
                                                                  <span class="block-component"></span>these<span
                                                                    class="statement-end-if-in-editor"
                                                                  ></span>
                                                                  rights described above, please
                                                                  <span class="block-component"></span>email
                                                                  <span class="question">info@wrestlematness.com</span> or
                                                                  <span class="block-component"></span>visit
                                                                  <span style="color: rgb(0, 58, 250)"
                                                                    ><span class="question"
                                                                      >info@wrestlematness.com</span
                                                                    ></span
                                                                  ><span class="else-block"></span>.</span
                                                                ><span class="statement-end-if-in-editor"
                                                                  ><span style="font-size: 15px"></span></span
                                                                ><span class="block-component"
                                                                  ><span style="font-size: 15px"></span
                                                                ></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"><br /></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><strong
                                                                    ><span data-custom-class="heading_2"
                                                                      >Virginia Residents</span
                                                                    ></strong
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"><br /></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text"
                                                                    >Under the Virginia Consumer Data Protection Act
                                                                    (VCDPA):</span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"><br /></span>
                                                              </div>
                                                              <div data-custom-class="body_text" style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span class="block-component"
                                                                    ><span data-custom-class="body_text"></span></span
                                                                  >"Consumer"<span class="statement-end-if-in-editor"></span>
                                                                  means a natural person who is a resident of the
                                                                  Commonwealth acting only in an individual or household
                                                                  context. It does not include a natural person acting in a
                                                                  commercial or employment context.</span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"><br /></span>
                                                              </div>
                                                              <div data-custom-class="body_text" style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span class="block-component"
                                                                    ><span data-custom-class="body_text"></span></span
                                                                  >"Personal data"<span
                                                                    class="statement-end-if-in-editor"
                                                                  ></span>
                                                                  means any information that is linked or reasonably linkable
                                                                  to an identified or identifiable natural person.
                                                                  <span class="block-component"></span>"Personal data"<span
                                                                    class="statement-end-if-in-editor"
                                                                  ></span>
                                                                  does not include de-identified data or publicly available
                                                                  information.</span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"><br /></span>
                                                              </div>
                                                              <div data-custom-class="body_text" style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span class="block-component"
                                                                    ><span data-custom-class="body_text"></span></span
                                                                  >"Sale of personal data"<span
                                                                    class="statement-end-if-in-editor"
                                                                  ></span>
                                                                  means the exchange of personal data for monetary
                                                                  consideration.</span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"><br /></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text"
                                                                    >If this definition of
                                                                    <span class="block-component"></span>"consumer"<span
                                                                      class="statement-end-if-in-editor"
                                                                    ></span>
                                                                    applies to you, we must adhere to certain rights and
                                                                    obligations regarding your personal data.</span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text"
                                                                    ><u
                                                                      >Your rights with respect to your personal data</u
                                                                    ></span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span class="block-component"></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px"
                                                                    ><span data-custom-class="body_text"
                                                                      >Right to be informed whether or not we are processing
                                                                      your personal data</span
                                                                    ><span class="statement-end-if-in-editor"></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span class="block-component"></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px"
                                                                    ><span data-custom-class="body_text"
                                                                      >Right to access your personal data</span
                                                                    ><span class="statement-end-if-in-editor"></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span class="block-component"></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px"
                                                                    ><span data-custom-class="body_text"
                                                                      >Right to correct inaccuracies in your personal
                                                                      data</span
                                                                    ><span class="statement-end-if-in-editor"></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span class="block-component"></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px"
                                                                    ><span data-custom-class="body_text"
                                                                      >Right to request deletion of your personal data</span
                                                                    ><span class="statement-end-if-in-editor"></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span class="block-component"></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px"
                                                                    ><span data-custom-class="body_text"
                                                                      >Right to obtain a copy of the personal data you
                                                                      previously shared with us</span
                                                                    ><span class="statement-end-if-in-editor"></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span class="block-component"></span
                                                                ></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5">
                                                                  <span style="font-size: 15px"
                                                                    ><span data-custom-class="body_text"
                                                                      >Right to opt out of the processing of your personal
                                                                      data if it is used for targeted advertising, the sale
                                                                      of personal data, or profiling in furtherance of
                                                                      decisions that produce legal or similarly significant
                                                                      effects (<span class="block-component"></span
                                                                      >"profiling"<span
                                                                        class="statement-end-if-in-editor"
                                                                      ></span
                                                                      >)</span
                                                                    ><span class="statement-end-if-in-editor"></span
                                                                  ></span>
                                                                </li>
                                                              </ul>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span class="block-component"></span
                                                                ></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><u
                                                                    ><span data-custom-class="body_text"
                                                                      >Exercise your rights provided under the Virginia
                                                                      VCDPA</span
                                                                    ></u
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"><br /></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span class="block-component"></span
                                                                  ><span data-custom-class="body_text"
                                                                    >You may <span class="block-component"></span>contact us by
                                                                    email at
                                                                    <span class="question">info@wrestlematness.com</span> or
                                                                    <span class="block-component"></span>visit
                                                                    <span style="color: rgb(0, 58, 250)"
                                                                      ><span class="question"
                                                                        >info@wrestlematness.com</span
                                                                      ></span
                                                                    ><span class="else-block"></span>.</span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"><br /></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text"
                                                                    >If you are using an
                                                                    <span class="block-component"></span>authorized<span
                                                                      class="statement-end-if-in-editor"
                                                                    ></span>
                                                                    agent to exercise your rights, we may deny a request if
                                                                    the <span class="block-component"></span>authorized<span
                                                                      class="statement-end-if-in-editor"
                                                                    ></span>
                                                                    agent does not submit proof that they have been validly
                                                                    <span class="block-component"></span>authorized<span
                                                                      class="statement-end-if-in-editor"
                                                                    ></span>
                                                                    to act on your behalf.</span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><u
                                                                    ><span data-custom-class="body_text"
                                                                      >Verification process</span
                                                                    ></u
                                                                  ><br
                                                                /></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"><br /></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text"
                                                                    >We may request that you provide additional information
                                                                    reasonably necessary to verify you and your consumer's
                                                                    request. If you submit the request through an
                                                                    <span class="block-component"></span>authorized<span
                                                                      class="statement-end-if-in-editor"
                                                                    ></span>
                                                                    agent, we may need to collect additional information to
                                                                    verify your identity before processing your
                                                                    request.</span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"><br /></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text"
                                                                    >Upon receiving your request, we will respond without
                                                                    undue delay, but in all cases, within forty-five (45)
                                                                    days of receipt. The response period may be extended once
                                                                    by forty-five (45) additional days when reasonably
                                                                    necessary. We will inform you of any such extension
                                                                    within the initial 45-day response period, together with
                                                                    the reason for the extension.</span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text"
                                                                    ><u>Right to appeal</u></span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"><br /></span>
                                                              </div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px"
                                                                  ><span data-custom-class="body_text"
                                                                    >If we decline to take action regarding your request, we
                                                                    will inform you of our decision and reasoning behind it.
                                                                    If you wish to appeal our decision, please email us at
                                                                    <span class="block-component"></span
                                                                    ><span class="question">info@wrestlematness.com</span
                                                                    ><span class="else-block"></span>. Within sixty (60) days
                                                                    of receipt of an appeal, we will inform you in writing of
                                                                    any action taken or not taken in response to the appeal,
                                                                    including a written explanation of the reasons for the
                                                                    decisions. If your appeal is denied, you may contact the
                                                                    <a
                                                                      data-custom-class="link"
                                                                      href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint"
                                                                      rel="noopener noreferrer"
                                                                      target="_blank"
                                                                      ><span style="color: rgb(0, 58, 250)"
                                                                        ><span data-custom-class="link"
                                                                          >Attorney General to submit a complaint</span
                                                                        ></span
                                                                      ></a
                                                                    >.</span
                                                                  ></span
                                                                ><span class="statement-end-if-in-editor"></span
                                                                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span style="color: rgb(89, 89, 89); font-size: 15px"
                                                                      ><span data-custom-class="body_text"
                                                                        ><span
                                                                          style="color: rgb(89, 89, 89); font-size: 15px"
                                                                          ><span data-custom-class="body_text"
                                                                            ><span
                                                                              style="color: rgb(89, 89, 89); font-size: 15px"
                                                                              ><span data-custom-class="body_text"
                                                                                ><span
                                                                                  style="
                                                                                    color: rgb(89, 89, 89);
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  ><span data-custom-class="body_text"
                                                                                    ><span class="statement-end-if-in-editor"
                                                                                      ><span data-custom-class="body_text"
                                                                                        ><span
                                                                                          style="
                                                                                            font-size: 15px;
                                                                                            color: rgb(89, 89, 89);
                                                                                          "
                                                                                          ><span
                                                                                            style="
                                                                                              font-size: 15px;
                                                                                              color: rgb(89, 89, 89);
                                                                                            "
                                                                                            ><span
                                                                                              style="
                                                                                                color: rgb(89, 89, 89);
                                                                                                font-size: 15px;
                                                                                              "
                                                                                              ><span
                                                                                                data-custom-class="body_text"
                                                                                                ><span
                                                                                                  style="
                                                                                                    color: rgb(89, 89, 89);
                                                                                                    font-size: 15px;
                                                                                                  "
                                                                                                  ><span
                                                                                                    data-custom-class="body_text"
                                                                                                    ><span
                                                                                                      style="
                                                                                                        color: rgb(
                                                                                                          89,
                                                                                                          89,
                                                                                                          89
                                                                                                        );
                                                                                                        font-size: 15px;
                                                                                                      "
                                                                                                      ><span
                                                                                                        data-custom-class="body_text"
                                                                                                        ><span
                                                                                                          style="
                                                                                                            color: rgb(
                                                                                                              89,
                                                                                                              89,
                                                                                                              89
                                                                                                            );
                                                                                                            font-size: 15px;
                                                                                                          "
                                                                                                          ><span
                                                                                                            data-custom-class="body_text"
                                                                                                            ><span
                                                                                                              class="
                                                                                                                statement-end-if-in-editor
                                                                                                              "
                                                                                                              ><span
                                                                                                                class="
                                                                                                                  statement-end-if-in-editor
                                                                                                                "
                                                                                                              ></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span
                                                                ><span class="block-component"
                                                                  ><span style="font-size: 15px"></span></span
                                                                ><span class="block-component"
                                                                  ><span style="font-size: 15px"></span
                                                                ></span>
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div id="policyupdates" style="line-height: 1.5">
                                                                <span style="color: rgb(127, 127, 127)"
                                                                  ><span style="color: rgb(89, 89, 89); font-size: 15px"
                                                                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                        ><span id="control" style="color: rgb(0, 0, 0)"
                                                                          ><strong
                                                                            ><span data-custom-class="heading_1"
                                                                              >12. DO WE MAKE UPDATES TO THIS NOTICE?</span
                                                                            ></strong
                                                                          ></span
                                                                        ></span
                                                                      ></span
                                                                    ></span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      ><em
                                                                        ><strong>In Short:&nbsp;</strong>Yes, we will update
                                                                        this notice as necessary to stay compliant with
                                                                        relevant laws.</em
                                                                      ></span
                                                                    ></span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      >We may update this privacy notice from time to time.
                                                                      The updated version will be indicated by an updated
                                                                      <span class="block-component"></span>"Revised"<span
                                                                        class="statement-end-if-in-editor"
                                                                      ></span>
                                                                      date and the updated version will be effective as soon
                                                                      as it is accessible. If we make material changes to
                                                                      this privacy notice, we may notify you either by
                                                                      prominently posting a notice of such changes or by
                                                                      directly sending you a notification. We encourage you
                                                                      to review this privacy notice frequently to be informed
                                                                      of how we are protecting your information.</span
                                                                    ></span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div id="contact" style="line-height: 1.5">
                                                                <span style="color: rgb(127, 127, 127)"
                                                                  ><span style="color: rgb(89, 89, 89); font-size: 15px"
                                                                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                        ><span id="control" style="color: rgb(0, 0, 0)"
                                                                          ><strong
                                                                            ><span data-custom-class="heading_1"
                                                                              >13. HOW CAN YOU CONTACT US ABOUT THIS
                                                                              NOTICE?</span
                                                                            ></strong
                                                                          ></span
                                                                        ></span
                                                                      ></span
                                                                    ></span
                                                                  ></span
                                                                >
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      >If you have questions or comments about this notice,
                                                                      you may
                                                                      <span style="color: rgb(89, 89, 89); font-size: 15px"
                                                                        ><span data-custom-class="body_text"
                                                                          ><span class="block-component"></span></span></span
                                                                      >contact our Data Protection Officer (DPO)<span
                                                                        class="block-component"
                                                                      ></span
                                                                      >
                                                                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                        ><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                          ><span data-custom-class="body_text"
                                                                            ><span
                                                                              class="statement-end-if-in-editor"
                                                                            ></span></span
                                                                          ><span data-custom-class="body_text"
                                                                            >&nbsp;by email at&nbsp;</span
                                                                          ></span
                                                                        ></span
                                                                      ><span style="color: rgb(89, 89, 89); font-size: 15px"
                                                                        ><span data-custom-class="body_text"
                                                                          ><span class="question">info@wrestlematness.com</span></span
                                                                        ></span
                                                                      ></span
                                                                ></span></span>
                                                              </div>
                                                              <div style="line-height: 1.5"><br /></div>
                                                              
                                                             
                                                              
                                                              <div style="line-height: 1.5">
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                                                                    ><span data-custom-class="body_text"
                                                                      ><span class="block-component"></span>Based on the
                                                                      applicable laws of your country, you may have the right
                                                                      to request access to the personal information we
                                                                      collect from you, change that information, or delete
                                                                      it.
                                                                      <span class="else-block"
                                                                        ><span class="block-component"></span>To request to
                                                                        review, update, or delete your personal information,
                                                                        please
                                                                        <span class="block-component"></span></span></span
                                                                    ><span data-custom-class="body_text"
                                                                      >visit:
                                                                      <span style="color: rgb(0, 58, 250)"
                                                                        ><span class="question"
                                                                          >info@wrestlematness.com</span
                                                                        ></span
                                                                      ><span class="else-block"></span></span></span
                                                                  ><span data-custom-class="body_text">.</span></span
                                                                >
                                                              </div>
                                                            </div>
                                                            <div
                                                              style="
                                                                color: #595959;
                                                                font-size: 14px;
                                                                font-family: Arial;
                                                                padding-top: 16px;
                                                              "
                                                            >
                                                              This privacy policy was created using Termly's
                                                              <a
                                                                style="color: rgb(48, 48, 241) !important"
                                                                href="https://termly.io/products/privacy-policy-generator/"
                                                                >Privacy Policy Generator</a
                                                              >.
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
[data-custom-class='body'],
[data-custom-class='body'] * {
  background: transparent !important;
}
[data-custom-class='title'],
[data-custom-class='title'] * {
  font-family: Arial !important;
  font-size: 26px !important;
  color: #000000 !important;
}
[data-custom-class='subtitle'],
[data-custom-class='subtitle'] * {
  font-family: Arial !important;
  color: #595959 !important;
  font-size: 14px !important;
}
[data-custom-class='heading_1'],
[data-custom-class='heading_1'] * {
  font-family: Arial !important;
  font-size: 19px !important;
  color: #000000 !important;
}
[data-custom-class='heading_2'],
[data-custom-class='heading_2'] * {
  font-family: Arial !important;
  font-size: 17px !important;
  color: #000000 !important;
}
[data-custom-class='body_text'],
[data-custom-class='body_text'] * {
  color: #595959 !important;
  font-size: 14px !important;
  font-family: Arial !important;
}
[data-custom-class='link'],
[data-custom-class='link'] * {
  color: #3030f1 !important;
  font-size: 14px !important;
  font-family: Arial !important;
  word-break: break-word !important;
}

ul {
  list-style-type: square;
  padding-inline-start: 40px;
}
ul > li > ul {
  list-style-type: circle;
}
ul > li > ul > li > ul {
  list-style-type: square;
}
ol li {
  font-family: Arial;
}

strong {
    font-weight: 600;
}
em {
    font-style: italic;
}
</style>