<template>
  <modal-dialog ref="myModal">
    <template v-slot:modal-title>Re-Seed This Dual?</template>
    <template v-slot:modal-contents>
      <p>
        Are you sure you want to re-seed this event, <span class="bold">{{ eventName }}</span>?
      </p>
      <p>
        This cannot be undone. Users' picks for the affected matches will be cleared. The affected users will
        be notified by email.
      </p>
      <p v-if="canSaveWithoutReSeeding">
        If you are only making small edits, you may save without re-seeding. These changes will be published immediately,
        and users will <span class="bold">not</span> be notified.
      </p>
    </template>
    <template v-slot:modal-actions>
      <rebel-button button-type="button" type="primary" color="danger" text="Re-Seed Dual" @click.prevent="reSeed" />
      <rebel-button v-if="canSaveWithoutReSeeding" button-type="button" type="ghosted" color="default" text="Save Without Re-Seeding" @click.prevent="saveWithoutReSeeding" />
      <a @click.prevent="cancel">Cancel</a>
    </template>
  </modal-dialog>
</template>

<script>
import RebelButton from '@/components/RebelButton.vue'
import ModalDialog from '@/components/ModalDialog.vue'

import emitEvents from '@/utils/emitEvents'

export default {
  components: {
    RebelButton,
    ModalDialog
  },

  props: {
    canSaveWithoutReSeeding: {
      type: Boolean,
      required: true
    },

    eventName: {
      type: String,
      required: true
    },
  },

  emits: [emitEvents.RESEED, emitEvents.SAVE_WITHOUT_RESEED],

  methods: {
    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    reSeed() {
      this.$emit(emitEvents.RESEED)
      this.closeModal()
    },

    saveWithoutReSeeding() {
      this.$emit(emitEvents.SAVE_WITHOUT_RESEED)
      this.closeModal()
    },
  }
}
</script>
