<template>
  <div>
    <header class="flex align-baseline gap-0-5">
      <h2>Members</h2>
      <span class="heading2 semi-bold">({{ sortedLeagueMembers.length }})</span>
    </header>
    <table
      style="width: calc(100vw - 4rem); max-width: min(1440px, 90rem); border-spacing: 0 6px; border-collapse: separate">
      <tr v-for="member in sortedLeagueMembers" :key="member.user.id">
        <td style="white-space: nowrap">
          <div class="flex align-center">
            <avatar-component :src="member.user.imageSrc" :height="24" :width="24" />
            <p class="body2 ml-0-25">{{ member.user.displayName }}</p>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import AvatarComponent from '@/components/AvatarComponent.vue'

export default {
  components: {
    AvatarComponent
  },

  props: {
    leagueMembers: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    sortedLeagueMembers() {
      const leagueMembersCopy = [...this.leagueMembers]

      return leagueMembersCopy.sort((a, b) => a.displayName - b.displayName)
    }
  }
}
</script>