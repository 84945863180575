<template>
  <p v-if="isDisplaying" class="color-danger">{{ errors }}</p>
</template>

<script>
export default {
  props: {
    hide: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isDisplaying() {
      if (this.hide) {
        return false
      }

      return this.errors.length > 0
    }
  },

  data() {
    return {
      errors: ''
    }
  },

  expose: ['addErrors', 'clearErrors', 'setErrors'],

  methods: {
    addErrors(...errorsToAdd) {
      this.errors = errorsToAdd.join('\n')
    },

    clearErrors() {
      this.errors = ''
    },

    setErrors(...errorsToAdd) {
        this.clearErrors()
        this.addErrors(errorsToAdd)
    }
  }
}
</script>