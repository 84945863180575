import { defineStore } from 'pinia'

export const useStylingStore = defineStore('styling', {
  state: () => ({
    darkBackground: false,
    showEventBackgroundImage: false
  }),

  actions: {
    setDarkBackground(darkBackground) {
      this.darkBackground = darkBackground
    },
    setShowEventBackgroundImage(showEventBackgroundImage) {
      this.showEventBackgroundImage = showEventBackgroundImage
    }
  }
})
