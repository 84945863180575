import axios from 'axios'

export default {
  getGroups(eventId) {
    return axios.get(`/pick-em/${eventId}/group`)
  },

  saveGroup(eventId, groupToSave) {
    return axios.post(`/pick-em/${eventId}/group`, groupToSave)
  },

  deleteGroupById(eventId, groupId) {
    return axios.delete(`/pick-em/${eventId}/group/${groupId}`)
  },

  deleteMatchupById(eventId, groupId, matchupId) {
    return axios.delete(`/pick-em/${eventId}/group/${groupId}/matchup/${matchupId}`)
  },

  updateSortOrder(eventId, groupId, dto) {
    return axios.put(`/pick-em/${eventId}/group/${groupId}/matchup/sort-order`, dto)
  },

  getTeams(eventId) {
    return axios.get(`/pick-em/${eventId}/teams`)
  },

  saveTeams(eventId, dto) {
    return axios.put(`/pick-em/${eventId}/teams`, dto)
  },

  publishResults(dto) {
    return axios.put(`/pick-em/${dto.eventId}/matchups`, dto)
  },

  saveUserPicks(leagueId, dto) {
    return axios.put(`/pick-em/${dto.eventId}/league/${leagueId}/matchups`, dto)
  },

  reseed(eventId, dto) {
    return axios.put(`/pick-em/${eventId}/reseed`, dto)
  }

}