<template>
  <modal-dialog ref="myModal" @submit.prevent="submit">
    <template v-slot:modal-title>Customize Weight Class Units</template>
    <template v-slot:modal-contents>
      <div class="layout-form-list">
        <div class="form-input">
          <label for="units">Units</label>
          <select id="units" v-model="weightClassDetails.units" required>
            <option value="kg">kg</option>
            <option value="lbs" selected>lbs</option>
            <option value="">None</option>
          </select>
        </div>
      </div>
    </template>
    <template v-slot:modal-actions>
      <rebel-button button-type="submit" type="primary" color="default" text="Save" :is-loading="isSaving"
        :disabled="!dataIsDirty"></rebel-button>
      <a href="javascript:void(null)" @click.prevent="cancel">Cancel</a>
    </template>
  </modal-dialog>
</template>

<script>
import emitEvents from '@/utils/emitEvents'
import eventService from '@/services/EventService'
import ModalDialog from '@/components/ModalDialog.vue'
import RebelButton from '@/components/RebelButton.vue'

export default {
  created() {
    this.initialWeightClassDetails = { ...this.weightClassDetails }
  },

  components: {
    ModalDialog,
    RebelButton
  },

  emits: [emitEvents.EVENT_WEIGHT_CLASS_UNITS_CHANGED],

  props: {
    eventId: {
      type: [Number, String],
      required: true
    },

    units: {
      type: String,
      default: 'lbs'
    }
  },

  computed: {
    dataIsDirty() {
      return this.initialWeightClassDetails.units !== this.weightClassDetails.units
    },
  },

  data() {
    return {
      isSaving: false,

      initialWeightClassDetails: undefined,

      weightClassDetails: {
        units: this.units
      },
    }
  },

  methods: {
    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    async submit() {
      this.isSaving = true
      try {
        const dto = {
          units: this.weightClassDetails.units
        }

        await eventService.updateWeightClassUnits(this.eventId, dto)

        this.$emit(emitEvents.EVENT_WEIGHT_CLASS_UNITS_CHANGED, dto.units)
        this.closeModal()
      } finally {
        this.isSaving = false
      }
    }
  }
}
</script>