export default {

  BRACKET_TOURNAMENT_ID: 1,
  BRACKET_TOURNAMENT_NAME: 'Athletic Competition',

  POPULAR_VOTE_TOURNAMENT_ID: 2,
  POPULAR_VOTE_TOURNAMENT_NAME: 'Popular Vote Tournament (Bracket)',

  PICK_EM_ID: 3,
  PICK_EM_NAME: 'Pick \'Em Format',

  getEventTypeOptions() {
    return [
      {
        value: this.BRACKET_TOURNAMENT_ID,
        text: this.BRACKET_TOURNAMENT_NAME
      },
      {
        value: this.POPULAR_VOTE_TOURNAMENT_ID,
        text: this.POPULAR_VOTE_TOURNAMENT_NAME
      },
      {
        value: this.PICK_EM_ID,
        text: this.PICK_EM_NAME
      },
    ]
  },

  getEventTypeLabel(typeId) {
    const eventTypeOption = this.getEventTypeOptions().find(eto => eto.value == typeId)

    if (!eventTypeOption) throw Error('Event Type not found')

    return eventTypeOption.text
  },

  isBracketTournament(typeId) {
    return typeId == this.BRACKET_TOURNAMENT_ID
  },

  isPopularVoteTournament(typeId) {
    return typeId == this.POPULAR_VOTE_TOURNAMENT_ID
  },

  isPickEm(typeId) {
    return typeId == this.PICK_EM_ID
  }  
}