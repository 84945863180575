<template>
  <div class="flex col" id="outer-div">
    <div class="flex space-between gap-1 align-center mb-2">
      <h1>My Profile</h1>
      <router-link id="link-user-profile-settings" :to="{ name: 'user-profile-settings' }">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.828
        2.25C10.9115 2.25 10.1292 2.91265 9.97854 3.81675L9.88699 4.36602C9.84017 4.64695 9.63707 4.87407 9.37035
        4.97395C9.20811 5.0347 9.04853 5.1009 8.89184 5.17235C8.63259 5.29056 8.32822 5.27363 8.09636 5.10801L7.64299
        4.78418C6.89714 4.25143 5.87546 4.33599 5.22734 4.9841L4.98413 5.22732C4.33601 5.87544 4.25145 6.89712 4.7842
        7.64297L5.10803 8.09633C5.27364 8.32819 5.29057 8.63256 5.17236 8.89182C5.10091 9.04851 5.0347 9.20809 4.97395
        9.37034C4.87408 9.63706 4.64695 9.84016 4.36602 9.88698L3.81675 9.97852C2.91265 10.1292 2.25 10.9114 2.25
        11.828V12.172C2.25 13.0885 2.91265 13.8708 3.81675 14.0215L4.36602 14.113C4.64695 14.1598 4.87407 14.3629 4.97395
        14.6297C5.03469 14.7919 5.1009 14.9515 5.17234 15.1082C5.29056 15.3674 5.27362 15.6718 5.10801 15.9036L4.78416
        16.357C4.25141 17.1029 4.33597 18.1246 4.98408 18.7727L5.2273 19.0159C5.87541 19.664 6.8971 19.7486 7.64295
        19.2158L8.09633 18.892C8.32819 18.7264 8.63256 18.7094 8.89182 18.8276C9.04851 18.8991 9.20809 18.9653 9.37034
        19.026C9.63706 19.1259 9.84016 19.353 9.88698 19.634L9.97852 20.1832C10.1292 21.0873 10.9114 21.75 11.828
        21.75H12.172C13.0885 21.75 13.8708 21.0874 14.0215 20.1832L14.113 19.634C14.1598 19.3531 14.3629 19.1259 14.6297
        19.0261C14.7919 18.9653 14.9515 18.8991 15.1082 18.8277C15.3674 18.7094 15.6718 18.7264 15.9036 18.892L16.357
        19.2158C17.1029 19.7486 18.1245 19.664 18.7727 19.0159L19.0159 18.7727C19.664 18.1246 19.7485 17.1029 19.2158
        16.357L18.892 15.9037C18.7264 15.6718 18.7094 15.3674 18.8276 15.1082C18.8991 14.9515 18.9653 14.7919 19.026
        14.6297C19.1259 14.3629 19.353 14.1598 19.634 14.113L20.1832 14.0215C21.0873 13.8708 21.75 13.0886 21.75
        12.172V11.828C21.75 10.9115 21.0874 10.1292 20.1832 9.97854L19.634 9.88699C19.3531 9.84017 19.1259 9.63707 19.0261
        9.37035C18.9653 9.20811 18.8991 9.04854 18.8277 8.89185C18.7094 8.63259 18.7264 8.32822 18.892 8.09636L19.2158
        7.64297C19.7486 6.89712 19.664 5.87544 19.0159 5.22732L18.7727 4.9841C18.1246 4.33599 17.1029 4.25143 16.3571
        4.78418L15.9037 5.10802C15.6718 5.27364 15.3674 5.29057 15.1082 5.17236C14.9515 5.10091 14.7919 5.0347 14.6297
        4.97395C14.3629 4.87408 14.1598 4.64695 14.113 4.36602L14.0215 3.81675C13.8708 2.91265 13.0886 2.25 12.172
        2.25H11.828ZM12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25
        9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z" fill="#2860F0" />
        </svg>
      </router-link>
    </div>

    <div class="layout-form-sections">
      <div>
        <img ref="avatarResult" v-if="avatarSource && !showPlaceholderAvatar" id="avatar-result" :src="avatarSource"
          alt="" width="200" />
        <svg v-else width="199" height="199" viewBox="0 0 199 199" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_14_14277)">
            <circle cx="99.5" cy="99.5" r="99.5" fill="#748FA8" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M171.848 167.809C188.68 149.987 199 125.949 199 99.5C199 44.5477 154.452 0 99.5 0C44.5477 0 0 44.5477 0 99.5C0 126.02 10.3756 150.117 27.2889 167.953C39.3516 140.035 67.1876 120.492 99.5997 120.492C131.956 120.492 159.752 139.968 171.848 167.809ZM99.5788 199H99.4212C99.4475 199 99.4737 199 99.5 199C99.5263 199 99.5525 199 99.5788 199ZM58.7051 71.1425C58.7051 49.873 75.1834 32.4522 96.07 30.9501C97.2301 30.8298 98.4077 30.7681 99.5997 30.7681C102.892 30.7681 106.074 31.2386 109.082 32.116C126.46 36.5913 139.3 52.3674 139.3 71.1425C139.3 93.3982 121.258 111.44 99.0026 111.44C76.7469 111.44 58.7051 93.3982 58.7051 71.1425Z"
              fill="#DFE6ED" />
          </g>
          <defs>
            <clipPath id="clip0_14_14277">
              <rect width="199" height="199" rx="99.5" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <svg @click="imageUploader.isShowing = true" class="camera" width="44" height="44" viewBox="0 0 44 44" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <rect x="2" y="2" width="40" height="40" rx="20" fill="#2860F0" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M13 20C13 18.8954 13.8954 18 15 18H15.9296C16.5983 18 17.2228 17.6658 17.5937 17.1094L18.4063 15.8906C18.7772 15.3342 19.4017 15 20.0704 15H23.9296C24.5983 15 25.2228 15.3342 25.5937 15.8906L26.4063 17.1094C26.7772 17.6658 27.4017 18 28.0704 18H29C30.1046 18 31 18.8954 31 20V27C31 28.1046 30.1046 29 29 29H15C13.8954 29 13 28.1046 13 27V20ZM26.5 23C26.5 25.4853 24.4853 27.5 22 27.5C19.5147 27.5 17.5 25.4853 17.5 23C17.5 20.5147 19.5147 18.5 22 18.5C24.4853 18.5 26.5 20.5147 26.5 23ZM22 26C23.6569 26 25 24.6569 25 23C25 21.3431 23.6569 20 22 20C20.3431 20 19 21.3431 19 23C19 24.6569 20.3431 26 22 26Z"
            fill="white" />
          <rect x="2" y="2" width="40" height="40" rx="20" stroke="white" stroke-width="4" />
        </svg>

        <input ref="avatarUploader" id="avatar-uploader" class="btn-file" type="file" @change="previewFile"
          v-show="false" />
      </div>

      <div class="layout-form-list">
        <div class="form-labeled-value">
          <h4>Email</h4>
          <span>{{ email }}</span>
        </div>

        <div class="form-labeled-value">
          <h4>Display Name</h4>
          <div class="flex space-between gap-1">
            <span>{{ displayName }}</span>
            <a id="edit-display-name" class="action" href="javascript:void(0)" @click.prevent="editDisplayName">Edit</a>
          </div>
        </div>

        <div class="form-labeled-value">
          <h4>Password</h4>
          <div class="flex space-between gap-1">
            <span>&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;</span>
            <a id="edit-password" class="action" href="javascript:void(0)" @click.prevent="changePassword">Edit</a>
          </div>
        </div>

        <div class="form-labeled-value" v-if="roles.length">
          <h4>Special Role</h4>
          <rebel-pill v-for="role in roles" :key="role" :text="role" :color="STRINGS.COLOR_SUCCESS"></rebel-pill>
        </div>
      </div>

      <change-password-modal v-if="changePasswordVisible" @close="changePasswordClosed" />
      <edit-display-name-modal v-if="editDisplayNameVisible" @close="editDisplayNameClosed" :value="displayName" />
      <image-uploader-modal v-if="imageUploader.isShowing" matness-image-type="avatar" header-label="Profile"
        description-text="" placeholder-image-name="person" :image-height="200" :image-width="200" :boundary-width="300"
        :boundary-height="300" maskType="circle" :show-zoomer="true" :image-src="avatarSource"
        @image-uploader-deleted="imageUploaderDeleted" @image-uploader-saved="imageUploaderSaved"
        @close="imageUploaderClosed" />
    </div>
  </div>
</template>

<script>
import ChangePasswordModal from '@/components/user-profile/ChangePasswordModal.vue'
import EditDisplayNameModal from '@/components/user-profile/EditDisplayNameModal.vue'
import ImageUploaderModal from '@/components/ImageUploaderModal.vue'
import RebelPill from '@/components/RebelPill.vue'
import { useAuthenticationStore } from '@/stores/authentication'
import STRINGS from '@/utils/strings.json'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore(),
      STRINGS
    }
  },

  components: {
    ChangePasswordModal,
    EditDisplayNameModal,
    ImageUploaderModal,
    RebelPill,
  },

  data() {
    return {
      changePasswordVisible: false,
      editDisplayNameVisible: false,
      email: this.authStore.loggedInUser?.user?.email,
      imageUploader: {
        isShowing: false
      },
      showPlaceholderAvatar: false
    }
  },

  computed: {
    avatarSource() {
      return this.authStore.avatarSource
    },

    displayName() {
      if (this.authStore?.loggedInUser?.user?.displayName == null) {
        return ''
      }

      return this.authStore.loggedInUser.user.displayName
    },

    roles() {
      if (this.authStore.isAdmin) {
        return ['Administrator']
      }

      return []
    }
  },

  methods: {
    avatarFailedToLoad() {
      this.showPlaceholderAvatar = true
    },

    editDisplayName() {
      this.editDisplayNameVisible = true
      this.changePasswordVisible = false
    },

    editDisplayNameClosed() {
      this.editDisplayNameVisible = false
    },

    changePassword() {
      this.editDisplayNameVisible = false
      this.changePasswordVisible = true
    },

    changePasswordClosed() {
      this.changePasswordVisible = false
    },

    imageUploaderClosed() {
      this.imageUploader.isShowing = false
    },

    async imageUploaderDeleted() {
      await this.imageUploaderSaved(null)
    },

    async imageUploaderSaved(imageSrc) {
      try {
        const updateRes = await this.authStore.updateMyAvatar(imageSrc)
        if (!updateRes.success) {
          alert('Avatar upload failed')
        }
        this.showPlaceholderAvatar = false
      } catch {
        alert('Avatar upload failed')
      } finally {
        this.imageUploaderClosed()
      }
    }
  },

  mounted() {
    if (this.$refs.avatarResult) {
      this.$refs.avatarResult.addEventListener('error', this.avatarFailedToLoad)
    }
  }
}
</script>

<style scoped>
.camera {
  cursor: pointer;
  position: relative;
  right: 40px;
}

svg,
svg * {
  cursor: pointer;
}
</style>
