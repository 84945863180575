<template>
  <span id="predictions-remaining" v-if="value" class="caption">{{ value }}</span>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped>
#predictions-remaining {
  background: var(--color-danger);
  color: var(--color-white);
  border-radius: 1rem;
  padding: 0 0.5rem 1px 0.5rem;
}
</style>