<template>
  <section class="flex col gap-0-5">
    <h2>{{ capitalizeFirst(eventStatus) }}</h2>
    <p v-if="events.length == 0" class="gray3">You do not have any {{ eventStatus }} events.</p>

    <div class="flex col gap-0-75">
      <div v-for="event in events" :key="event.eventId" :data-testid="`event${event.eventId}`">
        <div class="event-card flex align-center">
          <div class="event-image-text flex align-center width-100">
            <img v-if="useEventLogos && event.logoUrl" width="120" :src="event.logoUrl" alt="Event logo" />
            <router-link v-if="isAdmin" :to="{ name: 'event-details', params: { eventId: event.eventId } }"
              class="event-text">
              <event-text :event="event" :size="2" :darkBackground="false"></event-text>
            </router-link>
            <event-text v-else :event="event" :size="2" :darkBackground="false"></event-text>
          </div>

          <div v-if="event.popularVoteTournament" class="event-actions flex">
            <div class="upcoming-actions flex row-to-col-1024 gap-0-5">
              <template v-if="!event.published">
                <rebel-button type="primary" color="default" text="Voting opens soon" disabled></rebel-button>
                <rebel-button v-if="event.infoUrl" type="ghosted" color="default" text="Event Info"
                  @click="openInNewWindow(event.infoUrl)">
                  <template v-slot:icon-leading>
                    <arrow-top-right-on-square-icon class="icon-20" />
                  </template>
                </rebel-button>
              </template>
              <template v-else-if="!event.participationEnded">
                <rebel-button type="primary" color="default" text="Vote Now" @click="voteNow(event)"></rebel-button>
              </template>
              <template v-else>
                <rebel-button type="ghosted" color="default" text="Results"
                  @click="viewPopularEventResults(event)"></rebel-button>
              </template>
            </div>
          </div>
          <div v-else class="event-actions flex">
            <div class="upcoming-actions flex row-to-col-1024 gap-0-5" v-if="eventStatus == 'upcoming'">
              <rebel-button type="primary" color="default" @click="startLeague(event)"
                text="Start a League"></rebel-button>
              <rebel-button v-if="!isMemberOfOpenTournament(event)" type="ghosted" color="default"
                @click="joinOpenTournament(event)" text="Join Open Tournament"></rebel-button>
            </div>
            <rebel-button v-else type="ghosted" color="default" text="Results"
              @click="viewEventResults(event)"></rebel-button>
          </div>
        </div>

        <template v-for="league in event.leagues" :key="league.leagueId">
          <div class="mt-0-125" v-if="isMobile">
            <router-link class="league-card flex col"
              :to="{ name: 'league-details', params: { leagueId: league.leagueId } }">
              <div class="flex space-between gap-1 width-100">
                <div class="flex align-center league-name-outer">
                  <h4 class="heading3 black mr-0-5 league-name">{{ league.name }}</h4>
                  <predictions-remaining v-if="eventStatus == 'upcoming'" :value="league.predictionsRemaining" />
                </div>
                <avatar-grouping :member-count="league.memberCount"
                  :avatar-sources="league.userAvatarsRandomlyOrdered" />
              </div>
              <template v-if="event.published">
                <div v-if="eventStatus == 'ongoing' && event.resultsPending" class="flex gap-1 league-data pt-1">
                  <league-data-item-fraction label1="won" :value1=league.points label2="possible"
                    :value2=league.pointsPossible />
                  <league-data-item :value=league.overallLeagueRank label="league rank" />
                  <league-data-item :value=league.overallEventRank label="MATNESS rank" />
                </div>
                <div v-else-if="eventStatus == 'past' || !event.resultsPending" class="flex gap-1 league-data pt-1">
                  <league-data-item :value=league.points label="points" />
                  <league-data-item :value=league.overallLeagueRank label="league rank" />
                  <league-data-item :value=league.overallEventRank label="MATNESS rank" />
                </div>
                <div v-else-if="eventStatus == 'upcoming'" class="league-data flex align-center pt-1">
                  <progress-bar :value1="Math.round(league.progressPercentage)" :value2="100" :max-value="100"
                    :width="100">
                    <span class="caption gray2">picks completed</span>
                  </progress-bar>
                </div>
              </template>
            </router-link>
          </div>

          <div class="mt-0-125" v-else>
            <router-link class="league-card flex space-between gap-1"
              :to="{ name: 'league-details', params: { leagueId: league.leagueId } }">
              <div class="flex align-center">
                <div class="flex align-center mr-1 league-name-outer">
                  <h4 class="heading3 black mr-0-5 league-name">{{ league.name }}</h4>
                  <predictions-remaining v-if="eventStatus == 'upcoming'" :value="league.predictionsRemaining" />
                </div>
                <template v-if="event.published">
                  <div v-if="eventStatus == 'ongoing' && event.resultsPending" class="flex gap-1 league-data">
                    <league-data-item-fraction label1="won" :value1=league.points label2="possible"
                      :value2=league.pointsPossible />
                    <league-data-item :value=league.overallLeagueRank label="league rank" />
                    <league-data-item :value=league.overallEventRank label="MATNESS rank" />
                  </div>
                  <div v-else-if="eventStatus == 'past' || !event.resultsPending" class="flex gap-1 league-data">
                    <league-data-item :value=league.points label="points" />
                    <league-data-item :value=league.overallLeagueRank label="league rank" />
                    <league-data-item :value=league.overallEventRank label="MATNESS rank" />
                  </div>
                  <div v-else-if="eventStatus == 'upcoming'" class="league-data flex align-center">
                    <progress-bar :value1="Math.round(league.progressPercentage)" :value2="100" :max-value="100"
                      :width="100">
                      <span class="caption gray2"> picks completed</span>
                    </progress-bar>
                  </div>
                </template>
              </div>
              <avatar-grouping :member-count="league.memberCount" :avatar-sources="league.userAvatarsRandomlyOrdered" />
            </router-link>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import APP_SETTINGS from '@/appSettings.js'
import { useAuthenticationStore } from '@/stores/authentication'
import emitEvents from '../../utils/emitEvents.js'

import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/20/solid'
import AvatarGrouping from '@/components/AvatarGrouping.vue'
import EventText from '@/components/events/EventText.vue'
import LeagueDataItem from '@/components/events/LeagueDataItem.vue'
import LeagueDataItemFraction from '@/components/events/LeagueDataItemFraction.vue'
import PredictionsRemaining from '@/components/PredictionsRemaining.vue'
import ProgressBar from '@/components/ProgressBar.vue'
import RebelButton from '@/components/RebelButton.vue'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore()
    }
  },

  components: {
    ArrowTopRightOnSquareIcon,
    AvatarGrouping,
    EventText,
    LeagueDataItem,
    LeagueDataItemFraction,
    PredictionsRemaining,
    ProgressBar,
    RebelButton
  },

  computed: {
    isAdmin() {
      return this.authStore.isAdmin
    },

    isMobile() {
      return this.windowWidth <= 768
    }
  },

  data() {
    return {
      useEventLogos: APP_SETTINGS.USE_EVENT_LOGOS,

      windowWidth: window.innerWidth
    }
  },

  mounted() {
    window.addEventListener('resize', this.onResize)
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },

  props: {
    eventStatus: {
      type: String,
      required: true,
      default: ''
    },

    events: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  emits: [emitEvents.VIEW_EVENT_RESULTS, emitEvents.VIEW_POPULAR_EVENT_RESULTS, emitEvents.VOTE_NOW, emitEvents.CREATE_LEAGUE, emitEvents.JOIN_OPEN_TOURNAMENT],

  methods: {
    openInNewWindow(url) {
      window.open(url, '_blank')
    },

    capitalizeFirst(word) {
      if (word == null || word.length === 0) {
        return ''
      }

      return word.charAt(0).toUpperCase() + word.slice(1)
    },

    isMemberOfOpenTournament(event) {
      if (event == null || event.leagues == null || event.leagues.length === 0) {
        return false
      }

      return event.leagues.some((league) => league.openTournament)
    },

    joinOpenTournament(event) {
      this.$emit(emitEvents.JOIN_OPEN_TOURNAMENT, event)
    },

    onResize() {
      this.windowWidth = window.innerWidth
    },

    startLeague(event) {
      this.$emit(emitEvents.CREATE_LEAGUE, event)
    },

    viewEventResults(event) {
      this.$emit(emitEvents.VIEW_EVENT_RESULTS, event)
    },

    viewPopularEventResults(event) {
      this.$emit(emitEvents.VIEW_POPULAR_EVENT_RESULTS, event)
    },

    voteNow(event) {
      this.$emit(emitEvents.VOTE_NOW, event)
    }
  }
}
</script>

<style scoped>
.event-card {
  background: var(--color-white);
  border-radius: 2px;
  padding: 1.25rem;
  padding-bottom: 1rem;
  gap: 1.5rem;
  flex-direction: row;
}

.event-image-text {
  gap: 2rem;
}

.event-actions {
  width: initial;
}

@media screen and (max-width: 640px) {
  .event-card {
    flex-direction: column;
    gap: 1rem;
  }

  .event-image-text {
    gap: 1.5rem;
  }

  .event-actions {
    width: 100%;
    box-sizing: border-box;
  }

  .upcoming-actions {
    width: 100%;
    box-sizing: border-box;
  }

  .event-actions>button {
    flex-grow: 1;
  }
}

@media screen and (max-width: 432px) {
  .upcoming-actions {
    flex-direction: column;
  }

  .event-image-text {
    gap: 1.25rem;
  }
}

.league-card {
  background: var(--color-white);
  border-radius: 2px;
  padding: 0.5rem 1.25rem;
}

.event-card:has(.unpublished-event) {
  border: 1px solid var(--color-danger);
}

.league-name-outer {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 1;
}

.league-name-outer>.league-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}


.league-data {
  min-width: 275px;
  max-width: 400px;
}
</style>