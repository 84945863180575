<template>
  <svg width="100%" height="100%" viewBox="0 0 41 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="37" height="3" transform="translate(2 16)" fill="#546D87" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M4 0C1.79086 0 0 1.79086 0 4V15.5C0.93192 15.5 1.71497 16.1374 1.93699 17H39.063C39.285 16.1374 40.0681 15.5 41 15.5V4C41 1.79086 39.2091 0 37 0H4Z"
      fill="url(#paint0_linear_2200_164494)" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M4 35C1.79086 35 0 33.2091 0 31V19.5C0.93192 19.5 1.71497 18.8626 1.93699 18H39.063C39.285 18.8626 40.0681 19.5 41 19.5V31C41 33.2091 39.2091 35 37 35H4Z"
      fill="url(#paint1_linear_2200_164494)" />
    <defs>
      <linearGradient id="paint0_linear_2200_164494" x1="20.5" y1="0" x2="20.5" y2="17" gradientUnits="userSpaceOnUse">
        <stop offset="0.5" stop-color="#6A89AA" />
        <stop offset="1" stop-color="#5B7591" />
      </linearGradient>
      <linearGradient id="paint1_linear_2200_164494" x1="20.5" y1="35" x2="20.5" y2="18" gradientUnits="userSpaceOnUse">
        <stop offset="0.7" stop-color="#6A89AA" />
        <stop offset="1" stop-color="#5B7591" />
      </linearGradient>
    </defs>
  </svg>
</template>