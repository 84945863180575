<template>
  <div ref="rebelTable" class="overflow-x-auto">
    <table>
      <thead>
        <tr>
          <th v-for="column in columns" :key="column.prop" :class="{ clickable: sortable }" @click="sortColumn(column)">
            <div class="small-caps-1 gray3 column-heading-content">
              {{ column.label }}
              <div class="chevrons" v-if="sortable">
                <i class="fa fa-chevron-up" :class="{ asc: column.sortDirection === 'asc' }"></i>
                <i class="fa fa-chevron-down" :class="{ desc: column.sortDirection === 'desc' }"></i>
              </div>
            </div>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in sortedTableData" :key="row[rowKey] || index">
          <td v-for="column in columns" :key="column.prop">
            <span class="body2"> {{ row[column.prop] || '' }} </span>
          </td>
          <td :id="`fly-out-link-cell${index}`" class="text-right" v-if="row.role !== 'Commissioner'">
            <a data-testid="show-options" class="fly-out-link" @click.prevent="showMoreOptions(index)"><i class="fly-out-link fa fa-ellipsis"></i></a>
          </td>
          <td class="text-right" v-else></td>
        </tr>

        <tr v-if="tableData.length === 0 && !loading">
          <td :colspan="columns.length">
            <span> No Data </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <ol v-if="rowIndexWithMoreOptionsShowing >= 0" class="fly-out body2" :style="moreOptionsStyles">
    <li>
      <a class="" @touchstart.prevent="assignLeagueCommissioner" @click.prevent="assignLeagueCommissioner"
        >Assign league commissioner</a
      >
    </li>
    <li>
      <a class="color-danger" @touchstart.prevent="removeFromLeague" @click.prevent="removeFromLeague">Remove from league</a>
    </li>
  </ol>
</template>

<script>
import emitEvents from '../utils/emitEvents'

export default {
  emits: [emitEvents.CONFIRM_ASSIGN_LEAGUE_COMMISSIONER, emitEvents.CONFIRM_REMOVE_LEAGUE_MEMBER],

  created() {
    if (this.sortable && this.columns.length) {
      this.sortColumn(this.columns[0])
    }
  },

  data() {
    return {
      areActionsShowing: false,
      rowIndexWithMoreOptionsShowing: -1
    }
  },

  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    rowKey: {
      type: String,
      default: 'id'
    },
    columns: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: true
    },
    sortable: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    columnToSort() {
      return this.columns.find((c) => c.sortDirection)
    },

    moreOptionsRow() {
      if (this.rowIndexWithMoreOptionsShowing < 0 || this.rowIndexWithMoreOptionsShowing > this.sortedTableData.length) {
        return null
      }

      return this.sortedTableData[this.rowIndexWithMoreOptionsShowing]
    },

    moreOptionsStyles() {
      const flyOutLinkElem = document.getElementById('fly-out-link-cell' + this.rowIndexWithMoreOptionsShowing)

      let offsetTop = flyOutLinkElem?.offsetTop + flyOutLinkElem?.offsetHeight

      return {
        top: offsetTop + 'px' || 0
      }
    },

    sortedTableData() {
      if (!this.columnToSort) return this.tableData

      const tableDataClone = this.tableData.map((x) => x)

      tableDataClone.sort((a, b) => {
        let aColumnData = a[this.columnToSort.prop]
        let bColumnData = b[this.columnToSort.prop]
        if (typeof aColumnData === 'string' && typeof bColumnData === 'string') {
          aColumnData = aColumnData.toLowerCase()
          bColumnData = bColumnData.toLowerCase()
        }

        if (this.columnToSort.sortDirection === 'asc') {
          return aColumnData === bColumnData ? 0 : aColumnData > bColumnData ? 1 : -1
        }

        return aColumnData === bColumnData ? 0 : bColumnData > aColumnData ? 1 : -1
      })

      return tableDataClone
    }
  },

  methods: {
    async assignLeagueCommissioner() {
      this.$emit(emitEvents.CONFIRM_ASSIGN_LEAGUE_COMMISSIONER, this.moreOptionsRow)
    },
    closeFlyout(event) {
      if (!event.target.classList.contains('fly-out-link')) {
        this.rowIndexWithMoreOptionsShowing = -1
      }
    },
    removeFromLeague() {
      this.$emit(emitEvents.CONFIRM_REMOVE_LEAGUE_MEMBER, this.moreOptionsRow)
    },

    showMoreOptions(rowIndex) {
      // hide if clicked again
      if (this.rowIndexWithMoreOptionsShowing === rowIndex) {
        this.rowIndexWithMoreOptionsShowing = -1
        return
      }

      this.rowIndexWithMoreOptionsShowing = rowIndex
    },

    sortColumn(column) {
      // reset other columns that may be sorted
      this.columns.forEach((c) => {
        if (c.label != column.label) {
          c.sortDirection = ''
        }
      })

      if (!column.sortDirection || column.sortDirection === 'desc') {
        column.sortDirection = 'asc'
      } else {
        column.sortDirection = 'desc'
      }
    }
  },

  mounted() {
    window.addEventListener('click', this.closeFlyout)
    window.addEventListener('touchstart', this.closeFlyout)
  },

  unmounted() {
    window.removeEventListener('click', this.closeFlyout)
    window.removeEventListener('touchstart', this.closeFlyout)
  }
}
</script>

<style scoped>
.column-heading-content {
  display: flex;
  align-items: center;
}

.chevrons {
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 0.5rem;
  margin-left: 0.5rem;
  color: var(--color-gray3);
}

.asc,
.desc {
  color: var(--color-action);
}

table {
  width: 100%;
  table-layout: auto;
}
table th {
  text-align: left;
}

.clickable {
  cursor: pointer;
}

table th,
table td {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 2rem;
  white-space: nowrap;
}
table th:first-child,
table td:first-child {
  padding-left: 0.5rem;
}
table th:last-child,
table td:last-child {
  padding-right: 0.5rem;
}
table thead tr {
  border-top: 1px solid var(--color-gray2);
  border-bottom: 1px solid var(--color-gray2);
}
table tbody tr:nth-child(odd) {
  background-color: var(--color-gray0-5);
}

table th:last-child,
table td:last-child {
  width: 100%;
}

.overflow-x-auto {
  overflow-x: auto;
}
</style>