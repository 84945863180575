<template>
  <div v-if="isMobileLayout" class="participant border grow flex space-between gap-0-25"
    :class="`${getUpcomingAdminClasses} ${getAllResultsClasses} ${getSelectedClasses} ${getEditableClass} ${getLightDarkClasses} ${getMobileDesktopClass}`"
    @click="participantClicked">
    <match-participant-data :actualResultState="actualResultState" :darkBackground="darkBackground"
      :hasSelector="hasSelector" :isEditable="isEditable" :isSelected="isSelected" :participantName="participantName"
      :participantRank="participantRank" :userPredictionState="userPredictionState" :userScore="userScore" />
  </div>

  <match-participant-container v-else :darkBackground="darkBackground">
    <template v-slot:content>
      <div class="participant border grow flex space-between gap-0-25"
        :class="`${getUpcomingAdminClasses} ${getAllResultsClasses} ${getSelectedClasses} ${getEditableClass} ${getLightDarkClasses}`"
        @click="participantClicked">
        <match-participant-data :actualResultState="actualResultState" :darkBackground="darkBackground"
          :hasSelector="hasSelector" :isEditable="isEditable" :isSelected="isSelected"
          :participantName="participantName" :participantRank="participantRank"
          :userPredictionState="userPredictionState" :userScore="userScore" />
      </div>
    </template>
  </match-participant-container>

</template>

<script>
import emitEvents from '@/utils/emitEvents';
import { validWhoIsEditing, validActualResultStates, validUserPredictionStates } from '@/utils/styles.js'
import MatchParticipantContainer from './MatchParticipantContainer.vue';
import MatchParticipantData from './MatchParticipantData.vue';

export default {
  components: {
    MatchParticipantContainer,
    MatchParticipantData
  },

  emits: [emitEvents.MATCHUP_CHANGED],

  props: {
    actualResultState: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return value == null || validActualResultStates.includes(value)
      }
    },

    darkBackground: {
      type: Boolean,
      default: false
    },

    hasSelector: {
      type: Boolean,
      default: false
    },

    isEditable: {
      type: Boolean,
      default: false
    },

    isMobileLayout: {
      type: Boolean,
      default: false
    },

    isSelected: {
      type: Boolean,
      default: false
    },

    participantName: {
      type: String,
      required: true,
    },

    participantRank: {
      type: String,
      required: false,
    },

    userPredictionState: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return value == null || validUserPredictionStates.includes(value)
      }
    },

    userScore: {
      type: Number,
      default: null
    },

    whoIsEditing: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return value == null || validWhoIsEditing.includes(value)
      }
    }
  },

  computed: {
    getAllClasses() {
      return [this.getAllResults, this.getLightDarkClasses, this.getPropertyClasses].join(' ')
    },

    getAllResultsClasses() {
      return [this.actualResultState, this.userPredictionState].filter(str => str != null && str !== '').join(' ')
    },

    getEditableClass() {
      return this.isEditable ? 'editable' : 'not-editable'
    },

    getLightDarkClasses() {
      return this.darkBackground ? 'dark-background' : 'light-background'
    },

    getMobileDesktopClass() {
      return this.isMobileLayout ? 'mobile' : 'desktop'
    },

    getSelectedClasses() {
      if (!this.hasSelector) return ''
      if (this.isSelected) return 'selected'
      return 'unselected'
    },

    getUpcomingAdminClasses() {
      // For upcoming events, the match participants are not editable for the admin
      if (this.whoIsEditing == 'admin' && !this.isEditable) {
        return 'upcoming-admin'
      }

      return ''
    },

    getUserDisplayScore() {
      if (this.userScore == null || this.userScore == 0) return null

      return '+' + this.userScore
    },
  },

  data() {
    return {
      isActuallySelected: this.isSelected,
      windowWidth: window.innerWidth
    }
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },

    participantClicked() {
      if (!this.isEditable) return

      this.isActuallySelected = !this.isActuallySelected
      this.$emit(emitEvents.MATCHUP_CHANGED)
    },

    unselect() {
      this.isActuallySelected = false
    }
  },

  mounted() {
    window.addEventListener('resize', this.onResize)
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },
}
</script>

<style scoped>
.editable {
  cursor: pointer;
}

/* Participant container */
.participant-container {
  display: flex;
  flex-basis: 185px;
  align-items: center;
  padding: 4px;
  border-radius: 8px;
}

.participant-container.light-background {
  background-color: var(--color-gray1-5);
}

.participant-container.dark-background {
  background: linear-gradient(90deg, rgba(249, 205, 165, 0.8) 0%, rgba(141, 189, 235, 0.8) 100%);
  box-shadow: 2px 4px 6px 0px #00000066;
  backdrop-filter: blur(8px);
}

/* Border */
.border {
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  border-radius: 6px;
}

.border.editable.unselected {
  border: none;
  padding: 6px;
}

.border.editable.selected {
  border: 2px solid var(--color-action);
}

.border.editable.selected.dark-background {
  border: 2px solid var(--color-action-dark-background);
}

.border.winner.correct {
  border: 2px solid var(--color-success);
}

.border.loser.incorrect {
  border: 2px solid var(--color-danger-dark-background);
  mix-blend-mode: multiply;
}

/* Participant */
.participant {
  border-radius: 4px;
  box-sizing: border-box;
  padding: 4px;

  display: flex;
  align-items: center;
  min-height: 1rem;
  height: 100%;
  width: 100%;
}

.participant.mobile {
  flex-basis: auto;
}

.participant.upcoming-admin,
.participant.editable.selected,
.participant.winner {
  background-color: var(--color-white);
}
</style>