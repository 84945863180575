<template>
  <div>
    <svg data-testid="toggle-button-on" @click="change" v-if="value" width="44" height="24" viewBox="0 0 44 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.181763" width="44" height="23.6364" rx="11.8182" fill="#2860F0" />
      <g filter="url(#filter0_d_1409_12591)">
        <ellipse cx="32" cy="11.9999" rx="10" ry="9.81818" fill="white" />
      </g>
      <defs>
        <filter
          id="filter0_d_1409_12591"
          x="21"
          y="1.18176"
          width="22"
          height="21.6364"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1409_12591" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1409_12591" result="shape" />
        </filter>
      </defs>
    </svg>

    <svg data-testid="toggle-button-off" @click="change" v-else width="44" height="24" viewBox="0 0 44 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.181885" width="44" height="23.6364" rx="11.8182" fill="#DFE6ED" />
      <g filter="url(#filter0_d_1409_32291)">
        <ellipse cx="12" cy="12.0001" rx="10" ry="9.81818" fill="white" />
      </g>
      <defs>
        <filter
          id="filter0_d_1409_32291"
          x="1"
          y="1.18188"
          width="22"
          height="21.6365"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1409_32291" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1409_32291" result="shape" />
        </filter>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  methods: {
    change() {
      this.$emit('toggled', !this.value)
    }
  }
}
</script>

<style scoped>
/*
TODO: https://www.w3schools.com/howto/howto_css_switch.asp
*/

svg {
  cursor: pointer;
}
</style>

