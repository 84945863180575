<template>
  <button
    ref="rebelButton"
    :type="buttonType"
    :class="`action ${type} ${color} ${getLightDarkBackground} ${getBackgroundBlurClass} nowrap`"
    :disabled="disabled || isLoading"
    :style="applyInlineStyles"
  >
    
    <div v-if="!isLoading" class="flex align-center justify-center gap-0-5 button-content">
      <slot name="icon-leading"></slot>
      <slot name="body">{{ text }}</slot>
      <slot name="icon-trailing"></slot>
    </div>  
    <span v-else class="flex justify-center align-center">
      <svg
        class="loading-icon"
        version="1.1"
        id="L9"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enable-background="new 0 0 0 0"
        xml:space="preserve"
      >
        <path :fill="fillColorForLoadingIcon" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="1s"
            from="0 50 50"
            to="360 50 50"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    buttonType: {
      type: String,
      default: null,
      validator(value) {
        return value == null || value === 'button' || value === 'submit' || value === 'reset'
      }
    },

    color: {
      type: String,
      default: 'default',
      validator(value) {
        return value == 'default' || value == 'inverse' || value == 'success' || value == 'danger'
      }
    },

    darkBackground: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    isLoading: {
      type: Boolean,
      default: false
    },

    text: {
      type: String,
      default: ''
    },

    type: {
      type: String,
      default: '',
      validator(value) {
        return value == 'primary' || value == 'primary-inverse' || value == 'ghosted' || value == 'borderless'
      }
    }
  },

  computed: {
    applyInlineStyles() {
      if (this.initialButtonWidthCSS === 'initial') return undefined // don't apply any inline styles if they're not needed because it overrides any other CSS

      return `width: ${this.initialButtonWidthCSS}`
    },

    fillColorForLoadingIcon() {
      if (this.type === 'ghosted' || this.type === 'borderless' ) {
        if (this.getLightDarkBackground === 'light-background') {
          // color-action
          return '#2860F0'
        } else {
          // color-action-dark-background
          return '#DE6800';
        }
      } else {
        // remaining types are primary and inverse-ghosted
        // color-white
        return '#FFFFFF'
      }
    },

    getBackgroundBlurClass() {
      if (this.type == 'ghosted' && this.darkBackground) {
        return 'dark-opacity-blur'
      }
      return ''
    },

    getLightDarkBackground() {
      return this.darkBackground ? 'dark-background' : 'light-background'
    }
  },

  data() {
    return {
      initialButtonWidthCSS: 'initial'
    }
  },

  watch: {
    isLoading(prev, curr) {
      if (!curr) {
        // capture the width when the current value of isLoading is false
        this.initialButtonWidthCSS = this.$refs.rebelButton.clientWidth + 'px'
      } else {
        this.initialButtonWidthCSS = 'initial'
      }
    }
  }
}
</script>

<style scoped>
button {
  --button-height: 1.2rem;

  border-radius: 4px;
  border: 1px solid var(--color-actual);
  cursor: pointer;
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.button-content {
  font-family: 'Blinker', Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: var(--button-height);
  text-wrap: nowrap;
}

button.dark-background {
  filter: drop-shadow(2px 4px 6px #00000090)
}

button:disabled {
  opacity: 50%;
  cursor: default;
}

.loading-icon {
  height: var(--button-height);
}

.default {
  --color-actual: var(--color-action);
}

.default.dark-background {
  --color-actual: var(--color-action-dark-background);
}

.danger, .danger .button-content {
  --color-actual: var(--color-danger);
}

.success, .success .button-content {
  --color-actual: var(--color-success);
}

.primary {
  border: 2px solid var(--color-actual);
  background-color: var(--color-actual);
}

.primary.dark-background {
  border: 2px solid var(--color-white);
}

.primary.dark-background:disabled {
  border: 2px solid var(--color-action-dark-background);
}

.ghosted {
  border: 2px solid var(--color-actual);
  background: transparent;
}

.ghosted.dark-background {
  border: 2px solid var(--color-white);
}

.borderless {
  border: none;
  background: transparent;
}

.primary-inverse {
  border: none;
  background: var(--color-white);
}

.primary, .primary .button-content, .ghosted.dark-background, .ghosted.dark-background .button-content, .borderless.dark-background, .borderless.dark-background .button-content{
  color: var(--color-white);
}

.ghosted, .ghosted .button-content, .borderless, .borderless .button-content {
  color: var(--color-actual);
}

.primary-inverse, .primary-inverse .button-content {
  color: var(--color-black)
}
</style>