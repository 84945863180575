<template>
  <div id="prediction-radio-button" class="flex align-center justify-center icon-24"
    :class="{ 'dark-background': darkBackground, 'editable': isEditable, 'selected': isSelected, 'correct': isCorrect, 'incorrect': isIncorrect }">
    <check-circle-icon v-if="isCorrect" class="icon-24" :class="getCorrectColor" />
    <x-circle-icon v-else-if="isIncorrect" class="icon-24" :class="getIncorrectColor" />
    <svg v-else-if="isSelected && !isEditable" width="16" height="16" viewBox="0 0 16 16" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#6A89AA" />
        <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#1B2A39" />
        <circle cx="8" cy="8" r="3" fill="white" />
      </g>
    </svg>
    <svg v-else-if="isSelected && darkBackground" width="16" height="16" viewBox="0 0 16 16" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#FC8012" />
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#DE6800" />
      <circle cx="8" cy="8" r="3" fill="white" />
    </svg>
    <svg v-else-if="isSelected && !darkBackground" width="16" height="16" viewBox="0 0 16 16" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#2860F0" />
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#1B2A39" />
      <circle cx="8" cy="8" r="3" fill="white" />
    </svg>
    <svg v-else-if="!isSelected && darkBackground" width="16" height="16" viewBox="0 0 16 16" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#6A89AA" />
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#3B5166" />
    </svg>
    <svg v-else-if="!isSelected && !darkBackground" width="16" height="16" viewBox="0 0 16 16" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#DFE6ED" />
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#3B5166" />
    </svg>
    
  </div>
</template>

<script>
import { validUserPredictionStates } from '@/utils/styles.js'
import { CheckCircleIcon } from '@heroicons/vue/20/solid';
import { XCircleIcon } from '@heroicons/vue/20/solid';

export default {
  // Selected colors (inactive - not editable)
  // --color-light-blue: #6A89AA
  // --color-dark-blue: #1B2A39

  // Selected colors (dark background):
  // --color-orange: #FC8012
  // --color-dark-orange: #DE6800

  // Selected colors (light background):
  // --color-action: #2860F0
  // --color-dark-blue: #1B2A39

  // Unselected colors (dark background): 
  // --color-light-blue: #6A89AA
  // --color-gray3: #3B5166

  // Unselected colors (light background): 
  // --color-gray1: #DFE6ED
  // --color-gray3: #3B5166

  components: {
    CheckCircleIcon,
    XCircleIcon
  },

  props: {
    darkBackground: {
      type: Boolean,
      default: false
    },

    isEditable: {
      type: Boolean,
      required: true
    },

    isSelected: {
      type: Boolean,
      default: false
    },

    userPredictionState: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return value == null || validUserPredictionStates.includes(value)
      }
    }
  },

  computed: {
    getCorrectColor() {
      return this.darkBackground ? 'color-success dark-background' : 'color-success'
    },

    getIncorrectColor() {
      return this.darkBackground ? 'incorrect' : 'color-danger'
    },

    isCorrect() {
      return this.userPredictionState == 'correct'
    },

    isIncorrect() {
      return this.userPredictionState == 'incorrect'
    }
  }
}
</script>

<style scoped>
.incorrect {
  color: var(--color-danger-dark-background);
  mix-blend-mode: multiply;
}
</style>