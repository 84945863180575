<template>
  <div v-if="!isWithinBreakpointMid" id="containerDesktop" class="button-container outer justify-center"
    :class="getContainerDesktopClasses">
    <slot v-if="!showPrimaryActionsLastOnDesktop && $slots.primaryAction1" name="primaryAction1"></slot>
    <slot v-if="!showPrimaryActionsLastOnDesktop && $slots.primaryAction2" name="primaryAction2"></slot>
    <slot v-if="$slots.secondaryAction1" name="secondaryAction1"></slot>
    <slot v-if="$slots.secondaryAction2" name="secondaryAction2"></slot>
    <slot v-if="showPrimaryActionsLastOnDesktop && $slots.primaryAction1" name="primaryAction1"></slot>
    <slot v-if="showPrimaryActionsLastOnDesktop && $slots.primaryAction2" name="primaryAction2"></slot>
  </div>
  <div v-else id="containerMobile" class="button-container" :class="getContainerMobileClasses">
    <div v-if="$slots.primaryAction1 || $slots.primaryAction2" id="innerContainerPrimary"
      class="button-container" :class="getContainerInnerClasses">
      <slot v-if="$slots.primaryAction1" name="primaryAction1"></slot>
      <slot v-if="$slots.primaryAction2" name="primaryAction2"></slot>
    </div>
    <div v-if="$slots.secondaryAction1 || $slots.secondaryAction2" id="innerContainerSecondary"
      class="button-container" :class="getContainerInnerClasses">
      <slot v-if="$slots.secondaryAction1" name="secondaryAction1"></slot>
      <slot v-if="$slots.secondaryAction2" name="secondaryAction2"></slot>
    </div>
  </div>
</template>

<script>
import { validBreakpoints } from '@/utils/styles.js' 

export default {
  props: {
    breakpointInner: {
      type: Number,
      default: null,
      validator(value) {
        return value == null || validBreakpoints.includes(value)
      }
    },

    breakpointMid: {
      type: Number,
      default: null,
      validator(value) {
        return value == null || validBreakpoints.includes(value)
      }
    },
    
    breakpointOuter: {
      type: Number,
      default: null,
      validator(value) {
        return value == null || validBreakpoints.includes(value)
      }
    },

    showPrimaryActionsLastOnDesktop: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      windowWidth: window.innerWidth
    }
  },

  computed: {
    getContainerDesktopClasses(){
      if (this.isWithinBreakpointOuter) {
        return 'width-100'
      }
      return ''
    },
    getContainerMobileClasses(){
      if (this.isWithinBreakpointMid) {
        return `width-100 ${this.getResponsiveClass(this.breakpointMid)}`
      }
      return this.getResponsiveClass(this.breakpointMid)
    },
    getContainerInnerClasses(){
      if (this.isWithinBreakpointMid) {
        return `width-100 ${this.getResponsiveClass(this.breakpointInner)}`
      }
      return this.getResponsiveClass(this.breakpointInner)
    },

    isWithinBreakpointInner() {
      return this.breakpointInner != null && this.windowWidth <= this.breakpointInner
    },
    isWithinBreakpointMid() {
      if (this.isWithinBreakpointInner) {
        return true
      } 
      return this.breakpointMid != null && this.windowWidth <= this.breakpointMid
    },
    isWithinBreakpointOuter() {
      if (this.isWithinBreakpointInner || this.isWithinBreakpointMid ) {
        return true
      }
      return this.breakpointOuter != null && this.windowWidth <= this.breakpointOuter
    }
  },

  methods: {
    getResponsiveClass(breakpoint) {
      if (breakpoint == null) {
        return ''
      }
      return `row-to-col-${breakpoint}`
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
  },

  mounted() {
    window.addEventListener('resize', this.onResize)
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },
}
</script>

<style>
.button-container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

#containerMobile {
  flex-direction: column;
}

#containerDesktop button,
#containerMobile button,
#containerDesktop select,
#containerMobile select {
  flex-grow: 1;
}

@media screen and (max-width: 432px) {
  #containerMobile button,
  #containerMobile select {
    width: 100%;
  }
}
</style>