<template>
  <image-uploader-modal matness-image-type="event-background-image" header-label="Background Image"
    description-text="We recommend choosing an image with either MATNESS color branding (orange/blue), or use a grayscale image."
    supported-file-types="image/png|image/jpg|image/jpeg" placeholder-image-name="landscape" :image-height="1440" :image-width="1440"
    :boundary-width="350" :boundary-height="350" maskType="square" :show-zoomer="true" :image-src="uploaderImageSrc"
    @image-uploader-deleted="imageUploaderDeleted" @image-uploader-saved="imageUploaderSaved"
    @close="closeImageUploader" />
</template>

<script>
import ImageUploaderModal from '../ImageUploaderModal.vue'

import emitEvents from '../../utils/emitEvents'
import eventService from '../../services/EventService'

export default {
  components: {
    ImageUploaderModal
  },

  props: {
    eventId: {
      type: Number,
      required: true
    },

    uploaderImageSrc: {
      type: String,
      required: true
    }
  },

  emits: [emitEvents.CLOSE, emitEvents.IMAGE_UPLOADER_DELETED, emitEvents.IMAGE_UPLOADER_SAVED],

  methods: {
    closeImageUploader() {
      this.$emit(emitEvents.CLOSE)
    },

    imageUploaderDeleted() {
      this.imageUploaderSaved('')
    },

    async imageUploaderSaved(imageSrc) {
      try {
        this.$emit(emitEvents.IMAGE_UPLOADER_SAVED, imageSrc)
        this.closeImageUploader()
        eventService.updateEventBackgroundImage(this.eventId, imageSrc)
      } catch {
        alert('Something went wrong saving the event image')
      }
    }
  }
}
</script>