<template>
  <rebel-button v-if="showLeagueButton" :dark-background="darkBackground" color="default" type="ghosted"
    text="My League" @click.prevent="goToLeague"></rebel-button>
</template>

<script>
import eventTypes from '../../utils/eventTypes';
import leagueService from '@/services/LeagueService.js'
import RebelButton from '@/components/RebelButton.vue'

export default {
  props: {
    darkBackground: {
      type: Boolean,
      default: false
    },

    eventData: {
      type: Object,
      required: true
    }
  },

  async created() {
    try {
      const allLeagueOptions = (await leagueService.getAllOptions(this.eventData.eventId)).data
      this.leagueOptions = allLeagueOptions
    } catch {
      console.log('LeagueShortcutButton: Could not retrieve league options')
    }
  },

  components: {
    RebelButton
  },

  computed: {
    showLeagueButton() {
      return this.leagueOptions?.length > 0
    }
  },

  data() {
    return {
      leagueOptions: []
    }
  },

  methods: {
    goToLeague() {
      if (!this.showLeagueButton) return

      if (eventTypes.isBracketTournament(this.eventData.typeId)) { 
        return this.goToLeagueBracket()
      } else if (eventTypes.isPickEm(this.eventData.typeId)) {
        return this.goToLeaguePickEm()
      }
    },

    goToLeagueBracket() {
      if (this.eventData.upcoming) {
        this.$router.push({ name: 'league-brackets-edit', params: { leagueId: this.leagueOptions[0].leagueId } })
      } else {
        this.$router.push({ name: 'league-brackets-view', params: { leagueId: this.leagueOptions[0].leagueId } })
      }
    },
    
    goToLeaguePickEm() {
      if (this.eventData.upcoming) {
        this.$router.push({ name: 'league-pick-em-edit', params: { leagueId: this.leagueOptions[0].leagueId } })
      } else {
        this.$router.push({ name: 'league-pick-em-view', params: { leagueId: this.leagueOptions[0].leagueId } })
      }
    },
  }
}
</script>