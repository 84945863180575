<template>
  <modal-dialog ref="myModal" @submit.prevent="confirm">
    <template v-slot:modal-title>Delete This League?</template>
    <template v-slot:modal-contents>
      <p>
        Are you sure you want to permanently delete the league <span class="bold">{{ leagueName }}</span
        >?
      </p>
      <p>This cannot be undone. All members will be removed and their picks will be lost.</p>
    </template>
    <template v-slot:modal-actions>
      <rebel-button
        button-type="submit"
        :is-loading="deleteInProgress"
        type="primary"
        color="danger"
        text="Delete League"
      ></rebel-button>
      <a @click.prevent="cancel">Cancel</a>
    </template>
  </modal-dialog>
</template>

<script>
import { recordLeagueDelete } from '@/services/GoogleTagManagerService.js'
import leagueService from '@/services/LeagueService.js'

import RebelButton from '@/components/RebelButton.vue'
import ModalDialog from '@/components/ModalDialog.vue'

export default {
  components: {
    RebelButton,
    ModalDialog
  },

  data() {
    return {
      deleteInProgress: false
    }
  },

  props: {
    eventCategory: {
      required: false,
      default: 'unknown'
    },

    leagueId: {
      required: true
    },

    leagueName: {
      type: String,
      required: true
    }
  },

  methods: {
    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    async confirm() {
      this.deleteInProgress = true

      try {
        await leagueService.delete(this.leagueId)
        recordLeagueDelete({ eventCategory: this.eventCategory })
        this.$router.push({ name: 'events' })
      } catch {
        alert('Something went wrong')
      } finally {
        this.deleteInProgress = false
      }
    }
  }
}
</script>

