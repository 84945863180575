<template>
  <div class="flex space-between gap-1 align-center mb-1">
    <h2>League Prediction Progress</h2>
    <rebel-button color="default" type="ghosted" @click="editMyPredictions" text="My Predictions" class="nowrap">
      <template v-slot:icon-trailing>
        <predictions-remaining :value="overallPredictionsRemaining" />
      </template>
    </rebel-button>
  </div>

  <weight-class-selector @selected-weight-class-change="setWeightClass" :weightClasses="weightClassDetails.weightClasses" :units="weightClassDetails.units"></weight-class-selector>

  <table class="mt-0-5" style="border-spacing: 0 6px; border-collapse: separate">
    <tr v-for="member in rankedLeagueMembers" :key="member.user.id">
      <td style="padding: 0.5rem; white-space: nowrap">
        <div class="flex align-center">
          <avatar-component :src="member.user.imageSrc" :height="24" :width="24" />
          <p class="body2 ml-0-25">{{ member.user.displayName }}</p>
        </div>
      </td>
      <td style="width: 200px; max-width: 200px">
        <progress-bar :value1="member.progressPercentage" :value2="100" :max-value="100" />
      </td>
    </tr>
  </table>
</template>

<script>
import defaults from '@/utils/defaults'
import eventTypes from '@/utils/eventTypes'
import { useAuthenticationStore } from '@/stores/authentication'
import AvatarComponent from '@/components/AvatarComponent.vue'
import PredictionsRemaining from '@/components/PredictionsRemaining.vue'
import ProgressBar from '@/components/ProgressBar.vue'
import RebelButton from '@/components/RebelButton.vue'
import WeightClassSelector from '@/components/WeightClassSelector.vue'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore()
    }
  },

  components: {
    AvatarComponent,
    PredictionsRemaining,
    ProgressBar,
    RebelButton,
    WeightClassSelector
  },

  props: {
    eventTypeId: {
      type: Number,
      required: true
    },

    leagueId: {
      required: true
    },

    leagueMembers: {
      type: Array,
      default: () => []
    },

    weightClassDetails: {
      type: Object,
      default: () => {
        return {
          weightClasses: defaults.WEIGHT_CLASSES,
          units: defaults.WEIGHT_CLASS_UNITS
        }
      }
    }
  },

  data() {
    return {
      leagueMembersWithOverallProgress: [...this.leagueMembers],
      selectedWeightClass: '-1'
    }
  },

  computed: {
    overallPredictionsRemaining() {
      const myLeagueMember = this.leagueMembersWithOverallProgress.find((member) => member.user.id === this.authStore.loggedInUser?.user?.id)

      return myLeagueMember?.overallPredictionsRemaining
    },

    rankedLeagueMembers() {
      let leagueMembersCopy = [...this.leagueMembersWithOverallProgress]

      // add on progress percentage so that it can be used within the template
      leagueMembersCopy.forEach((leagueMember) => {
        if (this.selectedWeightClass === '-1') {
          leagueMember.progressPercentage = Math.round(leagueMember.overallProgressPercentage)
          return
        }

        const bracket = leagueMember.brackets.find((b) => b.weightClass === this.selectedWeightClass)
        leagueMember.progressPercentage = Math.round(bracket?.progressPercentage) || 0
      })

      leagueMembersCopy = leagueMembersCopy.sort((lm1, lm2) => {
        // sort by progress percentage
        // if the same progress percentage, sort by displayName
        // if the same displayName, sort by userId
        if (lm1.progressPercentage === lm2.progressPercentage) {
          if (lm1.user.displayName.toLowerCase() === lm2.user.displayName.toLowerCase()) {
            return lm1.user.id > lm2.user.id ? '-1' : 1
          } else {
            return lm1.user.displayName.toLowerCase() > lm2.user.displayName.toLowerCase() ? 1 : '-1'
          }
        }

        return lm1.progressPercentage > lm2.progressPercentage ? '-1' : 1
      })

      return leagueMembersCopy
    }
  },

  methods: {
    editMyPredictions() {
      if (eventTypes.isPickEm(this.eventTypeId)) {
        this.$router.push({ name: 'league-pick-em-edit', params: { leagueId: this.leagueId } })
        return
      }
      
      this.$router.push({ name: 'league-brackets-edit', params: { leagueId: this.leagueId } })
    },

    setWeightClass(weightClass) {
      this.selectedWeightClass = weightClass
    }
  },

  watch: {
    leagueMembers() {
      this.leagueMembersWithOverallProgress = [...this.leagueMembers]
    }
  }
}
</script>

<style scoped>
.action {
  color: var(--color-action);
}

td * {
  vertical-align: middle;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  vertical-align: middle;
}
</style>