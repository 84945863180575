<template>
  <modal-dialog ref="myModal" @submit.prevent="tryEditBracket">
    <template v-if="reSeedWeightClassModal.isShowing" v-slot:modal-title>Re-Seed This Weight Class?</template>
    <template v-else v-slot:modal-title>Edit Competitors</template>

    <template v-if="reSeedWeightClassModal.isShowing" v-slot:modal-contents>
      <p>
        Are you sure you want to re-seed the <span class="bold">{{ weightClass }} weight class</span> for the event
        <span class="bold">{{ eventName }}</span>?
      </p>
      <p>
        This cannot be undone. All members' bracket selections for this weight class will be cleared. All users will
        receive
        a notification email to make new selections.
      </p>
      <p>
        If you are only making small edits, you may save without re-seeding. These changes will be published immediately,
        and users will <span class="bold">not</span> be notified.
      </p>
    </template>
    <template v-else v-slot:modal-contents>
      <div class="form-labeled-value">
        <h4>Event</h4>
        <p>{{ eventName }}</p>
      </div>

      <div class="form-labeled-value">
        <h4>Event Type</h4>
        <p data-testid="event-type">{{ eventTypeLabel }}</p>
      </div>

      <div class="form-labeled-value">
        <h4>{{ eventDateTimeLabel }}</h4>
        <p>{{ eventStartDateTime }}</p>
      </div>

      <div class="form-labeled-value">
        <h4>Weight Class</h4>
        <p>{{ weightClass }}</p>
      </div>

      <div class="form-input">
        <label for="round-1-competitors">List of Round 1 Competitors</label>
        <textarea id="round-1-competitors" rows="12"
          placeholder="(1) First Last Name (SCHOOL) #-#&#10;(33) First Last Name (SCHOOL) #-#&#10;(17) First Last Name (SCHOOL) #-#&#10;(16) First Last Name (SCHOOL) #-#&#10;(9) First Last Name (SCHOOL) #-#&#10;(24) First Last Name (SCHOOL) #-#&#10;etc."
          v-model="round1CompetitorsString"></textarea>
        <p class="caption gray2">
          Copy and paste the list of Round 1 names in the order that they appear in the NCAA bracket. Each wrestler
          should be
          listed on a separate line.
        </p>
        <p class="caption gray2">
          {{ playInAndByeHelperMessage }}
        </p>
      </div>
      <div class="form-input">
        <p class="heading4"># Round 1 Competitors: {{ round1CompetitorsCount + totalPlayInWinnersCount }}</p>
        <p class="caption color-danger" v-show="showPowerOf2ErrorMessage">
          {{ powerOf2ErrorMessage }}
        </p>
        <p class="caption color-danger" v-show="showOneAsteriskPerPlayInMatch">
          There must be one asterisk for each play-in match winner.
        </p>
        <p class="caption color-danger" v-show="showPlayInCompetitorsCannotMatchUpError">
          Play-in winners ({{ PLAY_IN_WINNER_PLACEHOLDER }}) cannot play against each other in Round 1. The app does not
          yet support this.
        </p>
      </div>

      <div class="form-input" v-if="usePigTail && showPlayInSection">
        <label for="play-in-competitors">List of Play-In Competitors</label>

        <textarea id="play-in-competitors" rows="2"
          placeholder="(33) First Last Name (SCHOOL) #-#&#10;(32) First Last Name (SCHOOL) #-#"
          v-model="playInCompetitorsString"></textarea>
        <p class="caption gray2">
          Copy and paste the list of Play-In names in the order that they appear in the NCAA bracket. Each wrestler
          should be
          listed on a separate line.
        </p>
      </div>
      <div class="form-input" v-if="usePigTail && showPlayInSection">
        <p class="heading4"># Play-In Competitors: {{ playInCompetitorsCount }}</p>
        <p class="caption color-danger" v-show="showPlayInEvenCompetitorCountError">
          Play-in round must have an even number of competitors.
        </p>
      </div>

      <div class="form-input" v-if="showIncumbentSection">
        <label for="incumbent-competitor">Incumbent Competitor</label>
        <input id="incumbent-competitor" type="text" placeholder="(1) Jordan Burroughs"
          v-model="incumbentCompetitorString">
      </div>

      <div class="form-input" v-if="usePigTail && !showPlayInSection">
        <a class="action" @click.prevent="showPlayInSection = true">+ Add Play-In Competitors</a>
      </div>

      <div class="form-input" v-if="!showIncumbentSection">
        <a class="action" @click.prevent="showIncumbentSection = true">+ Add Incumbent Competitor</a>
      </div>
    </template>

    <template v-if="reSeedWeightClassModal.isShowing" v-slot:modal-actions>
      <rebel-button type="primary" color="danger" text="Re-Seed Weight Class" :is-loading="isSavingCompetitors"
        @click="confirmReSeed"></rebel-button>
      <rebel-button type="ghosted" color="default" text="Save without re-seeding" :is-loading="isSavingCompetitors"
        @click="confirmSaveWithoutReSeed"></rebel-button>
      <a @click.prevent="cancelReSeed">Cancel</a>
      <p class="color-danger" v-if="errorMessages.length">
        {{ errorMessages }}
      </p>
    </template>
    <template v-else v-slot:modal-actions>
      <rebel-button button-type="submit" :disabled="!dataIsDirty || !isValidBracket" type="primary" color="default"
        text="Edit Bracket" :is-loading="isSavingCompetitors"></rebel-button>
      <a href="javascript:void(null)" @click.prevent="cancel">Cancel</a>
      <p class="color-danger" v-if="errorMessages.length">
        {{ errorMessages }}
      </p>
    </template>
  </modal-dialog>
</template>

<script>
import APP_SETTINGS from '@/appSettings.js'
import emitEvents from '@/utils/emitEvents'
import eventBracketService from '@/services/EventBracketService.js'

import ModalDialog from '@/components/ModalDialog.vue'
import RebelButton from '@/components/RebelButton.vue'

import eventTypes from '@/utils/eventTypes'

export default {
  setup() {
    return {
      eventTypes,
    }
  },

  components: {
    ModalDialog,
    RebelButton
  },

  computed: {
    dataIsDirty() {
      const sanitizedPlayInCompetitorsOriginal = this.makeCompetitorArray(this.initialPlayInCompetitorsString).join('')
      const sanitizedPlayInCompetitorsEdited = this.makeCompetitorArray(this.playInCompetitorsString).join('')

      const sanitizedRound1CompetitorsOriginal = this.makeCompetitorArray(this.initialRound1CompetitorsString).join('')
      const sanitizedRound1CompetitorsEdited = this.makeCompetitorArray(this.round1CompetitorsString).join('')

      return (
        sanitizedPlayInCompetitorsOriginal != sanitizedPlayInCompetitorsEdited ||
        sanitizedRound1CompetitorsOriginal != sanitizedRound1CompetitorsEdited ||
        this.incumbentCompetitor != this.incumbentCompetitorString
      )
    },

    eventTypeLabel() {
      return eventTypes.getEventTypeLabel(this.eventTypeId)
    },

    eventDateTimeLabel() {
      if (this.isPopularVoteTournament) return 'Voting End Date and Time'

      return 'Start Date and Time'
    },

    isPopularVoteTournament() {
      return eventTypes.isPopularVoteTournament(this.eventTypeId)
    },

    playInAndByeHelperMessage() {
      if (this.usePigTail) {
        return 'Use an asterisk (' + this.PLAY_IN_WINNER_PLACEHOLDER + ') to indicate the position of each play-in match winner. Use BYE in place of an opponent\'s name when a competitor has a bye.'
      }

      return 'Use BYE in place of an opponent\'s name when a competitor has a bye.'
    },

    showPlayInEvenCompetitorCountError() {
      return this.playInCompetitorsCount % 2 !== 0
    },

    showPowerOf2ErrorMessage() {
      const totalRound1Competitors = this.round1CompetitorsCount + this.totalPlayInWinnersCount
      if (totalRound1Competitors === 1) return true

      return !this.isPowerOfTwo(totalRound1Competitors)
    },

    powerOf2ErrorMessage() {
      if (this.usePigTail) {
        return 'The total number of Round 1 competitors (plus BYEs and Play-in winners) must be an exact power of 2.'
      }

      return 'The total number of Round 1 competitors (plus BYEs) must be an exact power of 2.'
    },

    showOneAsteriskPerPlayInMatch() {
      if (!this.usePigTail) return false

      return this.totalPlayInWinnersCount !== this.playInCompetitorsCount / 2
    },

    showPlayInCompetitorsCannotMatchUpError() {
      if (!this.usePigTail) return false

      const arr = this.makeCompetitorArray(this.round1CompetitorsString)

      for (let i = 1; i < arr.length; i += 2) {
        if (arr[i - 1] === this.PLAY_IN_WINNER_PLACEHOLDER && arr[i] === this.PLAY_IN_WINNER_PLACEHOLDER) {
          return true
        }
      }

      return false
    },

    playInCompetitorsCount() {
      return this.makeCompetitorArray(this.playInCompetitorsString).length
    },

    hasRound1MatchupWithBothCompetitorsFromPlayIn() {
      if (!this.usePigTail) return false

      const arr = this.makeCompetitorArray(this.round1CompetitorsString)

      for (let i = 1; i < arr.length; i += 2) {
        if (arr[i - 1] === this.PLAY_IN_WINNER_PLACEHOLDER && arr[i] === this.PLAY_IN_WINNER_PLACEHOLDER) {
          return true
        }
      }

      return false
    },

    round1CompetitorsCount() {
      return this.makeCompetitorArray(this.round1CompetitorsString).filter(
        (competitor) => !this.usePigTail || competitor !== this.PLAY_IN_WINNER_PLACEHOLDER
      ).length
    },

    totalCompetitorsCount() {
      return this.playInCompetitorsCount + this.round1CompetitorsCount
    },

    totalPlayInWinnersCount() {
      return this.makeCompetitorArray(this.round1CompetitorsString).filter(
        (competitor) => this.usePigTail && competitor === this.PLAY_IN_WINNER_PLACEHOLDER
      ).length
    },

    isValidBracket() {
      const errorsArray = [
        this.showPlayInEvenCompetitorCountError,
        this.showPowerOf2ErrorMessage,
        this.showOneAsteriskPerPlayInMatch,
        this.showPlayInCompetitorsCannotMatchUpError
      ]

      return errorsArray.every(isErrorShowing => isErrorShowing === false)
    }
  },

  props: {
    bracketId: {
      required: true
    },

    eventId: {
      required: true
    },

    eventTypeId: {
      required: true
    },

    eventName: {
      type: String,
      required: true
    },

    eventStartDateTime: {
      type: String,
      required: true
    },

    eventIsPublished: {
      type: Boolean,
      default: false,
      required: true
    },

    incumbentCompetitor: {
      type: String,
      default: ''
    },

    playInCompetitors: {
      type: String,
      required: true
    },

    round1Competitors: {
      type: String,
      required: true
    },

    weightClass: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      errorMessages: '',

      PLAY_IN_WINNER_PLACEHOLDER: '*',

      initialPlayInCompetitorsString: this.playInCompetitors,
      initialRound1CompetitorsString: this.getInitialRound1Competitors(),

      isSavingCompetitors: false,
      incumbentCompetitorString: this.incumbentCompetitor,
      playInCompetitorsString: this.playInCompetitors,
      round1CompetitorsString: this.getInitialRound1Competitors(),

      reSeedWeightClassModal: {
        isShowing: false
      },

      usePigTail: APP_SETTINGS.USE_PIG_TAIL,

      showPlayInSection: this.playInCompetitors != null && this.playInCompetitors.length,
      showIncumbentSection: this.incumbentCompetitor != null && this.incumbentCompetitor.length,
    }
  },

  emits: [emitEvents.EDIT_COMPETITORS],

  methods: {
    cancel() {
      this.errorMessages = ''
      this.closeModal()
    },

    closeModal() {
      this.errorMessages = ''
      this.$refs.myModal.close()
    },

    cancelReSeed() {
      this.errorMessages = ''
      this.reSeedWeightClassModal.isShowing = false
    },

    confirmReSeed() {
      this.editBracket(false)
    },

    confirmSaveWithoutReSeed() {
      this.editBracket(true)
    },

    getInitialRound1Competitors() {
      return this.round1Competitors
        .split('\n')
        .map((competitor) => (competitor == null || competitor.trim() == '' ? this.PLAY_IN_WINNER_PLACEHOLDER : competitor))
        .join('\n')
    },

    tryEditBracket() {
      if (this.eventIsPublished) {
        if (!this.isValidBracket) return
        this.reSeedWeightClassModal.isShowing = true
        return
      }

      this.editBracket(false)
    },

    async editBracket(forceUpdateWithoutReSeed) {
      this.errorMessages = ''

      if (!this.isValidBracket) return

      const playInCompetitors = this.makeCompetitorArray(this.playInCompetitorsString)
      const round1Competitors = this.makeCompetitorArray(this.round1CompetitorsString)
      const participantCount = this.totalCompetitorsCount

      this.isSavingCompetitors = true
      try {
        const dto = {
          bracketId: this.bracketId,
          eventId: this.eventId,
          weightClass: Number(this.weightClass.split(' ')[0]),
          participantCount: participantCount,
          playInCompetitors,
          round1Competitors: this.replaceWithPigEmoji(round1Competitors),
          incumbentCompetitor: this.incumbentCompetitorString ? this.incumbentCompetitorString : null,
          updateWithoutReSeed: forceUpdateWithoutReSeed
        }

        await eventBracketService.update(this.eventId, this.bracketId, dto)
        this.$emit(emitEvents.EDIT_COMPETITORS, dto.weightClass)
      } catch (err) {
        alert('Something went wrong')
        this.errorMessages = err?.response?.data?.message
      } finally {
        this.isSavingCompetitors = false
      }
    },

    isPowerOfTwo(x) {
      return Math.log2(x) % 1 === 0
    },

    makeCompetitorArray(competitors) {
      return competitors
        .split('\n')
        .map((line) => line.trim())
        .filter((line) => line.length > 0)
    },

    replaceWithPigEmoji(arr) {
      return arr.map((elem) => {
        if (typeof elem === 'string' && elem.toLowerCase() === 'pig') {
          return '🐷 PIG'
        }
        return elem
      })
    }
  }
}
</script>

<style scoped>
textarea,
input[type="text"] {
  font-size: .83rem;
}
</style>