import axios from 'axios'

export default {
  getAllOptions(eventId) {
    return axios.get(`/events/${eventId}/leagues/options`)
  },

  getById(leagueId) {
    // return axios.get(`/leagues/${leagueId}`)

    // TODO: LeagueDetailsDto performance upgrade. 
    // Change downstream consumers of this method to work with LeagueDetailsDto model 
    // instead of original League model.
    
    return axios.get(`/leagues/${leagueId}`).then(res => {
      const league = res.data

      for (const member of league.members) {
        for (const memberBracket of member.brackets) {
          memberBracket.rounds = league.bracketMapToRounds[memberBracket.bracketId]
        }
      }

      delete league.bracketMapToRounds

      res.data = league
      return res
    })
  },


  getMyBrackets(leagueId) {
    return axios.get(`/leagues/${leagueId}/brackets`)
  },

  getBracketsForUser(leagueId, userId) {
    return axios.get(`/leagues/${leagueId}/brackets/${userId}`)
  },

  create(league) {
    return axios.post(`/leagues`, league)
  },

  update(league) {
    return axios.put(`/leagues/${league.leagueId}`, { name: league.name })
  },

  patchLeagueCommissioner(leagueId, newCommissionerUserId) {
    const dto = { 
      leagueId, 
      userId: newCommissionerUserId
    }

    return axios.patch(`/leagues/${leagueId}/commissioner`, dto)
  },

  getInviteLink(leagueId) {
    return axios.get(`/leagues/${leagueId}/invite-link`)
  },

  deleteInviteLink(leagueId) {
    return axios.delete(`/leagues/${leagueId}/invite-link`)
  },

  getAcceptInviteMetadata(leagueId, inviteToken) {
    return axios.get(`/leagues/${leagueId}/invite-meta-data/${inviteToken}`)
  },
  
  acceptInvite(leagueId, inviteToken) {
    return axios.post(`/leagues/${leagueId}/member`, { inviteToken })
  },

  removeMember(leagueId, userId, removedByCommissioner = false) {
    return axios.delete(`/leagues/${leagueId}/users/${userId}?removedByCommissioner=${removedByCommissioner}`)
  },
  
  delete(leagueId) {
    return axios.delete(`/leagues/${leagueId}`)
  },

  joinOpenTournamentForEvent(eventId) {
    return axios.post(`/events/${eventId}/leagues/open-tournament/member`)
  },

  getMyOpenTournamentLeagueIdForEvent(eventId) {
    return axios.get(`/events/${eventId}/leagues/open-tournament/id`)
  },

  copyPicks(dto) {
    return axios.post(`/leagues/${dto.toLeagueId}/brackets/copy`, dto)
  }
}
