<template>
  <div class="team-score-component flex justify-center align-center gap-0-5"
    :class="`${getBackgroundBlurClass} ${getLightDarkBackground}`">

    <img v-if="teamImageUrl" :src="teamImageUrl" alt="Team Image" width="104" height="104">

    <div class="flex col justify-center align-center gap-0-25">
      <template v-if="eventIsUpcoming">
        <h2 class="heading2 text-center">{{ teamName }}</h2>
        <div v-if="predictedPoints != null && eventHasTeamPoints" class="flex col justify-center align-center">
          <div class="large-points"> {{ predictedPoints }}</div>
          <div class="caption">points predicted</div>
        </div>
      </template>
      <template v-else>
        <h2 class="heading2 text-center">{{ teamName }}</h2>
        <div v-if="actualPoints != null && eventHasTeamPoints" class="flex col justify-center align-center">
          <div class="large-points"> {{ actualPoints }}</div>
          <div class="caption">points</div>
        </div>
        <h4 v-if="predictedPoints != null && eventHasTeamPoints" class="heading4 text-center">
          {{ predictedPoints }} points predicted
        </h4>
      </template>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    actualPoints: {
      type: Number,
      required: false
    },

    predictedPoints: {
      type: Number,
      required: false
    },

    darkBackground: {
      type: Boolean,
      required: false,
      default: false
    },

    eventHasTeamPoints: {
      type: Boolean,
      default: true
    },

    eventIsUpcoming: {
      type: Boolean,
      default: false
    },

    teamImageUrl: {
      type: String,
      required: false
    },

    teamName: {
      type: String,
      required: true
    },
  },

  computed: {
    getBackgroundBlurClass() {
      return this.darkBackground ? 'dark-opacity-blur' : ''
    },

    getLightDarkBackground() {
      return this.darkBackground ? 'dark-background' : 'light-background'
    }
  }
}
</script>

<style scoped>
.team-score-component {
  border-radius: 8px;
  padding: .5rem 1rem .75rem 1rem;
  flex-basis: 268px;
}

.team-score-component.light-background {
  background-color: var(--color-white);
  box-shadow: 2px 4px 6px 0px #00000066;
}

.team-score-component.light-background * {
  color: var(--color-gray4)
}

.team-score-component.dark-background * {
  color: var(--color-white);
}

.large-points {
  font-size: 3rem;
  font-weight: 700;
  line-height: 2.75rem;
}

@media screen and (max-width: 950px) {
  .team-score-component {
    flex-direction: column;
  }
}
</style>