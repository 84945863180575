<template>
  <router-link class="action flex align-center gap-0-125" :class="getLightDarkBackground" :to="routerLinkTo">
    <arrow-left-icon class="icon-20" />
    <div>Back to {{ pageName }}</div>
  </router-link>
</template>

<script>
import { ArrowLeftIcon } from '@heroicons/vue/20/solid';

export default {
  components: {
    ArrowLeftIcon
  },

  props: {
    darkBackground: {
      type: Boolean,
      default: false
    },

    routerLinkTo: {
      type: Object || String,
      required: true
    },

    pageName: {
      type: String,
      default: ''
    }
  },

  computed: {
    getLightDarkBackground() {
      return this.darkBackground ? 'dark-background' : 'light-background'
    }
  }
}
</script>

<style scoped>
a.dark-background {
  color: var(--color-white);
  filter: drop-shadow(2px 4px 6px #00000090);
}
</style>