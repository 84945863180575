import axios from 'axios'

const customHeader = { headers: { 'Content-Type': 'multipart/form-data ' } }

export default {
    // async delete(fileType, url) {
    //     return await axios.delete(`/files/${fileType}`, url)
    // },

    async upload(formData, fileType) {
        return await axios.post(`/files/${fileType}`, formData, customHeader)
    },
}