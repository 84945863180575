export default {

    CAPTURE_ANALYTICS: resolveSettingValueOrDefault(import.meta.env.VITE_CAPTURE_ANALYTICS),

    USE_EVENT_LOGOS: resolveSettingValueOrDefault(import.meta.env.VITE_EVENT_LOGOS),

    USE_PIG_TAIL: resolveSettingValueOrDefault(import.meta.env.VITE_PIG_TAIL),

}

function resolveSettingValueOrDefault(setting, defaultValue = false) {
    if (setting != null && typeof setting == 'string') {
        return setting.toLowerCase() === 'on' || setting.toLowerCase() === 'true'
    }

    return defaultValue
}