<template>
  <div id="register-component" class="layout-form-page">
    <error-summary ref="errorSummary" />

    <h1>Sign Up</h1>

    <form class="layout-form-list" @submit.prevent="submitRegistrationForm">
      <div class="form-input">
        <label for="email">Email</label>
        <input
          autofocus
          autocomplete="email"
          id="email"
          type="email"
          placeholder="Email"
          v-model="newUser.email"
          :required="dataConstraints.user.email.required"
          :maxlength="dataConstraints.user.email.maxlength"
          :minlength="dataConstraints.user.email.minlength"
        />
      </div>

      <div class="form-input">
        <label for="display-name">Display Name</label>
        <input
          id="display-name"
          autocomplete="name"
          type="text"
          placeholder="Display Name"
          v-model="newUser.displayName"
          :required="dataConstraints.user.displayName.required"
          :maxlength="dataConstraints.user.displayName.maxlength"
          :minlength="dataConstraints.user.displayName.minlength"
        />
      </div>

      <div class="form-input">
        <label for="password">Password</label>
        <input
          id="password"
          :type="showingPassword ? 'text' : 'password'"
          placeholder="Password"
          v-model="newUser.password"
          :required="dataConstraints.user.password.required"
          :maxlength="dataConstraints.user.password.maxlength"
          :minlength="dataConstraints.user.password.minlength"
        />
        <i class="fa fa-eye-slash" v-show="!showingPassword" @click="showingPassword = true"></i>
        <i class="fa fa-eye" v-show="showingPassword" @click="showingPassword = false"></i>
      </div>

      <div class="form-input">
        <label for="confirm-password">Confirm Password</label>
        <input
          id="confirm-password"
          :type="showingPassword ? 'text' : 'password'"
          placeholder="Confirm Password"
          v-model="newUser.confirmPassword"
          :required="dataConstraints.user.password.required"
          :maxlength="dataConstraints.user.password.maxlength"
          :minlength="dataConstraints.user.password.minlength"
        />
        <i class="fa fa-eye-slash" v-show="!showingPassword" @click="showingPassword = true"></i>
        <i class="fa fa-eye" v-show="showingPassword" @click="showingPassword = false"></i>
      </div>

      <div>
        <span class="caption gray2-5">By signing up, you agree to our </span>
        <a class="caption color-action semi-bold" href="/terms-of-service">Terms of Service</a>
        <span class="caption gray2-5"> and </span>
        <a class="caption color-action semi-bold" href="/privacy-policy">Privacy Policy</a>
        <span class="caption gray2-5">.</span>
      </div>

      <div class="mt-1">
        <rebel-button
          button-type="submit"
          @click="resetErrors"
          type="primary"
          color="default"
          :is-loading="submitInProgress"
          text="Sign Up"
        ></rebel-button>
      </div>
    </form>

    <div class="separator"></div>

    <div class="actions-list">
      <p>Already have an account?</p>
      <router-link :to="{ name: 'login' }">Log In</router-link>
    </div>

    <verify-email-modal v-if="showingVerifyEmailModal" :email="newUser.email" />
  </div>
</template>

<script>
import dataConstraints from '@/utils/dataConstraints.js'

import ErrorSummary from '@/components/ErrorSummary.vue'
import RebelButton from '@/components/RebelButton.vue'
import VerifyEmailModal from '@/components/VerifyEmailModal.vue'

import { useAuthenticationStore } from '@/stores/authentication'
import { recordSignUp } from '@/services/GoogleTagManagerService.js'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore(),
      dataConstraints
    }
  },

  components: {
    ErrorSummary,
    RebelButton,
    VerifyEmailModal
  },

  data() {
    return {
      newUser: {
        email: '',
        displayName: '',
        password: '',
        confirmPassword: '',
        inviteToken: null,
        redirect: null
      },
      showingPassword: false,
      showingVerifyEmailModal: false,
      submitInProgress: false
    }
  },

  methods: {
    isValidForm() {
      if (this.newUser.password !== this.newUser.confirmPassword) {
        this.$refs.errorSummary.setErrors('Passwords do not match')
        return false
      }

      return true
    },

    resetErrors() {
      this.$refs.errorSummary.clearErrors()
    },

    async submitRegistrationForm() {
      this.resetErrors()

      if (!this.isValidForm()) {
        return
      }

      this.submitInProgress = true

      // extract invite token from query string or from within redirect query string param
      if (this.$route.query.inviteToken) {
        this.newUser.inviteToken = this.$route.query.inviteToken
      } else if (this.$route.query.redirect) {
        if (this.$route.query.redirect.includes('inviteToken=')) {
          this.newUser.redirect = this.$route.query.redirect
        }
      }

      const registrationResponse = await this.authStore.register(this.newUser)

      this.submitInProgress = false

      if (!registrationResponse.success) {
        if (registrationResponse.error?.response?.status == 400 && registrationResponse.error.response?.data?.message) {
          this.$refs.errorSummary.setErrors(registrationResponse.error.response.data.message)
          return
        }

        this.$refs.errorSummary.setErrors('Something went wrong. Please try again in a few minutes.')

        return
      }

      recordSignUp({
        method: 'form'
      })

      this.showingVerifyEmailModal = true
    }
  }
}
</script>

