<template>
  <div class="participant-text flex gap-0-25 align-center"
    :class="`${getTypographyClass} ${getAllResultsClasses} ${getSelectedClasses} ${getEditableClass}`">
    <span class="ranking caption" v-if="participantRank != null">{{ participantRank }}</span>
    {{ participantName }}
  </div>
  <div v-if="hasSelector" class="flex align-center gap-0-125">
    <span class="bonus heading4" v-if="getUserDisplayScore != null">
      {{ getUserDisplayScore }}
    </span>
    <prediction-radio-button v-if="hasSelector" class="radio-selector" :dark-background="darkBackground"
      :is-editable="isEditable" :is-selected="isSelected" :userPredictionState="userPredictionState" />
  </div>
</template>

<script>
import { validActualResultStates, validUserPredictionStates } from '@/utils/styles.js'
import PredictionRadioButton from '@/components/icons/PredictionRadioButton.vue';

export default {
  components: {
    PredictionRadioButton
  },

  props: {
    actualResultState: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return value == null || validActualResultStates.includes(value)
      }
    },

    darkBackground: {
      type: Boolean,
      default: false
    },

    hasSelector: {
      type: Boolean,
      default: false
    },

    isEditable: {
      type: Boolean,
      default: false
    },

    isSelected: {
      type: Boolean,
      default: false
    },

    participantName: {
      type: String,
      required: true,
    },

    participantRank: {
      type: String,
      required: false,
    },

    userPredictionState: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return value == null || validUserPredictionStates.includes(value)
      }
    },

    userScore: {
      type: Number,
      default: null
    }
  },

  computed: {
    getAllResultsClasses() {      
      return [this.actualResultState, this.userPredictionState].filter(str => str != null && str !== '').join(' ')
    },

    getEditableClass() {
      return this.isEditable ? 'editable' : 'not-editable'
    },

    getSelectedClasses() {
      if (!this.hasSelector) return ''
      if (this.isSelected) return 'selected'
      return 'unselected'
    },

    getTypographyClass() {
      return this.windowWidth <= 512 ? 'caption' : 'body2'
    },

    getUserDisplayScore() {
      if (this.userScore == null || this.userScore == 0) return null

      return '+' + this.userScore
    },
  },

  data() {
    return {
      windowWidth: window.innerWidth
    }
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    }
  },

  mounted() {
    window.addEventListener('resize', this.onResize)
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },
}
</script>

<style scoped>
.bonus {
  color: var(--color-success);
}

.ranking {
  color: var(--color-gray4);
}

/* Participant text */
.participant-text.dark-background {
  color: var(--color-dark-blue);
}

.participant-text.editable.selected {
  color: var(--color-black);
}

.participant-text.actual-result-tbd.selected.not-editable,
.participant-text.winner.not-editable {
  color: var(--color-black);
  font-weight: 600;
}

.participant-text.loser,
.participant-text.loser .ranking {
  color: var(--color-gray3);
}

.participant-text.loser.incorrect {
  text-decoration: line-through;
}
</style>