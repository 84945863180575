<template>
  <div class="flex align-center justify-end">
    <a class="flex align-center fly-out-link" :data-testid="iconId" @click.prevent="showFlyout = !showFlyout">
      <slot name="icon"></slot>
    </a>
    <ol v-show="showFlyout" class="fly-out body2">
      <slot name="list-items">
      </slot>
    </ol>
  </div>
</template>

<script>
export default {
  props: {
    iconId: {
      type: String,
      required: false,
      default: undefined
    }
  },

  data() {
    return {
      showFlyout: false
    }
  },

  methods: {
    windowClick(event) {
      const clickedOnIcon = event.target.classList.contains('fly-out-link') || event.target.closest('a.fly-out-link') != null

      if (!clickedOnIcon) {
        this.showFlyout = false
      }
    },
  },

  mounted() {
    window.addEventListener('click', this.windowClick)
  },

  unmounted() {
    try {
      window.removeEventListener('click', this.windowClick)
    } catch {
      //
    }
  }

}
</script>

<style>
.fly-out-link {
  cursor: pointer;
}

/* find the parent element of the fly-out and position it relative so that the absolute position of the fly-out can be relative to it */
*:has(.fly-out) { 
  position: relative;
}

.fly-out{
  border-radius: 4px;
  min-width: 14rem;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray0-5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  right: 0rem;
  top: 1.75rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  z-index: 100;
}
.fly-out li {
  width: 100%;
}

.fly-out a, .fly-out a:visited{
  color: var(--color-black);
  padding: 0.25rem 1rem;
  font-size: 1rem;
  box-sizing: border-box;
  margin: initial;
  display: inline-block;
  width: 100%;
}

.fly-out a.color-danger,
.fly-out a.color-danger:visited {
  color: var(--color-danger);
}
</style>