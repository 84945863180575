<template>
  <div ref="bars" class="flex row align-center gap-0-5">
    <div class="value2-bar radius" :style="`width: ${value2Width}px;`">
      <div class="value1-bar radius" :style="`width: ${value1Width}px; z-index: 2;`"></div>
    </div>
    <div class="flex row align-baseline gap-0-25">
      <span class="heading3 color-action">{{percent}}%</span>
      <slot></slot>
    </div>
  </div>
</template>

<script>
const DEFAULT_CONTAINER_WIDTH = 84
const TEXT_SIZE_ADJUSTMENT = 75 // space reserved for text after progress bar

export default {
  props: {
    value1: {
      type: Number,
      required: true
    },
    value2: {
      type: Number,
      required: false
    },
    maxValue: {
      type: Number,
      required: true
    },
    width: {
      type: Number
    }
  },

  data() {
    return {
      containerWidth: this.width != null ? this.width : DEFAULT_CONTAINER_WIDTH
    }
  },

  mounted() {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },

  computed: {
    value1Width() {
      if (this.value1 === 0) return 0

      return this.containerWidth * (this.value1 / this.maxValue)
    },
    value2Width() {
      if (this.value2 === 0) return 0

      return this.containerWidth
    },
    percent() {
      return Math.round(this.value1 / this.value2 * 100)
    }
  },

  methods: {
    onResize() {
      if (this.width != null) {
        this.containerWidth = this.width
      } else {
        this.containerWidth = this.$refs.bars
          ? this.$refs.bars.parentElement.clientWidth - TEXT_SIZE_ADJUSTMENT
          : DEFAULT_CONTAINER_WIDTH
      }
    }
  }
}
</script>

<style scoped>
.value1-bar {
  background-color: var(--color-action);
  position: relative;
  height: 0.375rem;
}

.value2-bar {
  background-color: var(--color-gray1);
  height: 0.375rem;
}

.radius {
  border-radius: 0.375rem;
}
</style>    