<template>
  <div class="flex">
    <p class="logo-title" id="logo-title-wrestle">Wrestle</p>
    <p class="logo-title" id="logo-title-matness">MATNESS</p>
  </div>
</template>

<style scoped>
.logo-title {
  font-size: 26px;
  color: var(--color-white);
}

#logo-title-wrestle {
  font-weight: 700;
  letter-spacing: 0.78px;
  padding-right: 1px;
}

#logo-title-matness {
  font-weight: 300;
  letter-spacing: -0.26px;
}
</style>