<template>
  <rebel-pill v-if="authStore.isAdmin" :color="myTextAndColor.color" :text="myTextAndColor.text" :dark-background="darkBackground"/>
</template>

<script>
import { useAuthenticationStore } from '@/stores/authentication'
import eventTypes from '@/utils/eventTypes';
import STRINGS from '@/utils/strings.json'
import RebelPill from '@/components/RebelPill.vue'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore()
    }
  },

  components: {
    RebelPill
  },

  props: {
    event: {
      type: Object,
      required: true
    },

    darkBackground: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isPickEm() {
      return eventTypes.isPickEm(this.event.typeId)
    },

    myTextAndColor() {
      const defaults = {
        color: STRINGS.COLOR_DANGER,
        text: 'ADMIN'
      }

      if (this.event == null) return defaults

      if (!this.event.resultsPending || this.event.past) {
        return {
          color: STRINGS.COLOR_SUCCESS,
          text: STRINGS.RESULTS_PUBLISHED
        }
      }

      if (this.event.participationEnded) {
        return {
          color: STRINGS.COLOR_DANGER,
          text: STRINGS.RESULTS_UNPUBLISHED
        }
      }

      if (this.event.published) {
        return {
          color: STRINGS.COLOR_SUCCESS,
          text: STRINGS.COMPETITORS_PUBLISHED
        }
      }

      if (!this.event.published) {
        return {
          color: STRINGS.COLOR_DANGER,
          text: STRINGS.COMPETITORS_UNPUBLISHED
        }
      }

      return defaults
    }
  }
}
</script>
