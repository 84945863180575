export default {
    ADD_COMPETITORS: 'add-competitors',
    ASSIGN_LEAGUE_COMMISSIONER: 'assign-league-commissioner',
    CREATE_LEAGUE: 'create-league',
    CONFIRM_ASSIGN_LEAGUE_COMMISSIONER: 'confirm-assign-league-commissioner',
    CONFIRM_REMOVE_LEAGUE_MEMBER: 'confirm-remove-league-member',
    COPY_PICKS_COMPLETE: 'copy-picks-complete',
    CLOSE: 'close',
    DELETE_EVENT: 'delete-event',
    DESIGN_CUSTOMIZED: 'design-customized',
    DISCARD_CHANGES: 'discard-changes',
    EDIT_COMPETITORS: 'edit-competitors',
    EVENT_WEIGHT_CLASSES_CHANGED: 'event-weight-classes-changed',
    EVENT_WEIGHT_CLASS_UNITS_CHANGED: 'event-weight-class-units-changed',
    IMAGE_UPLOADER_DELETED: 'image-uploader-deleted',
    IMAGE_UPLOADER_SAVED: 'image-uploader-saved',
    JOIN_OPEN_TOURNAMENT: 'join-open-tournament',
    LEAGUE_CHANGED: 'league-changed',
    LEAGUE_UPDATED: 'league-updated',
    MATCHUP_CHANGED: 'matchup-changed',
    TEAMS_CHANGED: 'teams-changed',
    TIME_RUNNING_OUT: 'time-running-out',
    REMOVE_COMPETITOR_GROUP: 'remove-competitor-group',
    REMOVE_LEAGUE_MEMBER: 'remove-league-member',
    RESEED: 'reseed',
    RESET_PASSWORD_SENT: 'reset-password-sent',
    SAVE_CHANGES: 'save-changes',
    SAVE_WITHOUT_RESEED: 'save-without-reseed',
    SCORING_CUSTOMIZED: 'scoring-customized',
    SELECTED_WEIGHT_CLASS_CHANGED: 'selected-weight-class-change',
    TIME_EXPIRES: 'time-expires',
    TIME_ZONE_UPDATED: 'time-zone-updated',
    VIEW_EVENT_RESULTS: 'view-event-results',
    VIEW_POPULAR_EVENT_RESULTS: 'view-popular-event-results',
    VOTE_NOW: 'vote-now',
}