<template>
  <div class="flex col grow gap-0-25" :class="getLightDarkBackground">

    <div class="text-container gap-0-25" v-if="size==1">
      <h1 class="text1" >{{ event.name }}</h1>
      <p class="text2 body2">{{ eventSubText(event) }}</p>
    </div>

    <div class="text-container gap-0-25" v-if="size==2">
      <h2 class="text1" >{{ event.name }}</h2>
      <p class="text2 body2">{{ eventSubText(event) }}</p>
    </div>
    
    <div class="text-container" v-if="size==3">
      <h3 class="text1" >{{ event.name }}</h3>
      <p class="text2 body2">{{ eventSubText(event) }}</p>
    </div>

    <div class="pill-container">
      <label-admin :event="event" :darkBackground="darkBackground"/>
    </div>

  </div>
</template>

<script>
import { useAuthenticationStore } from '@/stores/authentication'

import DateUtils from '@/utils/dateUtils.js'
import LabelAdmin from '@/components/event-admin/LabelAdmin.vue'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore(),
    }
  },

  components: {
    LabelAdmin
  },

  props: {
    size: {
      type: Number,
      default: 1
    },

    darkBackground: {
      type: Boolean,
      default: false
    },

    event: {
      type: Object,
      required: true
    }
  },

  computed: {
    getLightDarkBackground() {
      return this.darkBackground ? 'dark-background' : 'light-background'
    }
  },

  methods: {
    eventSubText(event) {
      if (event.popularVoteTournament) {
        if (!event.published) return 'Brackets to be announced soon'
        if (!event.participationEnded) return 'Voting ends ' + this.formatDateTime(event.startDateTime)
        return 'Voting ended ' + this.formatDateTime(event.startDateTime)
      }

      return this.formatDateTime(event.startDateTime)
    },

    formatDateTime(date) {
      return DateUtils.formatDateTime(date, this.authStore?.loggedInUser?.user?.timeZoneSetting)
    }
  }

}
</script>

<style scoped>
.text-container {
  display: flex;
  flex-direction: column;
}

.light-background .text1 {
  color: var(--color-black);
}

.light-background .text2 {
  color: var(--color-gray3);
}

.dark-background .text1 {
  color: var(--color-white);
}

.dark-background .text2 {
  color: var(--color-gray1);
}
</style>
