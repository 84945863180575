<template>
  <modal-dialog ref="myModal" @submit.prevent="confirm">
    <template v-slot:modal-title>Dual Re-Seeded</template>
    <template v-slot:modal-contents>
      <p>
        The matchups for this dual have been re-seeded. You will need to select new
        picks.
      </p>
    </template>
    <template v-slot:modal-actions>
      <rebel-button button-type="submit" :is-loading="isClosing" type="primary" color="default" text="Ok"></rebel-button>
    </template>
  </modal-dialog>
</template>


<script>
import RebelButton from '@/components/RebelButton.vue'
import ModalDialog from '@/components/ModalDialog.vue'

export default {
  components: {
    RebelButton,
    ModalDialog
  },

  data() {
    return {
      isClosing: false
    }
  },

  methods: {
    closeModal() {
      this.$refs.myModal.close()
    },

    confirm() {
      this.isClosing = true
      this.closeModal()
    }
  }
}
</script>
