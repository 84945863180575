import axios from 'axios'

export default {

  getMyBracketsForEvent(eventId) {
    return axios.get(`/events/${eventId}/user-brackets`)
  },

  getBracketsForUser(eventId, userId) {
    return axios.get(`/events/${eventId}/user-brackets/${userId}`)
  },

  getPopularVoteResults(eventId) {
    return axios.get(`/events/${eventId}/user-brackets/results`)
  },
  
  updateUserBracket(userBracketId, dto) {
    return axios.put(`/user-bracket/${userBracketId}/matchups`, dto)
  }

}