<template>
  <modal-dialog ref="myModal" @submit.prevent="createLeague">
    <template v-slot:modal-title>Create New League</template>
    <template v-slot:modal-contents>
      <div class="form-labeled-value">
        <h4>Event</h4>
        <p>{{ event.name }}</p>
      </div>

      <div class="form-labeled-value">
        <h4>Start Date</h4>
        <p>{{ formatStartDate(event.startDateTime) }}</p>
      </div>

      <div class="form-labeled-value">
        <h4>Start Time</h4>
        <p>{{ formatStartTime(event.startDateTime) }}</p>
      </div>

      <div class="form-input">
        <label for="league-name">League Name</label>
        <input
          id="league-name"
          type="text"
          placeholder="League Name"
          v-model="league.name"
          :required="dataConstraints.league.name.required"
          :minlength="dataConstraints.league.name.minlength"
          :maxlength="dataConstraints.league.name.maxlength"
        />
        <div class="caption gray2">Each league name for this event must be unique.</div>
      </div>

      <error-summary class="text-center" ref="errorSummary" />
    </template>

    <template v-slot:modal-actions>
      <rebel-button
        button-type="submit"
        type="primary"
        color="default"
        text="Create League"
        :is-loading="createLeagueLoading"
      ></rebel-button>
      <a href="javascript:void(null)" @click.prevent="cancel">Cancel</a>
    </template>
  </modal-dialog>
</template>

<script>
import dataConstraints from '@/utils/dataConstraints.js'
import { recordLeagueCreate } from '@/services/GoogleTagManagerService.js'
import { useAuthenticationStore } from '@/stores/authentication'

import DateUtils from '@/utils/dateUtils.js'

import leagueService from '@/services/LeagueService.js'

import ErrorSummary from '@/components/ErrorSummary.vue'
import RebelButton from '@/components/RebelButton.vue'
import ModalDialog from '@/components/ModalDialog.vue'

export default {
  beforeCreate() {
    if (!this.authStore.isLoggedIn) {
      const loginRoute = {
        name: 'login',
        // save the location we were at to come back later
        query: { redirect: this.$route.fullPath }
      }

      this.$router.push(loginRoute)
    }
  },

  setup() {
    return {
      authStore: useAuthenticationStore(),
      dataConstraints
    }
  },

  components: {
    ErrorSummary,
    RebelButton,
    ModalDialog
  },

  props: ['event'],

  data() {
    return {
      createLeagueLoading: false,

      league: {
        name: ''
      }
    }
  },

  methods: {
    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    async createLeague() {
      this.resetErrors()

      if (!this.isValidForm()) {
        return
      }
      this.createLeagueLoading = true

      const dto = {
        eventId: this.event.eventId,
        name: this.league.name
      }

      try {
        // send request to server
        const response = await leagueService.create(dto)

        recordLeagueCreate({
          event_name: this.event.name
        })

        // navigate to new league's page if successful
        this.$router.push({ name: 'league-details', params: { leagueId: response.data.leagueId } })
      } catch (error) {
        if (error?.response?.status == 400 && error.response?.data?.message) {
          this.$refs.errorSummary.setErrors(error.response.data.message)
          return
        }

        this.$refs.errorSummary.setErrors('Something went wrong')
        // show error message if not
        alert(error)
      } finally {
        this.createLeagueLoading = false
      }
    },

    formatStartDate(startDateTime) {
      return DateUtils.formatDateFromDateTime(startDateTime, this.authStore.loggedInUser?.user?.timeZoneSetting)
    },

    formatStartTime(startTime) {
      return DateUtils.formatTimeFromDateTime(startTime, this.authStore.loggedInUser?.user?.timeZoneSetting)
    },

    isValidForm() {
      if (this.league.name == null || this.league.name === '') {
        this.$refs.errorSummary.setErrors('League Name is required')
        return false
      }

      return true
    },

    resetErrors() {
      this.$refs.errorSummary.clearErrors()
    }
  }
}
</script>

