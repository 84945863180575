import appSettings from "../appSettings";

export let gtag;

if (import.meta.env.PROD && appSettings.CAPTURE_ANALYTICS) {
    window.dataLayer = window.dataLayer || [];
    gtag = function() { 
        // eslint-disable-next-line no-undef
        dataLayer.push(arguments); 
    }
    gtag('js', new Date());
    gtag('config', `G-${import.meta.env.VITE_GTAG}`);
} else {
    gtag = function() { }
}


const EVENT_NAMES = {
    // league
    LEAGUE_ASSIGN_NEW_COMMISSIONER: 'league_update_commissioner',
    LEAGUE_CREATE: 'league_create',
    LEAGUE_DELETE: 'league_delete',
    LEAGUE_JOIN: 'league_join', // TODO add data to say how long until event begins
    LEAGUE_USER_LEAVE: 'league_user_leave', 
    LEAGUE_USER_REMOVE: 'league_user_remove',
    LEAGUE_VIEW: 'league_view',

    // user settings
    CHANGE_DISPLAY_NAME: 'change_display_name',  // TODO
    CHANGE_NOTIFICATION_PREF: 'change_notification_pref',  // TODO
    CHANGE_PASSWORD: 'change_password',  // TODO
    CHANGE_TIME_ZONE: 'change_time_zone',  // TODO

    // auth
    LOGIN: 'login',
    RESET_PASSWORD_INITIATED: 'reset_password_begin',  // TODO
    RESET_PASSWORD_COMPLETE: 'reset_password_complete',  // TODO
    SIGN_UP: 'sign_up',

}

export function recordLeagueAssignNewCommissioner(data) {
    recordEvent(EVENT_NAMES.LEAGUE_ASSIGN_NEW_COMMISSIONER, data)
}

export function recordLeagueCreate(data) {
    recordEvent(EVENT_NAMES.LEAGUE_CREATE, data)
}

export function recordLeagueDelete(data) {
    recordEvent(EVENT_NAMES.LEAGUE_DELETE, data)
}

export function recordLeagueView(data) {
    recordEvent(EVENT_NAMES.LEAGUE_VIEW, data)
}

export function recordLeagueUserLeave(data) {
    recordEvent(EVENT_NAMES.LEAGUE_USER_LEAVE, data)
}

export function recordLeagueUserRemove(data) {
    recordEvent(EVENT_NAMES.LEAGUE_USER_REMOVE, data)
}

export function recordLogin(data) {
    recordEvent(EVENT_NAMES.LOGIN, data)
}

export function recordSignUp(data) {
    recordEvent(EVENT_NAMES.SIGN_UP, data)
}

function recordEvent(eventName, data) {
    try {
        gtag('event', eventName, data)
    } catch { /* ignored */ }
}

