<template>
  <div>
    <header-action-bar>
      <template v-slot:linkBackTo>
        <link-back-to class="mb-0-5" :router-link-to="{ name: 'user-profile' }" page-name="Profile" />
      </template>
    </header-action-bar>
    

    <h1 class="mb-2">Settings</h1>

    <div class="layout-form-sections">
      <section>
        <h2>Email Notifications</h2>
        <div class="section-list">
          <div class="toggle-item">
            <p>When event matchups become available</p>
            <toggle-button data-testid="brackets-become-available" :disabled="eventBracketPublishChanging"
              :value="eventBracketPublishNotificationOn" @toggled="updateEventBracketPublishNotificationPreference" />
          </div>
          <div class="toggle-item">
            <p>When event matchups are re-seeded</p>
            <toggle-button data-testid="bracket-is-re-seeded" :disabled="bracketReseedingChanging"
              :value="bracketReSeedingNotificationOn" @toggled="updateBracketReseedNotificationPreference" />
          </div>
          <div class="toggle-item">
            <p>24 hours before the prediction / voting period ends</p>
            <toggle-button data-testid="event-is-starting-soon" :disabled="eventStartingSoonChanging"
              :value="eventStartingSoonNotificationOn" @toggled="updateEventStartingSoonNotificationPreference" />
          </div>
        </div>
      </section>

      <section>
        <h2>Preferences</h2>
        <div class="section-list">
          <div class="toggle-item">
            <p>For each event, add me to the Open Tournament by default</p>
            <toggle-button data-testid="add-me-to-open-tourney" :disabled="addMeToOpenTournamentPreferenceChanging"
              :value="addMeToOpenTournamentPreference" @toggled="updateAddMeToOpenTournamentPreference" />
          </div>
        </div>
      </section>

    <section>
      <label for="user-time-zone" class="heading2 noselect inline-block">Time Zone</label>
      <select-time-zone id="user-time-zone" :disabled="zoneChanging" :value="timeZoneSetting"
        @time-zone-updated="updateTimeZone" />
    </section>
  </div>
  </div>
</template>

<script>
import HeaderActionBar from '@/components/HeaderActionBar.vue'
import LinkBackTo from '@/components/LinkBackTo.vue'
import SelectTimeZone from '@/components/SelectTimeZone.vue'
import ToggleButton from '@/components/ToggleButton.vue'
import { useAuthenticationStore } from '@/stores/authentication'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore()
    }
  },

  components: {
    HeaderActionBar,
    LinkBackTo,
    SelectTimeZone,
    ToggleButton
  },

  computed: {
    addMeToOpenTournamentPreference() {
      return this.authStore?.loggedInUser?.user?.addMeToOpenTournament
    },

    bracketReSeedingNotificationOn() {
      return this.authStore?.loggedInUser?.user?.bracketReSeedingNotificationOn
    },

    eventBracketPublishNotificationOn() {
      return this.authStore?.loggedInUser?.user?.eventBracketPublishNotificationOn
    },

    eventStartingSoonNotificationOn() {
      return this.authStore?.loggedInUser?.user.eventStartingSoonNotificationOn
    },

    timeZoneSetting() {
      return this.authStore?.loggedInUser?.user?.timeZoneSetting
    }
  },

  data() {
    return {
      addMeToOpenTournamentPreferenceChanging: false,
      bracketReseedingChanging: false,
      eventBracketPublishChanging: false,
      eventStartingSoonChanging: false,
      zoneChanging: false
    }
  },

  methods: {
    async updateAddMeToOpenTournamentPreference(newPreference) {
      try {
        this.addMeToOpenTournamentPreferenceChanging = true

        const result = await this.authStore.updateAddMeToOpenTournamentPreference(newPreference)
        if (!result.success) {
          alert('Something went wrong')
        }
      } catch {
        alert('Something went wrong')
      } finally {
        this.addMeToOpenTournamentPreferenceChanging = false
      }
    },

    async updateBracketReseedNotificationPreference(newPreference) {
      try {
        this.bracketReseedingChanging = true

        const result = await this.authStore.updateBracketReseedNotificationPreference(newPreference)
        if (!result.success) {
          alert('Something went wrong')
        }
      } catch {
        alert('Something went wrong')
      } finally {
        this.bracketReseedingChanging = false
      }
    },

    async updateEventBracketPublishNotificationPreference(newPreference) {
      try {
        this.bracketReseedingChanging = true

        const result = await this.authStore.updateEventBracketPublishNotificationPreference(newPreference)
        if (!result.success) {
          alert('Something went wrong')
        }
      } catch {
        alert('Something went wrong')
      } finally {
        this.bracketReseedingChanging = false
      }
    },

    async updateEventStartingSoonNotificationPreference(newPreference) {
      try {
        this.eventStartingSoonChanging = true

        const result = await this.authStore.updateEventStartingSoonNotificationPreference(newPreference)
        if (!result.success) {
          alert('Something went wrong')
        }
      } catch {
        alert('Something went wrong')
      } finally {
        this.eventStartingSoonChanging = false
      }
    },

    async updateTimeZone(newTimeZone) {
      try {
        this.zoneChanging = true

        const result = await this.authStore.updateTimeZoneSetting(newTimeZone.name)
        if (!result.success) {
          alert('Something went wrong')
        }
      } catch {
        alert('Something went wrong')
      } finally {
        this.zoneChanging = false
      }
    }
  }
}
</script>

<style scoped>
section {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 1rem;
}

.section-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-self: stretch;
}

.toggle-item {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  max-width: 36rem;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>