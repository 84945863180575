<template>
  <div class="event-card-header grow" :class="darkBackground ? 'dark-background bg-gray4-opacity-50-blur' : 'light-background'">
    <div class="flex row gap-0-75 align-center grow">
      <img v-if="useEventLogos && event.logoUrl" width="100" :src="event.logoUrl" alt="Event logo" />
      <event-text :event="event" :size="2" :darkBackground="false"></event-text>
    </div>
  </div>
</template>

<script>
import APP_SETTINGS from '@/appSettings.js'
import EventText from '@/components/events/EventText.vue'

export default {
  components: {
    EventText
  },

  props: {
    darkBackground: {
      type: Boolean,
      default: false
    },

    event: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      useEventLogos: APP_SETTINGS.USE_EVENT_LOGOS
    }
  }
}
</script>

<style scoped>
.event-card-header {
  padding: 0.5rem;
}

.light-background.event-card-header {
  background-color: var(--color-gray-half);
}

.dark-background.event-card-header {
  border-radius: 4px;
}
</style>
