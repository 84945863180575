const ROLE_EVENT_ADMIN = 'ROLE_EVENT_ADMIN'
const ROLE_SITE_ADMIN = 'ROLE_SITE_ADMIN'
const ROLE_USER = 'ROLE_USER'



export default {
    ROLE_EVENT_ADMIN,
    ROLE_SITE_ADMIN,
    ROLE_USER,

    ADMIN_ROLES: [ROLE_SITE_ADMIN, ROLE_EVENT_ADMIN]
}