<template>
  <template v-if="src && !avatarFailedToLoad">
    <img ref="avatarImg" :class="cssClasses" class="avatar" :width="computedWidth" :height="computedHeight" :src="src" :title="title" alt="My Avatar" />
  </template>
  <template v-else>
    <svg
      aria-label="My Avatar"
      class="avatar"
      :class="cssClasses"
      :width="computedWidth"
      :height="computedHeight"
      :viewBox="`0 0 24 24`"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        :class="cssClasses"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.7254 20.2383C22.7554 18.0889 24 15.1898 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 15.1984 1.25133 18.1046 3.29113 20.2557C4.74593 16.8887 8.10303 14.5317 12.012 14.5317C15.9143 14.5317 19.2665 16.8806 20.7254 20.2383ZM12.0097 24H11.9903C11.9936 24 11.9968 24 12 24C12.0032 24 12.0064 24 12.0097 24ZM7.08002 8.58C7.08002 6.01484 9.06735 3.91384 11.5863 3.73267C11.7262 3.71816 11.8683 3.71072 12.012 3.71072C12.4091 3.71072 12.7929 3.76747 13.1557 3.87328C15.2515 4.41302 16.8 6.31567 16.8 8.58C16.8 11.2641 14.6241 13.44 11.94 13.44C9.25591 13.44 7.08002 11.2641 7.08002 8.58Z"
        fill="#DFE6ED"
      />
    </svg>
  </template>
</template>

<script>
export default {
  props: {
    cssClasses: {
      type: String,
      required: false,
      default: ''
    },

    src: {
      type: String,
      required: false,
      default: undefined
    },

    title: {
      type: String,
      required: false,
      default: undefined
    },

    height: {
      type: Number,
      required: false
    },

    width: {
      type: Number,
      required: false
    }
  },

  data() {
    return {
      DEFAULT_HEIGHT: 24,
      DEFAULT_WIDTH: 24,

      avatarFailedToLoad: false
    }
  },

  computed: {
    computedHeight() {
      return this.height ? this.height : this.DEFAULT_HEIGHT
    },

    computedWidth() {
      return this.width ? this.width : this.DEFAULT_WIDTH
    }
  },

  methods: {
    setAvatarFailedToLoad() {
      this.avatarFailedToLoad = true
    }
  },

  mounted() {
    if (this.$refs.avatarImg) {
      this.$refs.avatarImg.addEventListener('error', this.setAvatarFailedToLoad)
    }
  }
}
</script>

<style scoped>
.avatar {
  border: 2px solid var(--color-white);
  border-radius: 50%;
}
</style>