<template>
  <image-uploader-modal matness-image-type="event-image" header-label="Event"
    description-text="The event art will be cropped to a 3:4 aspect ratio. We recommend using a transparent background instead of a white background, in case the UI background color changes in the future."
    supported-file-types="image/png" placeholder-image-name="landscape" :image-height="90" :image-width="120"
    :boundary-width="400" :boundary-height="300" maskType="square" :show-zoomer="true" :image-src="uploaderImageSrc"
    @image-uploader-deleted="imageUploaderDeleted" @image-uploader-saved="imageUploaderSaved"
    @close="closeImageUploader" />
</template>

<script>
import ImageUploaderModal from '../ImageUploaderModal.vue'

import emitEvents from '../../utils/emitEvents'
import eventService from '../../services/EventService'

export default {
  components: {
    ImageUploaderModal
  },

  props: {
    eventId: {
      type: Number,
      required: true
    },

    uploaderImageSrc: {
      type: String,
      required: true
    }
  },

  emits: [emitEvents.CLOSE, emitEvents.IMAGE_UPLOADER_DELETED, emitEvents.IMAGE_UPLOADER_SAVED],

  methods: {
    closeImageUploader() {
      this.$emit(emitEvents.CLOSE)
    },

    imageUploaderDeleted() {
      this.imageUploaderSaved('')
    },

    async imageUploaderSaved(imageSrc) {
      try {
        this.$emit(emitEvents.IMAGE_UPLOADER_SAVED, imageSrc)
        this.closeImageUploader()
        eventService.updateEventImage(this.eventId, imageSrc)
      } catch {
        alert('Something went wrong saving the event image')
      }
    }
  }
}
</script>