<template>
  <div class="flex col gap-1">
    <h2 class="heading2">Teams</h2>

    <div class="flex space-between align-center">
      <div>
        <h4 class="heading4">Team-Based Event?</h4>
        <span class="body2">
          Team scores will be calculated based on the cumulative scores of all competitors.
        </span>
      </div>
      <toggle-button v-if="canToggle" :value="model.teamEvent" @toggled="toggleteamEvent" />
    </div>

    <div v-if="model.teamEvent">
      <form class="flex space-between gap-1-75" @input="teamsChanged">
        <div class="flex col gap-1 grow align-center">
          <div class="flex col space-between align-center gap-0-5 image-container">
            <img v-if="model.team1ImageUrl" :src="model.team1ImageUrl" alt="Team 1 Image" width="200">
            <svg v-else width="100%" height="100%" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_2676_209473)">
                <rect width="200" height="200" fill="#DFE6ED" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M39.7998 67.9917C39.7998 59.7488 46.482 53.0667 54.7248 53.0667H144.275C152.518 53.0667 159.2 59.7488 159.2 67.9917V131.008C159.2 139.251 152.518 145.933 144.275 145.933H54.7248C46.4819 145.933 39.7998 139.251 39.7998 131.008V67.9917ZM49.7498 106.536V131.008C49.7498 133.756 51.9772 135.983 54.7248 135.983H144.275C147.022 135.983 149.25 133.756 149.25 131.008V113.169L134.526 98.4452C132.583 96.5024 129.433 96.5024 127.49 98.4452L114.827 111.108L117.943 114.224C119.886 116.167 119.886 119.317 117.943 121.26C116 123.202 112.85 123.202 110.907 121.26L76.4843 86.8369C74.5415 84.894 71.3915 84.894 69.4486 86.8369L49.7498 106.536ZM112.766 79.6C112.766 83.2635 109.797 86.2333 106.133 86.2333C102.47 86.2333 99.4998 83.2635 99.4998 79.6C99.4998 75.9365 102.47 72.9667 106.133 72.9667C109.797 72.9667 112.766 75.9365 112.766 79.6Z"
                  fill="#B0BFCF" />
              </g>
              <defs>
                <clipPath id="clip0_2676_209473">
                  <rect width="199" height="199" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <rebel-button button-type="button" @click="team1ImageUploader.isShowing = true" type="primary"
              color="default" :text="`${model.team1ImageUrl ? 'Edit' : 'Add'} Image`" class="nowrap">
              <template v-slot:icon-leading>
                <camera-icon class="icon-20" />
              </template>
            </rebel-button>
          </div>
          <div class="form-input">
            <label for="team1Name">Team 1 Name</label>
            <input v-model="model.team1Name" type="text" id="team1Name" placeholder="Team Name" maxlength="100"
              :required="model.teamEvent">
          </div>
        </div>

        <div class="flex col gap-1 grow align-center">
          <div class="flex col space-between align-center gap-0-5 grow image-container">
            <img v-if="model.team2ImageUrl" :src="model.team2ImageUrl" alt="Team 2 Image" width="200">
            <svg v-else width="100%" height="100%" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_2676_209473)">
                <rect width="200" height="200" fill="#DFE6ED" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M39.7998 67.9917C39.7998 59.7488 46.482 53.0667 54.7248 53.0667H144.275C152.518 53.0667 159.2 59.7488 159.2 67.9917V131.008C159.2 139.251 152.518 145.933 144.275 145.933H54.7248C46.4819 145.933 39.7998 139.251 39.7998 131.008V67.9917ZM49.7498 106.536V131.008C49.7498 133.756 51.9772 135.983 54.7248 135.983H144.275C147.022 135.983 149.25 133.756 149.25 131.008V113.169L134.526 98.4452C132.583 96.5024 129.433 96.5024 127.49 98.4452L114.827 111.108L117.943 114.224C119.886 116.167 119.886 119.317 117.943 121.26C116 123.202 112.85 123.202 110.907 121.26L76.4843 86.8369C74.5415 84.894 71.3915 84.894 69.4486 86.8369L49.7498 106.536ZM112.766 79.6C112.766 83.2635 109.797 86.2333 106.133 86.2333C102.47 86.2333 99.4998 83.2635 99.4998 79.6C99.4998 75.9365 102.47 72.9667 106.133 72.9667C109.797 72.9667 112.766 75.9365 112.766 79.6Z"
                  fill="#B0BFCF" />
              </g>
              <defs>
                <clipPath id="clip0_2676_209473">
                  <rect width="199" height="199" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <rebel-button button-type="button" @click="team2ImageUploader.isShowing = true" type="primary"
              color="default" :text="`${model.team2ImageUrl ? 'Edit' : 'Add'} Image`" class="nowrap">
              <template v-slot:icon-leading>
                <camera-icon class="icon-20" />
              </template>
            </rebel-button>
          </div>
          <div class="form-input">
            <label for="team2Name">Team 2 Name</label>
            <input v-model="model.team2Name" type="text" id="team2Name" placeholder="Team Name" maxlength="100"
              :required="model.teamEvent">
          </div>
        </div>
      </form>
    </div>

    <image-uploader-modal v-if="team1ImageUploader.isShowing || team2ImageUploader.isShowing"
      matness-image-type="pick-em-team-image" header-label="Team"
      description-text="The team image should be a .png file with a transparent background, with a 1:1 aspect ratio."
      supported-file-types="image/png" placeholder-image-name="landscape" :image-height="200" :image-width="200"
      :boundary-width="300" :boundary-height="300" maskType="square" :show-zoomer="true" :image-src="uploaderImageSrc"
      @image-uploader-deleted="imageUploaderDeleted" @image-uploader-saved="imageUploaderSaved"
      @close="closeImageUploader" />

  </div>
</template>

<script>
/*
  TODO: 
  Make the Team Columns into components of their own
  Load the saved data
  Use data constraints for required, maxlength, etc
*/
import ImageUploaderModal from '../../ImageUploaderModal.vue'
import RebelButton from '../../RebelButton.vue'
import ToggleButton from '../../ToggleButton.vue'

import pickEmService from '../../../services/PickEmService'
import debounceUtil from '../../../utils/debounce'
import PQueue from 'p-queue'

import { usePickEmStore } from '@/stores/pickem'
import { useEventAdminStore } from '@/stores/eventAdmin'
import { CameraIcon } from '@heroicons/vue/20/solid'
import emitEvents from '@/utils/emitEvents'

export default {
  setup() {
    const savingQueue = new PQueue({ concurrency: 1 })

    return {
      eventAdminStore: useEventAdminStore(),
      pickEmStore: usePickEmStore(),
      savingQueue,
    }
  },

  async created() {
    const data = (await pickEmService.getTeams(this.eventId)).data

    if (data) {
      this.model = data
      this.initialState = JSON.parse(JSON.stringify(this.model))
    }

    this.pickEmStore.setIsValid(this.isValid)
  },

  components: {
    CameraIcon,
    ImageUploaderModal,
    RebelButton,
    ToggleButton
  },

  props: ['autoSave', 'eventId', 'canToggle'],

  emits: [emitEvents.TEAMS_CHANGED],

  data() {
    return {
      initialState: {},

      model: { // changes to this would need to be mirrored in the initial state of the database upon creating the event
        eventId: this.eventId,
        teamEvent: false,
        team1Name: '',
        team2Name: '',
        team1ImageUrl: '',
        team2ImageUrl: '',
      },

      debouncedSaveFn: null,

      team1ImageUploader: {
        isShowing: false
      },
      team2ImageUploader: {
        isShowing: false
      }
    }
  },

  computed: {
    hasAnythingChanged() {
      if (this.initialState.teamEvent != this.model.teamEvent) return true
      if (this.initialState.team1Name != this.model.team1Name) return true
      if (this.initialState.team2Name != this.model.team2Name) return true
      if (this.initialState.team1ImageUrl != this.model.team1ImageUrl) return true
      if (this.initialState.team2ImageUrl != this.model.team2ImageUrl) return true

      return false
    },

    isValid() {
      const notATeamEvent = !this.model.teamEvent
      const hasNamesNotImages = Boolean(this.model.team1Name) && Boolean(this.model.team2Name) && !this.model.team1ImageUrl && !this.model.team2ImageUrl
      const hasNamesAndImages = Boolean(this.model.team1Name) && Boolean(this.model.team2Name) && Boolean(this.model.team1ImageUrl) && Boolean(this.model.team2ImageUrl)

      return notATeamEvent || hasNamesNotImages || hasNamesAndImages
    },

    uploaderImageSrc() {
      if (this.team1ImageUploader.isShowing) {
        return this.model.team1ImageUrl
      }

      return this.model.team2ImageUrl
    }
  },

  methods: {
    imageUploaderDeleted() {
      if (this.team1ImageUploader.isShowing) {
        this.model.team1ImageUrl = ''
      }

      if (this.team2ImageUploader.isShowing) {
        this.model.team2ImageUrl = ''
      }

      this.teamsChanged()
      this.closeImageUploader()
    },

    imageUploaderSaved(imageSrc) {
      if (this.team1ImageUploader.isShowing) {
        this.model.team1ImageUrl = imageSrc
      }

      if (this.team2ImageUploader.isShowing) {
        this.model.team2ImageUrl = imageSrc
      }

      this.teamsChanged()
      this.closeImageUploader()
    },

    closeImageUploader() {
      this.team1ImageUploader.isShowing = false
      this.team2ImageUploader.isShowing = false
    },

    getDebouncedSaveFn() {
      if (!this.debouncedSaveFn) {
        this.debouncedSaveFn = debounceUtil.debounce(() => {
          this.savingQueue.clear()
          this.savingQueue.add(() => this.saveTeams())
        }, 1000)
      }

      return this.debouncedSaveFn
    },

    teamsChanged() {
      this.pickEmStore.setIsValid(this.isValid)

      if (!this.autoSave) return

      const fn = this.getDebouncedSaveFn()
      fn()
    },

    async saveTeams() {
      try {
        pickEmService.saveTeams(this.eventId, this.model)
        this.$emit(emitEvents.TEAMS_CHANGED, this.model)
      } catch {
        //
      }
    },

    toggleteamEvent() {
      this.model.teamEvent = !this.model.teamEvent

      this.teamsChanged()
    }
  },

  watch: {
    hasAnythingChanged(curr) {
      this.eventAdminStore.teamsChanged = curr
    }
  }
}
</script>

<style scoped>
.image-container {
  max-width: 16rem;
}
</style>