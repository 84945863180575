export default {
    event: {
        name: {
            maxlength: 100,
            required: true
        },

        startDate: {
            required: true
        },

        startTime: {
            required: true
        }
    },

    league: {
        name: {
            maxlength: 65,
            required: true
        }
    },

    scoring: {
        matnessPoints: {
            required: true
        },
        classificationOfVictory: {
            name: {
                required: true
            },
            actualPoints: {
                required: true
            },
            matnessPoints: {
                required: true
            },
        }
    },

    user: {
        email: {
            maxlength: 254,
            required: true
        },

        displayName: {
            maxlength: 100,
            required: true
        },

        password: {
            minlength: 8,
            required: true
        }
    }
}