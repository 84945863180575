<template>
  <modal-dialog ref="myModal" @submit.prevent="updateDisplayName">
    <template v-slot:modal-title>Edit Display Name</template>
    <template v-slot:modal-contents>
      <div class="form-input">
        <label for="display-name">Display Name</label>
        <input
          id="display-name"
          type="text"
          placeholder="Display Name"
          v-model="displayName"
          :required="dataConstraints.user.displayName.required"
          :minlength="dataConstraints.user.displayName.minlength"
          :maxlength="dataConstraints.user.displayName.maxlength"
        />
      </div>
    </template>
    <template v-slot:modal-actions>
      <rebel-button
        button-type="submit"
        type="primary"
        color="default"
        :is-loading="isUpdating"
        text="Update"
      ></rebel-button>

      <rebel-button type="borderless" color="default" text="Cancel" @click="cancel"></rebel-button>
    </template>
  </modal-dialog>
</template>

<script>
import dataConstraints from '@/utils/dataConstraints.js'
import { useAuthenticationStore } from '@/stores/authentication'

import ModalDialog from '@/components/ModalDialog.vue'
import RebelButton from '@/components/RebelButton.vue'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore(),
      dataConstraints
    }
  },

  components: {
    ModalDialog,
    RebelButton
  },

  props: ['value'],

  data() {
    return {
      displayName: this.value,
      isUpdating: false
    }
  },

  methods: {
    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    async updateDisplayName() {
      try {
        this.isUpdating = true
        // send request to server
        await this.authStore.updateDisplayName(this.displayName)

        // close if successful
        this.closeModal()
      } catch (error) {
        // show error message if not
        alert(error)
      } finally {
        this.isUpdating = false
      }
    }
  }
}
</script>

