<template>
  <modal-dialog ref="myModal" @submit.prevent="confirm">
    <template v-slot:modal-title>Password Reset</template>
    <template v-slot:modal-contents>
      <p>
        If <a href="mailto:{{ email }}">{{ email }}</a> is associated with an account, you will receive an email with a link to reset your password. Check
        your spam folder.
      </p>
    </template>
    <template v-slot:modal-actions>
      <rebel-button button-type="submit" type="primary" color="default" text="Ok"></rebel-button>
    </template>
  </modal-dialog>
</template>

<script>
import RebelButton from '@/components/RebelButton.vue'
import ModalDialog from '@/components/ModalDialog.vue'

export default {
  components: {
    RebelButton,
    ModalDialog
  },

  props: ['email'],

  methods: {
    closeModal() {
      this.$refs.myModal.close()
    },

    confirm() {
      this.closeModal()
    }
  }
}
</script>
