<template>
  <modal-dialog ref="myModal" @submit.prevent="confirm">
    <template v-slot:modal-title>🐷 PIG</template>
    <template v-slot:modal-contents>
      <div>
        <span>The </span>
        <span class="bold nowrap">PIG</span>
        <span>
          represents the WINNER of the pigtail or previous round.
        </span>
      </div>
      <div>
        <span>The </span>
        <span class="bold nowrap">PIG</span>
        <span>
          can be advanced to score points in all rounds.
        </span>
      </div>
    </template>
    <template v-slot:modal-actions>
      <rebel-button button-type="submit" type="primary" color="default" text="Ok"></rebel-button>
    </template>
  </modal-dialog>
</template>


<script>
import RebelButton from '@/components/RebelButton.vue'
import ModalDialog from '@/components/ModalDialog.vue'

export default {
  components: {
    RebelButton,
    ModalDialog
  },

  methods: {
    closeModal() {
      this.$refs.myModal.close()
    },

    confirm() {
      this.closeModal()
    }
  }
}
</script>
