<template>
  <modal-dialog ref="myModal" @submit.prevent="resetPassword">
    <template v-slot:modal-title>Forgot Password</template>
    <template v-slot:modal-contents>
      <div class="form-input">
        <label for="email">Email</label>
        <input
          autofocus
          id="email"
          type="email"
          placeholder="Email"
          v-model="email"
          :required="dataConstraints.user.email.required"
          :minlength="dataConstraints.user.email.minlength"
          :maxlength="dataConstraints.user.email.maxlength"
        />
      </div>
      <p>
        If this email is associated with an account, you will receive a link to reset your password.
      </p>
    </template>
    <template v-slot:modal-actions>
      <rebel-button
        :disabled="email.length === 0"
        type="primary"
        color="default"
        text="Reset Password"
        button-type="submit"
        :is-loading="resetPasswordLoading"
      ></rebel-button>
      <a href="javascript:void(null)" @click.prevent="cancel">Cancel</a>
    </template>
  </modal-dialog>
</template>

<script>
import dataConstraints from '@/utils/dataConstraints.js'

import authenticationService from '@/services/AuthenticationService.js'
import emitEvents from '@/utils/emitEvents.js'
import RebelButton from '@/components/RebelButton.vue'
import ModalDialog from '@/components/ModalDialog.vue'

export default {
  setup() {
    return {
      dataConstraints
    }
  },

  components: {
    RebelButton,
    ModalDialog
  },

  data() {
    return {
      email: '',
      resetPasswordLoading: false
    }
  },

  emits: [emitEvents.RESET_PASSWORD_SENT],

  methods: {
    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    async resetPassword() {
      try {
        this.resetPasswordLoading = true
        const trimmedEmail = this.email.trim()
        await authenticationService.forgotPassword(trimmedEmail)
        this.$emit(emitEvents.RESET_PASSWORD_SENT, trimmedEmail)
        this.closeModal()
      } catch (error) {
        if (error && error.response && error.response.status === 400 && error.response.data && error.response.data.message) {
          alert(error.response.data.message)
        } else {
          alert('Something went wrong')
        }
      } finally {
        this.resetPasswordLoading = false
      }
    }
  }
}
</script>

