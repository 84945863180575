import axios from 'axios'

export default {
  getAll(eventId) {
    return axios.get(`/events/${eventId}/brackets`)
  },

  create(eventId, eventBracketDto) {
    return axios.post(`/events/${eventId}/brackets`, eventBracketDto)
  },

  update(eventId, bracketId, eventBracketDto) {
    return axios.put(`/events/${eventId}/brackets/${bracketId}`, eventBracketDto)
  },

  patchRoundName(bracketId, roundNumber, newRoundName) {
    return axios.patch(`/brackets/${bracketId}/round/${roundNumber}`, { roundName: newRoundName })
  }
}