<template>
  <div v-if="isRedirectingToOpenTournament">
    The Open Tournament starting... Hang on for a few seconds and we will redirect you to your Mat.
  </div>
  <div v-else>
    <div v-if="isLoading"></div>
    <div v-else>
      <div class="flex space-between gap-1 align-start mb-0-5">
        <h1 class="black">{{ league.name }}</h1>

        <div class="mt-0-25">
          <fly-out-actions-menu>
            <template v-slot:icon>
              <cog-8-tooth-icon class="icon-24" />
            </template>
            <template v-slot:list-items>
              <li v-if="isCommissioner">
                <a @click.prevent="editLeagueName">Edit league name</a>
              </li>
              <li v-if="isCommissioner">
                <a class="color-danger" @click.prevent="deleteThisLeague">Delete this league</a>
              </li>
              <li v-if="!isCommissioner">
                <a class="color-danger" @click.prevent="leaveThisLeague">Leave this league</a>
              </li>
            </template>
          </fly-out-actions-menu>
        </div>
      </div>

      <event-card-header class="mb-0-5" :event="league.event" :darkBackground="false"></event-card-header>

      <div class="mb-2">
        <div v-if="isEventUpcoming">
          <countdown-timer :startDateTime="countdownTimerDate" :darkBackground="false"
            @time-expires="countdownTimeExpires" />
        </div>
        <div v-else-if="league.event.resultsPending">
          <my-score-during :won="myLeagueMember.leagueScore" :possible="myLeagueMember.pointsPossible"
            :leagueRank="myLeagueMember.leagueRankDisplay" :leagueMemberCount="leagueMembersWithPredictions.length"
            :overallRank="myLeagueMember.overallRankDisplay" :overallMemberCount="league.event.leagueMemberCount" />
        </div>
        <div v-else-if="isEventComplete || !league.event.resultsPending">
          <my-score-post :totalPoints="myLeagueMember.leagueScore" :leagueRank="myLeagueMember.leagueRankDisplay"
            :leagueMemberCount="leagueMembersWithPredictions.length" :overallRank="myLeagueMember.overallRankDisplay"
            :overallMemberCount="league.event.leagueMemberCount" />
        </div>

        <p class="mt-2 mb-3" v-if="showOpenTournamentVerbiage">
          The <span class="bold">Open Tournament</span> is available for all Wrestle MATNESS fans to join! At the
          start of
          the event, you will be randomly assigned to an <span class="bold">Open Tournament League</span> to compete
          against other fans.
        </p>
      </div>

      <div class="mb-2" v-if="!areMatchupsAnnounced">
        <h2>Matchups</h2>
        <p>
          Matchups are not yet available for this event.
          <template v-if="matchupsAnnouncedNotificationOn"> You will be notified by email when they become
            available.</template>
          <template v-else>
            Go to <router-link :to="{ name: 'user-profile-settings' }">settings</router-link> to enable notifications to
            receive an email when matchups are announced.</template>
        </p>
        <league-members class="mt-2" :league-members="league.members" />
      </div>
      <div class="mb-2" v-else>
        <bracket-progress v-if="isEventUpcoming" :event-type-id="league.event.typeId" :league-id="leagueId" :league-members="league.members"
          :weight-class-details="league.event.weightClasses"></bracket-progress>
        <leaderboard-in-progress v-if="isEventInProgress || isEventComplete" :event-type-id="league.event.typeId" :is-event-in-progress="league.event.resultsPending"
          :league-id="leagueId" :league-members="leagueMembersWithPredictions"
          :weight-class-details="league.event.weightClasses"></leaderboard-in-progress>
      </div>

      <div v-if="isCommissioner" class="mt-1">
        <div class="flex wrap space-between gap-1 align-center mb-1">
          <h2>Manage League Members</h2>
          <div class="flex wrap gap-0-5">
            <rebel-button class="grow" v-if="isEventUpcoming || isEventInProgress" :is-loading="loadingInviteLink"
              type="primary" color="default" text="Invite Members" @click="startSharingInviteLink">
              <template v-slot:icon-leading>
                <user-plus-icon class="icon-20" />
              </template>
            </rebel-button>
            <rebel-button class="grow" v-if="isEventUpcoming || isEventInProgress" type="ghosted" color="default"
              text="Regenerate Invite Link" @click="regenerateInvite">
              <template v-slot:icon-leading>
                <arrow-path-icon class="icon-20" />
              </template>
            </rebel-button>
          </div>
        </div>
        <div>
          <rebel-table @confirm-assign-league-commissioner="showAssignNewCommissionerModal"
            @confirm-remove-league-member="showRemoveFromLeagueModal" :columns="leagueMembersColumns"
            :table-data="leagueMembersComputed" row-key="email"></rebel-table>
        </div>
      </div>
    </div>

    <assign-new-commissioner-modal v-if="isCommissioner && assignNewCommissionerModalShowing"
      :user="assignNewCommissionerModalData" @close="assignNewCommissionerModalShowing = false"
      @assign-league-commissioner="assignLeagueCommissioner" />

    <remove-from-league-modal v-if="isCommissioner && removeFromLeagueModalShowing" :eventCategory="eventCategory"
      :league-name="league.name" :user-to-remove="removeFromLeagueModalUserData"
      @close="removeFromLeagueModalShowing = false" @remove-league-member="removeFromLeague" />

    <delete-league-modal v-if="isCommissioner && deleteLeagueModalShowing" :eventCategory="eventCategory"
      :league-id="leagueId" :league-name="league.name" @close="deleteLeagueModalShowing = false" />

    <share-invite-modal v-if="isCommissioner && isSharingInviteLink" :invite-link="inviteLink"
      :event-name="league.event.name" @close="isSharingInviteLink = false" />

    <regenerate-invite-modal v-if="isCommissioner && regenerateInviteModal.isShowing" :league-id="leagueId"
      @close="regenerateInviteModal.isShowing = false" />

    <edit-league-name-modal v-if="isCommissioner && editLeagueNameModalShowing" :league-id="leagueId"
      :league-name="league.name" :event="league.event" @league-updated="leagueUpdated"
      @close="editLeagueNameModalShowing = false" />

    <leave-league-modal v-if="leaveLeagueModalShowing" :brackets-are-published="areMatchupsAnnounced"
      :eventCategory="eventCategory" :league-id="leagueId" :league-name="league.name"
      @close="leaveLeagueModalShowing = false" />
  </div>
</template>

<script>
import dateUtils from '@/utils/dateUtils.js'
import leagueService from '@/services/LeagueService.js'
import { recordLeagueView } from '@/services/GoogleTagManagerService.js'
import PubSub from 'pubsub-js'
import STRINGS from '@/utils/strings.json'
import { useAuthenticationStore } from '@/stores/authentication'

import { ArrowPathIcon, Cog8ToothIcon, UserPlusIcon } from '@heroicons/vue/20/solid'
import BracketProgress from '@/components/leagues/BracketProgress.vue'
import EventCardHeader from '@/components/events/EventCardHeader.vue'
import LeaderboardInProgress from '@/components/leagues/LeaderboardInProgress.vue'
import LeagueMembers from '@/components/leagues/LeagueMembers.vue'
import RebelButton from '@/components/RebelButton.vue'
import RebelTable from '@/components/RebelTable.vue'
import AssignNewCommissionerModal from '@/components/leagues/AssignNewCommissionerModal.vue'
import RemoveFromLeagueModal from '@/components/leagues/RemoveFromLeagueModal.vue'
import CountdownTimer from '@/components/leagues/CountdownTimer.vue'
import MyScoreDuring from '@/components/leagues/MyScoreDuring.vue'
import MyScorePost from '@/components/leagues/MyScorePost.vue'
import DeleteLeagueModal from '@/components/leagues/DeleteLeagueModal.vue'
import LeaveLeagueModal from '@/components/leagues/LeaveLeagueModal.vue'
import RegenerateInviteModal from '@/components/leagues/RegenerateInviteModal.vue'
import ShareInviteModal from '@/components/leagues/ShareInviteModal.vue'
import EditLeagueNameModal from '@/components/leagues/EditLeagueNameModal.vue'
import FlyOutActionsMenu from '../components/FlyOutActionsMenu.vue'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore()
    }
  },

  async created() {
    await this.loadData()
    this.isLoading = false

    recordLeagueView({
      eventCategory: this.eventCategory
    })
  },

  components: {
    ArrowPathIcon,
    AssignNewCommissionerModal,
    BracketProgress,
    Cog8ToothIcon,
    CountdownTimer,
    DeleteLeagueModal,
    EditLeagueNameModal,
    EventCardHeader,
    FlyOutActionsMenu,
    LeaderboardInProgress,
    LeagueMembers,
    LeaveLeagueModal,
    MyScoreDuring,
    MyScorePost,
    RebelButton,
    RebelTable,
    RegenerateInviteModal,
    RemoveFromLeagueModal,
    ShareInviteModal,
    UserPlusIcon
  },

  computed: {
    countdownTimerDate() {
      return dateUtils.dateInUsersTimezone(this.league.event.startDateTime, this.authStore?.loggedInUser?.user?.timeZoneSetting)
    },

    eventCategory() {
      if (this.isEventUpcoming) return 'upcoming'
      if (this.isEventInProgress) return 'ongoing'
      if (this.isEventComplete) return 'past'

      return 'unknown'
    },

    showOpenTournamentVerbiage() {
      return (
        this.league?.event?.openTournamentLobbyLeagueId == this.leagueId &&
        this.league?.event?.openTournamentStarted === false
      )
    },

    leagueId() {
      return this.$route.params.leagueId
    },

    leagueMembersWithPredictions() {
      return this.league.members.filter(m => m.hasAtLeastOnePrediction)
    },

    leagueMembersComputed() {
      return this.league.members.map((member) => {
        const role = member.commissioner ? STRINGS.COMMISSIONER : STRINGS.MEMBER

        return {
          leagueId: this.leagueId,
          userId: member.user.id,
          name: member.user.displayName,
          email: member.user.email,
          role
        }
      })
    },

    areMatchupsAnnounced() {
      return this.league?.event?.published
    },

    matchupsAnnouncedNotificationOn() {
      return this.authStore.loggedInUser.user.eventBracketPublishNotificationOn
    },

    isEventUpcoming() {
      return this.league?.event?.upcoming
    },

    isEventInProgress() {
      return this.league?.event?.ongoing
    },

    isEventComplete() {
      return this.league?.event?.complete
    },

    myLeagueMember() {
      const result = this.league.members.find((member) => member.user.id === this.authStore.loggedInUser?.user?.id)

      if (result != null) return result

      return {}
    }
  },

  data() {
    return {
      league: {
        members: []
      },

      isLoading: true,
      isRedirectingToOpenTournament: false,

      isCommissioner: false,

      isSharingInviteLink: false,
      loadingInviteLink: false,
      inviteLink: '',

      leagueMembersColumns: [
        {
          label: 'NAME',
          prop: 'name',
          sortDirection: ''
        },
        {
          label: 'EMAIL',
          prop: 'email',
          sortDirection: ''
        },
        {
          label: 'ROLE',
          prop: 'role',
          sortDirection: ''
        }
      ],

      assignNewCommissionerModalData: {},
      assignNewCommissionerModalShowing: false,

      removeFromLeagueModalUserData: {},
      removeFromLeagueModalShowing: false,

      deleteLeagueModalShowing: false,
      leaveLeagueModalShowing: false,
      editLeagueNameModalShowing: false,

      regenerateInviteModal: {
        isShowing: false
      }
    }
  },

  mounted() {
    PubSub.subscribe('bracket-reseeded', this.reloadPage)
    PubSub.subscribe('event-results-published', this.reloadPage)
    PubSub.subscribe('event-brackets-published', this.reloadPage)
    PubSub.subscribe('league-members-changed', this.reloadPage)
    PubSub.subscribe('user-bracket-updated', this.reloadPage)
    PubSub.subscribe('league-name-changed', this.changeLeagueName)

    window.addEventListener('touchstart', this.close)
  },

  unmounted() {
    try {
      PubSub.unsubscribe('bracket-reseeded', this.reloadPage)
      PubSub.unsubscribe('event-results-published', this.reloadPage)
      PubSub.unsubscribe('event-brackets-published', this.reloadPage)
      PubSub.unsubscribe('league-members-changed', this.reloadPage)
      PubSub.unsubscribe('user-bracket-updated', this.reloadPage)
      PubSub.unsubscribe('league-name-changed', this.changeLeagueName)

      window.removeEventListener('touchstart', this.close)
    } catch {
      //
    }
  },

  methods: {
    changeLeagueName(topic, message) {
      const isMyLeague = message.leagueId != null && this.leagueId == message.leagueId

      if (isMyLeague && message.name != null) {
        this.league.name = message.name
      }
    },

    async loadData() {
      const response = await leagueService.getById(this.leagueId)

      this.league = response.data
      this.isCommissioner = this.league.commissioner
    },

    async reloadPage(topic, message) {
      try {
        const isEventMessage = message.eventId != null
        const isMyEvent = this.league.event.eventId == message.eventId
        const isMyLeague = this.leagueId == message.leagueId
        const isALeagueMessage = message.leagueId != null
        const isALeagueUserMessage = isALeagueMessage && message.userId != null
        const isAUserInMyLeague = this.league.members.some(m => m.user.id == message.userId)

        if (isEventMessage && !isMyEvent) return
        if (isALeagueMessage && !isMyLeague) return
        if (isALeagueUserMessage && !isAUserInMyLeague) return

        await this.loadData()
      } catch {
        // 
      }
    },

    async redirectToOpenTournament() {
      const res = await leagueService.getMyOpenTournamentLeagueIdForEvent(this.league.event.eventId)
      const myOpenTournamentLeagueId = res.data

      // for whatever reason I cannot come up with my open tournament league id
      if (myOpenTournamentLeagueId == null) {
        this.$router.push({ name: 'events' })
      }

      this.$router.push({ name: 'league-details', params: { leagueId: myOpenTournamentLeagueId } })
    },

    async countdownTimeExpires() {
      if (this.league?.event?.ongoing != null) {
        if (!this.league.openTournamentLeague) {
          setTimeout(() => {
            window.location.reload() // if it's not the open tournament, my league id will not change
          }, 3000)
          return
        }

        if (this.league.openTournamentLeague) {
          this.isRedirectingToOpenTournament = true

          setTimeout(async () => {
            await this.redirectToOpenTournament()
          }, 10000) // wait for a little while because the server needs to finish creating the league
        }
      }
    },

    leagueUpdated(league) {
      this.league.name = league.name
    },

    async assignLeagueCommissioner(row) {
      try {
        const response = await leagueService.patchLeagueCommissioner(row.leagueId, row.userId)

        alert('Successfully updated league commissioner')

        this.league.members = response.data.members
        this.isCommissioner = false
      } catch {
        alert('Failed to update league commissioner')
      } finally {
        this.assignNewCommissionerModalData = {}
      }
    },

    async removeFromLeague() {
      try {
        const leagueId = this.removeFromLeagueModalUserData.leagueId
        const userId = this.removeFromLeagueModalUserData.userId

        await leagueService.removeMember(leagueId, userId, true)

        // remove user on frontend
        this.league.members = this.league.members.filter((member) => member.user.id !== userId)
        alert('Successfully removed user')
      } catch {
        alert('Failed to remove user')
      } finally {
        this.removeFromLeagueModalUserData = {}
      }
    },

    showAssignNewCommissionerModal(row) {
      this.assignNewCommissionerModalData = row
      this.assignNewCommissionerModalShowing = true
    },
    showRemoveFromLeagueModal(row) {
      this.removeFromLeagueModalUserData = row
      this.removeFromLeagueModalShowing = true
    },

    regenerateInvite() {
      this.inviteLink = '' // delete existing locally to force a call to get a new invite link
      this.regenerateInviteModal.isShowing = true
    },

    async startSharingInviteLink() {
      this.loadingInviteLink = true

      try {
        if (this.inviteLink === '') {
          const response = await leagueService.getInviteLink(this.leagueId)
          this.inviteLink = response.data
        }

        this.isSharingInviteLink = true
      } catch {
        this.inviteLink = ''
        alert('Something went wrong')
      } finally {
        this.loadingInviteLink = false
      }
    },

    formatDateTime(date) {
      return dateUtils.formatDateTime(date, this.authStore?.loggedInUser?.user?.timeZoneSetting)
    },

    editLeagueName() {
      this.editLeagueNameModalShowing = true
    },

    deleteThisLeague() {
      this.deleteLeagueModalShowing = true
    },

    leaveThisLeague() {
      this.leaveLeagueModalShowing = true
    }
  }
}
</script>
