<template>
  <modal-dialog ref="myModal" v-if="authStore.isLoggedIn">
    <template v-if="!isValidToken" v-slot:modal-title>Invalid or Expired Invite</template>
    <template v-else-if="!isAlreadyAMember" v-slot:modal-title>Join League?</template>
    <template v-else v-slot:modal-title>Go to League</template>

    <template v-if="!isValidToken" v-slot:modal-contents>
      <p>This invite link is invalid or expired.</p>
    </template>
    <template v-else-if="!isAlreadyAMember" v-slot:modal-contents>
      <p>
        Would you like to join <span class="bold">{{ commissionerDisplayName }}</span>'s league, <span
          class="bold">{{ leagueName }}</span> for the {{ eventName }}?
      </p>
    </template>
    <template v-else v-slot:modal-contents>
      <p>
        You are already a member of <span class="bold">{{ commissionerDisplayName }}</span>'s league,
        <span class="bold">{{ leagueName }}</span> for the {{ eventName }}.
      </p>
      <p>Would you like to go to the league's page?</p>
    </template>

    <template v-slot:modal-actions>
      <rebel-button v-if="!isValidToken" type="primary" color="default" text="Ok" @click.prevent="cancel" />
      <rebel-button v-else-if="isValidToken && !isAlreadyAMember" :is-loading="isJoining" type="primary" color="default" text="Join League"
        @click.prevent="confirm"></rebel-button>
      <rebel-button v-else type="primary" color="default" text="Go to League"
        @click.prevent="goToLeagueDetails"></rebel-button>
      <a @click.prevent="cancel" v-if="isValidToken">Cancel</a>
    </template>

  </modal-dialog>
</template>

<script>
import { useAuthenticationStore } from '@/stores/authentication'

import leagueService from '@/services/LeagueService.js'
import RebelButton from '@/components/RebelButton.vue'
import ModalDialog from '@/components/ModalDialog.vue'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore(),
    }
  },

  async created() {
    try {
      const inviteTokenResponse = await leagueService.getAcceptInviteMetadata(this.leagueId, this.inviteToken)
      this.isValidToken = inviteTokenResponse.data.isValidToken
      this.isAlreadyAMember = inviteTokenResponse.data.isAlreadyAMember
      this.eventName = inviteTokenResponse.data.eventName
      this.leagueName = inviteTokenResponse.data.leagueName
      this.commissionerDisplayName = inviteTokenResponse.data.commissionerDisplayName
    } catch {
      this.isValidToken = false
    } finally {
      this.isLoading = false
    }
  },
  components: {
    RebelButton,
    ModalDialog
  },

  data() {
    return {
      isAlreadyAMember: false,
      commissionerDisplayName: '',
      eventName: '',
      leagueName: '',

      isLoading: true,
      isJoining: false,
      isValidToken: true
    }
  },

  props: ['leagueId', 'inviteToken'],

  methods: {
    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    async confirm() {
      this.isJoining = true

      try {
        const response = await leagueService.acceptInvite(this.leagueId, this.inviteToken)
        if (response.data === false) {
          alert('Something went wrong')
          return
        }
      } catch {
        alert('Something went wrong')
        return
      } finally {
        this.isJoining = false
      }

      this.goToLeagueDetails()
    },

    goToLeagueDetails() {
      this.$router.push({ name: 'league-details', params: { leagueId: this.leagueId } })
    }
  }
}
</script>
